import React from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardHeader, Grid } from '@mui/material';
import FormContainer from '../../components/Form/FormContainer/FormContainer';
import FixedAssetsFormHeader from '../../components/Form/FormContainer/FormHeader';
import TextInput from '../../components/Form/FormInputs/TextInput/TextInput';
import FormPartial from '../../components/Form/FormContainer/FormPartial';
import DatacorLogoSpinner from '../../components/DatacorLogoSpinner/DatacorLogoSpinner';
import useBusinessEntityForm from './hooks/useBusinessEntityForm';
import ExpandableDropdown from '../../components/Form/ExpandableDropdown/ExpandableDropdown';
import SelectInput from '../../components/Form/FormInputs/SelectInput/SelectInput';

interface ParamTypes {
    id: string;
}

const BusinessEntityForm = () => {
    const { id } = useParams<ParamTypes>();
    const {
        fields,
        isLoadingBusinessEntityTypeOptions,
        businessEntityTypeOptions,
        businessEntityOptions,
        handleClose,
        handleFieldChange,
        handleUpdateBusinessEntity,
        handleCreateBusinessEntity,
        isLoading,
        setValues,
        formMethods,
    } = useBusinessEntityForm(id);

    return (
        <Card>
            <CardHeader
                title={id ? fields.name : 'Create New Business Entity'}
            />
            {isLoading ? (
                <DatacorLogoSpinner />
            ) : (
                <CardContent>
                    <FormContainer
                        headerTitle={`Primary Information`}
                        onSubmit={() => formMethods.setIsFormSubmitted(true)}
                        headerComponent={
                            <FixedAssetsFormHeader
                                showOnDelete={false}
                                showOnSave={true}
                                onSave={async () => {
                                    if (!id) {
                                        await handleCreateBusinessEntity();
                                    } else {
                                        await handleUpdateBusinessEntity();
                                    }
                                }}
                                onDelete={handleClose}
                                onCancel={handleClose}
                                isEdit={!!id}
                            />
                        }>
                        <FormPartial>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextInput
                                        fullWidth
                                        label="Name"
                                        id="name"
                                        value={fields.name}
                                        formMethods={formMethods}
                                        onChange={handleFieldChange}
                                        required={true}
                                        maxLength={90}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextInput
                                        fullWidth
                                        label="Description"
                                        id="description"
                                        value={fields.description}
                                        onChange={handleFieldChange}
                                        maxLength={300}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <SelectInput
                                        fullWidth
                                        label="Type"
                                        required={true}
                                        formMethods={formMethods}
                                        id="businessEntityTypeId"
                                        value={fields.businessEntityTypeId}
                                        options={businessEntityTypeOptions}
                                        disabled={
                                            isLoadingBusinessEntityTypeOptions
                                        }
                                        onChange={handleFieldChange}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <ExpandableDropdown
                                        valueSelected={(value) =>
                                            setValues({
                                                parentId: value,
                                            })
                                        }
                                        defaultValue={fields.parentId}
                                        items={businessEntityOptions}
                                        idKey="id"
                                        labelKey="name"
                                        label="Parent Business Entity"
                                        id="parentId"
                                        formMethods={formMethods}
                                        disabled={!!id}
                                    />
                                </Grid>
                            </Grid>
                        </FormPartial>
                    </FormContainer>
                </CardContent>
            )}
        </Card>
    );
};

export default BusinessEntityForm;
