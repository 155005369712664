import React, { useEffect, useState } from 'react';
import {
    useGetGeneralLedgerAccountQuery,
    useCreateGeneralLedgerAccountMutation,
    useUpdateGeneralLedgerAccountMutation,
    useDeleteGeneralLedgerAccountMutation,
} from '../../../services/accounting/accounting.service';
import { skipToken } from '@reduxjs/toolkit/query';
import { SelectionOption } from '../../../types/shared.types';
import { GeneralLedgerAccount } from '../../../types/Accounting.types';
import useBaseForm from '../../Form/hooks/useBaseForm';
import { isNilOrEmpty } from '../../../utils/objectUtils';

const UseGeneralLedgerAccountForm = (id: string) => {
    const { data: activeAccount, isLoading: isLoadingAccount } =
        useGetGeneralLedgerAccountQuery(id ? id : skipToken);
    const [createGeneralLedgerAccount] =
        useCreateGeneralLedgerAccountMutation();
    const [updateGeneralLedgerAccount] =
        useUpdateGeneralLedgerAccountMutation();
    const [deleteGeneralLedgerAccount] =
        useDeleteGeneralLedgerAccountMutation();

    const statusOptions: SelectionOption[] = [
        { label: 'Active', value: 'Active' },
        { label: 'Inactive', value: 'Inactive' },
    ];

    const balanceTypeOptions: SelectionOption[] = [
        { label: 'Credit', value: 'Credit' },
        { label: 'Debit', value: 'Debit' },
    ];

    const accountTypeOptions: SelectionOption[] = [
        { label: 'Asset', value: 'Asset' },
        { label: 'Equity', value: 'Equity' },
        { label: 'Expense', value: 'Expense' },
        { label: 'Liability', value: 'Liability' },
        { label: 'Revenue', value: 'Revenue' },
    ];

    const blankAccount: GeneralLedgerAccount = {
        number: '',
        name: '',
        description: '',
        status: 'Active',
        balanceType: '',
        accountType: '',
    };

    const hasFunctions = (): boolean => {
        return !isNilOrEmpty(activeAccount?.functions);
    };

    const {
        fields,
        handleFieldChange,
        formMethods,
        toastProps,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
    } = useBaseForm({
        closePath: '/accounting/generalLedgerAccount',
        blankEntity: blankAccount,
        activeEntity: activeAccount,
        createEntity: () => {
            return createGeneralLedgerAccount({ postBody: fields });
        },
        updateEntity: () => {
            return updateGeneralLedgerAccount({
                id: id as unknown as number,
                postBody: fields,
            });
        },
        deleteEntity: () => {
            return deleteGeneralLedgerAccount(id);
        },
    });

    return {
        fields,
        formMethods,
        statusOptions,
        balanceTypeOptions,
        accountTypeOptions,
        toastProps,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        handleFieldChange,
        isLoadingAccount,
        activeAccount,
        hasFunctions,
    };
};

export default UseGeneralLedgerAccountForm;
