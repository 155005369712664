import React, { useEffect, useState } from 'react';
import {
    useCreateFixedAssetMutation,
    useGetAllCostCodesQuery,
    useGetFixedAssetQuery,
    useGetStatusesForDropdownQuery,
    useGetTypesForDropdownQuery,
    useUpdateFixedAssetMutation,
} from '../../../services/fixedAssets/fixedAssets.service';
import { skipToken } from '@reduxjs/toolkit/query';
import {
    FixedAsset,
    FixedAssetCost,
    FixedAssetCostCode,
} from '../../../types/FixedAsset.types';
import { SelectionOption } from '../../../types/shared.types';
import _ from 'lodash';
import useBaseForm from '../../Form/hooks/useBaseForm';
import { useGetAllContractsQuery } from '../../../services/accounting/accounting.service';
import { Contract } from '../../../types/Accounting.types';

const UseFixedAssetForm = (id: string) => {
    const [isLoadingCostWorksheet, setIsLoadingCostWorksheet] =
        useState<boolean>(true);
    const [costWorksheet, setCostWorksheet] = useState(null);

    const [selectedContracts, setSelectedContracts] = useState<Contract[]>([]);
    const [showContractSelectionModal, setShowContractSelectionModal] =
        useState(false);

    const { data: activeAsset, isLoading: isLoadingAsset } =
        useGetFixedAssetQuery(id ? id : skipToken);
    const [createAsset] = useCreateFixedAssetMutation();
    const [updateAsset] = useUpdateFixedAssetMutation();

    const { data: statusOptions, isLoading: isLoadingStatuses } =
        useGetStatusesForDropdownQuery();
    const { data: typeOptions, isLoading: isLoadingTypes } =
        useGetTypesForDropdownQuery();

    const { data: costCodeList, isLoading: isLoadingCostCodes } =
        useGetAllCostCodesQuery();

    const { data: contractList, isLoading: isLoadingContracts } =
        useGetAllContractsQuery();

    const testBuildingOptions: SelectionOption[] = [
        { value: 1, label: 'Florham Park' },
        { value: 2, label: 'Las Vegas' },
        { value: 3, label: 'San Diego' },
    ];

    const blankAsset: FixedAsset = {
        number: null,
        code: '',
        name: '',
        description: '',
        statusId: null,
        acquireDate: null,
        inServiceDate: null,
        disposalDate: null,
        ledgerTypeId: null,
        taxTypeId: null,
        buildingId: null,
        manufacturerSerialNumber: '',
        purchaseOrderNumber: '',
        supplierInvoiceNumber: '',
        capitalProjectNumber: '',
        contractList: [],
    };

    const {
        fields,
        handleFieldChange,
        formMethods,
        toastProps,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
    } = useBaseForm({
        closePath: '/accounting/fixedAssets/fixedAsset',
        blankEntity: blankAsset,
        activeEntity: activeAsset,
        createEntity: () => {
            return createAsset({
                postBody: buildPostBody(),
            });
        },
        updateEntity: () => {
            return updateAsset({
                id: id as unknown as number,
                postBody: buildPostBody(),
            });
        },
    });

    const buildPostBody = () => {
        return {
            ...fields,
            costs: costWorksheet,
            contracts: selectedContracts?.map((contract: Contract) => {
                return { contractId: contract.id };
            }),
        };
    };

    useEffect(() => {
        setSelectedContracts(activeAsset?.contractList || []);
    }, [activeAsset]);

    useEffect(() => {
        setIsLoadingCostWorksheet(true);

        if (!isLoadingCostCodes && costCodeList) {
            const costWorksheet: FixedAssetCost[] = _.sortBy(costCodeList, [
                'sortOrder',
            ]).map((costCode: FixedAssetCostCode) => {
                const existingAssetCost: FixedAssetCost =
                    activeAsset?.costs?.find(
                        (cost: FixedAssetCost) =>
                            cost.costCodeId === costCode.id
                    );

                return existingAssetCost
                    ? { ...existingAssetCost }
                    : {
                          fixedAssetId: activeAsset?.id,
                          costCodeId: costCode.id,
                          amount: 0,
                          costCode: costCode,
                      };
            });

            setCostWorksheet(costWorksheet);
            setIsLoadingCostWorksheet(false);
        }
    }, [costCodeList, activeAsset]);

    const updateDateField = (id: string, date: Date): void => {
        handleFieldChange({
            target: {
                id: id,
                value: date,
            },
        });
    };

    return {
        fields,
        handleFieldChange,
        formMethods,
        toastProps,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        isLoadingAsset,
        isLoadingStatuses,
        isLoadingTypes,
        isLoadingCostWorksheet,
        isLoadingContracts,
        costWorksheet,
        statusOptions,
        typeOptions,
        testBuildingOptions,
        contractList,
        updateDateField,
        showContractSelectionModal,
        setShowContractSelectionModal,
        selectedContracts,
        setSelectedContracts,
    };
};

export default UseFixedAssetForm;
