import { Auth } from 'aws-amplify';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import config from '../../config';
import {
    BaseCreateEntityArgs,
    BaseUpdateEntityArgs,
} from '../serviceInterfaces';
import * as _ from 'lodash';

export const organizationsApi = createApi({
    reducerPath: 'organizationsCoreApi',

    baseQuery: fetchBaseQuery({
        baseUrl: config.apiGateway.URL,
        prepareHeaders: async (headers, { getState }) => {
            const token = (await Auth.currentSession())
                .getIdToken()
                .getJwtToken();
            // If we have a token set in state, let's assume that we should be passing it.
            if (token) {
                headers.set('Authorization', token);
            }

            return headers;
        },
    }),

    tagTypes: [
        'Subscribers',
        'BusinessEntities',
        'BusinessEntityTypes',
        'BusinessEntityUsers',
        'BusinessEntityRoles',
        'BusinessEntityGroups',
        'BusinessEntityPermissions',
        'MessagingPreferences',
        'DepartmentCodes',
        'Buildings',
    ],
    endpoints: (build) => ({
        createSubscriber: build.mutation<any, BaseCreateEntityArgs<Subscriber>>(
            {
                query: (args) => ({
                    url: 'core-entities/subscribers',
                    method: 'POST',
                    body: args.postBody,
                }),
                invalidatesTags: ['Subscribers'],
            }
        ),
        updateSubscriber: build.mutation<any, BaseUpdateEntityArgs<Subscriber>>(
            {
                query: (args) => ({
                    url: `core-entities/subscribers/${args.id}`,
                    method: 'PUT',
                    body: args.postBody,
                }),
                invalidatesTags: ['Subscribers'],
            }
        ),
        getSubscribers: build.query<any, void>({
            query: () => `core-entities/subscribers`,
            providesTags: ['Subscribers'],
        }),
        getSubscribersForDropdown: build.query<any, void>({
            query: () => `core-entities/subscribers`,
            transformResponse: (response: BusinessEntityType[]) => {
                return response.map((subscriber) => {
                    return {
                        value: subscriber.id,
                        label: subscriber.name,
                    };
                });
            },
            providesTags: ['Subscribers'],
        }),
        getSubscriber: build.query<any, string>({
            query: (id) => `core-entities/subscribers/${id}`,
            providesTags: [],
        }),
        getDeparmentCodesForDropdown: build.query<any, any>({
            query: (args) => {
                return {
                    url: `core-entities/subscribers/${args.id}`,
                    params: { full: 'true' },
                };
            },
            transformResponse: (response: Subscriber) => {
                return response.departmentCodes.map((dep) => {
                    return { value: dep.id, label: dep.name };
                });
            },
            providesTags: [],
        }),
        createBusinessEntity: build.mutation<
            any,
            BaseCreateEntityArgs<BusinessEntity>
        >({
            query: (args) => ({
                url: 'core-entities/business-entities',
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['BusinessEntities'],
        }),
        updateBusinessEntity: build.mutation<
            any,
            BaseUpdateEntityArgs<BusinessEntity>
        >({
            query: (args) => ({
                url: `core-entities/business-entities/${args.id}`,
                method: 'PUT',
                body: args.postBody,
            }),
            invalidatesTags: ['BusinessEntities'],
        }),
        getBusinessEntities: build.query<any, string>({
            query: (subscriberId) => {
                return {
                    url: `core-entities/business-entities`,
                    params: { subscriberId: subscriberId },
                };
            },
            providesTags: ['BusinessEntities'],
        }),
        getBusinessEntityChildren: build.query<any, string>({
            query: (id) => {
                return {
                    url: `core-entities/business-entities/${id}`,
                    params: { full: 'true' },
                };
            },
            transformResponse: (response: BusinessEntity) => {
                return response ? response.children : [];
            },
            providesTags: [],
        }),
        getBusinessEntity: build.query<any, string>({
            query: (id) => `core-entities/business-entities/${id}`,
            providesTags: ['BusinessEntities'],
        }),

        getBusinessEntityTypes: build.query<any, string>({
            query: (businessEntityId) => {
                return {
                    url: `core-entities/businessEntityTypes`,
                    params: { businessEntityId: businessEntityId },
                };
            },
            providesTags: ['BusinessEntityTypes'],
        }),
        getBusinessEntityTypesForDropdown: build.query<any, void>({
            query: () => `core-entities/business-entity-types`,
            transformResponse: (response: BusinessEntityType[]) => {
                return response.map((businessEntityType) => {
                    return {
                        value: businessEntityType.id,
                        label: businessEntityType.name,
                    };
                });
            },
            providesTags: ['BusinessEntityTypes'],
        }),
        getBusinessEntityType: build.query<any, any>({
            query: (args) => {
                return {
                    url: `core-entities/businessEntityTypes/${args.businessEntityTypeId}`,
                    params: args.full ? { full: args.full } : null,
                };
            },
            providesTags: [],
        }),
        createBusinessEntityType: build.mutation<
            any,
            BaseCreateEntityArgs<BusinessEntityType>
        >({
            query: (args) => ({
                url: 'core-entities/businessEntityTypes',
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['BusinessEntityTypes'],
        }),
        updateBusinessEntityType: build.mutation<
            any,
            BaseUpdateEntityArgs<BusinessEntityType>
        >({
            query: (args) => ({
                url: `core-entities/businessEntityTypes/${args.id}`,
                method: 'PUT',
                body: args.postBody,
            }),
            invalidatesTags: ['BusinessEntityTypes'],
        }),

        getUsers: build.query<any, string>({
            query: (businessEntityId) => {
                return {
                    url: `core-entities/users`,
                    params: { businessEntityId: businessEntityId },
                };
            },
            providesTags: ['BusinessEntityUsers'],
        }),
        getUsersForDropdown: build.query<any, string>({
            query: (businessEntityId) => {
                return {
                    url: `core-entities/users`,
                    params: { businessEntityId: businessEntityId },
                };
            },
            transformResponse: (response: User[]) => {
                return response.map((user) => {
                    return {
                        value: user.id,
                        label: `${user.lastName}, ${user.firstName}`,
                    };
                });
            },
            providesTags: ['BusinessEntityUsers'],
        }),
        getUser: build.query<any, any>({
            query: (args) => {
                return {
                    url: `core-entities/users/${args.userId}`,
                    params: args.full ? { full: args.full } : null,
                };
            },
            transformResponse: (response: User): any => {
                const transformedUser: any = response;
                if (response.groups) {
                    transformedUser.groups = _.map(response.groups, 'id');
                }

                if (response.roles) {
                    transformedUser.roles = _.map(response.roles, 'id');
                }

                return transformedUser;
            },
            providesTags: ['BusinessEntityUsers'],
        }),
        getUserDetails: build.query<any, void>({
            query: () => {
                return {
                    url: `core-entities/users/userDetails`,
                };
            },
            transformResponse: (response: User): any => {
                const transformedUser: any = response;
                if (response.groups) {
                    transformedUser.groups = _.map(response.groups, 'id');
                }

                if (response.roles) {
                    transformedUser.roles = _.map(response.roles, 'id');
                }

                return transformedUser;
            },
            providesTags: ['BusinessEntityUsers'],
        }),
        createUser: build.mutation<any, BaseCreateEntityArgs<User>>({
            query: (args) => ({
                url: 'core-entities/users',
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['BusinessEntityUsers'],
        }),
        updateUser: build.mutation<any, BaseUpdateEntityArgs<User>>({
            query: (args) => ({
                url: `core-entities/users/${args.id}`,
                method: 'PUT',
                body: args.postBody,
            }),
            invalidatesTags: ['BusinessEntityUsers'],
        }),
        getGroups: build.query<any, string>({
            query: (businessEntityId) => {
                return {
                    url: `core-entities/groups`,
                    params: { businessEntityId: businessEntityId },
                };
            },
            providesTags: ['BusinessEntityGroups'],
        }),
        getGroupsForDropdown: build.query<any, string>({
            query: (businessEntityId) => {
                return {
                    url: `core-entities/groups`,
                    params: { businessEntityId: businessEntityId },
                };
            },
            transformResponse: (response: Group[]) => {
                return response.map((group) => {
                    return { value: group.id, label: group.name };
                });
            },
            providesTags: ['BusinessEntityGroups'],
        }),
        getGroup: build.query<any, any>({
            query: (args) => {
                return {
                    url: `core-entities/groups/${args.groupId}`,
                    params: args.full ? { full: args.full } : null,
                };
            },
            transformResponse: (response: Group): any => {
                const transformedUser: any = response;
                if (response.users) {
                    transformedUser.users = _.map(response.users, 'id');
                }

                return transformedUser;
            },
            providesTags: ['BusinessEntityGroups'],
        }),
        createGroup: build.mutation<any, BaseCreateEntityArgs<Group>>({
            query: (args) => ({
                url: 'core-entities/groups',
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['BusinessEntityGroups'],
        }),
        updateGroup: build.mutation<any, BaseUpdateEntityArgs<Group>>({
            query: (args) => ({
                url: `core-entities/groups/${args.id}`,
                method: 'PUT',
                body: args.postBody,
            }),
            invalidatesTags: ['BusinessEntityGroups'],
        }),
        getRoles: build.query<any, string>({
            query: (businessEntityId) => {
                return {
                    url: `core-entities/roles`,
                    params: { businessEntityId: businessEntityId },
                };
            },
            providesTags: ['BusinessEntityRoles'],
        }),
        getRolesForDropdown: build.query<any, string>({
            query: (businessEntityId) => {
                return {
                    url: `core-entities/roles`,
                    params: { businessEntityId: businessEntityId },
                };
            },
            transformResponse: (response: Role[]) => {
                return response.map((role) => {
                    return { value: role.id, label: role.name };
                });
            },
            providesTags: ['BusinessEntityRoles'],
        }),
        getRole: build.query<any, any>({
            query: (args) => {
                return {
                    url: `core-entities/roles/${args.roleId}`,
                    params: args.full ? { full: args.full } : null,
                };
            },
            transformResponse: (response: Role): any => {
                const transformedUser: any = response;
                if (response.users) {
                    transformedUser.users = _.map(response.users, 'id');
                }

                if (response.permissions) {
                    transformedUser.permissions = _.map(
                        response.permissions,
                        'id'
                    );
                }

                return transformedUser;
            },
            providesTags: ['BusinessEntityRoles'],
        }),
        createRole: build.mutation<any, BaseCreateEntityArgs<Role>>({
            query: (args) => ({
                url: 'core-entities/roles',
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['BusinessEntityRoles'],
        }),
        updateRole: build.mutation<any, BaseUpdateEntityArgs<Role>>({
            query: (args) => ({
                url: `core-entities/roles/${args.id}`,
                method: 'PUT',
                body: args.postBody,
            }),
            invalidatesTags: ['BusinessEntityRoles'],
        }),
        getPermissions: build.query<any, string>({
            query: (businessEntityId) => {
                return {
                    url: `core-entities/permissions`,
                    params: { businessEntityId: businessEntityId },
                };
            },
            providesTags: ['BusinessEntityPermissions'],
        }),
        getPermissionsForDropdown: build.query<any, string>({
            query: (businessEntityId) => {
                return {
                    url: `core-entities/permissions`,
                    params: { businessEntityId: businessEntityId },
                };
            },
            transformResponse: (response: Permission[]) => {
                return response.map((permission) => {
                    return { value: permission.id, label: permission.name };
                });
            },
            providesTags: ['BusinessEntityPermissions'],
        }),
        getPermission: build.query<any, any>({
            query: (args) => {
                return {
                    url: `core-entities/permissions/${args.permissionId}`,
                    params: args.full ? { full: args.full } : null,
                };
            },
            providesTags: [],
        }),
        createPermission: build.mutation<any, BaseCreateEntityArgs<Permission>>(
            {
                query: (args) => ({
                    url: 'core-entities/permissions',
                    method: 'POST',
                    body: args.postBody,
                }),
                invalidatesTags: ['BusinessEntityPermissions'],
            }
        ),
        updatePermission: build.mutation<any, BaseUpdateEntityArgs<Permission>>(
            {
                query: (args) => ({
                    url: `core-entities/permissions/${args.id}`,
                    method: 'PUT',
                    body: args.postBody,
                }),
                invalidatesTags: ['BusinessEntityPermissions'],
            }
        ),
        getDepartmentCodes: build.query<any, string>({
            query: (subscriberId) => {
                return {
                    url: `core-entities/department-codes`,
                    params: { subscriberId: subscriberId },
                };
            },
            providesTags: ['DepartmentCodes'],
        }),
        getDepartmentCodesForDropdown: build.query<any, string>({
            query: (subscriberId) => {
                return {
                    url: `core-entities/department-codes`,
                    params: { subscriberId: subscriberId },
                };
            },
            transformResponse: (response: DepartmentCode[]) => {
                return response.map((permission) => {
                    return { value: permission.id, label: permission.name };
                });
            },
            providesTags: ['DepartmentCodes'],
        }),
        getDepartmentCode: build.query<any, any>({
            query: (args) => {
                return {
                    url: `core-entities/department-codes/${args.departmentCodeId}`,
                    params: args.full ? { full: args.full } : null,
                };
            },
            providesTags: [],
        }),
        createDepartmentCode: build.mutation<
            any,
            BaseCreateEntityArgs<DepartmentCode>
        >({
            query: (args) => ({
                url: 'core-entities/department-codes',
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['DepartmentCodes'],
        }),
        updateDepartmentCode: build.mutation<
            any,
            BaseUpdateEntityArgs<DepartmentCode>
        >({
            query: (args) => ({
                url: `core-entities/permissions/${args.id}`,
                method: 'PUT',
                body: args.postBody,
            }),
            invalidatesTags: ['DepartmentCodes'],
        }),
        getMessagingPreferencesForDropdown: build.query<any, void>({
            query: () => `core-entities/messaging-preferences`,
            transformResponse: (response: MessagingPreference[]) => {
                return response.map((preference) => {
                    return { value: preference.id, label: preference.name };
                });
            },
            providesTags: ['MessagingPreferences'],
        }),
        getBuildings: build.query<any, any>({
            query: (id) => {
                return {
                    url: `core-entities/buildings`,
                    params: { businessEntityId: id },
                };
            },
            providesTags: ['Buildings'],
        }),
        getBuilding: build.query<any, any>({
            query: (id) => {
                return {
                    url: `core-entities/buildings/${id}`,
                };
            },
            providesTags: ['Buildings'],
        }),
        createBuilding: build.mutation<any, BaseCreateEntityArgs<Building>>({
            query: (args) => {
                return {
                    url: `core-entities/buildings`,
                    method: 'POST',
                    body: args.postBody,
                };
            },
            invalidatesTags: ['Buildings'],
        }),
        updateBuilding: build.mutation<any, BaseUpdateEntityArgs<Building>>({
            query: (args) => {
                return {
                    url: `core-entities/buildings/${args.id}`,
                    method: 'PUT',
                    body: args.postBody,
                };
            },
            invalidatesTags: ['Buildings'],
        }),
        getCountries: build.query<any, any>({
            query: () => {
                return {
                    url: `core-entities/address/countries`,
                };
            },
            providesTags: ['Buildings'],
        }),
        getCountriesForDropdown: build.query<any, void>({
            query: () => {
                return {
                    url: `core-entities/address/countries`,
                };
            },
            transformResponse: (response: CountryCode[]) => {
                return response.map((country) => {
                    return {
                        value: country.id,
                        label: country.name,
                    };
                });
            },
            providesTags: ['Buildings'],
        }),
        getStates: build.query<any, void>({
            query: () => {
                return {
                    url: `core-entities/address/states`,
                };
            },
            providesTags: ['Buildings'],
        }),
        getStatesForDropdown: build.query<any, void>({
            query: () => {
                return {
                    url: `core-entities/address/states`,
                };
            },
            transformResponse: (response: StateCode[]) => {
                return response.map((state) => {
                    return {
                        value: state.id,
                        label: state.code + ' - ' + state.name,
                    };
                });
            },
            providesTags: ['Buildings'],
        }),
    }),
});

export const {
    useCreateSubscriberMutation,
    useUpdateSubscriberMutation,
    useGetSubscribersQuery,
    useGetSubscriberQuery,
    useGetSubscribersForDropdownQuery,
    useGetDeparmentCodesForDropdownQuery,
    useCreateBusinessEntityMutation,
    useUpdateBusinessEntityMutation,
    useGetBusinessEntitiesQuery,
    useGetBusinessEntityChildrenQuery,
    useGetBusinessEntityQuery,
    useGetBusinessEntityTypesQuery,
    useGetBusinessEntityTypesForDropdownQuery,
    useGetBusinessEntityTypeQuery,
    useCreateBusinessEntityTypeMutation,
    useUpdateBusinessEntityTypeMutation,
    useGetUsersQuery,
    useGetUsersForDropdownQuery,
    useGetUserQuery,
    useGetUserDetailsQuery,
    useCreateUserMutation,
    useUpdateUserMutation,
    useGetGroupsQuery,
    useGetGroupsForDropdownQuery,
    useGetGroupQuery,
    useCreateGroupMutation,
    useUpdateGroupMutation,
    useGetRolesQuery,
    useGetRolesForDropdownQuery,
    useGetRoleQuery,
    useCreateRoleMutation,
    useUpdateRoleMutation,
    useGetPermissionsQuery,
    useGetPermissionsForDropdownQuery,
    useGetPermissionQuery,
    useCreatePermissionMutation,
    useUpdatePermissionMutation,
    useGetDepartmentCodesQuery,
    useGetDepartmentCodesForDropdownQuery,
    useGetDepartmentCodeQuery,
    useCreateDepartmentCodeMutation,
    useUpdateDepartmentCodeMutation,
    useGetMessagingPreferencesForDropdownQuery,
    useGetBuildingsQuery,
    useGetBuildingQuery,
    useUpdateBuildingMutation,
    useCreateBuildingMutation,
    useGetCountriesQuery,
    useGetStatesQuery,
    useGetCountriesForDropdownQuery,
    useGetStatesForDropdownQuery,
} = organizationsApi;

export interface DepartmentCode {
    id?: bigint;
    uuid?: string;
    name: string;
    code: string;
    subscriberId: bigint;
    createdAt?: string;
    updatedAt?: string;
}

export interface Building {
    id?: bigint;
    uuid?: string;
    address: Address;
    addressId: bigint;
    businessEntityId: bigint;
    code: string;
    createdAt?: string;
    updatedAt?: string;
    customerId?: bigint;
    name?: string;
    note?: string;
    supplierId?: bigint;
}

export interface Address {
    id?: bigint;
    uuid?: string;
    countryCode: CountryCode;
    countryCodeId: bigint;
    createdAt?: string;
    updatedAt?: string;
    postalCode: string;
    stateCode: StateCode;
    stateCodeId: bigint;
    street: string;
    city: string;
    county?: string;
}

export interface CountryCode {
    id?: bigint;
    code2?: string;
    code3?: string;
    createdAt?: string;
    updatedAt?: string;
    iso?: string;
    longName?: string;
    name: string;
    numericCode?: string;
}

export interface StateCode {
    id?: bigint;
    uuid?: string;
    category: string;
    code?: string;
    code2?: string;
    countryCodeId?: bigint;
    createdAt?: string;
    updatedAt?: string;
    name: string;
}

export interface Subscriber {
    id: bigint;
    name: string;
    uuid: string;
    description: string;
    departmentCodes?: DepartmentCode[];
    createdAt: string;
    updatedAt: string;
}

export interface BusinessEntityType {
    id: bigint;
    uuid: string;
    name: string;
}

export interface BusinessEntity {
    id: bigint;
    parentId: bigint;
    businessEntityTypeId: bigint;
    subscriberId: bigint;
    subscriber: Subscriber;
    children: BusinessEntity[];
    name: string;
    uuid: string;
    description: string;
    businessEntityType?: BusinessEntityType;
    createdAt: string;
    updatedAt: string;
}

export interface Permission {
    id: bigint;
    uuid: string;
    datacorOnly: boolean;
    name: string;
    code: string;
}

export interface Group {
    id?: bigint;
    uuid?: string;
    name: string;
    description: string;
    businessEntityId?: bigint;
    users?: User[];
}

export interface Role {
    id?: bigint;
    uuid?: string;
    name: string;
    description: string;
    businessEntityId?: bigint;
    users?: User[];
    permissions?: Permission[];
}

export interface User {
    id?: bigint;
    active?: boolean;
    email: string;
    firstName: string;
    middleName: string;
    lastName: string;
    mobilePhone: string;
    businessEntityId?: bigint;
    businessEntity?: BusinessEntity;
    subscriberId?: bigint;
    messagingPrefId: bigint;
    groups?: Group[];
    roles?: Role[];
    isDatacorAdmin: boolean;
    isAppStreamUser: boolean;
}

export interface Employee {
    id?: bigint;
    departmentCodeId: bigint;
    supervisorId: bigint;
    userId: string;
}

export interface MessagingPreference {
    id: bigint;
    name: string;
}
