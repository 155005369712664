export const PERMISSIONS = {
    SETTINGS: {
        FEATURE_FLAGS: {
            VIEW: 'permission.settings.featureFlag.view',
            EDIT: 'permission.settings.featureFlag.edit',
        },
    },
    USER_PORTAL: {
        VIEW: 'permission.userPortal.view',
        USERS: {
            VIEW: 'permission.userPortal.users.view',
            CREATE: 'permission.userPortal.users.create',
            EDIT: 'permission.userPortal.users.edit',
            DELETE: 'permission.userPortal.users.delete',
        },
        COMPANIES: {
            VIEW: 'permission.userPortal.companies.view',
            CREATE: 'permission.userPortal.companies.create',
            EDIT: 'permission.userPortal.companies.edit',
            DELETE: 'permission.userPortal.companies.delete',
        },
    },
    AP_AUTOMATION: {
        VIEW: 'permission.apAutomation.view',
        INVOICES: {
            UPDATE: 'permission.apAutomation.updateInvoice',
        },
        APPROVAL_WORKFLOWS: {
            VIEW: 'permission.apAutomation.approvalWorkflows.view',
            CREATE: 'permission.apAutomation.approvalWorkflows.create',
            DELETE: 'permission.apAutomation.approvalWorkflows.delete',
            UPDATE: 'permission.apAutomation.approvalWorkflows.update',
        },
        DEFAULT_TOLERANCE: {
            VIEW: 'permission.apAutomation.defaultTolerance.view',
            EDIT: 'permission.apAutomation.defaultTolerance.edit',
        },
        MANUAL_MATCH: 'permission.apAutomation.manualPoMatching',
    },
};
