import React, { useState, useEffect } from 'react';
import { isNil, isNilOrEmpty } from '../utils/objectUtils';
import { Validator } from '../validators/validator.interface';
import _ from 'lodash';

// custom React hook.
export function useFormFields(initialState: any) {
    const [fields, setValues] = useState(initialState);

    //pulling this out into a method because I believe as we move forward with more
    //complicated forms there will be more custom logic to consider.
    const getValue = (target: any) => {
        switch (target.type?.toUpperCase()) {
            case 'CHECKBOX':
                return target.checked;
            default:
                return target.value;
        }
    };

    const setMultipleValues = (newValue: any) => {
        setValues({
            ...fields,
            ...newValue,
        });
    };

    return [
        fields,
        function (event: React.ChangeEvent<HTMLInputElement>) {
            setValues({
                ...fields,
                [event.target.id]: getValue(event.target),
            });
        },
        setMultipleValues,
    ];
}

export function useForm(initialState: any) {
    const [fields, setFields] = useState(initialState);
    const [validators, setValidators] = useState(new Map());
    const [isFormValid, setIsFormValid] = useState(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [fieldValidationErrors, setFieldValidationErrors] = useState(
        new Map()
    );

    useEffect(() => {
        validate();
    }, [fields]);

    const setValues = (values: any) => {
        setFields({
            ...fields,
            ...values,
        });
    };

    const addValidator = (
        fieldName: string,
        fieldLabel: string,
        validator: Validator
    ) => {
        const fieldValidator = {
            key: validator.key,
            fieldLabel: fieldLabel,
            validator: validator,
        };

        const existingValidators = validators.get(fieldName);
        if (!isNilOrEmpty(existingValidators)) {
            existingValidators.push(fieldValidator);
        } else {
            setValidators(new Map(validators.set(fieldName, [fieldValidator])));
        }
    };

    const removeValidator = (field: string, validator: Validator) => {
        const existingValidators = validators.get(field);

        if (!isNil(existingValidators)) {
            _.remove(existingValidators, (fieldValidator: any) => {
                return fieldValidator.validator.key === validator.key;
            });
        }
    };

    const validate = () => {
        const validationErrorsMap = new Map();
        let hasErrors = false;

        Object.keys(fields).forEach((fieldName) => {
            const fieldValidators = validators.get(fieldName);
            fieldValidators?.forEach((fieldValidator: any) => {
                //If field is invalid for a given validator
                if (!fieldValidator.validator.isValid(fields[fieldName])) {
                    //add the validation error to the fieldValidationErrors
                    const fieldErrors = validationErrorsMap.get(fieldName)
                        ? validationErrorsMap.get(fieldName)
                        : [];
                    fieldErrors.push(
                        fieldValidator.validator.getErrorMessage(
                            fieldValidator.fieldLabel
                        )
                    );
                    validationErrorsMap.set(fieldName, fieldErrors);
                    hasErrors = true;
                }
            });
        });

        setIsFormValid(!hasErrors);
        setFieldValidationErrors(
            validationErrorsMap.size !== 0 ? new Map(validationErrorsMap) : null
        );
    };

    const updateField = (fieldName: string, value: any) => {
        setValues({ [fieldName]: value });
    };

    const getValue = (target: any) => {
        switch (target.type?.toUpperCase()) {
            case 'CHECKBOX':
                return target.checked;
            default:
                return target.value;
        }
    };

    const handleFieldChange = (event: any) => {
        setFields({
            ...fields,
            [event.target.id]: getValue(event.target),
        });
    };

    return {
        formMethods: {
            addValidator,
            removeValidator,
            validate,
            updateField,
            isFormSubmitted,
            setIsFormSubmitted,
            fieldValidationErrors,
            isFormValid,
        },
        fields,
        setValues,
        handleFieldChange,
        updateField,
        addValidator,
        removeValidator,
        validate,
        isFormValid,
        fieldValidationErrors,
        isFormSubmitted,
        setIsFormSubmitted,
    };
}

export interface FormMethods {
    removeValidator: (field: string, validator: any) => void;
    addValidator: (
        fieldName: string,
        fieldLabel: string,
        validator: Validator
    ) => void;
    updateField: (fieldName: string, value: any) => void;
    isFormSubmitted: boolean;
    setIsFormSubmitted: any;
    isFormValid: boolean;
    fieldValidationErrors: Map<string, string>;
}
