import { useHistory } from 'react-router-dom';
import { useGetAllDepreciationMethodStatusesQuery } from '../../../services/fixedAssets/fixedAssets.service';

const UseFixedAssetDepreciationMethodStatusGrid = () => {
    const history = useHistory();

    const { data: statusList, isLoading: isLoadingStatuses } =
        useGetAllDepreciationMethodStatusesQuery();

    const redirectToForm = (row: any) => {
        if (row.id) {
            history.push(
                `/accounting/fixedAssets/depreciationMethodStatus/${row.id}`
            );
        } else {
            history.push(
                '/accounting/fixedAssets/depreciationMethodStatus/new'
            );
        }
    };

    return {
        statusList,
        redirectToForm,
        isLoadingStatuses,
    };
};

export default UseFixedAssetDepreciationMethodStatusGrid;
