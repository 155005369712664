import React, { ChangeEvent, useEffect, useState } from 'react';
import { isNilOrEmpty } from '../../../utils/objectUtils';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const SelectDropdown = (props: any) => {
    const [value, setValue] = useState('');

    const customStyles = {
        menu: (provided: any) => ({
            ...provided,
            zIndex: '99999',
            menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
        }),
    };

    useEffect(() => {
        if (!isNilOrEmpty(props.options) && props.value != null) {
            setValue(props.value);
        }
    }, [props.value, props.options]);

    useEffect(() => {
        if (props.defaultValue && !isNilOrEmpty(props.options)) {
            const option = props.options.find(
                (option: any) => option.value === props.defaultValue
            );
            setValue(option.value);
            props.onChange(value);
        }
    }, [props.defaultValue]);

    const optionSelected = (selectedOption: any) => {
        setValue(selectedOption.target.value);

        if (props.onChangeEvent) {
            props.onChangeEvent({
                target: { id: props.id, value: selectedOption.target.value },
            } as unknown as ChangeEvent<HTMLInputElement>);
        }

        if (props.onChange) {
            props.onChange(selectedOption.target.value);
        }
    };

    const onMenuClose = () => {
        if (props.onMenuClose) {
            props.onMenuClose();
        }
    };

    return (
        <FormControl size={props.size || 'small'} fullWidth>
            <InputLabel id={props.id + '-label'}>
                {props.label || props.placeholder}
            </InputLabel>
            <Select
                fullWidth
                labelId={props.id + 'label-id'}
                id={props.id}
                value={value || ''}
                label={props.label || props.placeholder}
                onChange={(selectedOption) => optionSelected(selectedOption)}
                renderValue={props.renderValue}>
                {props?.options?.map((option: any) => {
                    return (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
};

export default SelectDropdown;
