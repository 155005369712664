import useBaseForm from '../../Form/hooks/useBaseForm';
import {
    useGetFixedAssetFunctionCodeQuery,
    useUpdateFunctionCodeMutation,
} from '../../../services/accounting/accounting.service';
import { useEffect, useState } from 'react';
import { GridReadyEvent, RowNode } from 'ag-grid-community';
import {
    AccountFunction,
    GeneralLedgerAccount,
} from '../../../types/Accounting.types';

const useFixedAssetFunctionCodesForm = (id: string) => {
    const [gridApi, setGridApi] = useState(null);
    const [isLoadingSelectedAccounts, setIsLoadingSelectedAccounts] =
        useState(false);

    const { data: activeFunctionCode, isLoading } =
        useGetFixedAssetFunctionCodeQuery(id);
    const [updateFunctionCode] = useUpdateFunctionCodeMutation();

    const onGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
    };

    useEffect(() => {
        setIsLoadingSelectedAccounts(true);

        if (activeFunctionCode && gridApi) {
            gridApi.forEachNode((node: RowNode) => {
                const currentAccount: GeneralLedgerAccount = node.data;
                node.setSelected(
                    activeFunctionCode.functions.some(
                        (aFunction: AccountFunction) =>
                            aFunction.accountId === currentAccount.id
                    )
                );
            });
        }

        setIsLoadingSelectedAccounts(false);
    }, [activeFunctionCode, gridApi]);

    const { fields, formMethods, onUpdate, closeForm } = useBaseForm({
        closePath: '/accounting/fixedAssets/functionCode',
        blankEntity: {},
        activeEntity: activeFunctionCode,
        createEntity: undefined,
        updateEntity: () => {
            const functionList: AccountFunction[] = gridApi
                ?.getSelectedRows()
                .map((account: GeneralLedgerAccount) => {
                    return {
                        accountId: account.id,
                        functionCodeId: activeFunctionCode.id,
                    };
                });

            return updateFunctionCode({
                id: id as unknown as number,
                postBody: { ...fields, functions: functionList },
            });
        },
        deleteEntity: undefined,
    });

    return {
        activeFunctionCode,
        fields,
        formMethods,
        onUpdate,
        onGridReady,
        closeForm,
        isLoading,
        isLoadingSelectedAccounts,
    };
};

export default useFixedAssetFunctionCodesForm;
