import React from 'react';
import { Button, Grid } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import LoaderButton from '../../LoaderButton';
import '../../../styles/forms.scss';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';

export interface FormHeaderProps {
    onSave: object;
    showOnSave: boolean;
    onDelete: object;
    showOnDelete: boolean;
    isEdit: boolean;
    onCancel: (event: any) => void;
}

const FixedAssetsFormHeader = (props: FormHeaderProps) => {
    return (
        <Grid
            container
            flexDirection="row"
            spacing={2}
            className="form-header-body">
            <Button
                className="form-header-button"
                variant="text"
                onClick={props.onCancel}>
                <CancelIcon />
                <div className="form-header-button-text">Cancel</div>
            </Button>
            <br />
            {props.showOnSave ? (
                <LoaderButton
                    className="form-header-button"
                    type="submit"
                    variant="text"
                    data-testid="updateUserBtn"
                    onClick={props.onSave}>
                    <SaveIcon style={{ color: '#00A84E' }} />
                    <div className="form-header-button-text">
                        {props.isEdit ? 'Save' : 'Create'}
                    </div>
                </LoaderButton>
            ) : null}
            {props.showOnDelete ? (
                <LoaderButton
                    className="form-header-button"
                    variant="text"
                    data-testid="updateUserBtn"
                    onClick={props.onDelete}>
                    <DeleteIcon style={{ color: '#FF0000' }} />
                    <div className="form-header-button-text">Delete</div>
                </LoaderButton>
            ) : null}
        </Grid>
    );
};

export default FixedAssetsFormHeader;
