import { Auth } from 'aws-amplify';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import config from '../../config';
import {
    FixedAsset,
    FixedAssetCostCode,
    FixedAssetType,
    FixedAssetDepreciationMethod,
    FixedAssetDepreciationMethodStatus,
    FixedAssetDepreciationMethodType,
    FixedAssetStatus,
    FixedAssetContract,
} from '../../types/FixedAsset.types';
import {
    BaseCreateEntityArgs,
    BaseUpdateEntityArgs,
} from '../serviceInterfaces';

export const fixedAssetsAPI = createApi({
    reducerPath: 'fixedAssetsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: config.apiGateway.URL,
        prepareHeaders: async (headers, { getState }) => {
            const token = (await Auth.currentSession())
                .getIdToken()
                .getJwtToken();
            // If we have a token set in state, let's assume that we should be passing it.
            if (token) {
                headers.set('Authorization', token);
            }

            return headers;
        },
    }),
    tagTypes: [
        'AllFixedAssets',
        'ActiveFixedAsset',
        'AllStatuses',
        'ActiveStatus',
        'AllCostCodes',
        'ActiveCostCode',
        'AllAccountGroups',
        'ActiveAccountGroup',
        'AllTypes',
        'ActiveType',
        'AllDepreciationMethodStatuses',
        'ActiveDepreciationMethodStatus',
        'AllDepreciationMethodTypes',
        'ActiveDepreciationMethodType',
        'AllDepreciationMethods',
        'ActiveDepreciationMethod',
    ],
    endpoints: (build) => ({
        //region Fixed Asset
        createFixedAsset: build.mutation<any, BaseCreateEntityArgs<FixedAsset>>(
            {
                query: (args) => ({
                    url: 'fixedAsset',
                    method: 'POST',
                    body: args.postBody,
                }),
                invalidatesTags: ['AllFixedAssets'],
            }
        ),

        updateFixedAsset: build.mutation<any, BaseUpdateEntityArgs<FixedAsset>>(
            {
                query: (args) => ({
                    url: `fixedAsset/${args.id}`,
                    method: 'PATCH',
                    body: args.postBody,
                }),
                invalidatesTags: ['AllFixedAssets', 'ActiveFixedAsset'],
            }
        ),

        getFixedAsset: build.query<any, string>({
            query: (id) => `fixedAsset/${id}?full=true`,
            providesTags: ['ActiveFixedAsset'],
            transformResponse: (asset: FixedAsset) => {
                //create an array of the underlying contracts
                asset.contractList = asset.contracts?.map(
                    (assetContract: FixedAssetContract) => {
                        return assetContract.contract;
                    }
                );

                return asset;
            },
        }),

        getAllFixedAssets: build.query<any, void>({
            query: () => `fixedAsset/getAll`,
            providesTags: ['AllFixedAssets'],
        }),
        //endregion

        //region Fixed Asset Status
        createStatus: build.mutation<
            any,
            BaseCreateEntityArgs<FixedAssetStatus>
        >({
            query: (args) => ({
                url: 'fixedAsset/status',
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['AllStatuses'],
        }),

        updateStatus: build.mutation<
            any,
            BaseUpdateEntityArgs<FixedAssetStatus>
        >({
            query: (args) => ({
                url: `fixedAsset/status/${args.id}`,
                method: 'PATCH',
                body: args.postBody,
            }),
            invalidatesTags: ['AllStatuses', 'ActiveStatus'],
        }),

        getStatus: build.query<any, string>({
            query: (id) => `fixedAsset/status/${id}`,
            providesTags: ['ActiveStatus'],
        }),

        getAllStatus: build.query<any, void>({
            query: () => `fixedAsset/status/getAll`,
            providesTags: ['AllStatuses'],
        }),

        getStatusesMap: build.query<any, void>({
            query: () => `fixedAsset/status/getAll`,
            transformResponse: (response: FixedAssetStatus[]) => {
                return new Map(
                    response.map((status: FixedAssetStatus) => [
                        status.id,
                        status,
                    ])
                );
            },
            providesTags: ['AllStatuses'],
        }),

        getStatusesForDropdown: build.query<any, void>({
            query: () => `fixedAsset/status/getAll`,
            transformResponse: (response: FixedAssetStatus[]) => {
                return response.map((status: FixedAssetStatus) => {
                    return { value: status.id, label: status.code };
                });
            },
            providesTags: ['AllStatuses'],
        }),

        deleteStatus: build.mutation<any, string>({
            query: (id) => ({
                url: `fixedAsset/status/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['AllStatuses'],
        }),
        //endregion

        //region Fixed Asset Cost Code
        createCostCode: build.mutation<
            any,
            BaseCreateEntityArgs<FixedAssetCostCode>
        >({
            query: (args) => ({
                url: 'fixedAsset/fixedAssetCostCode',
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['AllCostCodes'],
        }),

        updateCostCode: build.mutation<
            any,
            BaseUpdateEntityArgs<FixedAssetCostCode>
        >({
            query: (args) => ({
                url: `fixedAsset/fixedAssetCostCode/${args.id}`,
                method: 'PATCH',
                body: args.postBody,
            }),
            invalidatesTags: ['AllCostCodes', 'ActiveCostCode'],
        }),

        getCostCode: build.query<any, string>({
            query: (id) => `fixedAsset/fixedAssetCostCode/${id}`,
            providesTags: ['ActiveCostCode'],
        }),

        getAllCostCodes: build.query<any, void>({
            query: () => `fixedAsset/fixedAssetCostCode/getAll`,
            providesTags: ['AllCostCodes'],
        }),

        deleteCostCode: build.mutation<any, string>({
            query: (id) => ({
                url: `fixedAsset/fixedAssetCostCode/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['AllCostCodes'],
        }),
        //endregion

        //region Asset Types
        createType: build.mutation<any, BaseCreateEntityArgs<FixedAssetType>>({
            query: (args) => ({
                url: 'fixedAsset/type',
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['AllTypes'],
        }),

        updateType: build.mutation<any, BaseUpdateEntityArgs<FixedAssetType>>({
            query: (args) => ({
                url: `fixedAsset/type/${args.id}`,
                method: 'PATCH',
                body: args.postBody,
            }),
            invalidatesTags: ['AllTypes', 'ActiveType'],
        }),

        getType: build.query<any, string>({
            query: (typeId) => `fixedAsset/type/${typeId}?full=true`,
            providesTags: ['ActiveType'],
        }),

        getAllTypes: build.query<any, void>({
            query: () => `fixedAsset/type/getAll`,
            providesTags: ['AllTypes'],
        }),

        getTypesMap: build.query<any, void>({
            query: () => `fixedAsset/type/getAll`,
            transformResponse: (response: FixedAssetType[]) => {
                return new Map(
                    response.map((type: FixedAssetType) => [type.id, type])
                );
            },
            providesTags: ['AllTypes'],
        }),

        getTypesForDropdown: build.query<any, void>({
            query: () => `fixedAsset/type/getAll`,
            transformResponse: (response: FixedAssetType[]) => {
                return response.map((type: FixedAssetType) => {
                    return { value: type.id, label: type.code };
                });
            },
            providesTags: ['AllTypes'],
        }),

        deleteType: build.mutation<any, string>({
            query: (typeId) => ({
                url: `fixedAsset/type/${typeId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['AllTypes'],
        }),
        //endregion

        //region Depreciation Method Statuses
        createDepreciationMethodStatus: build.mutation<
            any,
            BaseCreateEntityArgs<FixedAssetDepreciationMethodStatus>
        >({
            query: (args) => ({
                url: 'fixedAsset/depreciationMethodStatus',
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['AllDepreciationMethodStatuses'],
        }),

        updateDepreciationMethodStatus: build.mutation<
            any,
            BaseUpdateEntityArgs<FixedAssetDepreciationMethodStatus>
        >({
            query: (args) => ({
                url: `fixedAsset/depreciationMethodStatus/${args.id}`,
                method: 'PATCH',
                body: args.postBody,
            }),
            invalidatesTags: [
                'AllDepreciationMethodStatuses',
                'ActiveDepreciationMethodStatus',
            ],
        }),

        getDepreciationMethodStatus: build.query<any, string>({
            query: (typeId) => `fixedAsset/depreciationMethodStatus/${typeId}`,
            providesTags: ['ActiveDepreciationMethodStatus'],
        }),

        getAllDepreciationMethodStatuses: build.query<any, void>({
            query: () => `fixedAsset/depreciationMethodStatus/getAll`,
            providesTags: ['AllDepreciationMethodStatuses'],
        }),

        getDepreciationMethodStatusesMap: build.query<any, void>({
            query: () => `fixedAsset/depreciationMethodStatus/getAll`,
            transformResponse: (
                response: FixedAssetDepreciationMethodStatus[]
            ) => {
                return new Map(
                    response.map(
                        (status: FixedAssetDepreciationMethodStatus) => [
                            status.id,
                            status,
                        ]
                    )
                );
            },
            providesTags: ['AllDepreciationMethodStatuses'],
        }),

        getDepreciationMethodStatusesForDropdown: build.query<any, void>({
            query: () => `fixedAsset/depreciationMethodStatus/getAll`,
            transformResponse: (
                response: FixedAssetDepreciationMethodStatus[]
            ) => {
                return response.map(
                    (status: FixedAssetDepreciationMethodStatus) => {
                        return { value: status.id, label: status.code };
                    }
                );
            },
            providesTags: ['AllDepreciationMethodStatuses'],
        }),

        deleteDepreciationMethodStatus: build.mutation<any, string>({
            query: (typeId) => ({
                url: `fixedAsset/depreciationMethodStatus/${typeId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['AllDepreciationMethodStatuses'],
        }),
        //endregion

        //region Depreciation Method Types
        createDepreciationMethodType: build.mutation<
            any,
            BaseCreateEntityArgs<FixedAssetDepreciationMethodType>
        >({
            query: (args) => ({
                url: 'fixedAsset/depreciationMethodType',
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['AllDepreciationMethodTypes'],
        }),

        updateDepreciationMethodType: build.mutation<
            any,
            BaseUpdateEntityArgs<FixedAssetDepreciationMethodType>
        >({
            query: (args) => ({
                url: `fixedAsset/depreciationMethodType/${args.id}`,
                method: 'PATCH',
                body: args.postBody,
            }),
            invalidatesTags: [
                'AllDepreciationMethodTypes',
                'ActiveDepreciationMethodType',
            ],
        }),

        getDepreciationMethodType: build.query<any, string>({
            query: (id) => `fixedAsset/depreciationMethodType/${id}`,
            providesTags: ['ActiveDepreciationMethodType'],
        }),

        getAllDepreciationMethodTypes: build.query<any, void>({
            query: () => `fixedAsset/depreciationMethodType/getAll`,
            providesTags: ['AllDepreciationMethodTypes'],
        }),

        getDepreciationMethodTypesMap: build.query<any, void>({
            query: () => `fixedAsset/depreciationMethodType/getAll`,
            transformResponse: (
                response: FixedAssetDepreciationMethodType[]
            ) => {
                return new Map(
                    response.map((type: FixedAssetDepreciationMethodType) => [
                        type.id,
                        type,
                    ])
                );
            },
            providesTags: ['AllDepreciationMethodTypes'],
        }),

        getDepreciationMethodTypesForDropdown: build.query<any, void>({
            query: () => `fixedAsset/depreciationMethodType/getAll`,
            transformResponse: (
                response: FixedAssetDepreciationMethodType[]
            ) => {
                return response.map(
                    (type: FixedAssetDepreciationMethodType) => {
                        return { value: type.id, label: type.code };
                    }
                );
            },
            providesTags: ['AllDepreciationMethodTypes'],
        }),

        deleteDepreciationMethodType: build.mutation<any, string>({
            query: (id) => ({
                url: `fixedAsset/depreciationMethodType/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['AllDepreciationMethodTypes'],
        }),
        //endregion

        //region Depreciation Methods
        createDepreciationMethod: build.mutation<
            any,
            BaseCreateEntityArgs<FixedAssetDepreciationMethod>
        >({
            query: (args) => ({
                url: 'fixedAsset/depreciationMethod',
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['AllDepreciationMethods'],
        }),

        updateDepreciationMethod: build.mutation<
            any,
            BaseUpdateEntityArgs<FixedAssetDepreciationMethod>
        >({
            query: (args) => ({
                url: `fixedAsset/depreciationMethod/${args.id}`,
                method: 'PATCH',
                body: args.postBody,
            }),
            invalidatesTags: [
                'AllDepreciationMethods',
                'ActiveDepreciationMethod',
            ],
        }),

        getDepreciationMethod: build.query<any, string>({
            query: (id) => `fixedAsset/depreciationMethod/${id}?full=true`,
            providesTags: ['ActiveDepreciationMethod'],
        }),

        getAllDepreciationMethods: build.query<any, void>({
            query: () => `fixedAsset/depreciationMethod/getAll?full=true`,
            providesTags: ['AllDepreciationMethods'],
        }),

        getDepreciationMethodsMap: build.query<any, void>({
            query: () => `fixedAsset/depreciationMethod/getAll`,
            transformResponse: (response: FixedAssetDepreciationMethod[]) => {
                return new Map(
                    response.map((method: FixedAssetDepreciationMethod) => [
                        method.id,
                        method,
                    ])
                );
            },
            providesTags: ['AllDepreciationMethods'],
        }),

        getDepreciationMethodsForDropdown: build.query<any, void>({
            query: () => `fixedAsset/depreciationMethod/getAll?full=true`,
            transformResponse: (response: FixedAssetDepreciationMethod[]) => {
                return response.map((method: FixedAssetDepreciationMethod) => {
                    return {
                        value: method.id,
                        label: method.code,
                        object: method,
                    };
                });
            },
            providesTags: ['AllDepreciationMethods'],
        }),

        deleteDepreciationMethod: build.mutation<any, string>({
            query: (id) => ({
                url: `fixedAsset/depreciationMethod/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['AllDepreciationMethods'],
        }),
        //endregion
    }),
});

export const {
    useGetFixedAssetQuery,
    useGetAllFixedAssetsQuery,
    useCreateFixedAssetMutation,
    useUpdateFixedAssetMutation,
    useGetAllStatusQuery,
    useGetStatusesMapQuery,
    useGetStatusesForDropdownQuery,
    useGetStatusQuery,
    useDeleteStatusMutation,
    useCreateStatusMutation,
    useUpdateStatusMutation,
    useGetAllCostCodesQuery,
    useGetCostCodeQuery,
    useDeleteCostCodeMutation,
    useCreateCostCodeMutation,
    useUpdateCostCodeMutation,
    useGetAllTypesQuery,
    useGetTypesMapQuery,
    useGetTypesForDropdownQuery,
    useGetTypeQuery,
    useDeleteTypeMutation,
    useCreateTypeMutation,
    useUpdateTypeMutation,
    useGetAllDepreciationMethodStatusesQuery,
    useGetDepreciationMethodStatusesMapQuery,
    useGetDepreciationMethodStatusesForDropdownQuery,
    useGetDepreciationMethodStatusQuery,
    useCreateDepreciationMethodStatusMutation,
    useUpdateDepreciationMethodStatusMutation,
    useDeleteDepreciationMethodStatusMutation,
    useGetAllDepreciationMethodTypesQuery,
    useGetDepreciationMethodTypesMapQuery,
    useGetDepreciationMethodTypesForDropdownQuery,
    useGetDepreciationMethodTypeQuery,
    useCreateDepreciationMethodTypeMutation,
    useUpdateDepreciationMethodTypeMutation,
    useDeleteDepreciationMethodTypeMutation,
    useGetAllDepreciationMethodsQuery,
    useGetDepreciationMethodsMapQuery,
    useGetDepreciationMethodsForDropdownQuery,
    useGetDepreciationMethodQuery,
    useCreateDepreciationMethodMutation,
    useUpdateDepreciationMethodMutation,
    useDeleteDepreciationMethodMutation,
} = fixedAssetsAPI;
