import React from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isFeatureFlagEnabled } from '../utils/FeatureFlag/FeatureFlagUtil';
import NotFound from '../containers/NotFound';
import { RootState } from '../store';

/* istanbul ignore file */
// creates a Route where its children are rendered only if the user is authenticated.
// If the user is not authenticated, then it redirects to the login page.
export default function AuthenticatedRoute({
    children,
    featureFlag,
    ...rest
}: any) {
    const { pathname, search } = useLocation();
    const user = useSelector((state: RootState) => state.user);
    let shouldLoadRoute = true;
    if (featureFlag) {
        shouldLoadRoute = isFeatureFlagEnabled(
            user.enabledFeatureFlags,
            featureFlag
        );
    }

    return (
        <>
            {shouldLoadRoute ? (
                <Route {...rest}>
                    {user.isSignedIn ? (
                        children
                    ) : (
                        <Redirect
                            to={
                                localStorage.getItem('isUserLegacy') === 'true'
                                    ? `/login?redirect=${pathname}${search}`
                                    : `/login/updated?redirect=${pathname}${search}`
                            }
                        />
                    )}
                </Route>
            ) : (
                <NotFound />
            )}
        </>
    );
}
