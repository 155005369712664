import React from 'react';
import { Card, CardHeader, CardContent, TextField, Grid } from '@mui/material';
import LoaderButton from '../../../components/LoaderButton';
import './Comments.css';
import { isInvoiceLocked } from '../utils';
import useCommentsBox from '../hooks/Comments/useCommentsBox';

const CommentsBox = ({ documentId }: any) => {
    const {
        fields,
        handleFieldChange,
        submitComment,
        isLoadingCommentSave,
        activeDocument,
        isCommentValid,
    } = useCommentsBox(documentId);

    return (
        <Card>
            <CardHeader title="Post a Comment" />
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            id="comment"
                            multiline
                            rows={4}
                            required
                            type="text"
                            value={fields.comment}
                            data-testid="comment-box"
                            onChange={handleFieldChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <div className="comment-box-footer">
                            <div className="text-muted comment-box-length">
                                {fields.comment.length} / 500
                            </div>
                            <LoaderButton
                                onClick={submitComment}
                                variant="contained"
                                type="submit"
                                data-testid="postCommentBtn"
                                isLoading={isLoadingCommentSave}
                                disabled={
                                    isInvoiceLocked(activeDocument) ||
                                    !isCommentValid()
                                }>
                                Post Comment
                            </LoaderButton>
                        </div>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default CommentsBox;
