import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormFields } from '../../../libs/hooksLib';
import { isNilOrEmpty } from '../../../utils/objectUtils';
import { Auth } from 'aws-amplify';
import { onError } from '../../../libs/errorLib';

const useChangePassword = () => {
    const history = useHistory();
    const [fields, handleFieldChange] = useFormFields({
        password: '',
        oldPassword: '',
        confirmPassword: '',
    });
    const [isChanging, setIsChanging] = useState(false);

    function validateForm() {
        return (
            !isNilOrEmpty(fields.oldPassword) &&
            !isNilOrEmpty(fields.password) &&
            fields.password === fields.confirmPassword
        );
    }

    async function handleChangeClick(
        event: React.MouseEvent<HTMLButtonElement>
    ) {
        event.preventDefault();

        setIsChanging(true);

        try {
            const currentUser = await Auth.currentAuthenticatedUser();
            await Auth.changePassword(
                currentUser,
                fields.oldPassword,
                fields.password
            );

            history.push('/settings');
        } catch (error) {
            onError(error);
            setIsChanging(false);
        }
    }

    return {
        fields,
        handleFieldChange,
        handleChangeClick,
        isChanging,
        validateForm,
    };
};

export default useChangePassword;
