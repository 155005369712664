import Scheduling_MRP_Production from '../libs/images/myApps/Scheduling_MRP_Production.png';
import Order_management from '../libs/images/myApps/Order_management.png';
import cloud_storage from '../libs/images/myApps/cloud_storage.png';
import ico02 from '../libs/images/myApps/ico02.png';
import PricingAnalysisTool from './images/myApps/PricingAnalysisTool.png';
import Implementation_Icons_Cleanup_Documentation from '../libs/images/myApps/Implementation_Icons_Cleanup_Documentation.png';
import CRM_demo_link from '../libs/images/myApps/CRM_demo_link.png';
import Business_intelligence_links from './images/myApps/Business_intelligence_links.png';
import Document_management from './images/myApps/Document_management.png';
import ApAutomation from './images/myApps/ApAutomation.png';
import { PERMISSIONS } from '../constants/permissions/permissions.constants';
import Analytics from './images/myApps/Analytics.png';
import Workflow from './images/myApps/Workflow.png';
import FixedAssets_211 from './images/myApps/FixedAssets_211.png';
import Lab_Management from './images/myApps/Lab_Management.png';
import NavLink from '../utils/Navigation/NavLink';

//Need to use CORS Proxy for now until we have custom domain established and setup as accepted origin in Azure API Manager
//export const DATACOR_MULTIPLEXER_URL = 'https://cors-anywhere.herokuapp.com/https://datacor-secure-multiplexer.azure-api.net/api/';
export const DATACOR_MULTIPLEXER_URL =
    'https://datacor-secure-multiplexer.azure-api.net/api/';
export const DATACOR_MULTIPLEXER_URL_DOCUMENTATION =
    'https://datacor-secure-multiplexer.azure-api.net/swagger/docs/v1';

//region My Apps
const myApps = new NavLink({
    url: '/myApps',
    name: 'My Applications',
    about: '',
    featureFlag: 'dashboard.nav.myApps',
    appImage: { Implementation_Icons_Cleanup_Documentation },
    imageName: 'Implementation_Icons_Cleanup_Documentation',
});
//endregion

//region Scheduler
const scheduler = new NavLink({
    url: '/scheduler',
    name: 'Scheduler',
    about: 'Schedule batch ticket steps within the set parameters.',
    appImage: { Scheduling_MRP_Production },
    imageName: 'Scheduling_MRP_Production',
    featureFlag: 'dashboard.nav.apps.scheduler',
});
//endregion

//region App Streaming
const appStreaming = new NavLink({
    url: '/erpAppStreaming',
    name: 'App Streaming',
    about: 'Open your desktop applications in a browser.',
    appImage: { cloud_storage },
    imageName: 'cloud_storage',
    featureFlag: 'dashboard.nav.apps.erpAppStreaming',
});
//endregion

//region Pricing Analysis
const pricingAnalysis = new NavLink({
    url: '/pricingAnalysisTool',
    name: 'Pricing Analysis Tool',
    about: 'Customer product pricing details with highlighted pricing assessment and concerns.',
    appImage: { PricingAnalysisTool },
    imageName: 'PricingAnalysisTool',
    featureFlag: 'dashboard.nav.apps.pricingAnalysisTool',
});
//endregion

//region User Portal
const userPortal = new NavLink({
    url: '/userPortal',
    name: 'My User Portal',
    about: 'Add users to the shared user pool.',
    appImage: { ico02 },
    imageName: 'ico02',
    featureFlag: 'dashboard.nav.apps.userPortal',
    permissionKey: PERMISSIONS.USER_PORTAL.VIEW,
});
//endregion

//region API Documentation
const apiDocumentation = new NavLink({
    url: '/apiDocumentation',
    name: 'API Documentation',
    about: 'VB ERP API Documentation.',
    appImage: { Order_management },
    imageName: 'Order_management',
    featureFlag: 'dashboard.nav.apps.apiDocumentation',
});
//endregion

//region AP Automation
const apAutomation = new NavLink({
    url: '/apAutomation',
    name: 'AP Invoice Automation',
    about: 'Automate your invoice processing',
    appImage: { ApAutomation },
    imageName: 'ApAutomation',
    featureFlag: 'dashboard.nav.apps.apAutomation',
    permissionKey: PERMISSIONS.AP_AUTOMATION.VIEW,
});
//endregion

//region Fixed Assets
const fixedAssetsModule = new NavLink({
    url: '/accounting/fixedAssets',
    name: 'Fixed Assets',
    about: 'Asset Value Tracking',
    appImage: { FixedAssets_211 },
    imageName: 'FixedAssets_211',
    featureFlag: 'dashboard.nav.apps.accounting.fixedAssets',
});

const fixedAssets = new NavLink({
    url: '/accounting/fixedAssets/fixedAsset',
    name: 'Fixed Asset Records',
    about: 'View/Edit Fixed Assets',
    featureFlag: 'dashboard.nav.apps.accounting.fixedAssets',
});

const contracts = new NavLink({
    url: '/accounting/contract',
    name: 'Contracts',
    about: 'View/Edit Contracts',
    featureFlag: 'dashboard.nav.apps.accounting.fixedAssets',
});

const fixedAssetSetup = new NavLink({
    url: null,
    name: 'Setup',
    about: null,
    featureFlag: null,
});

const fixedAssetFunctionCodes = new NavLink({
    url: '/accounting/fixedAssets/functionCode',
    name: 'Function Codes',
    about: 'View/Edit Fixed Asset Function Codes',
    featureFlag: 'dashboard.nav.apps.accounting.fixedAssets',
});

const fixedAssetCostCodes = new NavLink({
    url: '/accounting/fixedAssets/costCode',
    name: 'Asset Cost Codes',
    about: 'View/Edit Fixed Asset Cost Codes',
    featureFlag: 'dashboard.nav.apps.accounting.fixedAssets',
});

const fixedAssetStatuses = new NavLink({
    url: '/accounting/fixedAssets/status',
    name: 'Asset Statuses',
    about: 'View/Edit Fixed Asset Statuses',
    featureFlag: 'dashboard.nav.apps.accounting.fixedAssets',
});

const fixedAssetTypes = new NavLink({
    url: '/accounting/fixedAssets/type',
    name: 'Asset Types',
    about: 'View/Edit Fixed Asset Types',
    featureFlag: 'dashboard.nav.apps.accounting.fixedAssets',
});

const fixedAssetDepreciationMethodStatuses = new NavLink({
    url: '/accounting/fixedAssets/depreciationMethodStatus',
    name: 'Depreciation Method Statuses',
    about: 'View/Edit Fixed Asset Depreciation Method Statuses',
    featureFlag: 'dashboard.nav.apps.accounting.fixedAssets',
});

const fixedAssetDepreciationMethodTypes = new NavLink({
    url: '/accounting/fixedAssets/depreciationMethodType',
    name: 'Depreciation Method Types',
    about: 'View/Edit Fixed Asset Depreciation Method Types',
    featureFlag: 'dashboard.nav.apps.accounting.fixedAssets',
});

const fixedAssetDepreciationMethods = new NavLink({
    url: '/accounting/fixedAssets/depreciationMethod',
    name: 'Depreciation Methods',
    about: 'View/Edit Fixed Asset Depreciation Methods',
    featureFlag: 'dashboard.nav.apps.accounting.fixedAssets',
});

const contractTypes = new NavLink({
    url: '/accounting/contractType',
    name: 'Contract Types',
    about: 'View/Edit Contract Types',
    featureFlag: 'dashboard.nav.apps.accounting.fixedAssets',
});

const chartOfAccounts = new NavLink({
    url: '/accounting/generalLedgerAccount',
    name: 'Chart Of Accounts',
    about: 'View/Edit Chart Of accounts',
    featureFlag: 'dashboard.nav.apps.accounting.fixedAssets',
});

fixedAssetSetup.children = [
    fixedAssetFunctionCodes,
    fixedAssetCostCodes,
    fixedAssetStatuses,
    fixedAssetTypes,
    fixedAssetDepreciationMethodStatuses,
    fixedAssetDepreciationMethodTypes,
    fixedAssetDepreciationMethods,
    contractTypes,
    chartOfAccounts,
];

fixedAssetsModule.children = [fixedAssets, contracts, fixedAssetSetup];

//endregion

//region Formulation
const formulation = new NavLink({
    url: '/formulation',
    name: 'Formulation',
    about: 'Formulation',
    appImage: { Lab_Management },
    imageName: 'Lab_Management',
    featureFlag: 'dashboard.nav.apps.formulation',
});

const formulationSetup = new NavLink({
    url: null,
    name: 'Setup',
    about: null,
    featureFlag: null,
});

const formulationItemAttributeGroups = new NavLink({
    url: '/formulation/itemAttributeGroup',
    name: 'Item Attribute Groups',
    about: 'View/Edit/Create Item Attribute Groups',
    featureFlag: 'dashboard.nav.apps.formulation',
});

const formulationItemAttributes = new NavLink({
    url: '/formulation/itemAttribute',
    name: 'Item Attributes',
    about: 'View/Edit/Create Item Attributes',
    featureFlag: 'dashboard.nav.apps.formulation',
});

const casMaster = new NavLink({
    url: '/formulation/casMaster',
    name: 'Cas Master',
    about: 'View/Edit/Create Cas Numbers',
    featureFlag: 'dashboard.nav.apps.formulation',
});

const itemMaster = new NavLink({
    about: 'View/Edit/Create Items',
    featureFlag: 'dashboard.nav.apps.formulation',
    name: 'Item Master',
    url: '/formulation/itemMaster',
});

formulationSetup.children = [
    formulationItemAttributeGroups,
    formulationItemAttributes,
];

formulation.children = [casMaster, itemMaster, formulationSetup];
//endregion

//region Datacor CRM
const datacorCRM = new NavLink({
    url: 'https://www.echempax.com/demo/dsp/pub/Login.dsp;ECHEMPAXID=_UNvkIE4BoiMBQmLAZkICQ',
    name: 'CRM',
    about: 'Customer Relationship Manager',
    appImage: { CRM_demo_link },
    imageName: 'CRM_demo_link',
    featureFlag: 'dashboard.nav.apps.crmDemo',
});
//endregion

//region Business Intelligence
const businessIntelligence = new NavLink({
    url: '/businessIntelligence',
    name: 'Business Intelligence',
    about: 'Business Intelligence Links',
    appImage: { Business_intelligence_links },
    imageName: 'Business_intelligence_links',
    featureFlag: 'dashboard.nav.apps.businessIntelligence',
});
//endregion

//region Document Management
const documentManagement = new NavLink({
    url: '/documentManagement',
    name: 'Document Management',
    about: 'Securely send, store and retrieve your business workflow documents.',
    appImage: { Document_management },
    imageName: 'Document_management',
    featureFlag: 'dashboard.nav.apps.documentManagement',
});
//endregion

//region Analytics
const analytics = new NavLink({
    url: '/analytics',
    name: 'Analytics',
    about: 'Track real time information for your Datacor products.',
    appImage: { Analytics },
    imageName: 'Analytics',
    featureFlag: 'dashboard.nav.apps.analytics',
});
//endregion

//region Workflow
const workflow = new NavLink({
    url: '/workflow',
    name: 'Workflow',
    about: 'Workflow Designer and Engine.',
    appImage: { Workflow },
    imageName: 'Workflow',
    featureFlag: 'dashboard.nav.apps.workflow',
});
//endregion

//region Administration
const administrationModule = new NavLink({
    url: '/administration',
    name: 'Administration',
    about: 'Administration Options.',
    appImage: { Workflow },
    imageName: 'Workflow',
    featureFlag: 'dashboard.nav.apps.administration',
});

const subscribers = new NavLink({
    url: '/administration/organizations/subscribers',
    name: 'Subscribers',
    about: 'Administration Options.',
    featureFlag: 'dashboard.nav.apps.administration',
});

const businessEntities = new NavLink({
    url: '/administration/organizations/business-entities',
    name: 'Business Entities',
    about: 'Administration Options.',
    featureFlag: 'dashboard.nav.apps.administration',
});

const users = new NavLink({
    url: '/administration/organizations/users',
    name: 'Users',
    about: 'Administration Options.',
    featureFlag: 'dashboard.nav.apps.administration',
});

const groups = new NavLink({
    url: '/administration/organizations/groups',
    name: 'Groups',
    about: 'Administration Options.',
    featureFlag: 'dashboard.nav.apps.administration',
});

const roles = new NavLink({
    url: '/administration/organizations/roles',
    name: 'Roles',
    about: 'Administration Options.',
    featureFlag: 'dashboard.nav.apps.administration',
});

const permissions = new NavLink({
    url: '/administration/organizations/permissions',
    name: 'Permissions',
    about: 'Administration Options.',
    featureFlag: 'dashboard.nav.apps.administration',
});

const departmentCodes = new NavLink({
    url: '/administration/organizations/departmentCodes',
    name: 'Department Codes',
    about: 'Department Codes',
    featureFlag: 'dashboard.nav.apps.administration',
});

const buildings = new NavLink({
    url: '/administration/organizations/buildings',
    name: 'Buildings',
    about: 'Buildings',
    featureFlag: 'dashboard.nav.apps.administration',
});

administrationModule.children = [
    subscribers,
    businessEntities,
    buildings,
    users,
    groups,
    roles,
    departmentCodes,
    permissions,
];
//endregion

//we should change these to pull from a DB, but for now this works.
export const AVAILABLE_NAV_LINKS_AND_INCLUSION_CRITERIA: NavLink[] = [
    myApps,
    scheduler,
    appStreaming,
    pricingAnalysis,
    userPortal,
    apiDocumentation,
    apAutomation,
    fixedAssetsModule,
    formulation,
    datacorCRM,
    businessIntelligence,
    documentManagement,
    analytics,
    workflow,
    administrationModule,
];
