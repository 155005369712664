import React, { Fragment, useState, useEffect } from 'react';
import CollapsiblePanel from '../CollapsiblePanel/CollapsiblePanel';
import { isNilOrEmpty } from '../../utils/objectUtils';
import {
    FormGroup,
    FormControlLabel,
    Checkbox,
    IconButton,
    Tooltip,
} from '@mui/material';
import { Node } from '../../types/Settings.types';
import LaunchIcon from '@mui/icons-material/Launch';
import { useHistory } from 'react-router-dom';

interface TreeNodeProps {
    checked: boolean;
    onCheckChange: (node: Node) => void;
    label: string;
    value: string;
    nestedChildren: Array<any>;
    isTopLevelNode: boolean;
    disabled?: boolean;
    hasChildren: boolean;
    businessEntity?: boolean;
}

const TreeNode = (props: TreeNodeProps) => {
    const [isChecked, setIsChecked] = useState(props.checked);
    let history = useHistory();

    useEffect(() => {
        setIsChecked(props.checked);
    }, [props.checked]);
    const getChildNode = (child: Node, index: number) => {
        if (props.businessEntity) {
            return (
                <Fragment key={child.value ? child.value : index}>
                    <div className="custom-control custom-checkbox">
                        <TreeNode
                            isTopLevelNode={false}
                            label={child.name}
                            value={child.id.toString()}
                            checked={child.checked}
                            disabled={child.disabled}
                            onCheckChange={props.onCheckChange}
                            hasChildren={!isNilOrEmpty(child?.children)}
                            nestedChildren={child?.children}
                            key={child.value}
                            businessEntity
                        />
                    </div>
                </Fragment>
            );
        }
        return (
            <Fragment key={child.value ? child.value : index}>
                <div className="custom-control custom-checkbox">
                    <TreeNode
                        isTopLevelNode={false}
                        label={child.label}
                        value={child.value}
                        checked={child.checked}
                        disabled={child.disabled}
                        onCheckChange={props.onCheckChange}
                        hasChildren={!isNilOrEmpty(child?.children)}
                        nestedChildren={child?.children}
                        key={child.value}
                    />
                </div>
            </Fragment>
        );
    };

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (props.onCheckChange) {
            const node = {
                label: props.label,
                value: props.value,
                checked: event.target.checked,
            };

            props.onCheckChange(node);
            setIsChecked((prev) => !prev);
        }
    };

    return (
        <>
            {props.hasChildren ? (
                <CollapsiblePanel
                    label={props.label}
                    value={props.value}
                    onCheckChange={props.onCheckChange}
                    collapsed={false}
                    class={'treeNode item'}
                    hasCheckbox={false}
                    hasTooltip={props.businessEntity}
                    defaultChecked={props.checked}>
                    {props.nestedChildren.map((child: any, index: number) =>
                        getChildNode(child, index)
                    )}
                </CollapsiblePanel>
            ) : (
                <>
                    {props.value && (
                        <FormGroup>
                            {props.businessEntity ? (
                                <FormControlLabel
                                    control={
                                        <Tooltip
                                            title="View Business Entity"
                                            placement="left-start">
                                            <IconButton
                                                size="small"
                                                color="primary"
                                                ref={(ref) => {
                                                    if (!ref) {
                                                        return;
                                                    }

                                                    ref.onclick = (event) => {
                                                        history.push(
                                                            `/administration/organizations/business-entities/${props.value}`
                                                        );
                                                    };
                                                }}>
                                                <LaunchIcon />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                    label={props.label}
                                />
                            ) : (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={isChecked}
                                            disabled={props.disabled}
                                            onChange={(event) =>
                                                onChange(event)
                                            }
                                        />
                                    }
                                    label={props.label}
                                />
                            )}
                        </FormGroup>
                    )}
                </>
            )}
            <br />
        </>
    );
};

export default TreeNode;
