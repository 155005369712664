import React from 'react';
import { AgGridColumn } from 'ag-grid-react';
import Grid from '../../../components/Grids/Grid';
import DatacorLogoSpinner from '../../../components/DatacorLogoSpinner/DatacorLogoSpinner';
import { GridOptions } from '../../../components/Grids/grid.constants';
import UseFixedAssetDepreciationMethodStatusGrid from '../hooks/useFixedAssetDepreciationMethodStatusGrid';

const FixedAssetDepreciationMethodStatusGrid = () => {
    const { statusList, redirectToForm, isLoadingStatuses } =
        UseFixedAssetDepreciationMethodStatusGrid();

    return isLoadingStatuses ? (
        <DatacorLogoSpinner />
    ) : (
        <Grid
            rowData={statusList}
            gridClass="full-width-grid"
            displayToolbar={true}
            displayCreateNewButton={true}
            onCreateNew={redirectToForm}
            onRowClicked={({ data }) => redirectToForm(data)}
            defaultColDef={GridOptions.sortFilterAndWrapColumns}>
            <AgGridColumn
                minWidth={100}
                field="sortOrder"
                headerName="Sort Order"
            />
            <AgGridColumn minWidth={150} field="code" headerName="Code" />
            <AgGridColumn
                minWidth={250}
                field="description"
                headerName="Description"
            />
            <AgGridColumn minWidth={100} field="isActive" headerName="Active" />
        </Grid>
    );
};

export default FixedAssetDepreciationMethodStatusGrid;
