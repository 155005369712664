import React from 'react';
import './BatchScheduler.css';
import FullCalendar from '@fullcalendar/react';
import resourceDayGridPlugin from '@fullcalendar/resource-daygrid';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import dayGridPlugin from '@fullcalendar/daygrid';
import {
    Alert,
    Button,
    Grid,
    Card,
    CardHeader,
    CardContent,
} from '@mui/material';
import Notifications from './Notifications';
import HeaderControls from './HeaderControls';
import DatacorLogoSpinner from '../../components/DatacorLogoSpinner/DatacorLogoSpinner';
import useBatchScheduler from './hooks/useBatchScheduler';
let moment = require('moment');

const BUSINESS_HOURS = {
    daysOfWeek: [1, 2, 3, 4, 5],
    startTime: '06:00',
    endTime: '18:00',
};

const SCROLL_TO_TIME = moment().subtract(1, 'hours').format('HH:mm:ss');

const BatchScheduler = () => {
    const {
        batchTicketSet,
        scheduledSteps,
        activeSlotIndex,
        workcenters,
        calendarRef,
        setRefreshData,
        setShowAlertText,
        warehousesSet,
        refreshData,
        storeCurrentDateAndView,
        updateView,
        updateDate,
        zoomOut,
        zoomIn,
        newScheduledBatchTicketStep,
        allowStepToBeRescheduled,
        renderEventContent,
        workcentersSet,
        showAlertText,
        SLOTS,
    } = useBatchScheduler();

    return !refreshData &&
        !batchTicketSet.isLoading &&
        !warehousesSet.warehouseProductsIsLoading &&
        !workcentersSet.isLoading ? (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                {showAlertText !== null && (
                    <Alert severity="error">
                        Scheduling Error
                        <p>{showAlertText}</p>
                        <div className="d-flex justify-content-end">
                            <Button
                                onClick={() => setShowAlertText(null)}
                                color="error"
                                variant="contained">
                                Close
                            </Button>
                        </div>
                    </Alert>
                )}
            </Grid>
            <Grid item xs={4}>
                <HeaderControls
                    onDateChange={updateDate}
                    onViewChange={updateView}
                    onZoomIn={zoomIn}
                    onZoomOut={zoomOut}
                    onRefresh={() => setRefreshData(true)}
                />
            </Grid>
            <Grid item xs={4}>
                <Notifications />
            </Grid>
            <Grid item xs={4}>
                <FullCalendar
                    ref={calendarRef}
                    schedulerLicenseKey={
                        'CC-Attribution-NonCommercial-NoDerivatives'
                    }
                    height={'auto'}
                    nowIndicator={true}
                    plugins={[
                        resourceDayGridPlugin,
                        resourceTimeGridPlugin,
                        resourceTimelinePlugin,
                        interactionPlugin,
                        listPlugin,
                        dayGridPlugin,
                    ]}
                    resourceGroupField={'Category'}
                    resourceAreaColumns={[
                        {
                            field: 'Description',
                            headerContent: 'Work Center By Category',
                        },
                    ]}
                    businessHours={BUSINESS_HOURS}
                    resources={workcenters}
                    resourceAreaWidth={'15%'}
                    slotDuration={SLOTS[activeSlotIndex].slotDuration}
                    slotLabelInterval={SLOTS[activeSlotIndex].slotLabelInterval}
                    scrollTime={SCROLL_TO_TIME}
                    initialView="resourceTimeline"
                    editable={true}
                    eventStartEditable={true}
                    eventResizableFromStart={true}
                    eventDurationEditable={true}
                    eventResourceEditable={true}
                    droppable={true}
                    selectable={true}
                    selectMirror={true}
                    weekends={true}
                    events={scheduledSteps}
                    eventContent={renderEventContent}
                    eventOverlap={false}
                    eventChange={allowStepToBeRescheduled}
                    eventReceive={newScheduledBatchTicketStep}
                    datesSet={storeCurrentDateAndView}
                />
            </Grid>
        </Grid>
    ) : (
        <Card>
            <CardHeader title="Please have patience while we load your data." />
            <CardContent>
                {batchTicketSet.isLoading ? (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <DatacorLogoSpinner />
                        </Grid>
                        <Grid item xs={12}>
                            Loading Batch Tickets, Steps and Components.
                        </Grid>
                    </Grid>
                ) : null}
                {warehousesSet.warehouseProductsIsLoading ? (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <DatacorLogoSpinner />
                        </Grid>
                        <Grid item xs={12}>
                            Loading Component Product details.
                        </Grid>
                    </Grid>
                ) : null}
                {workcentersSet.isLoading ? (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <DatacorLogoSpinner />
                        </Grid>
                        <Grid item xs={12}>
                            Loading Warehouse work centers.
                        </Grid>
                    </Grid>
                ) : null}
            </CardContent>
        </Card>
    );
};

export default BatchScheduler;
