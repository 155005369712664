import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { onError } from '../../../libs/errorLib';
import { SystemWorkflowsService } from '../../../services/workflow/system/systemWorkflows.service';
import { dateTimeFormatter } from '../../../utils/formattingUtils';
import LoaderButton from '../../../components/LoaderButton';
import { Button, Card, CardHeader, CardContent, Grid } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';

const SystemWorkflow = () => {
    const [systemWorkflow, setSystemWorkflow] = useState(null);
    const history = useHistory();
    const { id } = useParams();

    useEffect(() => {
        const onLoad = async () => {
            loadData();
        };

        onLoad();
    }, []);

    const loadData = async () => {
        try {
            setSystemWorkflow(
                await SystemWorkflowsService.getSystemWorkflowFull(id)
            );
        } catch (e) {
            onError(e);
        }
    };

    const headerArea = () => {
        return (
            <>
                <h1>
                    System Workflow: {systemWorkflow?.name}
                    &nbsp;
                    <Link to="/workflow/">
                        <LoaderButton size="lg">Workflow Portal</LoaderButton>
                    </Link>
                </h1>
            </>
        );
    };

    const appUrl = () => {
        return `/workflow/apps/${systemWorkflow?.App?.id}`;
    };

    const systemWorkflowArea = () => {
        return (
            <List dense={true}>
                <ListItem>
                    <ListItemText
                        primary={'Name: ' + systemWorkflow?.name}
                        secondary="The name is the human-readable name for this
                        kind of workflow."
                    />
                </ListItem>
                <ListItem>
                    <ListItemText
                        primary={'Code: ' + systemWorkflow?.code}
                        secondary="The code is used to refer to this kind of workflow from other applications."
                    />
                </ListItem>
                <ListItem>
                    <ListItemText
                        primary={'Description: ' + systemWorkflow?.description}
                        secondary="Descriptive text about this kind of workflow."
                    />
                </ListItem>
                <ListItem>
                    <ListItemText
                        primary={'Code: ' + systemWorkflow?.description}
                        secondary="Descriptive text about this kind of workflow."
                    />
                </ListItem>
                <ListItem>
                    <ListItemText
                        primary={'App: ' + systemWorkflow?.App.name}
                        secondary="The app to which this system workflow belongs."
                    />
                    <ListItemSecondaryAction>
                        <Link to={appUrl()}>
                            <Button>Open App</Button>
                        </Link>
                    </ListItemSecondaryAction>
                </ListItem>

                <ListSubheader>URLS</ListSubheader>
                <ListItem>
                    <ListItemText
                        primary={'Callback URL: ' + systemWorkflow?.callbackUrl}
                    />
                </ListItem>
                <ListItem>
                    <ListItemText
                        primary={'Entity URL: ' + systemWorkflow?.entityUrl}
                    />
                </ListItem>

                <ListSubheader>Activity</ListSubheader>
                <ListItem>
                    <ListItemText
                        primary={
                            'Updated: ' +
                            dateTimeFormatter(systemWorkflow?.updatedAt)
                        }></ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemText
                        primary={
                            'Created: ' +
                            dateTimeFormatter(systemWorkflow?.createdAt)
                        }></ListItemText>
                </ListItem>
            </List>
        );
    };

    const actors = () => {
        if (systemWorkflow == null) {
            return (
                <div>
                    <em>Loading...</em>
                </div>
            );
        } else {
            return (
                <ul>
                    {systemWorkflow?.SystemActions?.map((o, i) => (
                        <li key={i}>
                            <Link to={`/workflow/systemActions/${o.id}`}>
                                {o.name}
                            </Link>
                        </li>
                    ))}
                </ul>
            );
        }
    };

    const systemActionsArea = () => {
        return <>{actors()}</>;
    };

    const templatesArea = () => {
        return (
            <>
                <p>
                    <em>Coming soon...</em>
                </p>
            </>
        );
    };

    const actorsArea = () => {
        return (
            <>
                <p>
                    <em>Coming soon...</em>
                </p>
            </>
        );
    };

    const tokensArea = () => {
        return (
            <>
                <p>
                    <em>Coming soon...</em>
                </p>
            </>
        );
    };

    return (
        <Card>
            <CardHeader
                title={`System Workflow: ${systemWorkflow?.name}`}
                action={
                    <Link to="/workflow/">
                        <LoaderButton variant="primary" size="lg">
                            Workflow Portal
                        </LoaderButton>
                    </Link>
                }></CardHeader>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Card>
                            <CardHeader title="Configuration"></CardHeader>
                            <CardContent>{systemWorkflowArea()}</CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={8}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Card>
                                    <CardHeader title="Tokens"></CardHeader>
                                    <CardContent>{tokensArea()}</CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={6}>
                                <Card>
                                    <CardHeader title="Actors"></CardHeader>
                                    <CardContent>{actorsArea()}</CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={6}>
                                <Card>
                                    <CardHeader title="Actions"></CardHeader>
                                    <CardContent>
                                        {systemActionsArea()}
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={6}>
                                <Card>
                                    <CardHeader title="Templates"></CardHeader>
                                    <CardContent>{templatesArea()}</CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default SystemWorkflow;
