import React from 'react';
import { AgGridColumn } from 'ag-grid-react';
import Grid from '../../../components/Grids/Grid';
import { GridOptions } from '../../../components/Grids/grid.constants';
import useCasGrid from '../hooks/useCasGrid';

const FormulationCasGrid = () => {
    const { redirectToForm } = useCasGrid();

    return (
        <Grid
            gridClass="full-width-grid"
            displayToolbar={true}
            displayCreateNewButton={true}
            onCreateNew={redirectToForm}
            defaultColDef={GridOptions.sortFilterAndWrapColumns}>
            <AgGridColumn
                minWidth={150}
                field="casIdentifier"
                headerName="Cas Number"
            />
            <AgGridColumn
                minWidth={200}
                field="chemicalName"
                headerName="Description"
            />
        </Grid>
    );
};

export default FormulationCasGrid;
