// @ts-nocheck
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import MomentUtils from '@date-io/moment';
import { LocalizationProvider } from '@mui/x-date-pickers';

//This uses the browser’s History API to create real URLs
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { Amplify, Auth } from 'aws-amplify';
import config from './config';
import { initSentry } from './libs/errorLib';
import store from './store';
import { Provider } from 'react-redux';

import { LicenseManager } from 'ag-grid-enterprise';
import { themeConfig } from './styles/theme/themeConfig';
LicenseManager.setLicenseKey(
    'CompanyName=Datacor, Inc.,LicensedApplication=Datacor Cloud/Web,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=3,LicensedProductionInstancesCount=1,AssetReference=AG-018051,ExpiryDate=12_August_2022_[v2]_MTY2MDI1ODgwMDAwMA==3ddd20cef1c2209dd273f71c9b763735'
);

/* istanbul ignore file */
initSentry();

// set the various AWS resources that we want to interact with and maintains user session
//NOTES: Amplify refers to Cognito as Auth, S3 as Storage, and API Gateway as API
Amplify.configure({
    Auth: {
        mandatorySignIn: true,
        region: config.cognito.REGION,
        userPoolId: config.cognito.USER_POOL_ID,
        identityPoolId: config.cognito.IDENTITY_POOL_ID,
        userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    },
    Storage: {
        region: config.s3.REGION,
        bucket: config.s3.BUCKET,
        identityPoolId: config.cognito.IDENTITY_POOL_ID,
    },
    API: {
        endpoints: [
            {
                name: 'datacorAPI',
                endpoint: config.apiGateway.URL,
                region: config.apiGateway.REGION,
            },
            {
                name: 'datacorAPIWithCustAuth',
                endpoint: config.apiGateway.URL,
                region: config.apiGateway.REGION,
                custom_header: async () => {
                    return {
                        Authorization: (await Auth.currentSession())
                            .getIdToken()
                            .getJwtToken(),
                    };
                },
            },
        ],
    },
});

const theme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: themeConfig.PRIMARY.main,
            light: themeConfig.PRIMARY.light,
        },
        secondary: {
            main: themeConfig.SECONDARY.main,
            light: themeConfig.SECONDARY.light,
        },
        headerNavIcons: {
            main: themeConfig.HEADER_NAV.MENU_ICONS.main,
            light: themeConfig.HEADER_NAV.MENU_ICONS.light,
        },
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                colorPrimary: {
                    backgroundColor: 'white',
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
            },
        },
    },
});

ReactDOM.render(
    <Router>
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={MomentUtils}>
                    <App />
                </LocalizationProvider>
            </ThemeProvider>
        </Provider>
    </Router>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
