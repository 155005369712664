import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormFields } from '../../../libs/hooksLib';
import { isNilOrEmpty } from '../../../utils/objectUtils';
import { loginLegacy, login } from '../../../store/user';
import { RootState } from '../../../store';

const useLogin = (useLegacy: boolean = true) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.user);
    const [fields, handleFieldChange] = useFormFields({
        email: '',
        password: '',
    });

    useEffect(() => {
        if (user.requirePasswordChange) {
            history.push('/login/newPasswordRequired');
        }
    }, [user.requirePasswordChange, history]);

    function validateForm() {
        return !isNilOrEmpty(fields.email) && !isNilOrEmpty(fields.password);
    }

    const loginAction = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        if (useLegacy) {
            localStorage.setItem('isUserLegacy', 'true');
            dispatch(loginLegacy(fields));
        } else {
            localStorage.setItem('isUserLegacy', 'false');
            dispatch(login(fields));
        }
    };
    return { user, fields, handleFieldChange, loginAction, validateForm };
};

export default useLogin;
