import { Invoice } from '../../types/ApAutomation.types';
import { isNilOrEmpty } from '../../utils/objectUtils';

export const isInvoiceLocked = (invoice: Invoice) => {
    return (
        !isNilOrEmpty(invoice?.voucherNumber) ||
        invoice?.approvalStatus?.key === 'AUTO_APPROVED' ||
        invoice?.approvalStatus?.key === 'APPROVED' ||
        invoice?.approvalStatus?.key === 'REJECTED'
    );
};

export const canInvoiceBeUpdated = (invoice: Invoice) => {
    return isNilOrEmpty(invoice?.voucherNumber);
};
