import { useEffect, useState } from 'react';
import {
    Subscriber,
    useCreateSubscriberMutation,
    useGetBusinessEntitiesQuery,
    useGetDepartmentCodesQuery,
    useGetSubscriberQuery,
    useUpdateSubscriberMutation,
} from '../../../services/organizations/organizations.service';
import { skipToken } from '@reduxjs/toolkit/query';
import { useHistory } from 'react-router-dom';
import { onError } from '../../../libs/errorLib';
import { useForm } from '../../../libs/hooksLib';
import {
    BaseCreateEntityArgs,
    BaseUpdateEntityArgs,
} from '../../../services/serviceInterfaces';
import { GridReadyEvent } from 'ag-grid-community';

const useSubscriberForm = (id: string) => {
    const history = useHistory();
    const { fields, handleFieldChange, setValues } = useForm({});
    const { data: subscriber, isLoading: isLoading } = useGetSubscriberQuery(
        id ? id : skipToken
    );

    const { data: businessEntities } = useGetBusinessEntitiesQuery(
        id ? id : skipToken
    );
    const { data: departmentCodes, isLoading: isDepartmentCodesLoading } =
        useGetDepartmentCodesQuery(id ? id : skipToken);
    const [createSubscriber] = useCreateSubscriberMutation();
    const [updateSubscriber] = useUpdateSubscriberMutation();
    const [gridApi, setGridApi] = useState(null);

    const defaultSubscriber = {
        name: '',
        description: '',
    };

    useEffect(() => {
        setValues(subscriber || defaultSubscriber);
    }, [subscriber]);

    const handleCreateSubscriber = async () => {
        const body: BaseCreateEntityArgs<Subscriber> = {
            postBody: fields,
        };

        try {
            await createSubscriber(body);
            handleClose();
        } catch (e) {
            return onError(e.message);
        }
    };

    const handleUpdateSubscriber = async () => {
        const body: BaseUpdateEntityArgs<Subscriber> = {
            id: id as unknown as number,
            postBody: fields,
        };

        try {
            await updateSubscriber(body);
            handleClose();
        } catch (e) {
            return onError(e.message);
        }
    };

    const handleClose = () => {
        history.push('/administration/organizations/subscribers');
    };

    const onGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
    };

    return {
        fields,
        subscriber,
        businessEntities,
        isLoading,
        handleCreateSubscriber,
        handleUpdateSubscriber,
        handleFieldChange,
        handleClose,
        setValues,
        onGridReady,
        departmentCodes,
        isDepartmentCodesLoading,
    };
};

export default useSubscriberForm;
