import { useHistory } from 'react-router-dom';
import {
    useGetAllContractsQuery,
    useGetAllContractTypesQuery,
} from '../../../services/accounting/accounting.service';
import { useEffect, useState } from 'react';
import { isNilOrEmpty } from '../../../utils/objectUtils';
import { ContractType } from '../../../types/Accounting.types';

const UseContractGrid = () => {
    const history = useHistory();

    const { data: contractList, isLoading: isLoadingContracts } =
        useGetAllContractsQuery();
    const { data: contractTypeList, isLoading: isLoadingContractTypes } =
        useGetAllContractTypesQuery();

    const [isLoadingAny, setIsLoadingAny] = useState(true);
    const [contractTypeDictionary, setContractTypeDictionary] = useState(null);

    useEffect(() => {
        setIsLoadingAny(
            isLoadingContracts ||
                isLoadingContractTypes ||
                !contractTypeDictionary
        );
    }, [isLoadingContracts, isLoadingContractTypes, contractTypeDictionary]);

    useEffect(() => {
        if (!isLoadingContractTypes && !isNilOrEmpty(contractTypeList)) {
            let cTypeDictionary: { [key: number]: ContractType } = {};

            contractTypeList.forEach((contractType: ContractType) => {
                cTypeDictionary[contractType.id] = contractType;
            });

            setContractTypeDictionary(cTypeDictionary);
        } else if (!isLoadingContractTypes) {
            setContractTypeDictionary([]);
        } else {
            setContractTypeDictionary(null);
        }
    }, contractTypeList);

    const redirectToForm = (row: any) => {
        if (row.id) {
            history.push(`/accounting/contract/${row.id}`);
        } else {
            history.push('/accounting/contract/new');
        }
    };

    const getContractTypeFromId = (id: number) => {
        if (!isNilOrEmpty(contractTypeDictionary)) {
            return contractTypeDictionary[id]?.code || '';
        } else {
            return '';
        }
    };

    return {
        contractList,
        redirectToForm,
        getContractTypeFromId,
        isLoadingAny,
    };
};

export default UseContractGrid;
