import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import { useLocation, useHistory } from 'react-router-dom';
import {
    organizationsApi,
    useGetBusinessEntitiesQuery,
    useGetSubscribersForDropdownQuery,
    useGetUserQuery,
    User,
    useUpdateUserMutation,
} from '../../services/organizations/organizations.service';
import { skipToken } from '@reduxjs/toolkit/query';
import SelectDropdown from '../Form/SelectDropdown/SelectDropdown';
import { Grid } from '@mui/material';
import {
    handleUpdateUserBusinessEntity,
    handleUpdateUserSubscriber,
} from '../../store/user';
import { BaseUpdateEntityArgs } from '../../services/serviceInterfaces';
import ExpandableDropdown from '../Form/ExpandableDropdown/ExpandableDropdown';
import DatacorLogoSpinner from '../DatacorLogoSpinner/DatacorLogoSpinner';

const BusinessEntitySelect = () => {
    const user = useSelector((state: RootState) => state.user);
    const dispatch = useDispatch();
    let history = useHistory();
    let location = useLocation();
    const {
        data: businessEntityOptions,
        isLoading: isLoadingBusinessEntities,
        isFetching: isFetchingBusinessEntities,
    } = useGetBusinessEntitiesQuery(
        user?.subscriber?.id ? user?.subscriber?.id.toString() : skipToken
    );

    const { data: userData, isLoading: isLoadingUser } = useGetUserQuery(
        user.userId
            ? {
                  userId: user.userId,
                  full: true,
              }
            : skipToken
    );
    const { data: subscriberOptions, isLoading: isLoadingSubscriberOptions } =
        useGetSubscribersForDropdownQuery();
    const [loading, setLoading] = useState(false);
    const shouldLoad = isLoadingSubscriberOptions;
    const [value, setValue] = useState(null);
    const [updateUser] = useUpdateUserMutation();

    const getCurrentValue = () => {
        if (!subscriberOptions) {
            return;
        }
        const option = subscriberOptions.find(
            (subscriber: any) => subscriber.value === user?.subscriber?.id
        );
        return option?.value || value;
    };

    const handleUpdateSubscriber = async (selectedValue: any) => {
        setValue(selectedValue);
        setLoading(true);
        const businessEnts: any = await dispatch(
            organizationsApi.endpoints.getBusinessEntities.initiate(
                selectedValue
            )
        );

        const subscriber: any = await dispatch(
            organizationsApi.endpoints.getSubscriber.initiate(selectedValue)
        );

        const { data } = businessEnts;
        const { data: subscriberData } = subscriber;

        const body: BaseUpdateEntityArgs<User> = {
            id: userData.id as unknown as number,
            postBody: {
                ...userData,
                businessEntityId: data[0]?.id || user?.businessEntity?.id,
                activeWebBusinessEntityId:
                    data[0]?.id || user?.businessEntity?.id,
            },
        };

        updateUser(body);

        await dispatch(
            handleUpdateUserSubscriber(
                subscriberData,
                data[0] || user?.businessEntity
            )
        );
        setLoading(false);
    };

    const handleUpdateBusinessEntity = async (selectedValue: any) => {
        const body: BaseUpdateEntityArgs<User> = {
            id: userData.id as unknown as number,
            postBody: {
                ...userData,
                businessEntityId: selectedValue || user?.businessEntity?.id,
                activeWebBusinessEntityId:
                    selectedValue || user?.businessEntity?.id,
            },
        };

        const businessEntity: any = await dispatch(
            organizationsApi.endpoints.getBusinessEntity.initiate(selectedValue)
        );

        //TODO: determine if this is the best solution
        const urlParams = location.pathname.split('/');
        // if the last index of the params is a number
        // then we are inside of an update form
        if (Number(urlParams.at(-1))) {
            //remove the number from the route
            urlParams.pop();
            const newLocation = urlParams.join('/');
            //redirect to the appropiate grid page
            history.push(newLocation);
        }
        const { data: businessEntityData } = businessEntity;

        updateUser(body);
        dispatch(handleUpdateUserBusinessEntity(businessEntityData));
    };

    return (
        <div>
            {!shouldLoad && (
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={2}>
                        {''}
                    </Grid>
                    <Grid item xs={4}>
                        {user.isDatacorAdmin ? (
                            <SelectDropdown
                                value={getCurrentValue()}
                                options={subscriberOptions}
                                onChange={handleUpdateSubscriber}
                            />
                        ) : (
                            <p
                                style={{
                                    fontSize: '16px',
                                    color: 'black',
                                    textAlign: 'right',
                                }}>
                                {(subscriberOptions &&
                                    subscriberOptions
                                        ?.find(
                                            (subscriber: any) =>
                                                subscriber.value ===
                                                user?.subscriber?.id
                                        )
                                        ?.label.toString()) + ' - ' || ''}
                            </p>
                        )}
                    </Grid>

                    <Grid item xs={4}>
                        {!isLoadingBusinessEntities &&
                        !loading &&
                        !isFetchingBusinessEntities ? (
                            <ExpandableDropdown
                                size="small"
                                valueSelected={handleUpdateBusinessEntity}
                                defaultValue={Number(user?.businessEntity?.id)}
                                items={businessEntityOptions}
                                idKey="id"
                                label="Business Entity"
                                labelKey="name"
                                id="businessEntityId"
                                disableClearable
                            />
                        ) : (
                            <DatacorLogoSpinner size={'40px'} />
                        )}
                    </Grid>
                </Grid>
            )}
        </div>
    );
};

export default BusinessEntitySelect;
