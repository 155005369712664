import React from 'react';
import {
    DesktopDatePicker,
    DesktopDatePickerProps,
} from '@mui/x-date-pickers/DesktopDatePicker';

export type DateInputProps = Omit<DesktopDatePickerProps, 'onChange'> & {
    onChange: (date: Date) => void;
};

const DateInput = (props: DateInputProps) => {
    // @ts-ignore
    return <DesktopDatePicker {...props} />;
};

export default DateInput;
