import React from 'react';
import { Card, CardContent, CardHeader } from '@mui/material';
import usePermissionTree from './hooks/usePermissionTree';
import TreeView from '../../components/TreeView/TreeView';
import DatacorLogoSpinner from '../../components/DatacorLogoSpinner/DatacorLogoSpinner';

const PermissionTree = () => {
    const { permissions, isLoading } = usePermissionTree();

    return (
        <Card>
            <CardHeader title={'Permissions'} />
            <CardContent>
                {!isLoading ? (
                    <TreeView dataSource={permissions} type="org" />
                ) : (
                    <DatacorLogoSpinner />
                )}
            </CardContent>
        </Card>
    );
};

export default PermissionTree;
