import { ParameterizedValidator, Validator } from './validator.interface';
import { isNil } from '../utils/objectUtils';

export const GreaterThanValidator: ParameterizedValidator = (
    greaterThan: number
) => {
    return {
        key: 'greaterThan',
        getErrorMessage: (fieldLabel: string) => {
            return `${fieldLabel} must be greater than or equal to ${greaterThan}`;
        },
        isValid: (value: any) => {
            return isNil(value) || Number(value) >= greaterThan;
        },
    } as Validator;
};
