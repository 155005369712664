import React from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardHeader, Grid } from '@mui/material';
import FormContainer from '../../components/Form/FormContainer/FormContainer';
import FixedAssetsFormHeader from '../../components/Form/FormContainer/FormHeader';
import TextInput from '../../components/Form/FormInputs/TextInput/TextInput';
import FormPartial from '../../components/Form/FormContainer/FormPartial';
import DatacorLogoSpinner from '../../components/DatacorLogoSpinner/DatacorLogoSpinner';
import useDepartmentCodeForm from './hooks/useDepartmentCodeForm';

interface ParamTypes {
    id: string;
}

const DepartmentCodeForm = () => {
    const { id } = useParams<ParamTypes>();
    const {
        fields,
        handleClose,
        handleFieldChange,
        handleUpdateDepartmentCode,
        handleCreateDepartmentCode,
        isLoading,
        formMethods,
    } = useDepartmentCodeForm(id);

    return (
        <Card>
            <CardHeader
                title={id ? fields.name : 'Create New Department Code'}
            />
            {isLoading ? (
                <DatacorLogoSpinner />
            ) : (
                <CardContent>
                    <FormContainer
                        headerTitle={`Primary Information`}
                        onSubmit={() => formMethods.setIsFormSubmitted(true)}
                        headerComponent={
                            <FixedAssetsFormHeader
                                showOnDelete={false}
                                showOnSave={true}
                                onSave={async () => {
                                    if (!id) {
                                        await handleCreateDepartmentCode();
                                    } else {
                                        await handleUpdateDepartmentCode();
                                    }
                                }}
                                onDelete={handleClose}
                                onCancel={handleClose}
                                isEdit={!!id}
                            />
                        }>
                        <FormPartial>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextInput
                                        fullWidth
                                        label="Name"
                                        id="name"
                                        value={fields.name}
                                        onChange={handleFieldChange}
                                        required={true}
                                        maxLength={90}
                                        formMethods={formMethods}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextInput
                                        fullWidth
                                        label="Code"
                                        id="code"
                                        value={fields.code}
                                        onChange={handleFieldChange}
                                        maxLength={90}
                                        formMethods={formMethods}
                                    />
                                </Grid>
                            </Grid>
                        </FormPartial>
                    </FormContainer>
                </CardContent>
            )}
        </Card>
    );
};

export default DepartmentCodeForm;
