import React from 'react';
import { AgGridColumn } from 'ag-grid-react';
import Grid from '../../../components/Grids/Grid';
import DatacorLogoSpinner from '../../../components/DatacorLogoSpinner/DatacorLogoSpinner';
import { GridOptions } from '../../../components/Grids/grid.constants';
import UseFixedAssetDepreciationMethodTypeGrid from '../hooks/useFixedAssetDepreciationMethodTypeGrid';

const FixedAssetDepreciationMethodTypeGrid = () => {
    const { typeList, redirectToForm, isLoadingTypes } =
        UseFixedAssetDepreciationMethodTypeGrid();

    return isLoadingTypes ? (
        <DatacorLogoSpinner />
    ) : (
        <Grid
            rowData={typeList}
            gridClass="full-width-grid"
            displayToolbar={true}
            displayCreateNewButton={true}
            onCreateNew={redirectToForm}
            onRowClicked={({ data }) => redirectToForm(data)}
            defaultColDef={GridOptions.sortFilterAndWrapColumns}>
            <AgGridColumn
                minWidth={100}
                field="sortOrder"
                headerName="Sort Order"
            />
            <AgGridColumn
                minWidth={150}
                field="code"
                headerName="Depreciation Method Type Code"
            />
            <AgGridColumn
                minWidth={250}
                field="description"
                headerName="Description"
            />
            <AgGridColumn
                minWidth={100}
                field="postsJournalEntries"
                headerName="Journal Posting"
            />
            <AgGridColumn
                minWidth={100}
                field="predefinedSchedule"
                headerName="Predefined Schedule"
            />
            <AgGridColumn
                minWidth={100}
                field="usageBased"
                headerName="Usage Based"
            />
        </Grid>
    );
};

export default FixedAssetDepreciationMethodTypeGrid;
