import { AgGridColumn } from 'ag-grid-react';
import React from 'react';
import moment from 'moment';
import CreateCompany from './CreateCompany';
import CompanyKeySecretModal from './CompanyKeySecretModal';
import AgGrid from '../../components/Grids/Grid';
import DatacorLogoSpinner from '../../components/DatacorLogoSpinner/DatacorLogoSpinner';
import useCompaniesGrid from './hooks/useCompaniesGrid';

const CompaniesGrid = () => {
    const {
        handleCompanyKeySecretModalClose,
        handleCreateCompanyModalClose,
        handleCreateCompanyModalOpen,
        Zones,
        onGridReady,
        canCreateCompany,
        newCompanyResponse,
        showCompanyKeySecretModal,
        showCreateCompanyModal,
        companiesList,
        isLoading,
    } = useCompaniesGrid();

    return (
        <>
            <CreateCompany
                show={showCreateCompanyModal}
                handleClose={handleCreateCompanyModalClose}
            />
            <CompanyKeySecretModal
                show={showCompanyKeySecretModal}
                handleClose={handleCompanyKeySecretModalClose}
                newCompanyResponse={newCompanyResponse}
            />
            {!isLoading ? (
                <AgGrid
                    testId="company-list-grid"
                    dataKey="companyID"
                    immutableData={true}
                    rowData={companiesList}
                    displayToolbar={true}
                    onCreateNew={handleCreateCompanyModalOpen}
                    displayCreateNewButton={canCreateCompany}
                    onGridReady={onGridReady}
                    defaultColDef={{
                        resizable: true,
                        sortable: true,
                        unSortIcon: true,
                        filter: true,
                        wrapText: true,
                    }}
                    frameworkComponents={{
                        zones: Zones,
                    }}>
                    <AgGridColumn
                        flex={1}
                        minWidth={250}
                        field="companyName"
                        headerName="Company Name"
                    />
                    <AgGridColumn
                        minWidth={200}
                        field="companyID"
                        headerName="Company ID"
                    />
                    <AgGridColumn
                        minWidth={100}
                        field="platform"
                        headerName="Platform"
                    />
                    <AgGridColumn
                        minWidth={100}
                        field="status"
                        headerName="Status"
                    />
                    <AgGridColumn
                        minWidth={150}
                        field="zones"
                        cellRenderer="zones"
                        headerName="Zones"
                    />
                    <AgGridColumn
                        minWidth={150}
                        field="techservID"
                        headerName="TechServ ID"
                    />
                    <AgGridColumn
                        minWidth={100}
                        field="setupThirdPartyAuth"
                        headerName="Has 3rd Party API Authorization"
                        valueFormatter={(val) =>
                            val.value ? val.value : false
                        }
                    />
                    <AgGridColumn
                        minWidth={200}
                        field="dateCreated"
                        headerName="Date Created"
                        valueFormatter={(val) =>
                            val.value && moment(val.value).isValid()
                                ? moment(val.value).format('L')
                                : ''
                        }
                    />
                    <AgGridColumn
                        minWidth={200}
                        field="lastUpdateDate"
                        headerName="Last Update Date"
                        valueFormatter={(val) =>
                            val.value && moment(val.value).isValid()
                                ? moment(val.value).format('L')
                                : ''
                        }
                    />
                </AgGrid>
            ) : (
                <DatacorLogoSpinner />
            )}
        </>
    );
};

export default CompaniesGrid;
