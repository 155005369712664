import React from 'react';
import TreeView from '../../../components/TreeView/TreeView';
import LoaderButton from '../../../components/LoaderButton';
import { isNil } from '../../../utils/objectUtils';
import './PermissionTree.css';
import ToastContainer from '../../../components/Toast/ToastContainer';
import DatacorLogoSpinner from '../../../components/DatacorLogoSpinner/DatacorLogoSpinner';
import { Card, CardContent } from '@mui/material';
import usePermissionTree from '../hooks/usePermissionTree';

interface PermissionTreeProps {
    roleId: string;
}

const PermissionTree = (props: PermissionTreeProps) => {
    const {
        roleDetails,
        treeData,
        onCheckChange,
        showToast,
        setShowToast,
        savingPermissionChanges,
        handleSubmit,
        onSubmit,
    } = usePermissionTree(props);
    return (
        <>
            {roleDetails ? (
                <Card>
                    <CardContent>
                        <div className="permissionTreePanel">
                            <TreeView
                                caption="Permissions"
                                dataSource={treeData}
                                onCheckChange={onCheckChange}
                                data-testid="permissionTree"
                            />
                        </div>
                        <LoaderButton
                            type="submit"
                            size="lg"
                            variant="contained"
                            color="primary"
                            isLoading={savingPermissionChanges}
                            onClick={handleSubmit(onSubmit)}
                            disabled={isNil(treeData)}>
                            Update
                        </LoaderButton>
                        <ToastContainer
                            show={showToast}
                            close={() => setShowToast(false)}
                            bodyText="You'll need to log out and log in again for these changes to take effect."
                        />
                    </CardContent>
                </Card>
            ) : (
                <DatacorLogoSpinner />
            )}
        </>
    );
};

export default PermissionTree;
