import React from 'react';
import { AgGridColumn } from 'ag-grid-react';
import Grid from '../../../components/Grids/Grid';
import DatacorLogoSpinner from '../../../components/DatacorLogoSpinner/DatacorLogoSpinner';
import useFixedAssetFunctionCodesGrid from '../hooks/useFixedAssetFunctionCodesGrid';

const FixedAssetFunctionCodesGrid = () => {
    const {
        functionCodeList,
        isLoading,
        AccountNumberColumn,
        AccountNameColumn,
        redirectToForm,
    } = useFixedAssetFunctionCodesGrid();

    return isLoading ? (
        <DatacorLogoSpinner />
    ) : (
        <Grid
            rowData={functionCodeList}
            gridClass="full-width-grid"
            displayToolbar={true}
            displayCreateNewButton={false}
            onRowClicked={(row) => redirectToForm(row.data.id)}
            defaultColDef={{
                resizable: true,
            }}
            frameworkComponents={{
                accountNumberColumn: AccountNumberColumn,
                accountNameColumn: AccountNameColumn,
            }}>
            <AgGridColumn
                minWidth={200}
                field="code"
                headerName="Function Code"
            />
            <AgGridColumn
                minWidth={200}
                headerName="Account Number"
                cellRenderer="accountNumberColumn"
                cellStyle={{
                    overflow: 'visible',
                    whiteSpace: 'normal',
                    height: 'auto',
                }}
                autoHeight={true}
            />
            <AgGridColumn
                minWidth={750}
                headerName="Account Name"
                cellRenderer="accountNameColumn"
                cellStyle={{
                    overflow: 'visible',
                    whiteSpace: 'normal',
                    height: 'auto',
                }}
                autoHeight={true}
            />
        </Grid>
    );
};

export default FixedAssetFunctionCodesGrid;
