import React from 'react';
import { Card, CardContent, CardHeader, Grid } from '@mui/material';
import DatacorLogoSpinner from '../../components/DatacorLogoSpinner/DatacorLogoSpinner';
import FormContainer from '../../components/Form/FormContainer/FormContainer';
import FixedAssetsFormHeader from '../../components/Form/FormContainer/FormHeader';
import FormPartial from '../../components/Form/FormContainer/FormPartial';
import TextInput from '../../components/Form/FormInputs/TextInput/TextInput';
import SelectInput from '../../components/Form/FormInputs/SelectInput/SelectInput';
import useBuildingsForm from './hooks/useBuildingsForm';
import { useParams } from 'react-router-dom';

interface ParamTypes {
    id: string;
}

const BuildingsForm = () => {
    const { id } = useParams<ParamTypes>();
    const {
        fields,
        addressFields,
        isLoadingBuilding,
        handleCreateBuilding,
        handleUpdateBuilding,
        handleFieldChange,
        handleAddressFieldChange,
        handleClose,
        formMethods,
        addressFormMethods,
        countryOptions,
        stateSelectOptions,
        loading,
    } = useBuildingsForm(id);

    return (
        <Card>
            <CardHeader title={id ? fields.name : 'Create New Building'} />
            {isLoadingBuilding || loading ? (
                <DatacorLogoSpinner />
            ) : (
                <CardContent>
                    <FormContainer
                        headerTitle={`Primary Information`}
                        onSubmit={() => {
                            formMethods.setIsFormSubmitted(true);
                            addressFormMethods.setIsFormSubmitted(true);
                        }}
                        headerComponent={
                            <FixedAssetsFormHeader
                                showOnDelete={false}
                                showOnSave={true}
                                onSave={async () => {
                                    if (!id) {
                                        await handleCreateBuilding();
                                    } else {
                                        await handleUpdateBuilding();
                                    }
                                }}
                                onDelete={handleClose}
                                onCancel={handleClose}
                                isEdit={!!id}
                            />
                        }>
                        <FormPartial>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextInput
                                        fullWidth
                                        label="Building Code"
                                        id="code"
                                        value={fields.code}
                                        formMethods={formMethods}
                                        onChange={handleFieldChange}
                                        required={true}
                                        maxLength={90}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextInput
                                        fullWidth
                                        label="Building Name"
                                        id="name"
                                        value={fields.name}
                                        formMethods={formMethods}
                                        onChange={handleFieldChange}
                                        maxLength={90}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextInput
                                        fullWidth
                                        label="Supplier Code"
                                        id="supplierId"
                                        value={fields.supplierId || ''}
                                        formMethods={formMethods}
                                        onChange={handleFieldChange}
                                        maxLength={90}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextInput
                                        fullWidth
                                        label="Customer Code"
                                        id="customerId"
                                        value={fields.customerId || ''}
                                        formMethods={formMethods}
                                        onChange={handleFieldChange}
                                        maxLength={90}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextInput
                                        fullWidth
                                        label="Note"
                                        id="note"
                                        value={fields.note}
                                        onChange={handleFieldChange}
                                        maxLength={300}
                                    />
                                </Grid>
                            </Grid>
                        </FormPartial>
                        <FormPartial title="Address Information">
                            <Grid container spacing={2}>
                                {id ? (
                                    <>
                                        <Grid item xs={6}>
                                            <TextInput
                                                fullWidth
                                                label="Street"
                                                id="street"
                                                value={addressFields.street}
                                                formMethods={addressFormMethods}
                                                onChange={
                                                    handleAddressFieldChange
                                                }
                                                required={true}
                                                multiline
                                                rows={4}
                                                maxLength={300}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextInput
                                                fullWidth
                                                label="City"
                                                id="city"
                                                value={addressFields.city}
                                                formMethods={addressFormMethods}
                                                onChange={
                                                    handleAddressFieldChange
                                                }
                                                required={true}
                                                maxLength={90}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextInput
                                                fullWidth
                                                label="County"
                                                id="county"
                                                value={addressFields.county}
                                                formMethods={addressFormMethods}
                                                onChange={
                                                    handleAddressFieldChange
                                                }
                                                required={true}
                                                maxLength={90}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <SelectInput
                                                fullWidth
                                                label="State / Province"
                                                id="stateCodeId"
                                                value={
                                                    addressFields?.stateCodeId
                                                }
                                                onChange={
                                                    handleAddressFieldChange
                                                }
                                                options={
                                                    stateSelectOptions || []
                                                }
                                                formMethods={addressFormMethods}
                                                required={true}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextInput
                                                fullWidth
                                                label="Zip"
                                                id="postalCode"
                                                value={
                                                    addressFields?.postalCode
                                                }
                                                onChange={
                                                    handleAddressFieldChange
                                                }
                                                required={true}
                                                maxLength={90}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <SelectInput
                                                fullWidth
                                                label="Country"
                                                id="countryCodeId"
                                                value={
                                                    addressFields?.countryCodeId
                                                }
                                                onChange={
                                                    handleAddressFieldChange
                                                }
                                                options={countryOptions || []}
                                                formMethods={addressFormMethods}
                                                required={true}
                                            />
                                        </Grid>
                                    </>
                                ) : (
                                    <>
                                        <Grid item xs={6}>
                                            <SelectInput
                                                fullWidth
                                                label="Country"
                                                id="countryCodeId"
                                                value={
                                                    addressFields?.countryCodeId
                                                }
                                                onChange={
                                                    handleAddressFieldChange
                                                }
                                                options={countryOptions || []}
                                                formMethods={addressFormMethods}
                                                required={true}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextInput
                                                fullWidth
                                                label="Zip"
                                                id="postalCode"
                                                value={
                                                    addressFields?.postalCode
                                                }
                                                onChange={
                                                    handleAddressFieldChange
                                                }
                                                required={true}
                                                maxLength={90}
                                                disabled={
                                                    addressFields?.countryCodeId
                                                        ?.length <= 0
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <SelectInput
                                                fullWidth
                                                label="State / Province"
                                                id="stateCodeId"
                                                value={
                                                    addressFields?.stateCodeId
                                                }
                                                onChange={
                                                    handleAddressFieldChange
                                                }
                                                options={
                                                    stateSelectOptions || []
                                                }
                                                formMethods={addressFormMethods}
                                                required={true}
                                                disabled={
                                                    addressFields?.postalCode
                                                        ?.length <= 0
                                                }
                                            />
                                        </Grid>

                                        <Grid item xs={6}>
                                            <TextInput
                                                fullWidth
                                                label="Street"
                                                id="street"
                                                value={addressFields.street}
                                                formMethods={addressFormMethods}
                                                onChange={
                                                    handleAddressFieldChange
                                                }
                                                required={true}
                                                multiline
                                                rows={4}
                                                maxLength={300}
                                                disabled={
                                                    addressFields?.stateCodeId
                                                        ?.length <= 0
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextInput
                                                fullWidth
                                                label="County"
                                                id="county"
                                                value={addressFields.county}
                                                formMethods={addressFormMethods}
                                                onChange={
                                                    handleAddressFieldChange
                                                }
                                                required={true}
                                                maxLength={90}
                                                disabled={
                                                    addressFields?.street
                                                        ?.length <= 0
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextInput
                                                fullWidth
                                                label="City"
                                                id="city"
                                                value={addressFields.city}
                                                formMethods={addressFormMethods}
                                                onChange={
                                                    handleAddressFieldChange
                                                }
                                                required={true}
                                                maxLength={90}
                                                disabled={
                                                    addressFields?.county
                                                        ?.length <= 0
                                                }
                                            />
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </FormPartial>
                    </FormContainer>
                </CardContent>
            )}
        </Card>
    );
};

export default BuildingsForm;
