import React from 'react';
import { Alert, AlertColor, Snackbar, SnackbarOrigin } from '@mui/material';

export interface ToastContainerProps {
    show: boolean;
    close: () => void;
    bodyText: string;
    autoHideDuration?: number;
    anchorOrigin?: SnackbarOrigin;
    severity?: AlertColor;
}

const ToastContainer = (props: ToastContainerProps) => {
    return (
        <Snackbar
            anchorOrigin={
                props.anchorOrigin || {
                    vertical: 'top',
                    horizontal: 'right',
                }
            }
            autoHideDuration={props.autoHideDuration || 5000}
            open={props.show}
            onClose={props.close}>
            <Alert
                onClose={props.close}
                severity={props.severity || 'info'}
                sx={{
                    width: '100%',
                    textAlign: 'center',
                    whiteSpace: 'pre-line',
                }}>
                {props.bodyText}
            </Alert>
        </Snackbar>
    );
};

export default ToastContainer;
