import React from 'react';
import { Card, CardContent, CardHeader, Grid, TextField } from '@mui/material';
import { useParams } from 'react-router-dom';
import FormContainer from '../../components/Form/FormContainer/FormContainer';
import DatacorLogoSpinner from '../../components/DatacorLogoSpinner/DatacorLogoSpinner';
import useContractTypeForm from './hooks/useContractTypeForm';
import FormPartial from '../../components/Form/FormContainer/FormPartial';
import TextInput from '../../components/Form/FormInputs/TextInput/TextInput';
import FixedAssetsFormHeader from '../../components/Form/FormContainer/FormHeader';
import ToastContainer from '../../components/Toast/ToastContainer';
import NumericInput from '../../components/Form/FormInputs/NumericInput/NumericInput';

interface ParamTypes {
    contractTypeId: string;
}

const ContractTypeForm = () => {
    const { contractTypeId } = useParams<ParamTypes>();
    const {
        fields,
        handleFieldChange,
        formMethods,
        toastProps,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        isLoadingContractType,
    } = useContractTypeForm(contractTypeId);

    return (
        <Card>
            <CardHeader title="Contract Type" />

            {isLoadingContractType ? (
                <DatacorLogoSpinner />
            ) : (
                <CardContent>
                    <ToastContainer {...toastProps} show={!!toastProps?.show} />

                    <FormContainer
                        headerTitle={`Primary Information`}
                        onSubmit={() => formMethods.setIsFormSubmitted(true)}
                        headerComponent={
                            <FixedAssetsFormHeader
                                showOnDelete={!!contractTypeId}
                                showOnSave={true}
                                onSave={() => {
                                    if (!contractTypeId) {
                                        onCreate();
                                    } else {
                                        onUpdate();
                                    }
                                }}
                                onDelete={() => {
                                    onDelete();
                                }}
                                onCancel={closeForm}
                                isEdit={!!contractTypeId}
                            />
                        }>
                        <FormPartial>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextInput
                                        fullWidth
                                        label="Code"
                                        id="code"
                                        value={fields.code}
                                        onChange={handleFieldChange}
                                        formMethods={formMethods}
                                        required={true}
                                        maxLength={30}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <NumericInput
                                        fullWidth
                                        label="Sort Order"
                                        id="sortOrder"
                                        value={fields.sortOrder}
                                        onChange={handleFieldChange}
                                        formMethods={formMethods}
                                        required={true}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextInput
                                        fullWidth
                                        label="Description"
                                        id="description"
                                        value={fields.description}
                                        onChange={handleFieldChange}
                                        formMethods={formMethods}
                                        maxLength={300}
                                    />
                                </Grid>
                            </Grid>
                        </FormPartial>
                    </FormContainer>
                </CardContent>
            )}
        </Card>
    );
};

export default ContractTypeForm;
