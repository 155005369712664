import React from 'react';
import { useHistory } from 'react-router-dom';
import { ICellRendererParams } from 'ag-grid-community';
import { AccountFunction } from '../../../types/Accounting.types';
import { useGetAllFixedAssetFunctionCodesQuery } from '../../../services/accounting/accounting.service';

const useFixedAssetFunctionCodesGrid = () => {
    const history = useHistory();
    const { data: functionCodeList, isLoading } =
        useGetAllFixedAssetFunctionCodesQuery();

    const redirectToForm = (id: bigint) => {
        history.push(`/accounting/fixedAssets/functionCode/${id}`);
    };

    const AccountNumberColumn = (params: ICellRendererParams) => {
        return (
            <div>
                {params.data.functions.map((aFunction: AccountFunction) => {
                    return (
                        <div key={aFunction.account.number}>
                            {aFunction.account.number}
                        </div>
                    );
                })}
            </div>
        );
    };

    const AccountNameColumn = (params: ICellRendererParams) => {
        return (
            <div>
                {params.data.functions.map((aFunction: AccountFunction) => {
                    return (
                        <div key={aFunction.account.number}>
                            {aFunction.account.name}
                        </div>
                    );
                })}
            </div>
        );
    };

    return {
        functionCodeList,
        isLoading,
        AccountNumberColumn,
        AccountNameColumn,
        redirectToForm,
    };
};

export default useFixedAssetFunctionCodesGrid;
