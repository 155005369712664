import React from 'react';
import { Controller } from 'react-hook-form';
import LoaderButton from '../../../components/LoaderButton';
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    TextField,
    FormGroup,
    Grid,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DatacorLogoSpinner from '../../../components/DatacorLogoSpinner/DatacorLogoSpinner';
import SelectDropdown from '../../../components/Form/SelectDropdown/SelectDropdown';
import useCreateRole from '../hooks/useCreateRole';

interface CreateRoleProps {
    handleClose: (_: boolean) => void;
}

const CreateRole = ({ handleClose }: CreateRoleProps) => {
    const {
        control,
        formState,
        isLoading,
        isLoadingRole,
        selectedCompany,
        companyList,
        companySelectionChanged,
        handleSubmit,
        onSubmit,
    } = useCreateRole({
        handleClose,
    });
    return (
        <Dialog
            open={true}
            onClose={handleClose}
            data-testid="create-new-role-modal"
            className="stepInfoModal">
            <DialogTitle>
                <Box display="flex" alignItems="center">
                    <Box flexGrow={1}>Create New Role</Box>
                    <Box>
                        <IconButton onClick={() => handleClose(true)}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
            </DialogTitle>

            <DialogContent>
                {!isLoading ? (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Controller
                                name="name"
                                control={control}
                                render={(field) => (
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        data-testid="roleName"
                                        name="name"
                                        label="Role Name"
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="note"
                                control={control}
                                render={(field) => (
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        label="Note"
                                        data-testid="roleNote"
                                        name="note"
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormGroup>
                                <SelectDropdown
                                    value={selectedCompany}
                                    placeholder={'select Company Identifiers'}
                                    options={companyList.map((company: any) => {
                                        return {
                                            value: company.companyID,
                                            label: company.companyID,
                                        };
                                    })}
                                    id="company-id"
                                    className="basic-select basic-multi-select"
                                    classNamePrefix="select"
                                    maxMenuHeight={175}
                                    menuPlacement={'top'}
                                    onChange={companySelectionChanged}
                                />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12}>
                            <LoaderButton
                                variant="contained"
                                fullWidth
                                color="primary"
                                type="submit"
                                data-testid="createRoleBtn"
                                onClick={handleSubmit(onSubmit)}
                                isLoading={isLoadingRole}
                                disabled={!formState.isValid}>
                                Create Role
                            </LoaderButton>
                        </Grid>
                    </Grid>
                ) : (
                    <DatacorLogoSpinner />
                )}
            </DialogContent>
        </Dialog>
    );
};

export default CreateRole;
