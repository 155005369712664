import { Auth } from 'aws-amplify';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import config from '../../config';
import {
    BaseCreateEntityArgs,
    BaseUpdateEntityArgs,
} from '../serviceInterfaces';
import {
    AccountFunction,
    AccountFunctionCode,
    Contract,
    ContractType,
    GeneralLedgerAccount,
} from '../../types/Accounting.types';

export const accountingAPI = createApi({
    reducerPath: 'accountingApi',

    baseQuery: fetchBaseQuery({
        baseUrl: config.apiGateway.URL,
        prepareHeaders: async (headers, { getState }) => {
            const token = (await Auth.currentSession())
                .getIdToken()
                .getJwtToken();
            // If we have a token set in state, let's assume that we should be passing it.
            if (token) {
                headers.set('Authorization', token);
            }

            return headers;
        },
    }),

    tagTypes: [
        'AllGeneralLedgerAccounts',
        'ActiveGeneralLedgerAccount',
        'AllContracts',
        'ActiveContract',
        'AllContractTypes',
        'ActiveContractType',
        'AllFunctionCodes',
        'ActiveFunctionCode',
        'AllFixedAssetAccountFunctions',
        'FixedAssetFunctionCodes',
    ],
    endpoints: (build) => ({
        //region General Ledger Accounts
        createGeneralLedgerAccount: build.mutation<
            any,
            BaseCreateEntityArgs<GeneralLedgerAccount>
        >({
            query: (contractType) => ({
                url: 'accounting/account',
                method: 'POST',
                body: contractType.postBody,
            }),
            invalidatesTags: [
                'AllGeneralLedgerAccounts',
                'AllFixedAssetAccountFunctions',
            ],
        }),

        updateGeneralLedgerAccount: build.mutation<
            any,
            BaseUpdateEntityArgs<GeneralLedgerAccount>
        >({
            query: (args) => ({
                url: `accounting/account/${args.id}`,
                method: 'PATCH',
                body: args.postBody,
            }),
            invalidatesTags: [
                'AllGeneralLedgerAccounts',
                'ActiveGeneralLedgerAccount',
                'AllFixedAssetAccountFunctions',
                'FixedAssetFunctionCodes',
            ],
        }),

        getGeneralLedgerAccount: build.query<any, string>({
            query: (id) => `accounting/account/${id}?full=true`,
            providesTags: ['ActiveGeneralLedgerAccount'],
        }),

        getAllGeneralLedgerAccounts: build.query<any, void>({
            query: () => `accounting/account/getAll`,
            providesTags: ['AllGeneralLedgerAccounts'],
        }),

        deleteGeneralLedgerAccount: build.mutation<any, string>({
            query: (id) => ({
                url: `accounting/account/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['AllGeneralLedgerAccounts'],
        }),
        //endregion

        //region Account Functions
        getAllGeneralLedgerAccountFunctionsForDropdowns: build.query<any, void>(
            {
                query: () => `accounting/accountFunction/getAll`,
                transformResponse: (response: AccountFunction[]) => {
                    const test = {
                        depreciationExpenseAccounts: response
                            .filter(
                                (af: AccountFunction) =>
                                    af.account && af.functionCodeId === 1
                            )
                            .map((af: AccountFunction) => {
                                return {
                                    value: af.account.id,
                                    label: af.account.number,
                                };
                            }),
                        accumulateDepreciationAccounts: response
                            .filter(
                                (af: AccountFunction) =>
                                    af.account && af.functionCodeId === 2
                            )
                            .map((af: AccountFunction) => {
                                return {
                                    value: af.account.id,
                                    label: af.account.number,
                                };
                            }),
                        assetAccounts: response
                            .filter(
                                (af: AccountFunction) =>
                                    af.account && af.functionCodeId === 3
                            )
                            .map((af: AccountFunction) => {
                                return {
                                    value: af.account.id,
                                    label: af.account.number,
                                };
                            }),
                        assetControlAccounts: response
                            .filter(
                                (af: AccountFunction) =>
                                    af.account && af.functionCodeId === 4
                            )
                            .map((af: AccountFunction) => {
                                return {
                                    value: af.account.id,
                                    label: af.account.number,
                                };
                            }),
                        disposalGainAccounts: response
                            .filter(
                                (af: AccountFunction) =>
                                    af.account && af.functionCodeId === 5
                            )
                            .map((af: AccountFunction) => {
                                return {
                                    value: af.account.id,
                                    label: af.account.number,
                                };
                            }),
                        disposalLossAccounts: response
                            .filter(
                                (af: AccountFunction) =>
                                    af.account && af.functionCodeId === 6
                            )
                            .map((af: AccountFunction) => {
                                return {
                                    value: af.account.id,
                                    label: af.account.number,
                                };
                            }),
                        disposalSettlementAccounts: response
                            .filter(
                                (af: AccountFunction) =>
                                    af.account && af.functionCodeId === 7
                            )
                            .map((af: AccountFunction) => {
                                return {
                                    value: af.account.id,
                                    label: af.account.number,
                                };
                            }),
                    };

                    return test;
                },
                providesTags: ['AllFixedAssetAccountFunctions'],
            }
        ),
        //endregion

        //region Contracts
        createContract: build.mutation<any, BaseCreateEntityArgs<Contract>>({
            query: (args) => ({
                url: 'accounting/contract',
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['AllContracts'],
        }),

        updateContract: build.mutation<any, BaseUpdateEntityArgs<Contract>>({
            query: (args) => ({
                url: `accounting/contract/${args.id}`,
                method: 'PATCH',
                body: args.postBody,
            }),
            invalidatesTags: ['AllContracts', 'ActiveContract'],
        }),

        getContract: build.query<any, string>({
            query: (id) => `accounting/contract/${id}`,
            providesTags: ['ActiveContract'],
        }),

        getAllContracts: build.query<any, void>({
            query: () => `accounting/contract/getAll`,
            providesTags: ['AllContracts'],
        }),

        deleteContract: build.mutation<any, string>({
            query: (id) => ({
                url: `accounting/contract/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['AllContracts'],
        }),
        //endregion

        //region Contract Types
        createContractType: build.mutation<
            any,
            BaseCreateEntityArgs<ContractType>
        >({
            query: (args) => ({
                url: 'accounting/contractType',
                method: 'POST',
                body: args.postBody,
            }),
            invalidatesTags: ['AllContractTypes'],
        }),

        updateContractType: build.mutation<
            any,
            BaseUpdateEntityArgs<ContractType>
        >({
            query: (args) => ({
                url: `accounting/contractType/${args.id}`,
                method: 'PATCH',
                body: args.postBody,
            }),
            invalidatesTags: ['AllContractTypes', 'ActiveContractType'],
        }),

        getContractType: build.query<any, string>({
            query: (id) => `accounting/contractType/${id}`,
            providesTags: ['ActiveContractType'],
        }),

        getAllContractTypes: build.query<any, void>({
            query: () => `accounting/contractType/getAll`,
            providesTags: ['AllContractTypes'],
        }),

        getContractTypesForDropdown: build.query<any, void>({
            query: () => `accounting/contractType/getAll`,
            transformResponse: (response: ContractType[]) => {
                return response.map((cType: ContractType) => {
                    return { value: cType.id, label: cType.code };
                });
            },
            providesTags: ['AllContractTypes'],
        }),

        deleteContractType: build.mutation<any, string>({
            query: (id) => ({
                url: `accounting/contractType/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['AllContractTypes'],
        }),
        //endregion

        //region Function Codes
        getFixedAssetFunctionCode: build.query<any, string>({
            query: (id) =>
                `accounting/functionCodes/${id}?includeAvailableAccounts=true`,
            providesTags: ['ActiveFunctionCode'],
        }),

        updateFunctionCode: build.mutation<
            any,
            BaseUpdateEntityArgs<AccountFunctionCode>
        >({
            query: (args) => ({
                url: `accounting/functionCodes/${args.id}`,
                method: 'PATCH',
                body: args.postBody,
            }),
            invalidatesTags: [
                'AllFunctionCodes',
                'FixedAssetFunctionCodes',
                'ActiveFunctionCode',
            ],
        }),

        getAllFunctionCodes: build.query<any, void>({
            query: () => `accounting/functionCodes/getAll`,
            providesTags: ['AllFunctionCodes'],
        }),

        getAllFixedAssetFunctionCodes: build.query<any, void>({
            query: () =>
                `accounting/functionCodes/getAll?functionGroup=Fixed Assets`,
            providesTags: ['FixedAssetFunctionCodes'],
        }),
        //endregion
    }),
});

export const {
    useGetGeneralLedgerAccountQuery,
    useGetAllGeneralLedgerAccountsQuery,
    useCreateGeneralLedgerAccountMutation,
    useUpdateGeneralLedgerAccountMutation,
    useDeleteGeneralLedgerAccountMutation,
    useGetAllGeneralLedgerAccountFunctionsForDropdownsQuery,
    useGetContractQuery,
    useGetAllContractsQuery,
    useCreateContractMutation,
    useUpdateContractMutation,
    useDeleteContractMutation,
    useGetContractTypeQuery,
    useGetAllContractTypesQuery,
    useGetContractTypesForDropdownQuery,
    useCreateContractTypeMutation,
    useUpdateContractTypeMutation,
    useDeleteContractTypeMutation,
    useGetFixedAssetFunctionCodeQuery,
    useGetAllFunctionCodesQuery,
    useUpdateFunctionCodeMutation,
    useGetAllFixedAssetFunctionCodesQuery,
} = accountingAPI;
