import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardHeader, CardContent } from '@mui/material';
import AuditList from './AuditList';
import ContactList from './ContactList';
import LoaderButton from '../../../components/LoaderButton';

const WorkflowTestHarnessPortal = () => {
    return (
        <>
            <Card>
                <CardHeader
                    style={{ height: '50px' }}
                    title="Workflow Test Harness"
                    action={
                        <Link to="/workflow/">
                            <LoaderButton variant="primary" size="lg">
                                Workflow Portal
                            </LoaderButton>
                        </Link>
                    }></CardHeader>
                <CardContent>
                    <div className="row">
                        <div className="col-6">
                            <ContactList></ContactList>
                        </div>

                        <div className="col-6">
                            <AuditList></AuditList>
                        </div>
                    </div>
                </CardContent>
            </Card>
        </>
    );
};

export default WorkflowTestHarnessPortal;
