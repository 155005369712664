import React, { useEffect, useState } from 'react';
import './App.css';
import Landing from './containers/Landing/Landing';
import './styles/app.scss';
import Login from './containers/Login/Login';
import { useDispatch, useSelector } from 'react-redux';
import { setUserDetails, setUserDetailsLegacy } from './store/user';
import { Auth } from 'aws-amplify';
import { onError } from './libs/errorLib';
import DatacorLogoSpinner from './components/DatacorLogoSpinner/DatacorLogoSpinner';
import { useGetUserDetailsQuery } from './services/organizations/organizations.service';
import { RootState } from './store';
import { skipToken } from '@reduxjs/toolkit/query';

const App = () => {
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.user);
    const [initialAuthChecked, setInitialAuthChecked] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const { data: userData, isLoading: isUserLoading } = useGetUserDetailsQuery(
        !user.isSignedIn && skipToken
    );

    useEffect(() => {
        const onLoad = async () => {
            try {
                if (!isUserLoading) {
                    setInitialAuthChecked(true);
                    await Auth.currentSession()
                        .then((session) => {
                            const isUserLegacy =
                                localStorage.getItem('isUserLegacy');
                            isUserLegacy === 'true'
                                ? dispatch(setUserDetailsLegacy(session))
                                : dispatch(setUserDetails(userData, session));
                        })
                        .catch((error) => onError(error, false));
                    setIsLoading(false);
                }
            } catch (e) {
                onError(e.message);
            }
        };

        onLoad();
    }, [dispatch, isUserLoading]);

    if (isLoading || isUserLoading) {
        return (
            <div
                style={{
                    height: '100vh',
                    display: 'grid',
                    placeItems: 'center',
                }}>
                <DatacorLogoSpinner />
            </div>
        );
    }

    return initialAuthChecked ? <Landing /> : <Login />;
};

export default App;
