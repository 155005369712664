import { AgGridColumn } from 'ag-grid-react';
import LoaderButton from '../../../components/LoaderButton';
import React from 'react';
import ApprovalsModal from '../ApprovalsModal';
import CommentsView from '../Comments/CommentsView';
import { PermissionsUtil } from '../../../utils/permissions/PermissionsUtil';
import ApproverWorkflowsButtonGroup from '../ApprovalWorkflows/ApproverWorkflowsButtonGroup';
import { isInvoiceLocked } from '../utils';
import Grid from '../../../components/Grids/Grid';
import useApprovalsTab from '../hooks/Tabs/useApprovalsTab';
import RejectOptionsDialog from '../../../components/ApAutomation/RejectOptionsDialog';

interface ApprovalsTabProps {
    documentId: string;
}

const ApprovalsTab = ({ documentId }: ApprovalsTabProps) => {
    const {
        user,
        getGridData,
        userList,
        canUserUpdateApprovers,
        isUserApprover,
        isUserApproved,
        approveCurrentDocument,
        unapproveCurrentDocument,
        isLoadingSaveApprove,
        isLoadingSaveUnapprove,
        activeDocument,
        showChangeDefaultModal,
        setShowChangeDefaultModal,
        open,
        handleClickOpen,
        handleClose,
        REJECTION_REASONS,
    } = useApprovalsTab(documentId);

    return (
        <>
            <RejectOptionsDialog
                open={open}
                onClose={handleClose}
                documentId={documentId}
            />
            <Grid
                gridStyle={{ width: '90%' }}
                testId="approvers-list-grid"
                autofitColumns={true}
                rowData={getGridData()}
                immutableData={true}
                dataKey={'email'}>
                <AgGridColumn
                    field="email"
                    headerName="Approver"
                    valueFormatter={(val) =>
                        userList?.getUsersFullNameFromEmail(val.value, true)
                    }
                    filterParams={() => {
                        return {
                            valueFormatter: (params: any) => {
                                return userList?.getUsersFullNameFromEmail(
                                    params.value,
                                    true
                                );
                            },
                        };
                    }}
                />
                <AgGridColumn
                    field="approvalStatus.value"
                    valueFormatter={(val: any) => {
                        if (val.data?.approvalStatus?.key === 'REJECTED') {
                            return `${val.data.approvalStatus.value} - ${
                                REJECTION_REASONS[
                                    val.data.approvalStatus.reason
                                ]
                            }`;
                        }
                        return val.data?.approvalStatus?.value
                            ? val.data?.approvalStatus?.value
                            : 'Auto Approved';
                    }}
                    headerName="Approval Status"
                />
            </Grid>
            <br />
            {canUserUpdateApprovers &&
            isUserApprover &&
            PermissionsUtil.isPermissionEnabled(
                user.permissions,
                'permission.apAutomation.updateInvoice'
            ) ? (
                isUserApproved ? (
                    <>
                        <LoaderButton
                            data-testid="unapproveInvoiceBtn"
                            onClick={unapproveCurrentDocument}
                            variant="primary"
                            isLoading={isLoadingSaveUnapprove}
                            disabled={isInvoiceLocked(activeDocument)}>
                            Unapprove
                        </LoaderButton>{' '}
                        <LoaderButton
                            data-testid="unapproveInvoiceBtn"
                            onClick={handleClickOpen}
                            color="error"
                            isLoading={isLoadingSaveUnapprove}
                            disabled={isInvoiceLocked(activeDocument)}>
                            Reject
                        </LoaderButton>
                    </>
                ) : (
                    <>
                        <LoaderButton
                            data-testid="approveInvoiceBtn"
                            onClick={approveCurrentDocument}
                            variant="contained"
                            color="success"
                            isLoading={isLoadingSaveApprove}
                            disabled={isInvoiceLocked(activeDocument)}>
                            Approve
                        </LoaderButton>{' '}
                        <LoaderButton
                            data-testid="unapproveInvoiceBtn"
                            onClick={handleClickOpen}
                            variant="contained"
                            color="error"
                            isLoading={isLoadingSaveUnapprove}
                            disabled={isInvoiceLocked(activeDocument)}>
                            Reject
                        </LoaderButton>
                    </>
                )
            ) : null}{' '}
            {canUserUpdateApprovers &&
            PermissionsUtil.isPermissionEnabled(
                user.permissions,
                'permission.apAutomation.updateInvoice'
            ) ? (
                <LoaderButton
                    isLoading={null}
                    variant="contained"
                    data-testid="changeInvoiceApproversBtn"
                    onClick={() => setShowChangeDefaultModal(true)}
                    disabled={isInvoiceLocked(activeDocument)}>
                    Change Invoice Approvers
                </LoaderButton>
            ) : null}
            <br />
            <br />
            Assign Workflow to Invoice:
            <br />
            <br />
            <ApproverWorkflowsButtonGroup documentId={documentId} />
            <br />
            <br />
            <CommentsView documentId={documentId} />
            <ApprovalsModal
                show={showChangeDefaultModal}
                handleClose={() => setShowChangeDefaultModal(false)}
                documentId={documentId}
            />
        </>
    );
};

export default ApprovalsTab;
