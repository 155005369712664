import React from 'react';
import { useHistory } from 'react-router-dom';

const useFormulationItemMasterGrid = () => {
    const history = useHistory();

    const redirectToForm = (row: unknown) => {
        history.push('/formulation/itemMaster/new');
    };

    return {
        redirectToForm,
    };
};

export default useFormulationItemMasterGrid;
