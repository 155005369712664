import React from 'react';
import { Button, CircularProgress } from '@mui/material';
import './LoaderButton.css';

export default function LoaderButton({
    isLoading,
    size = 'sm',
    className = '',
    onClick = null,
    disabled = false,
    type,
    ...props
}: any) {
    return (
        <Button
            className={`LoaderButton ${className}`}
            onClick={onClick}
            disabled={disabled || isLoading}
            type={type && type}
            {...props}>
            {isLoading ? <CircularProgress size={20} /> : <>{props.children}</>}
        </Button>
    );
}
