import React from 'react';
import AgGrid from '../../components/Grids/Grid';
import { AgGridColumn } from 'ag-grid-react';
import DatacorLogoSpinner from '../../components/DatacorLogoSpinner/DatacorLogoSpinner';
import useBuildingsGrid from './hooks/useBuildingsGrid';

const BuildingsGrid = () => {
    const { onGridReady, buildings, isLoadingBuildings, redirectToForm } =
        useBuildingsGrid();

    return (
        <>
            {!isLoadingBuildings ? (
                <AgGrid
                    testId="business-entity-list-grid"
                    rowData={buildings ? buildings : []}
                    displayToolbar={true}
                    onCreateNew={(row) => redirectToForm(row)}
                    displayCreateNewButton={true}
                    onRowClicked={({ data }) => redirectToForm(data)}
                    onGridReady={onGridReady}
                    defaultColDef={{
                        resizable: true,
                        sortable: true,
                        unSortIcon: true,
                        filter: true,
                        wrapText: true,
                    }}>
                    <AgGridColumn field="code" headerName="Building Code" />
                    <AgGridColumn field="name" headerName="Building Name" />
                    <AgGridColumn
                        field="supplierCode"
                        headerName="Supplier Code"
                    />
                    <AgGridColumn
                        field="customerCode"
                        headerName="Customer Code"
                    />
                    <AgGridColumn field="address.street" headerName="Street" />
                    <AgGridColumn field="address.city" headerName="City" />
                    <AgGridColumn field="address.county" headerName="County" />
                    <AgGridColumn
                        field="address.stateCode.name"
                        headerName="State"
                    />
                    <AgGridColumn
                        field="address.postalCode"
                        headerName="Postal Code"
                    />
                    <AgGridColumn
                        field="address.countryCode.name"
                        headerName="Country Code"
                    />
                    <AgGridColumn field="note" headerName="Note" />
                </AgGrid>
            ) : (
                <DatacorLogoSpinner />
            )}
        </>
    );
};

export default BuildingsGrid;
