import { Auth } from 'aws-amplify';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import config from '../../config';
import { AppStream } from '../../types/AppStreaming.types';

export const appStreamingAPI = createApi({
    reducerPath: 'appStreamingApi',
    baseQuery: fetchBaseQuery({
        baseUrl: config.apiGateway.URL,
        prepareHeaders: async (headers, { getState }) => {
            const token = (await Auth.currentSession())
                .getIdToken()
                .getJwtToken();
            // If we have a token set in state, let's assume that we should be passing it.
            if (token) {
                headers.set('Authorization', token);
            }

            return headers;
        },
    }),
    tagTypes: ['stream'],
    endpoints: (build) => ({
        setSessionStatusOpen: build.mutation<any, void>({
            query: (sessionId) => ({
                url: `/appStream/setSessionStatusOpen/${sessionId}`,
                method: 'POST',
            }),
            invalidatesTags: ['stream'],
        }),
        createStreamingSession: build.mutation<any, AppStream>({
            query: (appStream) => ({
                url: '/appStream/createStreamingSession',
                method: 'POST',
                body: appStream,
            }),
            invalidatesTags: ['stream'],
        }),
        getAppStreamingUrl: build.query<any, any>({
            query: (email) => `/erpAppStreamingUrl/${email}`,
            providesTags: ['stream'],
        }),
    }),
});

export const {
    useSetSessionStatusOpenMutation,
    useCreateStreamingSessionMutation,
    useGetAppStreamingUrlQuery,
} = appStreamingAPI;
