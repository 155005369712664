import React from 'react';
import {
    useGetContractQuery,
    useCreateContractMutation,
    useUpdateContractMutation,
    useDeleteContractMutation,
    useGetContractTypesForDropdownQuery,
} from '../../../services/accounting/accounting.service';
import { skipToken } from '@reduxjs/toolkit/query';
import { Contract } from '../../../types/Accounting.types';
import useBaseForm from '../../Form/hooks/useBaseForm';

const UseContractForm = (id: string) => {
    const { data: activeContract, isLoading: isLoadingContract } =
        useGetContractQuery(id ? id : skipToken);
    const [createContract] = useCreateContractMutation();
    const [updateContract] = useUpdateContractMutation();
    const [deleteContract] = useDeleteContractMutation();

    const { data: contractTypeOptions, isLoading: isLoadingContractTypes } =
        useGetContractTypesForDropdownQuery();

    const blankContract: Contract = {
        number: '',
        name: '',
        description: '',
        supplier: '',
        startDate: null,
        endDate: null,
        amount: null,
        contractTypeId: null,
    };

    const {
        fields,
        handleFieldChange,
        formMethods,
        toastProps,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
    } = useBaseForm({
        closePath: '/accounting/contract',
        blankEntity: blankContract,
        activeEntity: activeContract,
        createEntity: () => {
            return createContract({ postBody: fields });
        },
        updateEntity: () => {
            return updateContract({
                id: id as unknown as number,
                postBody: fields,
            });
        },
        deleteEntity: () => {
            return deleteContract(id);
        },
    });

    const updateDateField = (id: string, date: Date): void => {
        handleFieldChange({
            target: {
                id: id,
                value: date,
            },
        });
    };

    return {
        fields,
        handleFieldChange,
        formMethods,
        toastProps,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        isLoadingContract,
        isLoadingContractTypes,
        contractTypeOptions,
        updateDateField,
    };
};

export default UseContractForm;
