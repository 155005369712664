import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
    useGetAllUnmatchedByCompanyQuery,
    useGetAllUnmatchedByPoNumQuery,
    useGetDocumentQuery,
    useManuallyLinkPOToInvoiceMutation,
    useManuallyUnlinkPOToInvoiceMutation,
} from '../../../../services/apAutomation/apAutomation.service';
import { skipToken } from '@reduxjs/toolkit/query';
import { PermissionsUtil } from '../../../../utils/permissions/PermissionsUtil';
import { PERMISSIONS } from '../../../../constants/permissions/permissions.constants';
import { Button } from '@mui/material';
import { RootState } from '../../../../store';

const usePoInformationTab = (documentId: string) => {
    const user = useSelector((state: RootState) => state.user);
    const { data: activeDocument } = useGetDocumentQuery(
        documentId ? documentId : skipToken
    );
    const [showAll, setShowAll] = useState(
        localStorage.getItem('showAllReceipts') === 'true'
    );
    const { data: unmatchedPos, isLoading } = useGetAllUnmatchedByPoNumQuery(
        activeDocument.poNumber ? activeDocument.poNumber : skipToken
    );
    const { data: allUnmatchedPos, isLoading: isLoadingAllUnmatched } =
        useGetAllUnmatchedByCompanyQuery(!showAll && skipToken);
    const [manuallyUnlinkPOToInvoice] = useManuallyUnlinkPOToInvoiceMutation();
    const [manuallyLinkPOToInvoice] = useManuallyLinkPOToInvoiceMutation();
    const hasManualMatchPermission = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.AP_AUTOMATION.MANUAL_MATCH
    );
    const rowData = showAll ? allUnmatchedPos : unmatchedPos;
    const shouldLoad = showAll && isLoadingAllUnmatched;
    const isInvoiceApproved =
        activeDocument.approvalStatus?.key === 'APPROVED' ||
        activeDocument.approvalStatus?.key === 'AUTO_APPROVED';
    const shouldShowManualGrid = hasManualMatchPermission && !isInvoiceApproved;
    const isInvoicePoStatusManual =
        activeDocument.poStatus?.key === 'MANUAL_PARTIAL_MATCH' ||
        activeDocument.poStatus?.key === 'MANUAL_MATCH';
    const shouldShowButton =
        hasManualMatchPermission && isInvoicePoStatusManual;

    const handleUnlinkPo = (poInfo: any) => {
        const args = {
            poNumber: poInfo.poNumber,
            receiptNumber: poInfo.receiptNumber,
            erpCostCategory: poInfo.erpCostCategory,
        };

        manuallyUnlinkPOToInvoice(args);
    };

    const handleLinkPo = (poInfo: any) => {
        const args = {
            documentId: documentId,
            poNumber: poInfo.poNumber,
            receiptNumber: poInfo.receiptNumber,
            erpCostCategory: poInfo.erpCostCategory,
        };
        manuallyLinkPOToInvoice(args);
    };

    const handleClickShowAll = (e: React.ChangeEvent<HTMLInputElement>) => {
        localStorage.setItem(
            'showAllReceipts',
            JSON.stringify(e.target.checked)
        );
        setShowAll((prev: boolean) => !prev);
    };

    const unlinkPoColumn = (props: any) => {
        return (
            <Button
                variant="outlined"
                color="error"
                size={'small'}
                style={{ maxHeight: '20px' }}
                onClick={() => handleUnlinkPo(props.data)}>
                Unlink
            </Button>
        );
    };

    const linkPoColumn = (props: any) => {
        return (
            <Button
                color="success"
                style={{ maxHeight: '20px' }}
                variant="contained"
                size={'small'}
                onClick={() => handleLinkPo(props.data)}>
                Link
            </Button>
        );
    };

    return {
        shouldShowManualGrid,
        shouldShowButton,
        activeDocument,
        isLoading,
        unlinkPoColumn,
        linkPoColumn,
        rowData,
        showAll,
        handleClickShowAll,
        shouldLoad,
    };
};

export default usePoInformationTab;
