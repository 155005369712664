import { GridDefaults } from './grid.constants';
import { AgGridReact } from 'ag-grid-react';
import React, { ReactNode } from 'react';
import {
    CellClickedEvent,
    FilterChangedEvent,
    FirstDataRenderedEvent,
    GridReadyEvent,
    RowClassParams,
    RowClickedEvent,
    RowSelectedEvent,
    RowStyle,
    SortChangedEvent,
} from 'ag-grid-community';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';

interface GridProps {
    gridStyle?: object;
    onGridReady?: (params: GridReadyEvent) => void;
    domLayout?: string;
    testId?: string;
    immutableData?: boolean;
    tooltipShowDelay?: number;
    paginationPageSize?: number;
    getRowNodeId?: any;
    pagination?: boolean;
    autofitColumns?: boolean;
    defaultColDef?: any;
    dataKey?: string;
    rowData?: any;
    rowHeight?: number;
    gridOptions?: any;
    gridClass?: string;
    rowStyle?: any;
    headerHeight?: number;
    onFirstDataRendered?: (event: FirstDataRenderedEvent) => void;
    onSortChanged?: (event: SortChangedEvent) => void;
    onFilterChanged?: (event: FilterChangedEvent) => void;
    frameworkComponents?: any;
    onCellClicked?: (event: CellClickedEvent) => void;
    onRowClicked?: (event: RowClickedEvent) => void;
    rowSelection?: string;
    onRowSelected?: (event: RowSelectedEvent) => void;
    masterDetail?: boolean;
    detailCellRendererParams?: any;
    getRowStyle?: (Params: RowClassParams) => RowStyle;
    suppressMenuHide?: boolean;
    children?: ReactNode;
    displayToolbar?: boolean;
    onCreateNew?: (event: any) => void;
    displayCreateNewButton?: boolean;
}

const Grid = (props: GridProps) => {
    const dataKey = props.dataKey;
    const immutableData =
        props.immutableData || (dataKey && GridDefaults.immutableData);

    const onGridReady = (params: GridReadyEvent) => {
        if (props.autofitColumns) {
            params.api.sizeColumnsToFit();
        }
        if (props.onGridReady) {
            props.onGridReady(params);
        }
    };

    return (
        <div
            className={'ag-theme-alpine ' + (props.gridClass || '')}
            style={props.gridStyle}
            data-testid={props.testId}>
            {props.displayToolbar && (
                <div className="action-bar">
                    {props.displayCreateNewButton && (
                        <Button
                            variant="text"
                            size="small"
                            className="grid-create-new"
                            onClick={props.onCreateNew}
                            startIcon={<AddCircleIcon />}>
                            Add New
                        </Button>
                    )}
                </div>
            )}
            <AgGridReact
                domLayout={props.domLayout}
                immutableData={immutableData}
                getRowNodeId={dataKey ? (data) => data[dataKey] : null}
                defaultColDef={props.defaultColDef}
                gridOptions={props.gridOptions}
                tooltipShowDelay={props.tooltipShowDelay}
                pagination={props.pagination}
                paginationPageSize={props.paginationPageSize}
                rowData={props.rowData}
                rowStyle={props.rowStyle}
                headerHeight={props.headerHeight}
                onFirstDataRendered={props.onFirstDataRendered}
                onSortChanged={props.onSortChanged}
                onFilterChanged={props.onFilterChanged}
                onGridReady={onGridReady}
                frameworkComponents={props.frameworkComponents}
                onCellClicked={props.onCellClicked}
                onRowClicked={props.onRowClicked}
                rowSelection={props.rowSelection}
                onRowSelected={props.onRowSelected}
                masterDetail={props.masterDetail}
                detailCellRendererParams={props.detailCellRendererParams}
                getRowStyle={props.getRowStyle}
                suppressMenuHide={props.suppressMenuHide}>
                {props.children}
            </AgGridReact>
        </div>
    );
};

Grid.defaultProps = {
    domLayout: GridDefaults.domLayout,
    tooltipShowDelay: GridDefaults.tooltipShowDelay,
    pagination: GridDefaults.pagination,
    paginationPageSize: GridDefaults.paginationPageSize,
    autofitColumns: GridDefaults.autofitColumns,
    immutableData: GridDefaults.immutableData,
    displayToolbar: GridDefaults.displayToolbar,
};

export default Grid;
