import React from 'react';
import { Card, CardHeader, CardContent } from '@mui/material';
import { AgGridColumn } from 'ag-grid-react';
import Grid from '../../components/Grids/Grid';
import { themeConfig } from '../../styles/theme/themeConfig';
import useGeneralSummary from './hooks/useGeneralSummary';

const GeneralSummary = () => {
    const { rowData } = useGeneralSummary();

    return (
        <Card
            className="summaryGrids"
            style={{ minWidth: 250, height: '100%' }}>
            <CardHeader
                style={{
                    backgroundColor: themeConfig.PRIMARY.main,
                    height: 50,
                    color: 'white',
                }}
                title="General Summary"
            />
            <CardContent>
                <Grid
                    gridStyle={{ width: '100%' }}
                    rowData={rowData}
                    immutableData={true}
                    dataKey="value"
                    headerHeight={0}>
                    <AgGridColumn
                        width={200}
                        field="category"
                        cellStyle={{
                            'background-color': '#9dd9f2',
                            'font-weight': 'bold',
                        }}
                        headerClass="summaryGridHeader"
                    />
                    <AgGridColumn
                        field="value"
                        headerClass="summaryGridHeader"
                        cellStyle={{ 'text-align': 'center' }}
                        flex={1}
                    />
                </Grid>
            </CardContent>
        </Card>
    );
};

export default GeneralSummary;
