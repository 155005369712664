import { useEffect } from 'react';
import {
    BusinessEntity,
    useCreateBusinessEntityMutation,
    useGetBusinessEntitiesQuery,
    useGetBusinessEntityQuery,
    useGetBusinessEntityTypesForDropdownQuery,
    useUpdateBusinessEntityMutation,
} from '../../../services/organizations/organizations.service';
import { skipToken } from '@reduxjs/toolkit/query';
import { useHistory } from 'react-router-dom';
import { onError } from '../../../libs/errorLib';
import { useForm } from '../../../libs/hooksLib';
import {
    BaseCreateEntityArgs,
    BaseUpdateEntityArgs,
} from '../../../services/serviceInterfaces';
import { RootState } from '../../../store';
import { useSelector } from 'react-redux';

const useBusinessEntityForm = (id: string) => {
    const user = useSelector((state: RootState) => state.user);
    const history = useHistory();
    const { fields, handleFieldChange, setValues, formMethods, isFormValid } =
        useForm({});
    const { data: businessEntity, isLoading: isLoading } =
        useGetBusinessEntityQuery(id ? id : skipToken);
    const {
        data: businessEntityTypeOptions,
        isLoading: isLoadingBusinessEntityTypeOptions,
    } = useGetBusinessEntityTypesForDropdownQuery();

    const { data: businessEntityOptions } = useGetBusinessEntitiesQuery(
        user?.subscriber?.id ? user?.subscriber?.id.toString() : skipToken
    );

    const [createBusinessEntity] = useCreateBusinessEntityMutation();
    const [updateBusinessEntity] = useUpdateBusinessEntityMutation();

    const defaultBusinessEntity: any = {
        subscriberId: user?.subscriber?.id || '',
        parentId: user?.businessEntity?.parentId || null,
        name: '',
        description: '',
        businessEntityTypeId: '',
    };

    useEffect(() => {
        setValues(businessEntity || defaultBusinessEntity);
    }, [businessEntity]);

    const handleCreateBusinessEntity = async () => {
        if (!isFormValid) {
            return;
        }

        const body: BaseCreateEntityArgs<BusinessEntity> = {
            postBody: fields,
        };

        try {
            await createBusinessEntity(body);
            handleClose();
        } catch (e) {
            return onError(e.message);
        }
    };

    const handleUpdateBusinessEntity = async () => {
        if (!isFormValid) {
            return;
        }

        const body: BaseUpdateEntityArgs<BusinessEntity> = {
            id: id as unknown as number,
            postBody: fields,
        };

        try {
            await updateBusinessEntity(body);
            handleClose();
        } catch (e) {
            return onError(e.message);
        }
    };

    const handleClose = () => {
        history.push('/administration/organizations/business-entities');
    };

    return {
        fields,
        businessEntity,
        businessEntityOptions,
        businessEntityTypeOptions,
        isLoadingBusinessEntityTypeOptions,
        isLoading,
        handleCreateBusinessEntity: handleCreateBusinessEntity,
        handleUpdateBusinessEntity: handleUpdateBusinessEntity,
        handleFieldChange,
        handleClose,
        setValues,
        formMethods,
    };
};

export default useBusinessEntityForm;
