import { useForm } from '../../../libs/hooksLib';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    User,
    useCreateUserMutation,
    useUpdateUserMutation,
    useGetBusinessEntitiesQuery,
    useGetUserQuery,
    useGetMessagingPreferencesForDropdownQuery,
    useGetGroupsForDropdownQuery,
    useGetRolesForDropdownQuery,
    useGetDeparmentCodesForDropdownQuery,
    useGetUsersForDropdownQuery,
    Employee,
} from '../../../services/organizations/organizations.service';
import { onError } from '../../../libs/errorLib';
import {
    BaseCreateEntityArgs,
    BaseUpdateEntityArgs,
} from '../../../services/serviceInterfaces';
import { skipToken } from '@reduxjs/toolkit/query';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import config from '../../../config';

export const useUserForm = (id: string) => {
    const user = useSelector((state: RootState) => state.user);
    const history = useHistory();
    const { fields, handleFieldChange, setValues, formMethods, isFormValid } =
        useForm({});

    const [openToast, setOpenToast] = useState(false);
    const {
        fields: employeeFields,
        handleFieldChange: handleEmployeeFieldChange,
        setValues: setEmployeeValues,
        formMethods: employeeFormMethods,
        isFormValid: isEmployeeFormValid,
    } = useForm({});

    const { data: userData, isLoading: isLoading } = useGetUserQuery(
        id ? { userId: id, full: true } : skipToken
    );
    const { data: messagingPreferenceOptions } =
        useGetMessagingPreferencesForDropdownQuery();
    const { data: groupsOptions } = useGetGroupsForDropdownQuery(
        user?.businessEntity?.id
            ? user?.businessEntity?.id.toString()
            : skipToken
    );
    const { data: rolesOptions } = useGetRolesForDropdownQuery(
        user?.businessEntity?.id
            ? user?.businessEntity?.id.toString()
            : skipToken
    );
    const { data: businessEntityOptions } = useGetBusinessEntitiesQuery(
        user?.subscriber?.id ? user?.subscriber?.id.toString() : skipToken
    );
    const { data: departmentCodeOptions } =
        useGetDeparmentCodesForDropdownQuery(
            user?.businessEntity?.id
                ? {
                      id: user?.businessEntity?.id.toString(),
                  }
                : skipToken
        );
    const { data: usersDropdownOptions } = useGetUsersForDropdownQuery(
        user?.businessEntity?.id
            ? user?.businessEntity?.id.toString()
            : skipToken
    );
    const [createUser] = useCreateUserMutation();
    const [updateUser] = useUpdateUserMutation();

    const defaultUser: User = {
        active: true,
        firstName: '',
        lastName: '',
        middleName: '',
        mobilePhone: '',
        email: '',
        businessEntityId: user?.businessEntity?.id,
        messagingPrefId: null,
        groups: [],
        roles: [],
        isDatacorAdmin: null,
        isAppStreamUser: null,
    };

    const defaultEmployee: Employee = {
        departmentCodeId: null,
        supervisorId: null,
        userId: null,
    };

    useEffect(() => {
        setValues(userData || defaultUser);
        setEmployeeValues(userData?.employee || defaultEmployee);
    }, [userData, user]);

    const handleCreateUser = async () => {
        if (!isFormValid || !isEmployeeFormValid) {
            return;
        }

        const body: BaseCreateEntityArgs<User> = {
            postBody: {
                ...fields,
                userPoolId: config.cognito.USER_POOL_ID,
                employee: { ...employeeFields },
            },
        };
        createUser(body)
            .unwrap()
            .then(() => handleClose())
            .catch(() => {
                setOpenToast(true);
            });
    };

    const handleUpdateUser = async () => {
        if (!isFormValid || !isEmployeeFormValid) {
            return;
        }

        const body: BaseUpdateEntityArgs<User> = {
            id: id as unknown as number,
            postBody: { ...fields, employee: { ...employeeFields } },
        };

        try {
            await updateUser(body);
            handleClose();
        } catch (e) {
            return onError(e.message);
        }
    };

    const handleClose = () => {
        history.push('/administration/organizations/users');
    };

    return {
        fields,
        user,
        employeeFields,
        userData,
        isLoading,
        businessEntityOptions,
        messagingPreferenceOptions,
        groupsOptions,
        rolesOptions,
        departmentCodeOptions,
        usersDropdownOptions,
        handleCreateUser,
        handleUpdateUser,
        handleFieldChange,
        handleEmployeeFieldChange,
        handleClose,
        setValues,
        openToast,
        setOpenToast,
        employeeFormMethods,
        formMethods,
    };
};
