import { createSlice } from '@reduxjs/toolkit';
import { SliceState } from '../types/store/GridsStore.types';

const initialState: SliceState = {
    pricingAnalysisTool: {
        pricingConcernDetails: {
            column: null,
            filter: null,
        },
        pricingProposalReport: {
            column: null,
            filter: null,
        },
        pricingCustomerProductDetails: {
            column: null,
            filter: null,
        },
    },
    apAutomation: {
        documentList: {
            column: null,
            filter: null,
        },
    },
};

const slice = createSlice({
    name: 'grids',
    initialState: initialState,
    reducers: {
        updateGridModels: (state: any, action) => {
            state[action.payload.gridLocation][action.payload.gridName][
                action.payload.type
            ] = action.payload.model;
        },
    },
});

export default slice.reducer;

// Actions
export const { updateGridModels } = slice.actions;
