import React, { ChangeEvent, useEffect, useState } from 'react';
import {
    useGetAllErpVendorAddressesQuery,
    useGetDocumentQuery,
    useSaveDocumentMutation,
} from '../../../../services/apAutomation/apAutomation.service';
import { usersAPI } from '../../../../services/users/users.service';
import { skipToken } from '@reduxjs/toolkit/query';
import { useSelector, useDispatch } from 'react-redux';
import { useFormFields } from '../../../../libs/hooksLib';
import moment from 'moment';
import { SupplierAddress } from '../../../../types/ApAutomation.types';
import { isNilOrEmpty } from '../../../../utils/objectUtils';
import { RootState } from '../../../../store';

const useDocumentInfoTab = (documentId: string) => {
    const dispatch = useDispatch();
    const [saveDocument, { isLoading: isLoadingSave }] =
        useSaveDocumentMutation();
    const { data: activeDocument, isLoading: isLoadingDocument } =
        useGetDocumentQuery(documentId ? documentId : skipToken);
    const { data: erpVendorAddresses, isLoading: isLoadingErpVendorAddresses } =
        useGetAllErpVendorAddressesQuery();
    const user = useSelector((state: RootState) => state.user);
    const [selectedSupplierRemitTo, setSelectedSupplierRemitTo] =
        useState(null);
    const [isExistingSupplierAddress, setIsExistingSupplierAddress] =
        useState(true);
    const [fields, handleFieldChange] = useFormFields({
        invoiceNumber: '',
        poNumber: '',
        invoiceDate: '',
        due: '',
        supplierNumber: '',
        sequenceNumber: null,
        supplierName: '',
        Ad1: '',
        Ad2: '',
        Ad3: '',
        city: '',
        state: '',
        zip: '',
    });

    useEffect(() => {
        if (isLoadingDocument) {
            return;
        }
        fields.invoiceNumber = activeDocument?.invoiceNumber || '';

        fields.poNumber = activeDocument?.poNumber || '';
        fields.due = activeDocument?.due || '';
        if (moment(activeDocument?.invoiceDate).isValid()) {
            const utcDate = moment(activeDocument.invoiceDate).utc();
            fields.invoiceDate = moment({
                year: utcDate.year(),
                month: utcDate.month(),
                day: utcDate.date(),
            }).valueOf();
        } else {
            fields.invoiceDate = '';
        }

        setSupplierRemitToFromObject(activeDocument?.remitAddress);
    }, [activeDocument]);

    const setSupplierRemitToFromObject = (supplierAddress: SupplierAddress) => {
        fields.supplierNumber = supplierAddress?.supplierNumber || '';
        fields.sequenceNumber = supplierAddress?.sequenceNumber;
        fields.supplierName = supplierAddress?.supplierName || '';
        fields.Ad1 = supplierAddress?.Ad1 || '';
        fields.Ad2 = supplierAddress?.Ad2 || '';
        fields.Ad3 = supplierAddress?.Ad3 || '';
        fields.city = supplierAddress?.city || '';
        fields.state = supplierAddress?.state || '';
        fields.zip = supplierAddress?.zip || '';

        setSelectedSupplierRemitTo(supplierAddress);
        setIsExistingSupplierAddress(!isNilOrEmpty(fields.sequenceNumber));
    };

    const submitSaveDocument = async (
        event: React.ChangeEvent<HTMLFormElement>
    ) => {
        event.preventDefault();

        let remitAddressToSave: SupplierAddress;

        if (isExistingSupplierAddress) {
            remitAddressToSave = selectedSupplierRemitTo;
        } else {
            remitAddressToSave = {
                supplierNumber: fields.supplierNumber,
                supplierName: fields.supplierName,
                Ad1: fields.Ad1,
                Ad2: fields.Ad2,
                Ad3: fields.Ad3,
                city: fields.city,
                state: fields.state,
                zip: fields.zip,
            };
        }

        const args = {
            documentId: documentId,
            invoiceNumber: fields.invoiceNumber,
            invoiceDate: fields.invoiceDate,
            poNumber: fields.poNumber,
            due: fields.due,
            remitAddress: remitAddressToSave,
        };
        saveDocument(args);
        dispatch(usersAPI.util.invalidateTags(['Audit']));
    };

    const updateDate = (date: Date) => {
        handleFieldChange({
            target: {
                id: 'invoiceDate',
                value: date,
            },
        });
    };

    const setSelectSupplierOptions = () => {
        return erpVendorAddresses?.map((address: any) => {
            return {
                value: address,
                label: `${address.supplierNumber} - ${address.supplierName} (${address.Ad1})`,
            };
        });
    };

    const onManualOverwriteChanged = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            setSupplierRemitToFromObject(null);
        } else if (
            selectedSupplierRemitTo &&
            !isNilOrEmpty(selectedSupplierRemitTo.sequenceNumber)
        ) {
            setSupplierRemitToFromObject(selectedSupplierRemitTo);
        } else {
            setSupplierRemitToFromObject(activeDocument?.remitAddress);
        }
    };

    const prepareSupplierValueForField = (val: any) => {
        return val.supplierName;
    };

    return {
        user,
        fields,
        handleFieldChange,
        updateDate,
        selectedSupplierRemitTo,
        setSelectSupplierOptions,
        setSupplierRemitToFromObject,
        isLoadingErpVendorAddresses,
        isExistingSupplierAddress,
        onManualOverwriteChanged,
        isLoadingSave,
        submitSaveDocument,
        activeDocument,
        prepareSupplierValueForField,
    };
};

export default useDocumentInfoTab;
