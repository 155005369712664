import React from 'react';
import { AgGridColumn } from 'ag-grid-react';
import { DollarsPer, QuantityUom } from '../utils';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Alert, Button, IconButton } from '@mui/material';
import PricingAnalysisToolGridHeaderTooltips from '../PricingAnalysisToolGridHeaderTooltips';
import { percentFormatter } from '../../../utils/formattingUtils';
import Grid from '../../../components/Grids/Grid';
import usePricingProposalReportGrid from '../hooks/PricingProposalReport/usePricingProposalReportGrid';

const PricingProposalReportGrid = () => {
    const {
        reset,
        onBtExport,
        onCellValueChanged,
        onFirstDataRendered,
        onGridReady,
        onFilterChanged,
        onSortChanged,
        showMarginAlert,
        rowData,
    } = usePricingProposalReportGrid();

    return (
        <>
            {showMarginAlert && (
                <Alert
                    style={{ width: '100%' }}
                    className="text-center"
                    severity="error">
                    <span>
                        <b>Error:</b> Proposed margin must be between 1 - 100.
                    </span>
                </Alert>
            )}
            <IconButton
                color="primary"
                aria-label="export grid to excel"
                component="span"
                onClick={() => onBtExport()}>
                <FileDownloadIcon
                    style={{
                        color: 'green',
                        cursor: 'pointer',
                        padding: '5px',
                        fontSize: '30px',
                    }}
                />
            </IconButton>

            <Grid
                testId="pricing-proposal-report"
                gridStyle={{ width: '100%' }}
                getRowNodeId={(data: any) => data.id}
                immutableData={true}
                dataKey="id"
                defaultColDef={{
                    menuTabs: [
                        'filterMenuTab',
                        'generalMenuTab',
                        'columnsMenuTab',
                    ],
                    resizable: true,
                    sortable: true,
                    unSortIcon: true,
                    filter: true,
                    wrapText: true,
                    tooltipComponent: 'customTooltip',
                }}
                pagination={true}
                paginationPageSize={15}
                rowData={rowData}
                rowHeight={25}
                onFirstDataRendered={onFirstDataRendered}
                onSortChanged={onSortChanged}
                onFilterChanged={onFilterChanged}
                onGridReady={onGridReady}
                frameworkComponents={{
                    quantityUom: QuantityUom,
                    dollarsPer: DollarsPer,
                    customTooltip: PricingAnalysisToolGridHeaderTooltips,
                }}
                suppressMenuHide={true}>
                <AgGridColumn
                    field="externalCustomer"
                    headerName="Customer Name"
                    pinned={'left'}
                    width={200}
                    headerClass={'pick-list-header'}
                />
                <AgGridColumn
                    field="product"
                    headerName="Product"
                    pinned={'left'}
                    width={200}
                    headerClass={'pick-list-header'}
                />
                <AgGridColumn
                    field="orderType"
                    pinned={'left'}
                    headerName="Order Type"
                    headerClass={'pick-list-header'}
                    width={120}
                />
                <AgGridColumn
                    field="mostRecentTransaction.salesAgentName"
                    pinned={'left'}
                    headerName="Sales Agent"
                    headerClass={'pick-list-header'}
                    width={160}
                />
                <AgGridColumn headerName="Proposed" headerClass="grid-group-4">
                    <AgGridColumn
                        editable={true}
                        cellStyle={{ 'border-color': '#f69333' }}
                        valueSetter={onCellValueChanged}
                        field="proposedOrderPrice"
                        headerName="Order Price"
                        headerClass={'pick-list-header'}
                        width={140}
                        cellRenderer="dollarsPer"
                    />
                    <AgGridColumn
                        editable={true}
                        cellStyle={{ 'border-color': '#f69333' }}
                        valueSetter={onCellValueChanged}
                        field="proposedOrderCost"
                        headerName="Order Cost"
                        headerClass={'pick-list-header'}
                        width={140}
                        cellRenderer="dollarsPer"
                    />
                    <AgGridColumn
                        editable={true}
                        cellStyle={{ 'border-color': '#f69333' }}
                        valueSetter={onCellValueChanged}
                        field="proposedProfit"
                        headerName="Profit"
                        headerClass={'pick-list-header'}
                        width={140}
                        cellRenderer="dollarsPer"
                    />
                    <AgGridColumn
                        editable={true}
                        cellStyle={{ 'border-color': '#f69333' }}
                        valueSetter={onCellValueChanged}
                        field="proposedMargin"
                        headerName="Margin"
                        headerClass={'pick-list-header'}
                        width={140}
                        valueFormatter={percentFormatter}
                    />
                    <AgGridColumn
                        field="proposedDollarsGained"
                        headerName="Dollars Gained"
                        headerClass={'pick-list-header'}
                        width={140}
                        cellRenderer="dollarsPer"
                    />
                </AgGridColumn>
                <AgGridColumn
                    headerName="Most Recent Transaction"
                    headerClass="grid-group-3">
                    <AgGridColumn
                        field="mostRecentTransaction.productMeasure"
                        headerName="Measure"
                        headerClass={'pick-list-header'}
                        width={140}
                    />
                    <AgGridColumn
                        field="mostRecentTransaction.productUnitOfMeasure"
                        headerName="UOM"
                        headerClass={'pick-list-header'}
                        width={110}
                    />
                    <AgGridColumn
                        field="mostRecentTransaction.productUnitOfPackaging"
                        headerName="UOP"
                        headerClass={'pick-list-header'}
                        width={110}
                    />
                    <AgGridColumn
                        field="mostRecentTransaction.orderQuantity"
                        headerName="Quantity"
                        headerClass={'pick-list-header'}
                        width={140}
                    />
                    <AgGridColumn
                        field="mostRecentTransaction.orderPrice"
                        headerName="Order Price"
                        headerClass={'pick-list-header'}
                        width={140}
                        cellRenderer="dollarsPer"
                    />
                    <AgGridColumn
                        field="mostRecentTransaction.orderCost"
                        headerName="Order Cost"
                        headerClass={'pick-list-header'}
                        width={140}
                        cellRenderer="dollarsPer"
                    />
                    <AgGridColumn
                        field="mostRecentTransaction.profit"
                        headerName="Profit"
                        headerClass={'pick-list-header'}
                        width={140}
                        cellRenderer="dollarsPer"
                    />
                    <AgGridColumn
                        field="mostRecentTransaction.margin"
                        headerName="Margin"
                        headerClass={'pick-list-header'}
                        width={140}
                        valueFormatter={percentFormatter}
                    />
                    <AgGridColumn
                        field="mostRecentTransaction.averageMargin"
                        headerClass={'pick-list-header'}
                        headerName="Product Average Margin"
                        width={150}
                        valueFormatter={percentFormatter}
                        headerTooltip="averageMargin"
                        tooltipComponent="customTooltip"
                    />
                    <AgGridColumn
                        field="mostRecentTransaction.percentBelowAverageMargin"
                        headerClass={'pick-list-header'}
                        headerName="% Below Average"
                        width={180}
                        valueFormatter={percentFormatter}
                    />
                </AgGridColumn>
            </Grid>
            <Button
                style={{ margin: '10px' }}
                onClick={() => reset()}
                variant="contained"
                color="primary">
                Reset Proposals
            </Button>
        </>
    );
};

export default PricingProposalReportGrid;
