import { useSelector } from 'react-redux';
import { useGetZonesQuery } from '../../../services/users/users.service';
import React, { useState } from 'react';
import { GridReadyEvent } from 'ag-grid-community';
import { RootState } from '../../../store';

const useZonesGrid = () => {
    const user = useSelector((state: RootState) => state.user);
    const { data: zones, isLoading: isLoadingZones } = useGetZonesQuery();
    const [showCreateZoneModal, setShowCreateZoneModal] = useState(false);
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [showToast, setShowToast] = useState(false);

    const handleCreateZoneModalClose = (updateZones: boolean = false) => {
        setShowCreateZoneModal(false);
        if (updateZones) {
            setShowToast(true);
        }
    };

    const handleCreateZoneModalOpen = () => {
        setShowCreateZoneModal(true);
    };

    const onGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);

        params.api.sizeColumnsToFit();
    };
    return {
        onGridReady,
        handleCreateZoneModalOpen,
        handleCreateZoneModalClose,
        showToast,
        showCreateZoneModal,
        zones,
        isLoadingZones,
        user,
        setShowToast,
    };
};

export default useZonesGrid;
