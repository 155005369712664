import React from 'react';
import {
    useCreateDepreciationMethodStatusMutation,
    useDeleteDepreciationMethodStatusMutation,
    useGetDepreciationMethodStatusQuery,
    useUpdateDepreciationMethodStatusMutation,
} from '../../../services/fixedAssets/fixedAssets.service';
import { skipToken } from '@reduxjs/toolkit/query';
import { FixedAssetDepreciationMethodStatus } from '../../../types/FixedAsset.types';
import useBaseForm from '../../Form/hooks/useBaseForm';

const UseFixedAssetDepreciationMethodStatusForm = (id: string) => {
    const { data: activeStatus, isLoading: isLoadingStatus } =
        useGetDepreciationMethodStatusQuery(id ? id : skipToken);
    const [createStatus] = useCreateDepreciationMethodStatusMutation();
    const [updateStatus] = useUpdateDepreciationMethodStatusMutation();
    const [deleteStatus] = useDeleteDepreciationMethodStatusMutation();

    const blankStatus: FixedAssetDepreciationMethodStatus = {
        code: '',
        description: '',
        sortOrder: null,
        isActive: false,
    };

    const {
        fields,
        handleFieldChange,
        formMethods,
        toastProps,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
    } = useBaseForm({
        closePath: '/accounting/fixedAssets/depreciationMethodStatus',
        blankEntity: blankStatus,
        activeEntity: activeStatus,
        createEntity: () => {
            return createStatus({ postBody: fields });
        },
        updateEntity: () => {
            return updateStatus({
                id: id as unknown as number,
                postBody: fields,
            });
        },
        deleteEntity: () => {
            return deleteStatus(id);
        },
    });

    return {
        fields,
        handleFieldChange,
        formMethods,
        toastProps,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        isLoadingStatus,
    };
};

export default UseFixedAssetDepreciationMethodStatusForm;
