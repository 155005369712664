import { Auth } from 'aws-amplify';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import config from '../../config';
import { User, Zone, Company } from '../../types/User.types';
import { UserList } from '../../utils/users/userList';
import { dateFormatter } from '../../utils/formattingUtils';

export const usersAPI = createApi({
    reducerPath: 'usersApi',
    baseQuery: fetchBaseQuery({
        baseUrl: config.apiGateway.URL,
        prepareHeaders: async (headers, { getState }) => {
            const token = (await Auth.currentSession())
                .getIdToken()
                .getJwtToken();
            // If we have a token set in state, let's assume that we should be passing it.
            if (token) {
                headers.set('Authorization', token);
            }

            return headers;
        },
    }),
    tagTypes: ['User', 'Zones', 'Company', 'Audit'],
    endpoints: (build) => ({
        getUserById: build.query<User, string>({
            query: (userId) => `/users/getUserById/${userId}`,
            providesTags: ['User'],
        }),
        getAllUsers: build.query({
            query: (userId) => `/users/${userId}`,
            providesTags: ['User'],
            transformResponse: (response) => new UserList(response),
        }),
        getZones: build.query<any, void>({
            query: () => '/users/zonesList',
            providesTags: ['Zones'],
        }),
        getZonesForCompanies: build.mutation({
            query: (companyIds) => ({
                url: `/users/getZonesForCompanies`,
                method: 'POST',
                body: { companyIds: companyIds },
            }),
        }),
        getCompaniesByUserId: build.query<Company[], string>({
            query: (userId) => `/users/companies/${userId}`,
            providesTags: ['Company'],
        }),
        deleteUser: build.mutation<
            any,
            { username: string; userPoolId: string }
        >({
            query: (user) => ({
                url: '/users',
                method: 'DELETE',
                body: user,
            }),
            invalidatesTags: ['User'],
        }),
        createUser: build.mutation<any, User>({
            query: (user) => ({
                url: '/users',
                method: 'POST',
                body: user,
            }),
            invalidatesTags: ['User'],
        }),
        updateUser: build.mutation<any, User>({
            query: (user) => ({
                url: '/users/updateUser',
                method: 'POST',
                body: user,
            }),
            invalidatesTags: ['User'],
        }),
        updateUserStatus: build.mutation<any, { userStatus: string }>({
            query: (user) => ({
                url: '/users/updateUserStatus',
                method: 'POST',
                body: user,
            }),
            invalidatesTags: ['User'],
        }),
        createZone: build.mutation<any, { zone: Zone; companyId: string }>({
            query: (zone) => ({
                url: '/users/createZone',
                method: 'POST',
                body: zone,
            }),
            invalidatesTags: ['Zones'],
        }),
        createCompany: build.mutation<any, Company>({
            query: (company) => ({
                url: '/users/createCompany',
                method: 'POST',
                body: company,
            }),
            invalidatesTags: ['Company'],
        }),
        applyCompanyAndZone: build.mutation({
            query: (company) => ({
                url: '/users/updateUserCompanyAndZone',
                method: 'POST',
                body: company,
            }),
            invalidatesTags: ['Company'],
        }),
        getAuditDataByKey: build.query({
            query: (args) => ({
                url: `/getAuditDataByKey/${args.companyId}/${args.actionType}/${args.auditTrailKey}`,
                method: 'GET',
            }),
            transformResponse: (response: any) =>
                [...response].sort((a, b) => {
                    const dateA: any = new Date(a.dateCreated);
                    const dateB: any = new Date(b.dateCreated);
                    return dateB - dateA;
                }),
            providesTags: ['Audit'],
        }),
    }),
});

export const {
    useGetUserByIdQuery,
    useGetAllUsersQuery,
    useGetZonesQuery,
    useGetZonesForCompaniesMutation,
    useGetCompaniesByUserIdQuery,
    useDeleteUserMutation,
    useCreateUserMutation,
    useUpdateUserMutation,
    useUpdateUserStatusMutation,
    useCreateZoneMutation,
    useCreateCompanyMutation,
    useApplyCompanyAndZoneMutation,
    useGetAuditDataByKeyQuery,
} = usersAPI;
