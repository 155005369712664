import React, { useState } from 'react';
import FixedAssetGrid from './Pages/FixedAssetGrid';

const FixedAssets = () => {
    return (
        <>
            <FixedAssetGrid />
        </>
    );
};

export default FixedAssets;
