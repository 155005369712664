import React, { useContext } from 'react';
import {
    Popover,
    IconButton,
    Switch,
    FormControlLabel,
    FormGroup,
} from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import { DocumentGridContext } from '../../containers/ApAutomation/contexts/DocumentGridContext';
import { apAutomationAPI } from '../../services/apAutomation/apAutomation.service';
import { useDispatch } from 'react-redux';

const GridOptionsPopover = () => {
    const dispatch = useDispatch();
    const { documentGridOptions, setDocumentGridOptions } =
        useContext(DocumentGridContext);

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null
    );

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(apAutomationAPI.util.invalidateTags(['Invoice']));
        setDocumentGridOptions((prev: any) => ({
            ...prev,
            [e.target.id]: e.target.checked,
        }));
        localStorage.setItem(e.target.id, JSON.stringify(e.target.checked));
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
        <div className="grid-options">
            <IconButton aria-describedby={id} onClick={handleClick}>
                <TuneIcon />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}>
                <div className="options-container" style={{ padding: '10px' }}>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Switch
                                    id="showVouchered"
                                    checked={documentGridOptions.showVouchered}
                                    onChange={handleChange}
                                />
                            }
                            label="Show Vouchered Invoices"
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    id="showRejected"
                                    checked={documentGridOptions.showRejected}
                                    onChange={handleChange}
                                />
                            }
                            label="Show Rejected Invoices"
                        />
                    </FormGroup>
                </div>
            </Popover>
        </div>
    );
};

export default GridOptionsPopover;
