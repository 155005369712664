import React from 'react';
import { Card, CardContent, CardHeader, Grid, TextField } from '@mui/material';
import { useParams } from 'react-router-dom';
import FormContainer from '../../components/Form/FormContainer/FormContainer';
import DatacorLogoSpinner from '../../components/DatacorLogoSpinner/DatacorLogoSpinner';
import FormPartial from '../../components/Form/FormContainer/FormPartial';
import TextInput from '../../components/Form/FormInputs/TextInput/TextInput';
import useContractForm from './hooks/useContractForm';
import DateInput from '../../components/Form/FormInputs/DateInput/DateInput';
import CurrencyInput from '../../components/Form/FormInputs/CurrencyInput/CurrencyInput';
import FixedAssetsFormHeader from '../../components/Form/FormContainer/FormHeader';
import SelectInput from '../../components/Form/FormInputs/SelectInput/SelectInput';
import ToastContainer from '../../components/Toast/ToastContainer';

interface ParamTypes {
    contractId: string;
}

const ContractForm = () => {
    const { contractId } = useParams<ParamTypes>();
    const {
        fields,
        handleFieldChange,
        formMethods,
        toastProps,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        isLoadingContract,
        isLoadingContractTypes,
        contractTypeOptions,
        updateDateField,
    } = useContractForm(contractId);

    return (
        <Card>
            <CardHeader title="Contract" />

            {isLoadingContract ? (
                <DatacorLogoSpinner />
            ) : (
                <CardContent>
                    <ToastContainer {...toastProps} show={!!toastProps?.show} />

                    <FormContainer
                        headerTitle={`Primary Information`}
                        onSubmit={() => formMethods.setIsFormSubmitted(true)}
                        headerComponent={
                            <FixedAssetsFormHeader
                                showOnDelete={!!contractId}
                                showOnSave={true}
                                onSave={() => {
                                    if (!contractId) {
                                        onCreate();
                                    } else {
                                        onUpdate();
                                    }
                                }}
                                onDelete={() => {
                                    onDelete();
                                }}
                                onCancel={closeForm}
                                isEdit={!!contractId}
                            />
                        }>
                        <FormPartial>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextInput
                                        fullWidth
                                        label="Contract No"
                                        id="number"
                                        formMethods={formMethods}
                                        value={fields.number}
                                        onChange={handleFieldChange}
                                        required={true}
                                        maxLength={50}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextInput
                                        fullWidth
                                        label="Contract Name"
                                        id="name"
                                        formMethods={formMethods}
                                        value={fields.name}
                                        onChange={handleFieldChange}
                                        maxLength={90}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextInput
                                        fullWidth
                                        label="Description"
                                        id="description"
                                        formMethods={formMethods}
                                        value={fields.description}
                                        onChange={handleFieldChange}
                                        maxLength={300}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextInput
                                        fullWidth
                                        label="Supplier"
                                        id="supplier"
                                        formMethods={formMethods}
                                        value={fields.supplier}
                                        onChange={handleFieldChange}
                                        maxLength={90}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <DateInput
                                        label="Date Start"
                                        value={fields.startDate}
                                        onChange={(date: Date) =>
                                            updateDateField('startDate', date)
                                        }
                                        renderInput={(params) => (
                                            <TextField {...params} />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <DateInput
                                        label="Date End"
                                        value={fields.endDate}
                                        onChange={(date: Date) =>
                                            updateDateField('endDate', date)
                                        }
                                        renderInput={(params) => (
                                            <TextField {...params} />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <CurrencyInput
                                        fullWidth
                                        label="Contract Amount"
                                        id="amount"
                                        formMethods={formMethods}
                                        value={fields.amount}
                                        onChange={handleFieldChange}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <SelectInput
                                        fullWidth
                                        label="Contract Type"
                                        id="contractTypeId"
                                        formMethods={formMethods}
                                        value={fields.contractTypeId}
                                        onChange={handleFieldChange}
                                        options={contractTypeOptions}
                                        disabled={isLoadingContractTypes}
                                        required={true}
                                    />
                                </Grid>
                            </Grid>
                        </FormPartial>
                    </FormContainer>
                </CardContent>
            )}
        </Card>
    );
};

export default ContractForm;
