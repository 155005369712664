import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
    useGetAllTypesQuery,
    useGetDepreciationMethodsMapQuery,
} from '../../../services/fixedAssets/fixedAssets.service';

const UseFixedAssetTypeGrid = () => {
    const history = useHistory();
    const [isLoadingAny, setIsLoadingAny] = useState(true);

    const { data: typeList, isLoading: isLoadingTypes } = useGetAllTypesQuery();
    const { data: methodList, isLoading: isLoadingMethodList } =
        useGetDepreciationMethodsMapQuery();

    useEffect(() => {
        setIsLoadingAny(isLoadingTypes || isLoadingMethodList);
    }, [isLoadingTypes, isLoadingMethodList]);

    const redirectToForm = (row: any) => {
        if (row.id) {
            history.push(`/accounting/fixedAssets/type/${row.id}`);
        } else {
            history.push('/accounting/fixedAssets/type/new');
        }
    };

    const getDepreciationMethodFromId = (id: number) => {
        return methodList?.get(id)?.code || '';
    };

    return {
        typeList,
        redirectToForm,
        getDepreciationMethodFromId,
        isLoadingAny,
    };
};

export default UseFixedAssetTypeGrid;
