import React from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardHeader, Grid } from '@mui/material';
import FormContainer from '../../components/Form/FormContainer/FormContainer';
import FixedAssetsFormHeader from '../../components/Form/FormContainer/FormHeader';
import TextInput from '../../components/Form/FormInputs/TextInput/TextInput';
import FormPartial from '../../components/Form/FormContainer/FormPartial';
import DatacorLogoSpinner from '../../components/DatacorLogoSpinner/DatacorLogoSpinner';
import useGroupsForm from './hooks/useGroupForm';
import SelectInput from '../../components/Form/FormInputs/SelectInput/SelectInput';

interface ParamTypes {
    id: string;
}

const GroupsForm = () => {
    const { id } = useParams<ParamTypes>();
    const {
        fields,
        user,
        users,
        setValues,
        handleClose,
        handleFieldChange,
        handleUpdateGroup,
        handleCreateGroup,
        isLoading,
        businessEntityOptions,
        formMethods,
    } = useGroupsForm(id);

    return (
        <Card>
            <CardHeader
                title={
                    (id ? fields.name : 'Create New Group') +
                    ` (${user?.businessEntity?.name})`
                }
            />
            {isLoading ? (
                <DatacorLogoSpinner />
            ) : (
                <CardContent>
                    <FormContainer
                        headerTitle={`Primary Information`}
                        onSubmit={() => formMethods.setIsFormSubmitted(true)}
                        headerComponent={
                            <FixedAssetsFormHeader
                                showOnDelete={false}
                                showOnSave={true}
                                onSave={async () => {
                                    if (!id) {
                                        await handleCreateGroup();
                                    } else {
                                        await handleUpdateGroup();
                                    }
                                }}
                                onDelete={handleClose}
                                onCancel={handleClose}
                                isEdit={!!id}
                            />
                        }>
                        <FormPartial>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextInput
                                        fullWidth
                                        label="Name"
                                        id="name"
                                        value={fields.name}
                                        onChange={handleFieldChange}
                                        required={true}
                                        maxLength={90}
                                        formMethods={formMethods}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextInput
                                        fullWidth
                                        label="Description"
                                        id="description"
                                        value={fields.description}
                                        onChange={handleFieldChange}
                                        maxLength={90}
                                        formMethods={formMethods}
                                    />
                                </Grid>
                                {/*
                                //TODO: determine if we want to keep this in the form or just have it be in the title
                                <Grid item xs={6}>
                                    <ExpandableDropdown
                                        valueSelected={(value) =>
                                            setValues({
                                                businessEntityId: value,
                                            })
                                        }
                                        defaultValue={fields.businessEntityId}
                                        items={businessEntityOptions}
                                        idKey="id"
                                        labelKey="name"
                                        label="Parent Business Entity"
                                        id="businessEntityId"
                                        formMethods={formMethods}
                                        required={true}
                                    />
                                </Grid>
                                */}
                                {!!id && (
                                    <>
                                        <Grid item xs={6}>
                                            <SelectInput
                                                fullWidth
                                                SelectProps={{ multiple: true }}
                                                label="Users"
                                                id="users"
                                                value={fields?.users}
                                                onChange={handleFieldChange}
                                                options={users}
                                                formMethods={formMethods}
                                            />
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </FormPartial>
                    </FormContainer>
                </CardContent>
            )}
        </Card>
    );
};

export default GroupsForm;
