import React, { useEffect } from 'react';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import SideMenuItem from './SideMenuItem';
import { RootState } from '../../../store';
import { getAvailableNavLinks } from '../../../utils/Navigation/NavigationUtils';
import NavLink from '../../../utils/Navigation/NavLink';

export interface SideMenuProps {
    children: React.ReactNode;
}

const SideMenu = (props: SideMenuProps) => {
    const user = useSelector((state: RootState) => state.user);
    const drawerWidth = 312;
    const [selectedKey, setSelectedKey] = React.useState(null);

    const [allNavigationLinks, setAllNavigationLinks] = React.useState([]);
    const [navigationLinks, setNavigationLinks] = React.useState([]);

    useEffect(() => {
        const allNavigationLinks = getAvailableNavLinks(
            user.enabledFeatureFlags,
            user.permissions
        );

        setAllNavigationLinks(allNavigationLinks);
        setNavigationLinks(allNavigationLinks);
    }, [user]);

    const handleListItemClick = (key: string) => {
        setSelectedKey(key);
    };

    const searchMenuItems = (event: any) => {
        const matchingLinks = allNavigationLinks.filter((link: NavLink) =>
            link.name.toLowerCase().includes(event.target.value.toLowerCase())
        );

        setNavigationLinks(matchingLinks);
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <Drawer
                variant="permanent"
                anchor="left"
                open={true}
                PaperProps={{
                    elevation: 2,
                    sx: { backgroundColor: '#F8F8F8' },
                }}
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}>
                <Toolbar sx={{ height: 80 }} />
                <Container disableGutters sx={{ height: 110 }}>
                    <h3
                        style={{
                            paddingLeft: '10px',
                            fontFamily: 'Noto Sans, sans-serif',
                        }}>
                        Menu
                    </h3>

                    <Container
                        disableGutters
                        sx={{
                            backgroundColor: '#DCDFE2',
                            height: 65,
                            width: '100%',
                            paddingLeft: '10px',
                            paddingRight: '20px',
                        }}>
                        <TextField
                            sx={{
                                backgroundColor: 'white',
                                margin: '10px 5px 10px 5px',
                                textAlign: 'center',
                                marginLeft: 0,
                                marginRight: 0,
                                padding: '10px 0px 0px 10px',
                                borderRadius: '5px',
                            }}
                            InputProps={{
                                disableUnderline: true,
                                autoComplete: 'new-password',
                            }}
                            placeholder="Search..."
                            variant="standard"
                            onChange={($event) => {
                                searchMenuItems($event);
                            }}
                            fullWidth
                        />
                    </Container>
                </Container>
                <List style={{ paddingTop: '20px' }}>
                    {navigationLinks.length > 0 &&
                        navigationLinks.map((navLink: NavLink) => {
                            return (
                                <SideMenuItem
                                    key={navLink.key}
                                    navLink={navLink}
                                    selectedKey={selectedKey}
                                    setSelectedKey={handleListItemClick}
                                />
                            );
                        })}
                </List>
            </Drawer>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    p: 3,
                    overflow: 'scroll',
                    backgroundColor: '#DCDFE2',
                    height: 'calc(100vh - 100px)',
                }}>
                {props.children}
            </Box>
        </Box>
    );
};

export default SideMenu;
