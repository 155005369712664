import React from 'react';
import { AgGridColumn } from 'ag-grid-react';
import ApproversGrid from '../../components/Grids/Grid';
import { UserData } from '../../types/User.types';
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    IconButton,
    Button,
    Grid,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SelectDropdown from '../../components/Form/SelectDropdown/SelectDropdown';
import useApprovalsModal from './hooks/useApprovalsModal';

interface ApprovalsModalProps {
    show: boolean;
    handleClose: (_: boolean) => void;
    documentId?: string;
}

const ApprovalsModal = ({
    show,
    handleClose,
    documentId = null,
}: ApprovalsModalProps) => {
    const {
        users,
        setSelectApproverOptions,
        addApprover,
        isLoadingUsers,
        localApprovers,
        Delete,
        saveChanges,
    } = useApprovalsModal({
        handleClose,
        documentId,
    });

    return (
        <Dialog
            open={show}
            onClose={handleClose}
            maxWidth="md"
            fullWidth
            data-testid="approvals-modal">
            <DialogTitle data-testid="approval-workflows-modal-header">
                <Box display="flex" alignItems="center">
                    <Box flexGrow={1}>
                        {documentId
                            ? 'Edit Invoice Approvers'
                            : 'Edit Default Approvers'}
                    </Box>
                    <Box>
                        <IconButton onClick={() => handleClose(true)}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
            </DialogTitle>
            <DialogContent className="ap-automation-approvers">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <SelectDropdown
                            className="basic-select"
                            menuPlacement={'bottom'}
                            id="select-approvers"
                            options={setSelectApproverOptions()}
                            placeholder={'Select User'}
                            onChange={(selectedValue: string) =>
                                addApprover(
                                    users.find(
                                        (user: UserData) =>
                                            user.email === selectedValue
                                    )
                                )
                            }
                            isDisabled={isLoadingUsers}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ApproversGrid
                            testId="approvers-list-grid"
                            rowData={localApprovers}
                            autofitColumns={true}
                            immutableData={true}
                            dataKey="email"
                            frameworkComponents={{
                                delete: Delete,
                            }}>
                            <AgGridColumn
                                field="delete"
                                headerName=""
                                width={55}
                                cellRenderer="delete"
                            />
                            <AgGridColumn field="firstName" />
                            <AgGridColumn field="lastName" />
                            <AgGridColumn field="email" />
                        </ApproversGrid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="secondary"
                    data-testid="cancelEditApproversBtn"
                    onClick={() => handleClose(true)}>
                    Close
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    data-testid="saveEditApproversBtn"
                    onClick={saveChanges}>
                    Save Changes
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ApprovalsModal;
