import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useFormFields } from '../../../libs/hooksLib';
import { isNilOrEmpty } from '../../../utils/objectUtils';
import { Auth } from 'aws-amplify';
import { setRequirePasswordChange } from '../../../store/user';
import { onError } from '../../../libs/errorLib';
import { useUpdateUserStatusMutation } from '../../../services/users/users.service';
import { RootState } from '../../../store';

const useNewPasswordRequired = () => {
    const [updateUserStatus] = useUpdateUserStatusMutation();
    const user = useSelector((state: RootState) => state.user);
    const dispatch = useDispatch();
    const history = useHistory();
    const [fields, handleFieldChange] = useFormFields({
        password: '',
        confirmPassword: '',
    });
    const [isConfirming, setIsConfirming] = useState(false);

    function validateResetForm() {
        return (
            !isNilOrEmpty(fields.password) &&
            fields.password === fields.confirmPassword
        );
    }

    const handleConfirmClick = async (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();

        setIsConfirming(true);

        try {
            await Auth.completeNewPassword(
                user.unauthenticatedUser,
                fields.password,
                []
            );
            const request = {
                userStatus: 'CONFIRMED',
            };
            await updateUserStatus(request);
            dispatch(setRequirePasswordChange());
            history.push('/login/resetSuccess');
        } catch (error) {
            onError(error);
            setIsConfirming(false);
        }
    };

    return {
        fields,
        handleFieldChange,
        handleConfirmClick,
        isConfirming,
        validateResetForm,
    };
};

export default useNewPasswordRequired;
