import { useSelector } from 'react-redux';
import { useGetCompaniesByUserIdQuery } from '../../../services/users/users.service';
import React, { useState } from 'react';
import { PermissionsUtil } from '../../../utils/permissions/PermissionsUtil';
import { PERMISSIONS } from '../../../constants/permissions/permissions.constants';
import { GridReadyEvent } from 'ag-grid-community';
import { Zone } from '../../../types/User.types';
import { RootState } from '../../../store';

const useCompaniesGrid = () => {
    const user = useSelector((state: RootState) => state.user);
    const [gridApi, setGridApi] = useState(null);
    const { data: companiesList, isLoading } = useGetCompaniesByUserIdQuery(
        user.userId
    );
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [showCreateCompanyModal, setShowCreateCompanyModal] = useState(false);
    const [showCompanyKeySecretModal, setShowCompanyKeySecretModal] =
        useState(false);
    const [newCompanyResponse, setNewCompanyResponse] = useState(null);
    const canCreateCompany = PermissionsUtil.isPermissionEnabled(
        user.permissions,
        PERMISSIONS.USER_PORTAL.COMPANIES.CREATE
    );

    const onGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    };

    const Zones = (props: any) => {
        return props.value ? (
            <span>
                {props.value.map((zone: Zone) => zone.zoneId).join(', ')}
            </span>
        ) : (
            <span />
        );
    };

    const handleCreateCompanyModalOpen = () => {
        setShowCreateCompanyModal(true);
    };

    const handleCreateCompanyModalClose = (newCompanyData: any) => {
        setShowCreateCompanyModal(false);
        if (newCompanyData?.data?.verification?.appClientSecret) {
            setNewCompanyResponse(newCompanyData?.data);
            setShowCompanyKeySecretModal(true);
        } else {
            setShowCompanyKeySecretModal(false);
        }
    };

    const handleCompanyKeySecretModalClose = () => {
        setShowCompanyKeySecretModal(false);
    };

    return {
        handleCompanyKeySecretModalClose,
        handleCreateCompanyModalClose,
        handleCreateCompanyModalOpen,
        Zones,
        onGridReady,
        canCreateCompany,
        newCompanyResponse,
        showCompanyKeySecretModal,
        showCreateCompanyModal,
        companiesList,
        isLoading,
    };
};

export default useCompaniesGrid;
