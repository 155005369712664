import { AgGridColumn } from 'ag-grid-react';
import React from 'react';
import moment from 'moment';
import AgGrid from '../../components/Grids/Grid';
import DatacorLogoSpinner from '../../components/DatacorLogoSpinner/DatacorLogoSpinner';
import useDepartmentCodesGrid from './hooks/useDepartmentCodesGrid';

const DepartmentCodesGrid = () => {
    const { onGridReady, rowData, isLoading, redirectToForm } =
        useDepartmentCodesGrid();

    return (
        <>
            {!isLoading ? (
                <AgGrid
                    testId="department-codes-list-grid"
                    dataKey="id"
                    immutableData={true}
                    rowData={rowData}
                    displayToolbar={true}
                    onCreateNew={(row) => redirectToForm(row)}
                    displayCreateNewButton={true}
                    onRowClicked={({ data }) => redirectToForm(data)}
                    onGridReady={onGridReady}
                    defaultColDef={{
                        resizable: true,
                        sortable: true,
                        unSortIcon: true,
                        filter: true,
                        wrapText: true,
                    }}>
                    <AgGridColumn
                        flex={1}
                        minWidth={250}
                        field="name"
                        headerName="Name"
                    />
                    <AgGridColumn
                        minWidth={200}
                        field="code"
                        headerName="Code"
                    />
                    <AgGridColumn
                        minWidth={200}
                        field="createdAt"
                        headerName="Date Created"
                        valueFormatter={(val) =>
                            val.value && moment(val.value).isValid()
                                ? moment(val.value).format('L')
                                : ''
                        }
                    />
                    <AgGridColumn
                        minWidth={200}
                        field="updatedAt"
                        headerName="Last Update Date"
                        valueFormatter={(val) =>
                            val.value && moment(val.value).isValid()
                                ? moment(val.value).format('L')
                                : ''
                        }
                    />
                </AgGrid>
            ) : (
                <DatacorLogoSpinner />
            )}
        </>
    );
};

export default DepartmentCodesGrid;
