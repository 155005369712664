import { isNil } from '../objectUtils';
import { Permission } from '../../types/Settings.types';

export const PermissionsUtil = {
    isPermissionEnabled: (
        userPermissions: Array<Permission>,
        permission: any
    ) => {
        if (isNil(permission)) {
            return true;
        }

        return userPermissions.includes(permission);
    },
};
