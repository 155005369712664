import { useForm } from '../../../libs/hooksLib';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
    DepartmentCode,
    useCreateDepartmentCodeMutation,
    useGetDepartmentCodeQuery,
    useUpdateDepartmentCodeMutation,
} from '../../../services/organizations/organizations.service';
import { onError } from '../../../libs/errorLib';
import {
    BaseCreateEntityArgs,
    BaseUpdateEntityArgs,
} from '../../../services/serviceInterfaces';
import { skipToken } from '@reduxjs/toolkit/query';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';

const useDepartmentCodeForm = (id: string) => {
    const user = useSelector((state: RootState) => state.user);
    const history = useHistory();
    const { fields, handleFieldChange, setValues, formMethods, isFormValid } =
        useForm({});
    const { data: departmentCode, isLoading: isLoading } =
        useGetDepartmentCodeQuery(id ? { departmentCodeId: id } : skipToken);
    const [createDepartmentCode] = useCreateDepartmentCodeMutation();
    const [updateDepartmentCode] = useUpdateDepartmentCodeMutation();

    const defaultDepartmentCode: DepartmentCode = {
        name: '',
        code: '',
        subscriberId: user?.subscriber?.id,
    };

    useEffect(() => {
        setValues(departmentCode || defaultDepartmentCode);
    }, [departmentCode]);

    const handleCreateDepartmentCode = async () => {
        if (!isFormValid) {
            return;
        }

        const body: BaseCreateEntityArgs<DepartmentCode> = {
            postBody: fields,
        };

        try {
            await createDepartmentCode(body);
            handleClose();
        } catch (e) {
            return onError(e.message);
        }
    };

    const handleUpdateDepartmentCode = async () => {
        if (!isFormValid) {
            return;
        }

        const body: BaseUpdateEntityArgs<DepartmentCode> = {
            id: id as unknown as number,
            postBody: fields,
        };

        try {
            await updateDepartmentCode(body);
            handleClose();
        } catch (e) {
            return onError(e.message);
        }
    };

    const handleClose = () => {
        history.push('/administration/organizations/departmentCodes');
    };

    return {
        fields,
        departmentCode,
        isLoading,
        handleCreateDepartmentCode,
        handleUpdateDepartmentCode,
        handleFieldChange,
        handleClose,
        setValues,
        formMethods,
    };
};

export default useDepartmentCodeForm;
