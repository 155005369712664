import React from 'react';
import { Card, CardContent, CardHeader, Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import FormContainer from '../../../components/Form/FormContainer/FormContainer';
import DatacorLogoSpinner from '../../../components/DatacorLogoSpinner/DatacorLogoSpinner';
import FormPartial from '../../../components/Form/FormContainer/FormPartial';
import TextInput from '../../../components/Form/FormInputs/TextInput/TextInput';
import FixedAssetsFormHeader from '../../../components/Form/FormContainer/FormHeader';
import SelectInput from '../../../components/Form/FormInputs/SelectInput/SelectInput';
import useFixedAssetDepreciationMethodForm from '../hooks/useFixedAssetDepreciationMethodForm';
import ToastContainer from '../../../components/Toast/ToastContainer';
import NumericInput from '../../../components/Form/FormInputs/NumericInput/NumericInput';
import MultiSelectGrid from '../../../components/Grids/MultiSelectGrid';
import { AgGridColumn } from 'ag-grid-react';

interface ParamTypes {
    methodId: string;
}

const FixedAssetDepreciationMethodForm = () => {
    const { methodId } = useParams<ParamTypes>();
    const {
        fields,
        handleFieldChange,
        formMethods,
        toastProps,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        isLoadingMethod,
        isLoadingStatuses,
        isLoadingTypes,
        isLoadingCostCodes,
        statusOptions,
        typeOptions,
        costCodeList,
        onGridReady,
        onRowSelected,
    } = useFixedAssetDepreciationMethodForm(methodId);

    return (
        <Card>
            <CardHeader title="Fixed Asset Depreciation Method" />

            {isLoadingMethod ? (
                <DatacorLogoSpinner />
            ) : (
                <CardContent>
                    <ToastContainer {...toastProps} show={!!toastProps?.show} />

                    <FormContainer
                        headerTitle={`Primary Information`}
                        onSubmit={() => formMethods.setIsFormSubmitted(true)}
                        headerComponent={
                            <FixedAssetsFormHeader
                                showOnDelete={!!methodId}
                                showOnSave={true}
                                onSave={() => {
                                    if (!methodId) {
                                        onCreate();
                                    } else {
                                        onUpdate();
                                    }
                                }}
                                onDelete={() => onDelete()}
                                onCancel={closeForm}
                                isEdit={!!methodId}
                            />
                        }>
                        <FormPartial>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <TextInput
                                        fullWidth
                                        label="Depreciation Method Code"
                                        id="code"
                                        value={fields.code}
                                        onChange={handleFieldChange}
                                        formMethods={formMethods}
                                        required={true}
                                        maxLength={30}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextInput
                                        fullWidth
                                        label="Depreciation Method Name"
                                        id="name"
                                        value={fields.name}
                                        onChange={handleFieldChange}
                                        formMethods={formMethods}
                                        maxLength={90}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <NumericInput
                                        fullWidth
                                        label="Sort Order"
                                        id="sortOrder"
                                        value={fields.sortOrder}
                                        onChange={handleFieldChange}
                                        formMethods={formMethods}
                                        required={true}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextInput
                                        fullWidth
                                        label="Description"
                                        id="description"
                                        value={fields.description}
                                        onChange={handleFieldChange}
                                        formMethods={formMethods}
                                        maxLength={300}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <SelectInput
                                        fullWidth
                                        label="Depreciation Method Status"
                                        id="depreciationMethodStatusId"
                                        value={
                                            fields.depreciationMethodStatusId
                                        }
                                        onChange={handleFieldChange}
                                        formMethods={formMethods}
                                        options={statusOptions}
                                        disabled={isLoadingStatuses}
                                        required={true}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <SelectInput
                                        fullWidth
                                        label="Depreciation Method Type"
                                        id="depreciationMethodTypeId"
                                        value={fields.depreciationMethodTypeId}
                                        onChange={handleFieldChange}
                                        formMethods={formMethods}
                                        options={typeOptions}
                                        disabled={isLoadingTypes}
                                        required={true}
                                    />
                                </Grid>
                            </Grid>
                        </FormPartial>
                        <FormPartial
                            title="Cost Codes"
                            isVisible={!isLoadingCostCodes}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <MultiSelectGrid
                                        rowData={costCodeList || []}
                                        onGridReady={onGridReady}
                                        onRowSelected={onRowSelected}
                                        rowMultiSelectWithClick={true}
                                        gridClass="full-width-grid">
                                        <AgGridColumn
                                            maxWidth={100}
                                            checkboxSelection={true}
                                            headerName="Selected"
                                        />
                                        <AgGridColumn
                                            minWidth={50}
                                            field="code"
                                            headerName="Cost Code"
                                        />
                                    </MultiSelectGrid>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextInput
                                        fullWidth
                                        label="Total Amount"
                                        id="totalAmount"
                                        value={fields.totalAmount}
                                        disabled={true}
                                    />
                                </Grid>
                            </Grid>
                        </FormPartial>
                    </FormContainer>
                </CardContent>
            )}
        </Card>
    );
};

export default FixedAssetDepreciationMethodForm;
