export const isNil = (value: any) => {
    return value === null || value === undefined || value === '';
};

export const isNilOrEmpty = (value: any) => {
    return value === null || value === undefined || value?.length === 0;
};

export const StringUtils = {
    equals: (stringA: string, stringB: string) => {
        return stringA.toLowerCase() === stringB.toLowerCase();
    },
};
