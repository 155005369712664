import React, { useEffect } from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { FormMethods } from '../../../../libs/hooksLib';
import { RequiredValidator } from '../../../../validators/required.validator';
import { isNil } from '../../../../utils/objectUtils';

export type InputBaseProps = {
    formMethods?: FormMethods;
} & TextFieldProps;

const InputBase = ({ formMethods, ...props }: InputBaseProps) => {
    useEffect(() => {
        if (props.required) {
            formMethods?.addValidator(
                props.id,
                props.label.toString(),
                RequiredValidator
            );
        } else {
            formMethods?.removeValidator(props.id, RequiredValidator);
        }
    }, [props.required]);

    return (
        <TextField
            {...props}
            error={
                formMethods?.isFormSubmitted &&
                !isNil(formMethods?.fieldValidationErrors?.get(props.id))
            }
            helperText={
                formMethods?.isFormSubmitted
                    ? formMethods?.fieldValidationErrors?.get(props.id)
                    : null
            }
        />
    );
};

export default InputBase;
