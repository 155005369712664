import React from 'react';
import { Card, CardMedia, Grid } from '@mui/material';
import cloud_storage from '../../libs/images/myApps/cloud_storage.png';
import chemcadImages from './images/ChemcadAppStreaming_Image.png';
import './AppStreaming.css';
import DatacorLogoSpinner from '../../components/DatacorLogoSpinner/DatacorLogoSpinner';
import useAppStreaming from './hooks/useAppStreaming';

const AppStreaming = () => {
    const { streamingAppUrls, isLoadingAppStream, openTabToStreamApp } =
        useAppStreaming();

    return (
        <div className="app-streaming">
            <Grid container spacing={2} className="app-streaming-card-deck">
                {streamingAppUrls && !isLoadingAppStream ? (
                    streamingAppUrls.map((streamingInfo: any) =>
                        streamingInfo.StreamingURLResponse ? (
                            <Grid item xs={4} key={streamingInfo.name}>
                                <Card
                                    style={{ height: '300px' }}
                                    className="app-streaming-card"
                                    onClick={() =>
                                        openTabToStreamApp(streamingInfo)
                                    }
                                    key={streamingInfo.name}>
                                    <CardMedia
                                        style={{ height: '200px' }}
                                        className="app-streaming-image"
                                        image={
                                            streamingInfo.name === 'Chemcad'
                                                ? chemcadImages
                                                : cloud_storage
                                        }
                                    />

                                    <p
                                        style={{
                                            textAlign: 'center',
                                            fontSize: '24px',
                                        }}>
                                        {streamingInfo.name}
                                    </p>
                                </Card>
                            </Grid>
                        ) : null
                    )
                ) : (
                    <DatacorLogoSpinner />
                )}
            </Grid>
        </div>
    );
};

export default AppStreaming;
