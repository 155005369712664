import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    getCustomerProductRecommendationsSuccess,
    setSelectedCustomerProductDetails,
} from '../../../../store/pricingAnalysisTool';
import {
    IconButton,
    Paper,
    Popover,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Tooltip,
} from '@mui/material';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import {
    ReplacementPrice,
    OpportunityScore,
} from '../../../../types/PricingAnalysis.types';
import {
    currencyFormatter,
    dateFormatter,
    percentFormatter,
} from '../../../../utils/formattingUtils';
import { updateGridModels } from '../../../../store/grids';
import { GridReadyEvent } from 'ag-grid-community';
import { isNilOrEmpty } from '../../../../utils/objectUtils';
import { RootState } from '../../../../store';

const usePricingConcernDetailsGrid = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [rowData, setRowData] = useState([]);
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [
        openRecommendationModalWithProps,
        setOpenRecommendationModalWithProps,
    ] = useState(null);
    const [openDetailsModal, setOpenDetailsModal] = useState(false);
    const { pricingAnalysisToolSet } = useSelector(
        (state: RootState) => state.pricingAnalysisTool
    );
    const { pricingAnalysisTool } = useSelector(
        (state: RootState) => state.grids
    );

    useEffect(() => {
        setRowData(pricingAnalysisToolSet.activePricingConcernDetails);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pricingAnalysisToolSet.activePricingConcernDetails]);

    const cellClickedEvent = (event: any) => {
        event.column.colId === 'recommendation'
            ? setOpenRecommendationModalWithProps(event.data)
            : history.push(
                  `/pricingAnalysisTool/pricingCustomerProductDetails/${event.data.id}`
              );
    };

    const onRowSelected = (event: any) => {
        dispatch(
            setSelectedCustomerProductDetails(event.data, event.node.selected)
        );
    };

    const Recommendation = () => {
        const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            setOpenRecommendationModalWithProps(event);
        };

        return (
            <Tooltip
                title={
                    'Click to make price recommendation or see previous recommendations.'
                }>
                <IconButton onClick={handleClick} size="small">
                    <LightbulbIcon />
                </IconButton>
            </Tooltip>
        );
    };

    const handleRecommendationModalClose = () => {
        setOpenRecommendationModalWithProps(null);
        dispatch(
            getCustomerProductRecommendationsSuccess({
                customerProductRecommendations: null,
                customerProductRecommendationsLoading: false,
            })
        );
    };

    const HasReplacementPrice = (props: ReplacementPrice) => {
        const [anchorEl, setAnchorEl] =
            React.useState<HTMLButtonElement | null>(null);

        const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
            setOpenRecommendationModalWithProps(event);
        };

        const open = Boolean(anchorEl);
        const popover = (
            <Popover id="popover-basic" open={open} anchorEl={anchorEl}>
                <Typography>
                    <strong>Replacement Cost Details</strong>
                </Typography>
                <Typography>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableHead>
                                        <b>Replacement Date:</b>
                                    </TableHead>
                                    <TableCell>
                                        {dateFormatter(
                                            props.data.mostRecentTransaction
                                                .replacementCostDate
                                        )}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableHead>
                                        <b>Replacement Cost:</b>
                                    </TableHead>
                                    <TableCell>
                                        {currencyFormatter(
                                            props.data.mostRecentTransaction
                                                .replacementCost
                                        )}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableHead>
                                        <b>Margin At Replacement Cost:</b>
                                    </TableHead>
                                    <TableCell>
                                        {percentFormatter(
                                            props.data.mostRecentTransaction
                                                .marginAtReplacementCost
                                        )}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Typography>
            </Popover>
        );

        return props.data.mostRecentTransaction.replacementCost ? (
            <>
                {popover}
                <IconButton onClick={handleClick}>
                    <span>{'Yes'}</span>
                </IconButton>
            </>
        ) : (
            <span>{'No'}</span>
        );
    };

    const OpportunityScore = (props: OpportunityScore) => {
        const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            setOpenRecommendationModalWithProps(event);
        };

        const tooltipData = (
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <b>Recency Score:</b>{' '}
                                {`${props.data.recencyScore} / 4`}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <b>Frequency Score:</b>{' '}
                                {`${props.data.frequencyScore} / 4`}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <b>Monetization Score:</b>{' '}
                                {`${props.data.monitizationScore} / 4`}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <b>Outdated Pricing Score:</b>{' '}
                                {`${props.data.outdatedPricingScore} / 4`}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <b>Pricing Lag Score:</b>{' '}
                                {`${props.data.pricingLagScore} / 4`}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <b>Below Average Margin Score:</b>{' '}
                                {`${props.data.belowAverageMarginScore} / 4`}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <b>Margin Below Goal Score:</b>{' '}
                                {`${props.data.marginBelowGoalScore} / 4`}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <b>Below Average Price Score:</b>{' '}
                                {`${props.data.belowAveragePriceScore} / 4`}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        );

        return (
            <Tooltip title={tooltipData}>
                <IconButton onClick={handleClick}>
                    <span style={{ fontSize: '12px' }}>
                        {props.data.datacorScore}
                    </span>
                </IconButton>
            </Tooltip>
        );
    };

    const onSortChanged = () => {
        dispatch(
            updateGridModels({
                gridLocation: 'pricingAnalysisTool',
                gridName: 'pricingConcernDetails',
                type: 'column',
                model: gridColumnApi.getColumnState(),
            })
        );
    };

    const onFilterChanged = () => {
        dispatch(
            updateGridModels({
                gridLocation: 'pricingAnalysisTool',
                gridName: 'pricingConcernDetails',
                type: 'filter',
                model: gridApi.getFilterModel(),
            })
        );
    };

    const onGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
        setRowData(pricingAnalysisToolSet.activePricingConcernDetails);
    };

    const onFirstDataRendered = () => {
        gridColumnApi.applyColumnState({
            state: pricingAnalysisTool.pricingConcernDetails.column,
            applyOrder: true,
        });

        gridApi.setFilterModel(
            pricingAnalysisTool.pricingConcernDetails.filter
        );

        if (
            !isNilOrEmpty(pricingAnalysisToolSet.selectedCustomerProductDetails)
        ) {
            gridApi.forEachNode((node: any) => {
                node.setSelected(
                    pricingAnalysisToolSet.selectedCustomerProductDetails.some(
                        (selectedRow: any) => selectedRow.id === node.data.id
                    )
                );
            });
        }
    };

    const onBtExport = () => {
        gridApi.exportDataAsExcel();
    };

    return {
        pricingAnalysisToolSet,
        openRecommendationModalWithProps,
        handleRecommendationModalClose,
        openDetailsModal,
        setOpenDetailsModal,
        onBtExport,
        rowData,
        onFirstDataRendered,
        onSortChanged,
        onFilterChanged,
        onGridReady,
        Recommendation,
        HasReplacementPrice,
        OpportunityScore,
        cellClickedEvent,
        onRowSelected,
    };
};

export default usePricingConcernDetailsGrid;
