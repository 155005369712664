import { useParams } from 'react-router-dom';
import useFixedAssetFunctionCodesForm from '../hooks/useFixedAssetFunctionCodesForm';
import { Card, CardContent, CardHeader, Grid } from '@mui/material';
import DatacorLogoSpinner from '../../../components/DatacorLogoSpinner/DatacorLogoSpinner';
import FormContainer from '../../../components/Form/FormContainer/FormContainer';
import FixedAssetsFormHeader from '../../../components/Form/FormContainer/FormHeader';
import FormPartial from '../../../components/Form/FormContainer/FormPartial';
import TextInput from '../../../components/Form/FormInputs/TextInput/TextInput';
import MultiSelectGrid from '../../../components/Grids/MultiSelectGrid';
import { AgGridColumn } from 'ag-grid-react';
import React from 'react';

interface ParamTypes {
    functionCodeId: string;
}

const FixedAssetFunctionCodesForm = () => {
    const { functionCodeId } = useParams<ParamTypes>();
    const {
        activeFunctionCode,
        fields,
        formMethods,
        onUpdate,
        onGridReady,
        closeForm,
        isLoading,
        isLoadingSelectedAccounts,
    } = useFixedAssetFunctionCodesForm(functionCodeId);

    return (
        <Card>
            <CardHeader title="Fixed Asset Function Code" />

            {isLoading ? (
                <DatacorLogoSpinner />
            ) : (
                <CardContent>
                    <FormContainer
                        headerTitle={`Primary Information`}
                        onSubmit={() => formMethods.setIsFormSubmitted(true)}
                        headerComponent={
                            <FixedAssetsFormHeader
                                showOnDelete={false}
                                showOnSave={true}
                                onSave={() => {
                                    onUpdate();
                                }}
                                onDelete={closeForm}
                                onCancel={closeForm}
                                isEdit={true}
                            />
                        }>
                        <FormPartial>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <TextInput
                                        fullWidth
                                        label="Function Code"
                                        id="code"
                                        value={fields.code}
                                        disabled={true}
                                    />
                                </Grid>
                            </Grid>
                        </FormPartial>
                        <FormPartial title="Accounts">
                            {isLoadingSelectedAccounts ? (
                                <DatacorLogoSpinner />
                            ) : (
                                <MultiSelectGrid
                                    rowData={
                                        activeFunctionCode?.availableAccounts ||
                                        []
                                    }
                                    onGridReady={onGridReady}
                                    rowMultiSelectWithClick={true}
                                    gridClass="full-width-grid">
                                    <AgGridColumn
                                        maxWidth={100}
                                        checkboxSelection={true}
                                        headerName="Selected"
                                    />
                                    <AgGridColumn
                                        minWidth={150}
                                        field="number"
                                        headerName="Number"
                                    />
                                    <AgGridColumn
                                        minWidth={200}
                                        field="name"
                                        headerName="Name"
                                    />
                                </MultiSelectGrid>
                            )}
                        </FormPartial>
                    </FormContainer>
                </CardContent>
            )}
        </Card>
    );
};

export default FixedAssetFunctionCodesForm;
