import React, { useEffect, useState } from 'react';
import { DialogProps } from '@mui/material/Dialog/Dialog';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MultiSelectGrid from '../Grids/MultiSelectGrid';
import {
    ColDef,
    ColGroupDef,
} from 'ag-grid-community/dist/lib/entities/colDef';
import { GridReadyEvent, RowNode } from 'ag-grid-community';
import { GridApi } from 'ag-grid-community/dist/lib/gridApi';
import { isNilOrEmpty } from '../../utils/objectUtils';

export type MultiSelectGridModalProps = Omit<
    DialogProps,
    'onClose' | 'children'
> & {
    title: string;
    onClose: () => void;
    onSelected: (selectedRows: any[]) => void;
    rowData: any[];
    columnDefs: (ColDef | ColGroupDef)[];
    preSelectedIds?: () => (number | bigint)[];
};

const MultiSelectGridModal = (props: MultiSelectGridModalProps) => {
    const [gridApi, setGridApi] = useState<GridApi>(null);

    const processSelected = () => {
        props.onSelected(gridApi.getSelectedRows());
        props.onClose();
    };

    const onGridReady = (props: GridReadyEvent) => {
        setGridApi(props.api);
    };

    useEffect(() => {
        const selectedIdList: (number | bigint)[] = props.preSelectedIds();

        if (gridApi && !isNilOrEmpty(selectedIdList)) {
            gridApi.forEachNode((node: RowNode) => {
                node.setSelected(selectedIdList.includes(node.data.id));
            });
        }
    }, [gridApi]);

    return (
        <Dialog
            open={props.open}
            fullWidth
            maxWidth={'lg'}
            onClose={props.onClose}>
            <DialogTitle>
                <Grid container spacing={2}>
                    <Grid item xs={11}>
                        {props.title}
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton
                            aria-label="close"
                            onClick={props.onClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                            }}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>

            <DialogContent>
                <MultiSelectGrid
                    rowData={props.rowData}
                    columnDefs={[
                        {
                            headerName: 'Selected',
                            checkboxSelection: true,
                            maxWidth: 100,
                        },
                        ...props.columnDefs,
                    ]}
                    rowMultiSelectWithClick={true}
                    onGridReady={onGridReady}
                    gridClass="full-width-grid"></MultiSelectGrid>
            </DialogContent>

            <DialogActions>
                <Button autoFocus onClick={processSelected}>
                    Select
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default MultiSelectGridModal;
