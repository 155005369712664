import { useDispatch } from 'react-redux';
import { logout } from '../../../store/user';
import { isFeatureFlagEnabled } from '../../../utils/FeatureFlag/FeatureFlagUtil';
import { PermissionsUtil } from '../../../utils/permissions/PermissionsUtil';
import { PERMISSIONS } from '../../../constants/permissions/permissions.constants';
import { Menu, MenuItem } from '@mui/material';
import { Link } from 'react-router-dom';
import React from 'react';

interface SettingsMenuProps {
    user: any;
    anchorEl: HTMLElement | null;
    setAnchorEl: (value: any) => void;
}

const SettingsMenu = ({ user, anchorEl, setAnchorEl }: SettingsMenuProps) => {
    const dispatch = useDispatch();
    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        dispatch(logout());
    };
    const menuItems = [
        {
            title: 'Change Email',
            link: '/settings/email',
            shouldShow: true,
        },
        {
            title: 'Change Password',
            link: '/settings/password',
            shouldShow: true,
        },
        {
            title: 'Access Roles',
            link: '/settings/roles',
            shouldShow: isFeatureFlagEnabled(
                user.enabledFeatureFlags,
                'settings.roles'
            ),
        },
        {
            title: 'Feature Flags',
            link: '/settings/featureFlags',
            shouldShow:
                user.isDatacorAdmin &&
                PermissionsUtil.isPermissionEnabled(
                    user.permissions,
                    PERMISSIONS.SETTINGS.FEATURE_FLAGS.VIEW
                ),
        },
        {
            title: 'Logout',
            shouldShow: true,
        },
    ];
    return (
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}>
            {menuItems.map((item) => {
                if (item.shouldShow) {
                    return (
                        <Link
                            key={item.title}
                            to={item.link || '/'}
                            style={{
                                textDecoration: 'none',
                                color: 'black',
                            }}>
                            <MenuItem
                                onClick={
                                    item.link ? handleClose : handleLogout
                                }>
                                {item.title}
                            </MenuItem>
                        </Link>
                    );
                }
            })}
        </Menu>
    );
};

export default SettingsMenu;
