import React from 'react';
import { AgGridColumn } from 'ag-grid-react';
import { useHistory } from 'react-router-dom';
import Grid from '../../../components/Grids/Grid';
import { useGetAllStatusQuery } from '../../../services/fixedAssets/fixedAssets.service';
import DatacorLogoSpinner from '../../../components/DatacorLogoSpinner/DatacorLogoSpinner';
import { GridOptions } from '../../../components/Grids/grid.constants';

const FixedAssetStatusGrid = () => {
    const history = useHistory();
    const { data: statusList, isLoading } = useGetAllStatusQuery();

    const redirectToForm = (row: any) => {
        if (row.id) {
            history.push(`/accounting/fixedAssets/status/${row.id}`);
        } else {
            history.push('/accounting/fixedAssets/status/new');
        }
    };

    return isLoading ? (
        <DatacorLogoSpinner />
    ) : (
        <Grid
            rowData={statusList}
            gridClass="full-width-grid"
            displayToolbar={true}
            displayCreateNewButton={true}
            onCreateNew={redirectToForm}
            onRowClicked={({ data }) => redirectToForm(data)}
            defaultColDef={GridOptions.sortFilterAndWrapColumns}>
            <AgGridColumn
                minWidth={100}
                field="sortOrder"
                headerName="Sort Order"
            />
            <AgGridColumn
                minWidth={150}
                field="code"
                headerName="Status Code"
            />
            <AgGridColumn
                minWidth={250}
                field="description"
                headerName="Description"
            />
            <AgGridColumn
                minWidth={75}
                field="isInService"
                headerName="In Service"
            />
            <AgGridColumn
                minWidth={75}
                field="isDisposal"
                headerName="Disposal"
            />
        </Grid>
    );
};

export default FixedAssetStatusGrid;
