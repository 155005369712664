let moment = require('moment');

export const currencyFormatter = (param: any, precision = 2) => {
    const rawValue = param instanceof Object ? param.value : param;

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: precision,
    });

    return isFinite(rawValue) ? formatter.format(rawValue) : rawValue;
};

export const dateFormatter = (param: any) => {
    const rawValue = param instanceof Object ? param.value : param;
    if (moment(rawValue).isValid()) {
        const utcDate = moment(rawValue).utc();
        return moment({
            year: utcDate.year(),
            month: utcDate.month(),
            day: utcDate.date(),
        }).format('L');
    }

    return '';
};

export const dateTimeFormatter = (param: any) => {
    const rawValue = param instanceof Object ? param.value : param;
    return moment(rawValue).isValid() ? moment(rawValue).format('L LT') : '';
};

export const numberFormatter = (param: any) => {
    const rawValue = param instanceof Object ? param.value : param;

    return new Intl.NumberFormat().format(rawValue);
};

export const percentFormatter = (param: any) => {
    const rawValue = param instanceof Object ? param.value : param;

    return isFinite(rawValue)
        ? Number(rawValue / 100).toLocaleString(undefined, {
              style: 'percent',
              minimumFractionDigits: 2,
          })
        : rawValue;
};

export const yesNoFormatter = (param: any) => {
    return param.value !== null && param.value !== '' ? 'Yes' : 'No';
};
