import React from 'react';
import { Card, CardContent, CardHeader, Grid, TextField } from '@mui/material';
import { useParams } from 'react-router-dom';
import FormContainer from '../../../components/Form/FormContainer/FormContainer';
import DatacorLogoSpinner from '../../../components/DatacorLogoSpinner/DatacorLogoSpinner';
import FormPartial from '../../../components/Form/FormContainer/FormPartial';
import TextInput from '../../../components/Form/FormInputs/TextInput/TextInput';
import FixedAssetsFormHeader from '../../../components/Form/FormContainer/FormHeader';
import useFixedAssetForm from '../hooks/useFixedAssetForm';
import DateInput from '../../../components/Form/FormInputs/DateInput/DateInput';
import SelectInput from '../../../components/Form/FormInputs/SelectInput/SelectInput';
import EntityGrid from '../../../components/Grids/Grid';
import { AgGridColumn } from 'ag-grid-react';
import { currencyFormatter } from '../../../utils/formattingUtils';
import { numberAsStringComparator } from '../../../utils/comparisonUtils';
import ToastContainer from '../../../components/Toast/ToastContainer';
import { GridOptions } from '../../../components/Grids/grid.constants';
import MultiSelectGridModal from '../../../components/Modals/MultiSelectGridModal';
import { Contract } from '../../../types/Accounting.types';

interface ParamTypes {
    assetId: string;
}

const FixedAssetForm = () => {
    const { assetId } = useParams<ParamTypes>();
    const {
        fields,
        handleFieldChange,
        formMethods,
        toastProps,
        onCreate,
        onUpdate,
        closeForm,
        isLoadingAsset,
        isLoadingStatuses,
        isLoadingTypes,
        isLoadingCostWorksheet,
        isLoadingContracts,
        costWorksheet,
        statusOptions,
        typeOptions,
        testBuildingOptions,
        contractList,
        updateDateField,
        showContractSelectionModal,
        setShowContractSelectionModal,
        selectedContracts,
        setSelectedContracts,
    } = useFixedAssetForm(assetId);

    return (
        <Card>
            <CardHeader title="Fixed Asset" />

            <MultiSelectGridModal
                title="Contract Selection"
                open={showContractSelectionModal}
                onClose={() => setShowContractSelectionModal(false)}
                onSelected={(contractList: Contract[]) =>
                    setSelectedContracts(contractList)
                }
                rowData={contractList || []}
                columnDefs={[
                    { field: 'number', headerName: 'Number', minWidth: 150 },
                    { field: 'name', headerName: 'Name', minWidth: 200 },
                ]}
                preSelectedIds={() => {
                    return selectedContracts?.map(
                        (contract: Contract) => contract.id
                    );
                }}
            />

            {isLoadingAsset ? (
                <DatacorLogoSpinner />
            ) : (
                <CardContent>
                    <ToastContainer {...toastProps} show={!!toastProps?.show} />

                    <FormContainer
                        headerTitle={`Primary Information`}
                        onSubmit={() => formMethods.setIsFormSubmitted(true)}
                        headerComponent={
                            <FixedAssetsFormHeader
                                showOnDelete={false}
                                showOnSave={true}
                                onSave={() => {
                                    if (!assetId) {
                                        onCreate();
                                    } else {
                                        onUpdate();
                                    }
                                }}
                                onDelete={closeForm}
                                onCancel={closeForm}
                                isEdit={!!assetId}
                            />
                        }>
                        <FormPartial>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextInput
                                        fullWidth
                                        label="Code"
                                        id="code"
                                        value={fields.code}
                                        onChange={handleFieldChange}
                                        formMethods={formMethods}
                                        required={true}
                                        maxLength={30}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextInput
                                        fullWidth
                                        label="Name"
                                        id="name"
                                        value={fields.name}
                                        onChange={handleFieldChange}
                                        formMethods={formMethods}
                                        maxLength={90}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextInput
                                        fullWidth
                                        label="Description"
                                        id="description"
                                        value={fields.description}
                                        onChange={handleFieldChange}
                                        formMethods={formMethods}
                                        maxLength={300}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <SelectInput
                                        fullWidth
                                        label="Status"
                                        id="statusId"
                                        value={fields.statusId}
                                        onChange={handleFieldChange}
                                        formMethods={formMethods}
                                        options={statusOptions}
                                        disabled={isLoadingStatuses}
                                        required={true}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <DateInput
                                        label="Acquire Date"
                                        value={fields.acquireDate}
                                        onChange={(date: Date) =>
                                            updateDateField('acquireDate', date)
                                        }
                                        renderInput={(params) => (
                                            <TextField fullWidth {...params} />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <DateInput
                                        label="In Service Date"
                                        value={fields.inServiceDate}
                                        onChange={(date: Date) =>
                                            updateDateField(
                                                'inServiceDate',
                                                date
                                            )
                                        }
                                        renderInput={(params) => (
                                            <TextField fullWidth {...params} />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <DateInput
                                        label="Disposal Date"
                                        value={fields.disposalDate}
                                        onChange={(date: Date) =>
                                            updateDateField(
                                                'disposalDate',
                                                date
                                            )
                                        }
                                        renderInput={(params) => (
                                            <TextField fullWidth {...params} />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <SelectInput
                                        fullWidth
                                        label="Asset Type (Book)"
                                        id="ledgerTypeId"
                                        value={fields.ledgerTypeId}
                                        onChange={handleFieldChange}
                                        formMethods={formMethods}
                                        options={typeOptions}
                                        disabled={isLoadingTypes}
                                        required={true}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <SelectInput
                                        fullWidth
                                        label="Asset Type (Tax)"
                                        id="taxTypeId"
                                        value={fields.taxTypeId}
                                        onChange={handleFieldChange}
                                        formMethods={formMethods}
                                        options={typeOptions}
                                        disabled={isLoadingTypes}
                                        required={true}
                                    />
                                </Grid>
                            </Grid>
                        </FormPartial>
                        <FormPartial title="References">
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <SelectInput
                                        fullWidth
                                        label="Building"
                                        id="buildingId"
                                        value={fields.buildingId}
                                        onChange={handleFieldChange}
                                        formMethods={formMethods}
                                        options={testBuildingOptions}
                                        disabled={false}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextInput
                                        fullWidth
                                        label="Manufacturer Serial #"
                                        id="manufacturerSerialNumber"
                                        value={fields.manufacturerSerialNumber}
                                        onChange={handleFieldChange}
                                        formMethods={formMethods}
                                        maxLength={90}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextInput
                                        fullWidth
                                        label="Purchase Order #"
                                        id="purchaseOrderNumber"
                                        value={fields.purchaseOrderNumber}
                                        onChange={handleFieldChange}
                                        formMethods={formMethods}
                                        maxLength={30}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextInput
                                        fullWidth
                                        label="Supplier Invoice #"
                                        id="supplierInvoiceNumber"
                                        value={fields.supplierInvoiceNumber}
                                        onChange={handleFieldChange}
                                        formMethods={formMethods}
                                        maxLength={30}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextInput
                                        fullWidth
                                        label="Capital Project #"
                                        id="capitalProjectNumber"
                                        value={fields.capitalProjectNumber}
                                        onChange={handleFieldChange}
                                        formMethods={formMethods}
                                        maxLength={30}
                                    />
                                </Grid>
                            </Grid>
                        </FormPartial>
                        <FormPartial title="Costs">
                            {isLoadingCostWorksheet ? (
                                <DatacorLogoSpinner />
                            ) : (
                                <EntityGrid
                                    rowData={costWorksheet || []}
                                    gridClass="full-width-grid">
                                    <AgGridColumn
                                        minWidth={150}
                                        field="costCode.code"
                                        headerName="Code"
                                    />
                                    <AgGridColumn
                                        minWidth={200}
                                        field="amount"
                                        headerName="Amount"
                                        valueFormatter={currencyFormatter}
                                        comparator={numberAsStringComparator}
                                        editable={true}
                                    />
                                </EntityGrid>
                            )}
                        </FormPartial>
                        <FormPartial title="Contracts">
                            <EntityGrid
                                rowData={selectedContracts}
                                gridClass="full-width-grid"
                                displayToolbar={true}
                                displayCreateNewButton={!isLoadingContracts}
                                onCreateNew={() =>
                                    setShowContractSelectionModal(true)
                                }
                                defaultColDef={
                                    GridOptions.sortFilterAndWrapColumns
                                }>
                                <AgGridColumn
                                    minWidth={150}
                                    field="number"
                                    headerName="Number"
                                />
                                <AgGridColumn
                                    minWidth={200}
                                    field="name"
                                    headerName="Name"
                                />
                                <AgGridColumn
                                    minWidth={250}
                                    field="description"
                                    headerName="Description"
                                />
                                <AgGridColumn
                                    minWidth={200}
                                    field="contractType.code"
                                    headerName="Type"
                                />
                            </EntityGrid>
                        </FormPartial>
                    </FormContainer>
                </CardContent>
            )}
        </Card>
    );
};

export default FixedAssetForm;
