import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import BatchTicketStepDetails from '../../components/BatchTicket/BatchTicketStepDetails';
import { updateBatchStepStartAndEndDate } from '../../store/scheduler';
import LoaderButton from '../../components/LoaderButton';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Box,
    IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Step } from '../../types/BatchTicket.types';

interface StepInfoModalProps {
    show: boolean;
    step: any;
    handleClose: (_: boolean) => void;
}

const StepInfoModal = ({
    show = false,
    step = null,
    handleClose,
}: StepInfoModalProps) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const stepProps = step && step.extendedProps ? step.extendedProps : step;

    const unscheduleBatchTicketStep = () => {
        setIsLoading(true);
        const unscheduledStep: Step = {
            id: step.id,
            BatchNumber: stepProps.BatchNumber,
            StepNumber: stepProps.StepNumber,
            start: null,
            end: null,
        };
        dispatch(
            updateBatchStepStartAndEndDate(
                unscheduledStep,
                stepProps.Workcenter,
                false,
                () => handleClose
            )
        );
    };

    //removing functionality for now as it isn't needed
    /*const unscheduleAllBatchTicketSteps = () => {
        schedulerDataSet.scheduledSteps.forEach(scheduledStep => {
            if(scheduledStep.BatchNumber === stepProps.BatchNumber) {
                unscheduleBatchTicketStep(scheduledStep);
            }
        })
    }*/

    return step ? (
        <Dialog
            open={show}
            onClose={handleClose}
            className="stepInfoModal"
            data-testid="step-info-modal">
            <DialogTitle data-testid="approval-workflows-modal-header">
                <Box display="flex" alignItems="center">
                    <Box flexGrow={1}>{step.id}</Box>
                    <Box>
                        <IconButton onClick={() => handleClose(true)}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
            </DialogTitle>

            <DialogContent>
                <BatchTicketStepDetails step={stepProps} />
            </DialogContent>
            <DialogActions>
                {step.start ? (
                    <>
                        <LoaderButton
                            variant="contained"
                            color="primary"
                            isLoading={isLoading}
                            onClick={() => unscheduleBatchTicketStep()}>
                            Unschedule Step
                        </LoaderButton>
                    </>
                ) : null}
            </DialogActions>
        </Dialog>
    ) : null;
};

export default StepInfoModal;
