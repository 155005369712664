import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
    useGetAllApprovalWorkflowsQuery,
    useGetUsersQuery,
    useUpdateApprovalWorkflowMutation,
} from '../../../../services/apAutomation/apAutomation.service';
import { RootState } from '../../../../store';

const useApproversCellRenderer = ({ data, rowIndex }: any) => {
    const user = useSelector((state: RootState) => state.user);
    const [updateApprovalWorkflow] = useUpdateApprovalWorkflowMutation();
    const { data: approvalWorkflows } = useGetAllApprovalWorkflowsQuery();
    const { data: users } = useGetUsersQuery();
    const [localApproversOptions, setLocalApproversOptions] = useState(
        data.approvers
    );
    const [isRowSaving, setIsRowSaving] = useState(false);

    const updateWorkflow = async () => {
        setIsRowSaving(true);

        const args = {
            workflowId: data.workflowId,
            workflowName: data.workflowName,
            approvers: localApproversOptions,
            isActive: data.isActive,
        };

        updateApprovalWorkflow(args);
        setIsRowSaving(false);
    };

    return {
        user,
        approvalWorkflows,
        localApproversOptions,
        users,
        setLocalApproversOptions,
        updateWorkflow,
        isRowSaving,
    };
};

export default useApproversCellRenderer;
