import React from 'react';
import {
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    FormControlLabel,
    Grid,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import FormContainer from '../../../components/Form/FormContainer/FormContainer';
import DatacorLogoSpinner from '../../../components/DatacorLogoSpinner/DatacorLogoSpinner';
import useFixedAssetsCostCodeForm from '../hooks/useFixedAssetsCostCodeForm';
import FixedAssetsFormHeader from '../../../components/Form/FormContainer/FormHeader';
import FormPartial from '../../../components/Form/FormContainer/FormPartial';
import TextInput from '../../../components/Form/FormInputs/TextInput/TextInput';
import NumericInput from '../../../components/Form/FormInputs/NumericInput/NumericInput';
import ToastContainer from '../../../components/Toast/ToastContainer';

interface ParamTypes {
    costCodeId: string;
}

const FixedAssetsCostCodeForm = () => {
    const { costCodeId } = useParams<ParamTypes>();
    const {
        fields,
        handleFieldChange,
        formMethods,
        toastProps,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        isLoadingCostCode,
    } = useFixedAssetsCostCodeForm(costCodeId);

    return (
        <Card>
            <CardHeader title="Fixed Asset Cost Code" />

            {isLoadingCostCode ? (
                <DatacorLogoSpinner />
            ) : (
                <CardContent>
                    <ToastContainer {...toastProps} show={!!toastProps?.show} />

                    <FormContainer
                        headerTitle={'Primary Information'}
                        onSubmit={() => formMethods.setIsFormSubmitted(true)}
                        headerComponent={
                            <FixedAssetsFormHeader
                                showOnDelete={!!costCodeId}
                                showOnSave={true}
                                onSave={() => {
                                    if (!costCodeId) {
                                        onCreate();
                                    } else {
                                        onUpdate();
                                    }
                                }}
                                onDelete={() => {
                                    onDelete();
                                }}
                                onCancel={closeForm}
                                isEdit={!!costCodeId}
                            />
                        }>
                        <FormPartial>
                            <Grid container flexDirection="row" spacing={2}>
                                <Grid item xs={6}>
                                    <TextInput
                                        fullWidth
                                        label="Cost Code"
                                        id="code"
                                        value={fields.code}
                                        onChange={handleFieldChange}
                                        formMethods={formMethods}
                                        required={true}
                                        maxLength={30}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <NumericInput
                                        fullWidth
                                        label="Sort Order"
                                        id="sortOrder"
                                        value={fields.sortOrder}
                                        onChange={handleFieldChange}
                                        formMethods={formMethods}
                                        required={true}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControlLabel
                                        label="Is Add"
                                        control={
                                            <Checkbox
                                                id="isAdd"
                                                checked={fields.isAdd}
                                                onChange={handleFieldChange}
                                            />
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </FormPartial>
                    </FormContainer>
                </CardContent>
            )}
        </Card>
    );
};

export default FixedAssetsCostCodeForm;
