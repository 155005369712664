import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { useParams } from 'react-router-dom';
import { useGetDocumentQuery } from '../../../services/apAutomation/apAutomation.service';
import { skipToken } from '@reduxjs/toolkit/query';

interface TabPanelProps {
    children?: React.ReactNode;
    index?: number;
    value: number;
}

interface ParamTypes {
    documentId: string;
}

const useDocumentView = () => {
    const { documentId } = useParams<ParamTypes>();
    const [value, setValue] = useState(0);
    const { isLoading: isLoadingDoc } = useGetDocumentQuery(
        documentId ? documentId : skipToken
    );

    const TabPanel = (props: TabPanelProps) => {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}>
                {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
            </div>
        );
    };

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return { isLoadingDoc, value, handleChange, documentId, TabPanel };
};

export default useDocumentView;
