import React from 'react';
import FormulationCasGrid from './Pages/FormulationCasGrid';

const CasMaster = () => {
    return (
        <>
            <FormulationCasGrid />
        </>
    );
};

export default CasMaster;
