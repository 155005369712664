import React from 'react';
import { AgGridColumn } from 'ag-grid-react';
import { useHistory } from 'react-router-dom';
import Grid from '../../components/Grids/Grid';
import { useGetAllGeneralLedgerAccountsQuery } from '../../services/accounting/accounting.service';
import DatacorLogoSpinner from '../../components/DatacorLogoSpinner/DatacorLogoSpinner';
import { GridOptions } from '../../components/Grids/grid.constants';

const GeneralLedgerAccountGrid = () => {
    const history = useHistory();
    const { data: generalLedgerAccountList, isLoading } =
        useGetAllGeneralLedgerAccountsQuery();

    const redirectToForm = (row: any) => {
        if (row.id) {
            history.push(`/accounting/generalLedgerAccount/${row.id}`);
        } else {
            history.push('/accounting/generalLedgerAccount/new');
        }
    };

    return isLoading ? (
        <DatacorLogoSpinner />
    ) : (
        <Grid
            rowData={generalLedgerAccountList}
            gridClass="full-width-grid"
            displayToolbar={true}
            displayCreateNewButton={true}
            onCreateNew={redirectToForm}
            onRowClicked={({ data }) => redirectToForm(data)}
            defaultColDef={GridOptions.sortFilterAndWrapColumns}>
            <AgGridColumn minWidth={150} field="number" headerName="Number" />
            <AgGridColumn minWidth={200} field="name" headerName="Name" />
            <AgGridColumn
                minWidth={250}
                field="description"
                headerName="Description"
            />
            <AgGridColumn minWidth={200} field="status" headerName="Status" />
            <AgGridColumn
                minWidth={200}
                field="balanceType"
                headerName="Balance Type"
            />
            <AgGridColumn
                minWidth={200}
                field="accountType"
                headerName="Account Type"
            />
        </Grid>
    );
};

export default GeneralLedgerAccountGrid;
