import React from 'react';
import { List, ListItem } from '@mui/material';

const SummaryHeading = ({ analyticData }: any) => {
    const flexContainer: any = {
        display: 'flex',
        flexDirection: 'row',
        padding: 0,
    };

    return (
        <List style={flexContainer}>
            <ListItem className="analytics-list-group-item">
                <h4>Total Invoices</h4>
                <h1>{analyticData?.allDocs?.totalDocs}</h1>
            </ListItem>
            <ListItem className="analytics-list-group-item">
                <h4>Total Processed</h4>
                <h1>{analyticData?.allDocs?.totalProcessed}</h1>
            </ListItem>
            <ListItem className="analytics-list-group-item">
                <h4>Average Days to Process</h4>
                <h1>
                    {parseFloat(
                        analyticData?.allDocs?.averageDaysToProcess
                    ).toFixed(2)}
                </h1>
            </ListItem>
            <ListItem className="analytics-list-group-item">
                <h4>Total Approved</h4>
                <h1>{analyticData?.allDocs?.totalApproved}</h1>
            </ListItem>
            <ListItem className="analytics-list-group-item">
                <h4>Total Pending Approval</h4>
                <h1>{analyticData?.allDocs?.totalPendingApproval}</h1>
            </ListItem>
            <ListItem className="analytics-list-group-item">
                <h4>Total Pending My Approval</h4>
                <h1>{analyticData?.totalUserPendingApproval}</h1>
            </ListItem>
        </List>
    );
};

export default SummaryHeading;
