import React from 'react';
import {
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    FormControlLabel,
    Grid,
    TextField,
} from '@mui/material';
import useFormulationCasForm from '../hooks/useFormulationCasForm';
import DatacorLogoSpinner from '../../../components/DatacorLogoSpinner/DatacorLogoSpinner';
import FormContainer from '../../../components/Form/FormContainer/FormContainer';
import FixedAssetsFormHeader from '../../../components/Form/FormContainer/FormHeader';
import FormPartial from '../../../components/Form/FormContainer/FormPartial';
import TextInput from '../../../components/Form/FormInputs/TextInput/TextInput';
import DateInput from '../../../components/Form/FormInputs/DateInput/DateInput';
import NumericInput from '../../../components/Form/FormInputs/NumericInput/NumericInput';

const FormulationCasForm = () => {
    const {
        isLoadingCasRecord,
        fields,
        formMethods,
        handleClose,
        handleFieldChange,
        updateDateField,
        handleCreateCasRecord,
    } = useFormulationCasForm();

    return (
        <Card>
            <CardHeader title="CAS Maintenance" />
            {isLoadingCasRecord ? (
                <DatacorLogoSpinner />
            ) : (
                <CardContent>
                    <FormContainer
                        headerTitle={`Primary Information`}
                        onSubmit={() => formMethods.setIsFormSubmitted(true)}
                        headerComponent={
                            <FixedAssetsFormHeader
                                showOnDelete={false}
                                showOnSave={true}
                                onSave={handleCreateCasRecord}
                                onDelete={() => {}}
                                onCancel={handleClose}
                                isEdit={false}
                            />
                        }>
                        <FormPartial>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <TextInput
                                        fullWidth
                                        id="casNum"
                                        value={fields.casNum}
                                        label="CAS No."
                                        maxLength={20}
                                        formMethods={formMethods}
                                        required={true}
                                        onChange={handleFieldChange}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextInput
                                        fullWidth
                                        id="chemicalName"
                                        value={fields.chemicalName}
                                        label="Chemical Name"
                                        maxLength={250}
                                        formMethods={formMethods}
                                        onChange={handleFieldChange}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControlLabel
                                        label="Chemical Update"
                                        control={
                                            <Checkbox
                                                id="chemicalUpdate"
                                                checked={fields.chemicalUpdate}
                                                onChange={handleFieldChange}
                                            />
                                        }
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <DateInput
                                        label="Last Chemical Update"
                                        value={fields.lastChemicalUpdate}
                                        readOnly={true}
                                        onChange={(date: Date) =>
                                            updateDateField(
                                                'lastChemicalUpdate',
                                                date
                                            )
                                        }
                                        renderInput={(params) => (
                                            <TextField fullWidth {...params} />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </FormPartial>
                        <FormPartial title="Additional Identification">
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <TextInput
                                        fullWidth
                                        id="indexNum"
                                        value={fields.indexNum}
                                        label="Index No."
                                        maxLength={15}
                                        formMethods={formMethods}
                                        onChange={handleFieldChange}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextInput
                                        fullWidth
                                        id="ecNum"
                                        value={fields.ecNum}
                                        label="EC No."
                                        maxLength={10}
                                        formMethods={formMethods}
                                        onChange={handleFieldChange}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextInput
                                        fullWidth
                                        id="reachNum"
                                        value={fields.reachNum}
                                        label="Reach No."
                                        maxLength={25}
                                        formMethods={formMethods}
                                        onChange={handleFieldChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextInput
                                        fullWidth
                                        id="classification"
                                        value={fields.classification}
                                        label="Classification"
                                        maxLength={250}
                                        multiline={true}
                                        rows={3}
                                        formMethods={formMethods}
                                        onChange={handleFieldChange}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextInput
                                        fullWidth
                                        id="molecularFormula"
                                        value={fields.molecularFormula}
                                        label="Molecular Formula"
                                        maxLength={50}
                                        formMethods={formMethods}
                                        onChange={handleFieldChange}
                                    />
                                </Grid>
                                <Grid item xs={6}></Grid>
                                <Grid item xs={2}>
                                    <FormControlLabel
                                        label="Show on SDS"
                                        control={
                                            <Checkbox
                                                id="showOnSDS"
                                                checked={fields.showOnSDS}
                                                onChange={handleFieldChange}
                                            />
                                        }
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <NumericInput
                                        fullWidth
                                        id="weightThreshold"
                                        label="If weight is greater than"
                                        value={
                                            fields.weightThreshold == null
                                                ? ''
                                                : fields.weightThreshold
                                        }
                                        onChange={handleFieldChange}
                                        formMethods={formMethods}
                                        numDecimals={2}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControlLabel
                                        label="Trade Secret"
                                        control={
                                            <Checkbox
                                                id="tradeSecret"
                                                checked={fields.tradeSecret}
                                                onChange={handleFieldChange}
                                            />
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </FormPartial>
                    </FormContainer>
                </CardContent>
            )}
        </Card>
    );
};

export default FormulationCasForm;
