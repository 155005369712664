import React, { ChangeEvent, useEffect, useState } from 'react';
import InputBase, { InputBaseProps } from '../InputBase/InputBase';
import { Checkbox, MenuItem } from '@mui/material';
import { isNilOrEmpty } from '../../../../utils/objectUtils';
import { SelectionOption } from '../../../../types/shared.types';

export type SelectInputProps = {
    options: SelectionOption[];
    SelectProps?: { multiple: boolean };
} & InputBaseProps;

const SelectInput = (props: SelectInputProps) => {
    const [value, setValue] = useState(props.SelectProps?.multiple ? [] : '');

    useEffect(() => {
        if (!isNilOrEmpty(props.options) && props.value != null) {
            setValue(props.value as any);
        }
    }, [props.value, props.options]);

    const optionSelected = (selectedOption: any) => {
        setValue(selectedOption.target.value);

        const changEvent = {
            target: { id: props.id, value: selectedOption.target.value },
        } as unknown as ChangeEvent<HTMLInputElement>;

        props.onChange(changEvent);
    };

    const getNullStateValue = (): any => {
        if (props.SelectProps?.multiple) {
            return [];
        }

        return '';
    };

    return (
        <InputBase
            {...props}
            select
            value={value || getNullStateValue()}
            onChange={(selectedOption) => optionSelected(selectedOption)}>
            {props.options?.map((option: SelectionOption) => (
                <MenuItem key={option.label} value={option.value}>
                    {props.SelectProps?.multiple ? (
                        <Checkbox checked={value.indexOf(option.value) > -1} />
                    ) : null}
                    {option.label}
                </MenuItem>
            ))}
        </InputBase>
    );
};

export default SelectInput;
