import { isNil } from '../utils/objectUtils';
import { ParameterizedValidator, Validator } from './validator.interface';

export const MaxLengthValidator: ParameterizedValidator = (
    maxLength: number
) => {
    return {
        key: 'maxLength',
        getErrorMessage: (fieldLabel: string) => {
            return `${fieldLabel} has a maximum length of ${maxLength} characters`;
        },
        isValid: (value: any) => {
            return isNil(value) || value.toString().length <= maxLength;
        },
    } as Validator;
};
