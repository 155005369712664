import { useHistory } from 'react-router-dom';
import {
    useGetAllDepreciationMethodsQuery,
    useGetDepreciationMethodStatusesMapQuery,
    useGetDepreciationMethodTypesMapQuery,
} from '../../../services/fixedAssets/fixedAssets.service';
import React, { useEffect, useState } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { Zone } from '../../../types/User.types';
import {
    FixedAssetCostCode,
    FixedAssetDepreciationMethodCost,
} from '../../../types/FixedAsset.types';
import { isNilOrEmpty } from '../../../utils/objectUtils';

const UseFixedAssetDepreciationMethodGrid = () => {
    const history = useHistory();
    const [isLoadingAny, setIsLoadingAny] = useState(true);

    const { data: methodList, isLoading: isLoadingMethods } =
        useGetAllDepreciationMethodsQuery();
    const { data: statusList, isLoading: isLoadingStatuses } =
        useGetDepreciationMethodStatusesMapQuery();
    const { data: typeList, isLoading: isLoadingTypes } =
        useGetDepreciationMethodTypesMapQuery();

    useEffect(() => {
        setIsLoadingAny(
            isLoadingMethods || isLoadingStatuses || isLoadingTypes
        );
    }, [isLoadingMethods, isLoadingStatuses, isLoadingTypes]);

    const redirectToForm = (row: any) => {
        if (row.id) {
            history.push(
                `/accounting/fixedAssets/depreciationMethod/${row.id}`
            );
        } else {
            history.push('/accounting/fixedAssets/depreciationMethod/new');
        }
    };

    const getStatusFromId = (id: bigint) => {
        return statusList?.get(id)?.code || '';
    };

    const getTypeFromId = (id: bigint) => {
        return typeList?.get(id)?.code || '';
    };

    const CostCodeColumn = (params: ICellRendererParams) => {
        return !isNilOrEmpty(params.data.costs) ? (
            <span>
                {params.data.costs
                    .map(
                        (dmCost: FixedAssetDepreciationMethodCost) =>
                            dmCost.costCode.code
                    )
                    .join(', ')}
            </span>
        ) : (
            <span />
        );
    };

    return {
        methodList,
        redirectToForm,
        getStatusFromId,
        getTypeFromId,
        CostCodeColumn,
        isLoadingAny,
    };
};

export default UseFixedAssetDepreciationMethodGrid;
