import { AgGridColumn } from 'ag-grid-react';
import React from 'react';
import moment from 'moment';
import AgGrid from '../../components/Grids/Grid';
import DatacorLogoSpinner from '../../components/DatacorLogoSpinner/DatacorLogoSpinner';
import useBusinessEntityGrid from './hooks/useBusinessEntityGrid';
import { Breadcrumbs, Link, Typography } from '@mui/material';

const BusinessEntityGrid = () => {
    const {
        breadcrumbs,
        onGridReady,
        rowData,
        isLoading,
        redirectToForm,
        viewEntitiesColumn,
        navigateToPreviousEntity,
    } = useBusinessEntityGrid();

    return (
        <>
            {!isLoading ? (
                <>
                    <Breadcrumbs maxItems={3} aria-label="breadcrumb">
                        {breadcrumbs.map((breadcrumb: any, index: number) => {
                            if (index === breadcrumbs.length - 1) {
                                return (
                                    <Typography
                                        color="text.primary"
                                        key={breadcrumb?.id}>
                                        {breadcrumb.name}
                                    </Typography>
                                );
                            }

                            return (
                                <Link
                                    underline="hover"
                                    color="inherit"
                                    key={breadcrumb.id}
                                    onClick={() => {
                                        navigateToPreviousEntity(breadcrumb);
                                    }}>
                                    {breadcrumb.name}
                                </Link>
                            );
                        })}
                    </Breadcrumbs>

                    <AgGrid
                        testId="business-entity-list-grid"
                        rowData={rowData}
                        displayToolbar={true}
                        onCreateNew={(row) => redirectToForm(row)}
                        displayCreateNewButton={true}
                        onRowClicked={({ data }) => redirectToForm(data)}
                        onGridReady={onGridReady}
                        frameworkComponents={{
                            viewEntities: viewEntitiesColumn,
                        }}
                        defaultColDef={{
                            resizable: true,
                            sortable: true,
                            unSortIcon: true,
                            filter: true,
                            wrapText: true,
                        }}>
                        <AgGridColumn
                            field="id"
                            headerName=""
                            width={55}
                            cellRenderer="viewEntities"
                        />
                        <AgGridColumn
                            flex={1}
                            minWidth={250}
                            field="name"
                            headerName="Name"
                        />
                        <AgGridColumn
                            minWidth={200}
                            field="description"
                            headerName="Description"
                        />
                        <AgGridColumn
                            minWidth={250}
                            field="subscriber.name"
                            headerName="Subscriber"
                        />
                        <AgGridColumn
                            minWidth={200}
                            field="createdAt"
                            headerName="Date Created"
                            valueFormatter={(val) =>
                                val.value && moment(val.value).isValid()
                                    ? moment(val.value).format('L')
                                    : ''
                            }
                        />
                        <AgGridColumn
                            minWidth={200}
                            field="updatedAt"
                            headerName="Last Update Date"
                            valueFormatter={(val) =>
                                val.value && moment(val.value).isValid()
                                    ? moment(val.value).format('L')
                                    : ''
                            }
                        />
                    </AgGrid>
                </>
            ) : (
                <DatacorLogoSpinner />
            )}
        </>
    );
};

export default BusinessEntityGrid;
