import { useForm } from '../../../libs/hooksLib';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Role,
    useCreateRoleMutation,
    useGetBusinessEntitiesQuery,
    useGetPermissionsForDropdownQuery,
    useGetRoleQuery,
    useGetUsersForDropdownQuery,
    useUpdateRoleMutation,
} from '../../../services/organizations/organizations.service';
import { onError } from '../../../libs/errorLib';
import {
    BaseCreateEntityArgs,
    BaseUpdateEntityArgs,
} from '../../../services/serviceInterfaces';
import { skipToken } from '@reduxjs/toolkit/query';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';

const useRoleForm = (id: string) => {
    const user = useSelector((state: RootState) => state.user);
    const history = useHistory();
    const { fields, handleFieldChange, setValues, formMethods, isFormValid } =
        useForm({});
    const { data: role, isLoading: isLoading } = useGetRoleQuery(
        id ? { roleId: id, full: true } : skipToken
    );

    const { data: users, isLoading: isLoadingUsers } =
        useGetUsersForDropdownQuery(
            user?.businessEntity?.id
                ? user?.businessEntity?.id.toString()
                : skipToken
        );

    const { data: businessEntityOptions } = useGetBusinessEntitiesQuery(
        user?.subscriber?.id ? user?.subscriber?.id.toString() : skipToken
    );
    const { data: permissions } = useGetPermissionsForDropdownQuery(
        user?.businessEntity?.id
            ? user?.businessEntity?.id.toString()
            : skipToken
    );
    const [createRole] = useCreateRoleMutation();
    const [updateRole] = useUpdateRoleMutation();

    const defaultRole: Role = {
        name: '',
        description: '',
        businessEntityId: user?.businessEntity?.id,
        permissions: [],
        users: [],
    };

    useEffect(() => {
        setValues(role || defaultRole);
    }, [role, user]);

    const handleCreateRole = async () => {
        if (!isFormValid) {
            return;
        }

        const body: BaseCreateEntityArgs<Role> = {
            postBody: fields,
        };

        try {
            await createRole(body);
            handleClose();
        } catch (e) {
            return onError(e.message);
        }
    };

    const handleUpdateRole = async () => {
        if (!isFormValid) {
            return;
        }

        const userIds = fields?.users?.map((user: any) => {
            return { id: user };
        });

        const permissionIds = fields?.permissions?.map((permission: any) => {
            return { id: permission };
        });
        const body: BaseUpdateEntityArgs<Role> = {
            id: id as unknown as number,
            postBody: {
                ...fields,
                users: [...userIds],
                permissions: [...permissionIds],
            },
        };

        try {
            await updateRole(body);
            handleClose();
        } catch (e) {
            return onError(e.message);
        }
    };

    const handleClose = () => {
        history.push('/administration/organizations/roles');
    };

    return {
        fields,
        user,
        role,
        users,
        permissions,
        isLoading,
        businessEntityOptions,
        handleCreateRole,
        handleUpdateRole,
        handleFieldChange,
        handleClose,
        setValues,
        formMethods,
    };
};

export default useRoleForm;
