import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { onError } from '../../../libs/errorLib';
import { AppsService } from '../../../services/workflow/system/apps.service';
import { AgGridColumn } from 'ag-grid-react';
import LoaderButton from '../../../components/LoaderButton';
import AgGrid from '../../../components/Grids/Grid';
import { Card, CardHeader, CardContent, IconButton } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const WorkflowApp = () => {
    const [app, setApp] = useState(null);
    const history = useHistory();
    const { id } = useParams();

    useEffect(() => {
        const onLoad = async () => {
            loadData();
        };

        onLoad();
    }, []);

    const loadData = async () => {
        try {
            setApp(await AppsService.getAppFull(id));
        } catch (e) {
            onError(e);
        }
    };

    const GotoSystemWorkflow = (props) => {
        return (
            <IconButton
                size="small"
                color="primary"
                onClick={() =>
                    history.push(`/workflow/systemWorkflows/${props.data.id}`)
                }>
                <OpenInNewIcon />
            </IconButton>
        );
    };

    const systemWorkflowsArea = () => {
        return (
            <>
                <Card>
                    <CardHeader
                        style={{ height: '50px' }}
                        title="System Workflows"></CardHeader>
                    <CardContent>
                        <AgGrid
                            rowHeight="35"
                            rowData={app?.SystemWorkflows}
                            domLayout={'autoHeight'}
                            suppressCellSelection={true}
                            frameworkComponents={{
                                gotoSystemWorkflow: GotoSystemWorkflow,
                            }}>
                            <AgGridColumn
                                filter={false}
                                resizable={false}
                                sortable={false}
                                width={50}
                                minWidth={50}
                                field="gotoSystemWorkflow"
                                headerName=""
                                cellRenderer="gotoSystemWorkflow"
                            />
                            <AgGridColumn field="code" />
                            <AgGridColumn field="name" />
                            <AgGridColumn field="description" flex={1} />
                        </AgGrid>
                    </CardContent>
                </Card>
            </>
        );
    };

    const dataLookupsArea = () => {
        return (
            <>
                <h2>Data Lookups</h2>
                <p>
                    <em>Coming soon...</em>
                </p>
            </>
        );
    };

    return (
        <>
            <Card>
                <CardHeader
                    title={`Workflow App: ${app?.name}`}
                    action={
                        <Link to="/workflow/">
                            <LoaderButton variant="primary" size="lg">
                                Workflow Portal
                            </LoaderButton>
                        </Link>
                    }></CardHeader>
                <CardContent>
                    <strong>{app?.description}</strong>
                    <div className="col-6">{systemWorkflowsArea()}</div>

                    <div className="col-6">{dataLookupsArea()}</div>
                </CardContent>
            </Card>
        </>
    );
};

export default WorkflowApp;
