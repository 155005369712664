import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isNil } from '../utils/objectUtils';
import { RootState } from '../store';

/* istanbul ignore file */
function querystring(name: string, url = window.location.href) {
    name = name.replace(/[[]]/g, '\\$&');

    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)', 'i');
    const results = regex.exec(url);

    if (!results) {
        return null;
    }
    if (!results[2]) {
        return '';
    }

    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

// ensure that the user is not authenticated before we render the child components.
export default function UnauthenticatedRoute({ children, ...rest }: any) {
    const user = useSelector((state: RootState) => state.user);
    const redirect = querystring('redirect');
    return (
        <Route {...rest}>
            {!user.isSignedIn ? (
                children
            ) : (
                <Redirect to={isNil(redirect) ? '/' : redirect} />
            )}
        </Route>
    );
}
