import React from 'react';
import {
    Area,
    Bar,
    CartesianGrid,
    Cell,
    ComposedChart,
    Legend,
    Line,
    Pie,
    PieChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import {
    currencyFormatter,
    percentFormatter,
} from '../../../utils/formattingUtils';
import { Card, CardHeader, CardContent, Grid } from '@mui/material';
import DatacorLogoSpinner from '../../../components/DatacorLogoSpinner/DatacorLogoSpinner';
import { themeConfig } from '../../../styles/theme/themeConfig';
import usePricingCustomerProductDetailsCharts from '../hooks/PricingCustomerProductDetails/usePricingCustomerProductDetailsCharts';

const PricingCustomerProductDetailsCharts = () => {
    const {
        pricingAnalysisToolSet,
        marginChartData,
        profitChartData,
        renderCustomizedLabel,
        dollarsLostChartData,
        COLORS,
    } = usePricingCustomerProductDetailsCharts();

    return !pricingAnalysisToolSet.activeCustomerProductDetailsLoading &&
        !pricingAnalysisToolSet.pricingConcernDetailsIsLoading ? (
        <Grid container spacing={2} className="mb-3">
            <Grid item xs={4}>
                <Card>
                    <CardHeader
                        style={{
                            backgroundColor: themeConfig.PRIMARY.main,
                            height: 50,
                            color: 'white',
                        }}
                        title=" Margin vs. Average Margin"
                    />
                    <CardContent>
                        <ResponsiveContainer width="100%" height={350}>
                            <ComposedChart
                                data={marginChartData}
                                margin={{
                                    top: 20,
                                    right: 20,
                                    bottom: 20,
                                    left: 20,
                                }}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis
                                    dataKey="orderDate"
                                    scale="band"
                                    height={70}
                                />
                                <YAxis
                                    label={{
                                        value: '%',
                                        angle: -90,
                                        position: 'insideLeft',
                                    }}
                                />
                                <Tooltip
                                    formatter={(value: any) =>
                                        percentFormatter(value)
                                    }
                                />
                                <Legend />
                                <Bar dataKey="Margin" fill="#f69333" />
                                <Bar dataKey="Average Margin" fill="#f3b231" />
                                <Area
                                    type="monotone"
                                    dataKey="Below Average Margin"
                                    stroke="#007cbb"
                                    fill="#007cbb"
                                />
                            </ComposedChart>
                        </ResponsiveContainer>
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xs={4}>
                <Card>
                    <CardHeader
                        title="Cost vs. Price"
                        style={{
                            backgroundColor: themeConfig.PRIMARY.main,
                            height: 50,
                            color: 'white',
                        }}
                    />
                    <CardContent>
                        <ResponsiveContainer width="100%" height={350}>
                            <ComposedChart
                                data={profitChartData}
                                margin={{
                                    top: 20,
                                    right: 20,
                                    bottom: 0,
                                    left: 20,
                                }}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis
                                    dataKey="orderDate"
                                    scale="band"
                                    height={70}
                                />
                                <YAxis
                                    label={{
                                        value: '$',
                                        angle: -90,
                                        position: 'insideLeft',
                                    }}
                                />
                                <Tooltip
                                    formatter={(value: any) =>
                                        currencyFormatter(value)
                                    }
                                />
                                <Legend />
                                <Line
                                    type="monotone"
                                    dataKey="Material Cost"
                                    stroke="#00A894"
                                />
                                <Line
                                    type="monotone"
                                    dataKey="Price"
                                    stroke="#f69333"
                                />
                                <Area
                                    type="monotone"
                                    dataKey="Profit"
                                    stroke="#f3b231"
                                    fill="#f3b231"
                                />
                            </ComposedChart>
                        </ResponsiveContainer>
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xs={4}>
                <Card>
                    <CardHeader
                        title="Dollars Lost by Pricing Concern"
                        style={{
                            backgroundColor: themeConfig.PRIMARY.main,
                            height: 50,
                            color: 'white',
                        }}
                    />
                    <CardContent>
                        <ResponsiveContainer width="100%" height={350}>
                            <PieChart>
                                <Legend />
                                <Pie
                                    dataKey="value"
                                    isAnimationActive={false}
                                    data={dollarsLostChartData}
                                    outerRadius={80}
                                    cx="50%"
                                    cy="50%"
                                    labelLine={false}
                                    label={renderCustomizedLabel}
                                    fill="#8884d8">
                                    {dollarsLostChartData.map(
                                        (entry, index) => (
                                            <Cell
                                                key={`cell-${index}`}
                                                fill={
                                                    COLORS[
                                                        index % COLORS.length
                                                    ]
                                                }
                                            />
                                        )
                                    )}
                                </Pie>

                                <Tooltip
                                    formatter={(value: any) =>
                                        currencyFormatter(-Math.abs(value))
                                    }
                                />
                            </PieChart>
                        </ResponsiveContainer>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    ) : (
        <DatacorLogoSpinner />
    );
};

export default PricingCustomerProductDetailsCharts;
