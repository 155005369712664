import React from 'react';
import LoaderButton from '../../components/LoaderButton';
import './ChangePassword.css';
import { Grid, TextField } from '@mui/material';
import useChangePassword from './hooks/useChangePassword';

const ChangePassword = () => {
    const {
        fields,
        handleFieldChange,
        handleChangeClick,
        isChanging,
        validateForm,
    } = useChangePassword();

    return (
        <div className="ChangePassword">
            <Grid container spacing={2} style={{ padding: '10px' }}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Old Password"
                        type="password"
                        id="oldPassword"
                        data-testid="oldPassword"
                        variant="outlined"
                        value={fields.oldPassword}
                        onChange={handleFieldChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="New Password"
                        type="password"
                        id="password"
                        data-testid="password"
                        variant="outlined"
                        value={fields.password}
                        onChange={handleFieldChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Confirm Password"
                        type="password"
                        id="confirmPassword"
                        data-testid="confirmPassword"
                        variant="outlined"
                        value={fields.confirmPassword}
                        onChange={handleFieldChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <LoaderButton
                        fullWidth
                        onClick={handleChangeClick}
                        type="submit"
                        variant="contained"
                        size="lg"
                        disabled={!validateForm()}
                        isLoading={isChanging}>
                        Change Password
                    </LoaderButton>
                </Grid>
            </Grid>
        </div>
    );
};

export default ChangePassword;
