import React, { useEffect, useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import {
    useCreateTypeMutation,
    useDeleteTypeMutation,
    useGetDepreciationMethodsForDropdownQuery,
    useGetTypeQuery,
    useUpdateTypeMutation,
} from '../../../services/fixedAssets/fixedAssets.service';
import {
    FixedAssetDepreciationMethod,
    FixedAssetType,
} from '../../../types/FixedAsset.types';
import { SelectionOption } from '../../../types/shared.types';
import { useGetAllGeneralLedgerAccountFunctionsForDropdownsQuery } from '../../../services/accounting/accounting.service';
import useBaseForm from '../../Form/hooks/useBaseForm';

const UseFixedAssetTypeForm = (id: string) => {
    const [accountsRequired, setAccountsRequired] = useState(false);

    const { data: activeType, isLoading: isLoadingType } = useGetTypeQuery(
        id ? id : skipToken
    );
    const [createType] = useCreateTypeMutation();
    const [updateType] = useUpdateTypeMutation();
    const [deleteType] = useDeleteTypeMutation();

    const {
        data: depreciationMethodOptions,
        isLoading: isLoadingDepreciationMethods,
    } = useGetDepreciationMethodsForDropdownQuery();

    const { data: accountDropdowns, isLoading: isLoadingAccountDropdowns } =
        useGetAllGeneralLedgerAccountFunctionsForDropdownsQuery();

    const recoveryTermOptions: SelectionOption[] = [
        { label: 'Hours', value: 'Hours' },
        { label: 'Months', value: 'Months' },
        { label: 'Years', value: 'Years' },
    ];

    const blankType: FixedAssetType = {
        sortOrder: null,
        code: '',
        description: '',
        recoveryTerm: 1,
        recoveryUnit: null,
        depreciationMethodId: null,
        depreciationExpenseAccountId: null,
        accumulatedDeprecationAccountId: null,
        assetAccountId: null,
        assetControlAccountId: null,
        disposalGainAccountId: null,
        disposalLossAccountId: null,
        disposalSettlementAccountId: null,
    };

    useEffect(() => {
        setAccountsRequired(
            !!(activeType as FixedAssetType)?.depreciationMethod
                ?.depreciationMethodType?.postsJournalEntries
        );
    }, [activeType]);

    const onDepreciationMethodChanged = (event: any) => {
        handleFieldChange(event);

        const depreciationMethod: FixedAssetDepreciationMethod =
            depreciationMethodOptions?.find(
                (optionList: SelectionOption) =>
                    optionList.value === event.target.value
            )?.object;

        setAccountsRequired(
            !!depreciationMethod?.depreciationMethodType?.postsJournalEntries
        );
    };

    const {
        fields,
        handleFieldChange,
        formMethods,
        toastProps,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
    } = useBaseForm({
        closePath: '/accounting/fixedAssets/type',
        blankEntity: blankType,
        activeEntity: activeType,
        createEntity: () => {
            return createType({ postBody: fields });
        },
        updateEntity: () => {
            return updateType({
                id: id as unknown as number,
                postBody: fields,
            });
        },
        deleteEntity: () => {
            return deleteType(id);
        },
    });

    return {
        fields,
        handleFieldChange,
        formMethods,
        toastProps,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        isLoadingType,
        isLoadingDepreciationMethods,
        isLoadingAccountDropdowns,
        depreciationMethodOptions,
        recoveryTermOptions,
        accountDropdowns,
        onDepreciationMethodChanged,
        accountsRequired,
    };
};

export default UseFixedAssetTypeForm;
