import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useForm } from '../../../libs/hooksLib';
import { ToastContainerProps } from '../../../components/Toast/ToastContainer';
import { MutationError } from '../../../types/shared.types';
import { MutationActionCreatorResult } from '@reduxjs/toolkit/dist/query/core/buildInitiate';

export interface UseBaseFormProps {
    closePath: string;
    blankEntity: object;
    activeEntity: object;
    createEntity: () => MutationActionCreatorResult<any>;
    updateEntity: () => MutationActionCreatorResult<any>;
    deleteEntity?: () => MutationActionCreatorResult<any>;
}

const useBaseForm = (props: UseBaseFormProps) => {
    const history = useHistory();
    const [toastProps, setToastProps] = useState(null);
    const { fields, handleFieldChange, setValues, formMethods, isFormValid } =
        useForm(props.blankEntity);

    useEffect(() => {
        setValues(props.activeEntity || props.blankEntity);
    }, [props.activeEntity]);

    const closeForm = () => {
        history.push(props.closePath);
    };

    const onCreate = () => {
        if (!isFormValid) {
            return;
        }

        props
            .createEntity()
            .unwrap()
            .then(() => closeForm())
            .catch((error: MutationError) => handleError(error));
    };

    const onUpdate = () => {
        if (!isFormValid) {
            return;
        }

        props
            .updateEntity()
            .unwrap()
            .then(() => closeForm())
            .catch((error: MutationError) => handleError(error));
    };

    const onDelete = () => {
        props
            .deleteEntity()
            .unwrap()
            .then(() => closeForm())
            .catch((error: MutationError) => handleError(error));
    };

    const showError = (message: string) => {
        const toastProps: ToastContainerProps = {
            show: true,
            autoHideDuration: 10000,
            bodyText: message,
            close: () => setToastProps(null),
            severity: 'error',
        };

        setToastProps(toastProps);
    };

    const handleError = (error: MutationError) => {
        if (error.status === 400) {
            const errorMessage = error.data
                .map((error: any) => {
                    return error.message;
                })
                .join('\r\n');

            showError(errorMessage);
        } else {
            showError(`An unexpected error occurred: ${error.data.toString()}`);
        }
    };

    return {
        fields,
        handleFieldChange,
        setValues,
        formMethods,
        toastProps,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
    };
};

export default useBaseForm;
