import React from 'react';
import LoaderButton from '../../../components/LoaderButton';
import { AgGridColumn } from 'ag-grid-react';
import Select from 'react-select';
import './ToleranceMatching.css';
import { PermissionsUtil } from '../../../utils/permissions/PermissionsUtil';
import { PERMISSIONS } from '../../../constants/permissions/permissions.constants';
import ToleranceGrid from '../../../components/Grids/Grid';
import { TextField, Button, Grid } from '@mui/material';
import DatacorLogoSpinner from '../../../components/DatacorLogoSpinner/DatacorLogoSpinner';
import useMatchingForm from '../hooks/ToleranceMatching/useMatchingForm';

interface MatchingFormProps {
    onSubmit: (_: boolean) => void;
}

const MatchingForm = ({ onSubmit }: MatchingFormProps) => {
    const {
        user,
        fields,
        handleFieldChange,
        setSelectTypeOptions,
        setSelectPoCostCategoryOptions,
        submitAddMatchCriterion,
        areFieldsPopulated,
        isLoadingDefaultTolerance,
        localMatching,
        Delete,
        updateTolerance,
        isLoadingSave,
    } = useMatchingForm({ onSubmit });

    return (
        <>
            {PermissionsUtil.isPermissionEnabled(
                user.permissions,
                PERMISSIONS.AP_AUTOMATION.DEFAULT_TOLERANCE.EDIT
            ) ? (
                <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    style={{ paddingBottom: '10px' }}>
                    <Grid item xs={3}>
                        Criteria
                        <Select
                            value={fields.matchCriteria}
                            className="basic-select"
                            menuPlacement={'bottom'}
                            isDisabled={true}
                            id="select-criteria"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        Value
                        <TextField
                            id="matchValue"
                            size="small"
                            fullWidth
                            variant="outlined"
                            required
                            type="text"
                            value={fields.matchValue}
                            onChange={handleFieldChange}
                            data-testid="matchValue"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        Type
                        <Select
                            value={fields.matchType}
                            className="basic-select"
                            menuPlacement={'bottom'}
                            id="select-criteria-type"
                            onChange={(data: any) =>
                                handleFieldChange({
                                    target: { id: 'matchType', value: data },
                                })
                            }
                            options={setSelectTypeOptions()}
                            placeholder={''}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        Cost Category
                        <Select
                            value={fields.poReceiptCostType}
                            className="basic-select"
                            menuPlacement={'bottom'}
                            id="select-criteria-type"
                            onChange={(data: any) =>
                                handleFieldChange({
                                    target: {
                                        id: 'poReceiptCostType',
                                        value: data,
                                    },
                                })
                            }
                            options={setSelectPoCostCategoryOptions()}
                            placeholder={''}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <LoaderButton
                            style={{ marginTop: '20px' }}
                            onClick={submitAddMatchCriterion}
                            variant="contained"
                            color="primary"
                            isLoading={null}
                            disabled={!areFieldsPopulated()}
                            type="submit"
                            data-testid="addCriterionBtn">
                            Add
                        </LoaderButton>
                    </Grid>
                </Grid>
            ) : null}
            {!isLoadingDefaultTolerance ? (
                <ToleranceGrid
                    testId="criteria-list-grid"
                    rowData={localMatching}
                    immutableData={true}
                    dataKey="value"
                    autofitColumns={true}
                    frameworkComponents={{
                        delete: Delete,
                    }}>
                    {PermissionsUtil.isPermissionEnabled(
                        user.permissions,
                        PERMISSIONS.AP_AUTOMATION.DEFAULT_TOLERANCE.EDIT
                    ) ? (
                        <AgGridColumn
                            field="delete"
                            headerName=""
                            width={65}
                            cellRenderer="delete"
                        />
                    ) : null}
                    <AgGridColumn
                        field="poReceiptCostType"
                        rowGroup
                        keyCreator={(val) => (val.value ? val.value.value : '')}
                        valueFormatter={(val) =>
                            val.value ? val.value.value : ''
                        }
                    />
                    <AgGridColumn
                        field="criteria"
                        width={250}
                        valueFormatter={(val) =>
                            val.value ? val.value.value : ''
                        }
                    />
                    <AgGridColumn field="value" />
                    <AgGridColumn
                        field="type"
                        width={150}
                        valueFormatter={(val) =>
                            val.value ? val.value.value : ''
                        }
                    />
                </ToleranceGrid>
            ) : (
                <DatacorLogoSpinner />
            )}
            {onSubmit ? <hr className="tolerance-line-break" /> : <br />}
            <div className="tolerance-button-group">
                {onSubmit ? (
                    <Button
                        className="tolerance-button"
                        variant="contained"
                        color="secondary"
                        data-testid="cancelEditApproversBtn"
                        onClick={() => onSubmit(true)}>
                        Close
                    </Button>
                ) : null}
                {PermissionsUtil.isPermissionEnabled(
                    user.permissions,
                    PERMISSIONS.AP_AUTOMATION.DEFAULT_TOLERANCE.EDIT
                ) ? (
                    <LoaderButton
                        variant="contained"
                        color="primary"
                        className="tolerance-button"
                        data-testid="saveCriterionsBtn"
                        onClick={updateTolerance}
                        isLoading={isLoadingSave}>
                        Save Changes
                    </LoaderButton>
                ) : null}
            </div>
        </>
    );
};

export default MatchingForm;
