import React from 'react';
import {
    updateSummaryAsOfDate,
    updateSummaryStartDate,
} from '../../store/pricingAnalysisTool';
import { Grid, Alert, Typography, Card, CardContent } from '@mui/material';
import LoaderButton from '../../components/LoaderButton';
import SummaryPricingConcernCard from './SummaryPricingConcernCard';
import './PricingAnalysisTool.css';
import MonthSelect from '../../components/MonthSelect';
import GeneralSummary from './GeneralSummary';
import DollarsLostByPricingConcernSummary from './DollarsLostByPricingConcernSummary';
import GrossMarginSummary from './GrossMarginSummary';
import Breadcrumbs from './Breadcrumbs';
import { isNilOrEmpty } from '../../utils/objectUtils';
import DatacorLogoSpinner from '../../components/DatacorLogoSpinner/DatacorLogoSpinner';
import usePricingAnalysisTool from './hooks/usePricingAnalysisTool';

const PricingAnalysisTool = () => {
    const {
        gotoDetails,
        handlePricingConcernChecks,
        maxAsOfDate,
        showAlert,
        dispatch,
        pricingAnalysisToolSet,
    } = usePricingAnalysisTool();

    return pricingAnalysisToolSet.activeCumulativeSummary ? (
        <Card>
            <CardContent>
                <Grid
                    container
                    spacing={2}
                    className="appPage"
                    justifyContent="center"
                    alignItems="stretch">
                    <Grid item xs={12}>
                        <Breadcrumbs active={'pricingAnalysisSummary'} />
                    </Grid>
                    <Grid item xs={12}>
                        {showAlert && (
                            <Alert
                                style={{ width: '100%' }}
                                className="text-center"
                                severity="error">
                                <span>
                                    <b>Error:</b> 'From' date can't be greater
                                    than 'To' date.
                                </span>
                            </Alert>
                        )}
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        container
                        justifyContent={'flex-end'}
                        alignItems="center">
                        <MonthSelect
                            monthEndOrStart="start"
                            placeholder={'Start Date'}
                            defaultValue={
                                pricingAnalysisToolSet.summaryStartDate
                            }
                            onChange={(selectedValue: any) =>
                                dispatch(updateSummaryStartDate(selectedValue))
                            }
                        />
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        container
                        justifyContent={'flex-start'}
                        alignItems="center">
                        <MonthSelect
                            monthEndOrStart="end"
                            placeholder="End Date"
                            defaultValue={
                                pricingAnalysisToolSet.summaryAsOfDate
                            }
                            onChange={(selectedValue: any) =>
                                dispatch(updateSummaryAsOfDate(selectedValue))
                            }
                        />
                    </Grid>

                    <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                        <DollarsLostByPricingConcernSummary />
                    </Grid>
                    <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                        <GeneralSummary />
                    </Grid>
                    <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                        <GrossMarginSummary />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
                        <SummaryPricingConcernCard
                            title="Outdated Pricing"
                            activeCumulativeSummary={
                                pricingAnalysisToolSet.activeCumulativeSummary
                            }
                            alertType="outdatedPricing"
                            handleCheck={handlePricingConcernChecks}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
                        <SummaryPricingConcernCard
                            title="Pricing Lag"
                            activeCumulativeSummary={
                                pricingAnalysisToolSet.activeCumulativeSummary
                            }
                            alertType="pricingLag"
                            handleCheck={handlePricingConcernChecks}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
                        <SummaryPricingConcernCard
                            title="Below Average Margin"
                            activeCumulativeSummary={
                                pricingAnalysisToolSet.activeCumulativeSummary
                            }
                            alertType="belowAverageMargin"
                            handleCheck={handlePricingConcernChecks}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
                        <SummaryPricingConcernCard
                            title="Margin Below Goal"
                            activeCumulativeSummary={
                                pricingAnalysisToolSet.activeCumulativeSummary
                            }
                            alertType="marginBelowGoal"
                            handleCheck={handlePricingConcernChecks}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
                        <SummaryPricingConcernCard
                            title="Price Below Average"
                            activeCumulativeSummary={
                                pricingAnalysisToolSet.activeCumulativeSummary
                            }
                            alertType="belowAveragePrice"
                            handleCheck={handlePricingConcernChecks}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <LoaderButton
                            isLoading={null}
                            variant="contained"
                            style={{ display: 'block' }}
                            onClick={() => gotoDetails()}
                            disabled={
                                isNilOrEmpty(
                                    pricingAnalysisToolSet.pricingConcernSelectedCategories
                                ) ||
                                pricingAnalysisToolSet.summaryAsOfDate
                                    .rawDate !== maxAsOfDate
                            }>
                            View Details for selected Pricing Concern(s)
                            {pricingAnalysisToolSet.summaryAsOfDate.rawDate !==
                            maxAsOfDate ? (
                                <Typography>
                                    <small style={{ color: 'red' }}>
                                        Select most recent 'End Date' to see
                                        view details.
                                    </small>
                                </Typography>
                            ) : null}
                        </LoaderButton>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    ) : (
        <DatacorLogoSpinner />
    );
};

export default PricingAnalysisTool;
