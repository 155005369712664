import { AVAILABLE_NAV_LINKS_AND_INCLUSION_CRITERIA } from '../../libs/constantsLib';
import { isFeatureFlagEnabled } from '../FeatureFlag/FeatureFlagUtil';
import { PermissionsUtil } from '../permissions/PermissionsUtil';
import NavLink from './NavLink';

export interface NavLinkOptions {
    url: string;
    name: string;
    about: string;
    appImage?: any;
    imageName?: string;
    featureFlag: string;
    permissionKey?: string;
    children?: NavLink[];
}

export const getAvailableNavLinks = (
    enabledFeatureFlags: any,
    enabledPermissions: any
): NavLink[] => {
    let availableNavLinks: NavLink[] = [];

    AVAILABLE_NAV_LINKS_AND_INCLUSION_CRITERIA.forEach((navLink: NavLink) => {
        if (
            isFeatureFlagEnabled(enabledFeatureFlags, navLink.featureFlag) &&
            PermissionsUtil.isPermissionEnabled(
                enabledPermissions,
                navLink.permissionKey
            )
        ) {
            availableNavLinks.push(navLink);
        }
    });

    return availableNavLinks;
};
