import React from 'react';
import FormulationItemMasterGrid from './Pages/FormulationItemMasterGrid';

const ItemMaster = () => {
    return (
        <>
            <FormulationItemMasterGrid />
        </>
    );
};

export default ItemMaster;
