import { createSlice } from '@reduxjs/toolkit';
import { getSchedulingErrors } from '../containers/BatchTicketScheduler/Utils';
import { Auth } from 'aws-amplify';
import { DATACOR_MULTIPLEXER_URL } from '../libs/constantsLib';
import { onError } from '../libs/errorLib';
import { isNil } from '../utils/objectUtils';
import { AppDispatch } from './index';
import { Step, Workcenter } from '../types/BatchTicket.types';
let moment = require('moment');

const slice = createSlice({
    name: 'scheduler',
    initialState: {
        schedulerDataSet: {
            selectedDate: null,
            selectedView: null,
            warehouseId: null,
            isLoading: true,
            scheduledSteps: [],
            workcenters: [],
            unscheduledSteps: [],
            schedulingErrors: [],
            allSteps: [],
            updateBatchStepDatesLoading: false,
        },
    },
    reducers: {
        stepsLoading: (state, action) => {
            state.schedulerDataSet.isLoading = action.payload;
        },
        updateBatchStepDatesLoading: (state, action) => {
            state.schedulerDataSet.updateBatchStepDatesLoading = action.payload;
        },
        setUpActiveScheduleDataSuccess: (state, action) => {
            state.schedulerDataSet.warehouseId = action.payload.warehouseId;
            state.schedulerDataSet.workcenters =
                action.payload.utilizedWorkcenters.map(
                    (workcenter: Workcenter) => ({
                        ...workcenter,
                        id: workcenter.Id,
                    })
                );
            state.schedulerDataSet.unscheduledSteps =
                action.payload.batchStepsByWarehouseId
                    .filter((step: Step) => {
                        return isNil(step.StartDateTime);
                    })
                    .sort((a: any, b: any) =>
                        moment(a.batchTicket.Duedate) >
                        moment(b.batchTicket.Duedate)
                            ? 1
                            : -1
                    )
                    .sort((a: any, b: any) => (a.id > b.id ? 1 : -1));
            state.schedulerDataSet.scheduledSteps =
                action.payload.batchStepsByWarehouseId.filter((step: Step) => {
                    return !isNil(step.StartDateTime);
                });
            state.schedulerDataSet.allSteps =
                state.schedulerDataSet.scheduledSteps.concat(
                    state.schedulerDataSet.unscheduledSteps
                );
            state.schedulerDataSet.schedulingErrors = getSchedulingErrors(
                state.schedulerDataSet.scheduledSteps
            );
            state.schedulerDataSet.isLoading = action.payload.isLoading;
        },
        updateStepSuccess: (state, action) => {
            /* eslint-disable eqeqeq */
            if (action.payload.updatedStep.start != null) {
                state.schedulerDataSet.scheduledSteps.map((step) => {
                    if (step.id === action.payload.updatedStep.id) {
                        step.start = action.payload.updatedStep.start;
                        step.end = action.payload.updatedStep.end;
                        step.Workcenter = action.payload.workcenter;
                        step.resourceId = action.payload.workcenter;
                        return step;
                    } else {
                        return step;
                    }
                });
            } else {
                const unscheduledStepIndex =
                    state.schedulerDataSet.scheduledSteps.findIndex(
                        (step) => step.id === action.payload.updatedStep.id
                    );
                const unscheduledStep =
                    state.schedulerDataSet.scheduledSteps.splice(
                        unscheduledStepIndex,
                        1
                    )[0];
                unscheduledStep.start = action.payload.updatedStep.start;
                unscheduledStep.end = action.payload.updatedStep.end;
                state.schedulerDataSet.unscheduledSteps.push({
                    ...unscheduledStep,
                });
            }
            state.schedulerDataSet.schedulingErrors = getSchedulingErrors(
                state.schedulerDataSet.scheduledSteps
            );
            state.schedulerDataSet.allSteps =
                state.schedulerDataSet.scheduledSteps.concat(
                    state.schedulerDataSet.unscheduledSteps
                );
            state.schedulerDataSet.updateBatchStepDatesLoading =
                action.payload.isLoading;
        },
        addNewlyScheduledStepSuccess: (state, action) => {
            const scheduledStepIndex =
                state.schedulerDataSet.unscheduledSteps.findIndex(
                    (step) => step.id === action.payload.newStep.id
                );
            if (scheduledStepIndex !== -1) {
                const scheduledStep =
                    state.schedulerDataSet.unscheduledSteps.splice(
                        scheduledStepIndex,
                        1
                    )[0];
                scheduledStep.Workcenter = action.payload.workcenter;
                scheduledStep.resourceId = action.payload.workcenter;
                scheduledStep.start = action.payload.newStep.start;
                scheduledStep.end = action.payload.newStep.end
                    ? action.payload.newStep.end
                    : moment(action.payload.newStep.start).add(2, 'hours');
                state.schedulerDataSet.scheduledSteps.push({
                    ...scheduledStep,
                });
                state.schedulerDataSet.unscheduledSteps = [
                    ...state.schedulerDataSet.unscheduledSteps,
                ];
                state.schedulerDataSet.schedulingErrors = getSchedulingErrors(
                    state.schedulerDataSet.scheduledSteps
                );
                state.schedulerDataSet.allSteps =
                    state.schedulerDataSet.scheduledSteps.concat(
                        state.schedulerDataSet.unscheduledSteps
                    );
                state.schedulerDataSet.updateBatchStepDatesLoading =
                    action.payload.isLoading;
            }
        },
        updateSelectedDateAndViewSuccess: (state, action) => {
            state.schedulerDataSet.selectedDate = action.payload.selectedDate;
            state.schedulerDataSet.selectedView = action.payload.selectedView;
        },
        updateWarehouseIdSuccess: (state, action) => {
            state.schedulerDataSet.warehouseId = action.payload;
        },
    },
});

export default slice.reducer;

// Actions
export const {
    stepsLoading,
    updateBatchStepDatesLoading,
    setUpActiveScheduleDataSuccess,
    updateStepSuccess,
    addNewlyScheduledStepSuccess,
    updateSelectedDateAndViewSuccess,
    updateWarehouseIdSuccess,
} = slice.actions;

export const setUpActiveScheduleData =
    (
        warehouseId: string,
        batchStepsByWarehouseId: string,
        utilizedWorkcenters: any
    ) =>
    async (dispatch: AppDispatch) => {
        dispatch(stepsLoading(true));
        if (warehouseId && batchStepsByWarehouseId) {
            dispatch(
                setUpActiveScheduleDataSuccess({
                    isLoading: false,
                    warehouseId: warehouseId,
                    batchStepsByWarehouseId: batchStepsByWarehouseId,
                    utilizedWorkcenters: utilizedWorkcenters,
                })
            );
        } else {
            dispatch(
                setUpActiveScheduleDataSuccess({
                    isLoading: false,
                    warehouseId: null,
                    batchStepsByWarehouseId: [],
                    utilizedWorkcenters: [],
                })
            );
        }
    };

export const updateSelectedDateAndView =
    (date: any, view: any) => async (dispatch: AppDispatch) => {
        dispatch(
            updateSelectedDateAndViewSuccess({
                selectedDate: date,
                selectedView: view,
            })
        );
    };

export const updateWarehouseId = (id: string) => (dispatch: AppDispatch) => {
    dispatch(updateWarehouseIdSuccess(id));
};

export const updateBatchStepStartAndEndDate =
    (
        step: Step,
        workcenter: Workcenter,
        isFirstTimeScheduled: boolean,
        onSuccessFunction: any = null
    ) =>
    async (dispatch: AppDispatch) => {
        dispatch(updateBatchStepDatesLoading(true));
        const sessionData: any = await Auth.currentSession();
        const stepProps = step.extendedProps ? step.extendedProps : step;
        await fetch(
            `${DATACOR_MULTIPLEXER_URL}batchtickets/steps/${stepProps.BatchNumber}`,
            {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + sessionData.idToken.jwtToken,
                    'Ocp-Apim-Trace': 'True',
                    'Ocp-Apim-Subscription-Key':
                        '536572ce1293439e9147aac7511889dd',
                },
                body: JSON.stringify({
                    BatchNumber: stepProps.BatchNumber,
                    StepNumber: stepProps.StepNumber,
                    StartDateTime: moment(step.start),
                    EndDateTime: step.end
                        ? moment(step.end)
                        : moment(step.start).add(1, 'hours'),
                    Workcenter: workcenter,
                }),
            }
        )
            // By design, the instant state and value of a promise cannot be inspected synchronously from code, without calling the then() method
            .then((dataWrappedByPromise) => dataWrappedByPromise.json())
            .then((data) => {
                if (data.ErrorCode || data.Message) {
                    throw new Error(
                        `Error updating ERP: ${
                            data.Message ? data.Message : data.ErrorText
                        }`
                    );
                }

                if (isFirstTimeScheduled) {
                    dispatch(
                        addNewlyScheduledStepSuccess({
                            newStep: step,
                            workcenter: workcenter,
                            isLoading: false,
                        })
                    );
                    console.log(
                        'BatchTicket step schedule for batch number: ' +
                            data.BatchNumber +
                            '-' +
                            data.StepNumber
                    );
                } else {
                    dispatch(
                        updateStepSuccess({
                            updatedStep: step,
                            workcenter: workcenter,
                            isLoading: false,
                        })
                    );
                    console.log(
                        'Updated batchTicket step schedule for batch number: ' +
                            data.BatchNumber +
                            '-' +
                            data.StepNumber
                    );
                }

                if (onSuccessFunction) {
                    onSuccessFunction();
                }
            })
            .catch((error) => {
                return onError(error.message);
            });
    };
