import { useDispatch } from 'react-redux';
import { useFormFields } from '../../../../libs/hooksLib';
import React, { useState } from 'react';
import { getCurrentUserName } from '../../../utils';
import { createCustomerProductRecommendation } from '../../../../store/pricingAnalysisTool';
import { onError } from '../../../../libs/errorLib';

const useRecommendationForm = ({
    handleClose = null,
    mostRecentTransaction = null,
}) => {
    const dispatch = useDispatch();
    const [fields, handleFieldChange] = useFormFields({
        recommendedPrice: '',
        comment: '',
        emailAgent: false,
    });

    const [isSubmitting, setIsSubmitting] = useState(false);

    const validateForm = () => {
        return (
            fields.recommendedPrice > 0 &&
            Number.isFinite(Number(fields.recommendedPrice))
        );
    };

    const handleSubmitClick = async (
        event: React.ChangeEvent<HTMLFormElement>
    ) => {
        event.preventDefault();

        setIsSubmitting(true);

        try {
            const recommendation = {
                externalCustomerId: mostRecentTransaction.externalCustomerId,
                productId: mostRecentTransaction.productId,
                recommendedPrice: fields.recommendedPrice,
                comment: fields.comment,
                userName: await getCurrentUserName(),
                mostRecentTransaction: mostRecentTransaction,
                emailedRecommentation: fields.emailAgent,
            };

            dispatch(
                createCustomerProductRecommendation(
                    recommendation,
                    onPostSuccess
                )
            );
        } catch (error) {
            onError(error);
            setIsSubmitting(false);
        }
    };
    const onPostSuccess = () => {
        fields.recommendedPrice = '';
        fields.comment = '';
        fields.emailAgent = false;

        handleClose();
        setIsSubmitting(false);
    };
    return {
        fields,
        handleFieldChange,
        handleSubmitClick,
        validateForm,
        isSubmitting,
    };
};
export default useRecommendationForm;
