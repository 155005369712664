import React, { useCallback, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGetAllUsersQuery } from '../../../services/users/users.service';
import {
    useGetAllDocumentsQuery,
    useGetAllOpenDocumentsQuery,
    useGetDocumentsByApproverQuery,
    useGetOpenDocumentsByApproverQuery,
} from '../../../services/apAutomation/apAutomation.service';
import { skipToken } from '@reduxjs/toolkit/query';
import { useHistory } from 'react-router-dom';
import { updateGridModels } from '../../../store/grids';
import { GridReadyEvent, SetFilterValuesFuncParams } from 'ag-grid-community';
import { DocumentGridContext } from '../contexts/DocumentGridContext';
import { RootState } from '../../../store';
import { PermissionsUtil } from '../../../utils/permissions/PermissionsUtil';
import {
    currencyFormatter,
    dateFormatter,
} from '../../../utils/formattingUtils';

const useDocumentListView = () => {
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.user);
    const { apAutomation } = useSelector((state: RootState) => state.grids);
    const [gridApi, setGridApi] = useState(null);
    const { documentGridOptions } = useContext(DocumentGridContext);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const viewOnlyAssignedPermission =
        !user.isDatacorAdmin &&
        PermissionsUtil.isPermissionEnabled(
            user.permissions,
            'permission.apAutomation.viewOnlyAssignedDocuments'
        );
    const { data: userList, isLoading } = useGetAllUsersQuery(user.userId);
    const {
        data: invoicesByApprover,
        isLoading: isLoadingInvoicesByApprover,
        isFetching: isFetchingByApprover,
    } = useGetDocumentsByApproverQuery(
        viewOnlyAssignedPermission && documentGridOptions.showVouchered
            ? user.signInEmail
            : skipToken
    );
    const {
        data: openInvoicesByApprover,
        isLoading: isLoadingOpenInvoicesByApprover,
        isFetching: isFetchingOpenByApprover,
    } = useGetOpenDocumentsByApproverQuery(
        viewOnlyAssignedPermission && !documentGridOptions.showVouchered
            ? user.signInEmail
            : skipToken
    );

    const getOpenToken =
        !viewOnlyAssignedPermission && !documentGridOptions.showVouchered;
    const getAllToken =
        !viewOnlyAssignedPermission && documentGridOptions.showVouchered;

    const {
        data: allDocuments,
        isLoading: isLoadingDocuments,
        isFetching: isFetchingAll,
    } = useGetAllDocumentsQuery(getAllToken ? null : skipToken);
    const {
        data: allOpenDocuments,
        isLoading: isLoadingOpenDocuments,
        isFetching: isFetchingOpen,
    } = useGetAllOpenDocumentsQuery(getOpenToken ? null : skipToken);

    const docsforRow = viewOnlyAssignedPermission
        ? invoicesByApprover
        : allDocuments;
    const openDocsForRow = viewOnlyAssignedPermission
        ? openInvoicesByApprover
        : allOpenDocuments;

    const rowData = documentGridOptions.showVouchered
        ? docsforRow
        : openDocsForRow;

    const filteredRowData =
        !documentGridOptions.showRejected && rowData
            ? rowData?.filter((row: any) => {
                  return row.approvalStatus.key !== 'REJECTED';
              })
            : rowData;

    const getValuesAsync = (
        params: SetFilterValuesFuncParams,
        fieldId: string,
        nested: string = null
    ) => {
        return filteredRowData.map((doc: any) => {
            return nested ? doc[fieldId][nested] : doc[fieldId];
        });
    };

    const history = useHistory();
    const shouldLoad =
        isLoading ||
        isLoadingDocuments ||
        isLoadingOpenDocuments ||
        isLoadingInvoicesByApprover ||
        isLoadingOpenInvoicesByApprover ||
        isFetchingAll ||
        isFetchingByApprover ||
        isFetchingOpenByApprover ||
        isFetchingOpen;

    /* istanbul ignore next */
    const openDocument = async (row: any) => {
        history.push(`/apAutomation/document/${row.data.documentId}`);
    };

    const onSortChanged = () => {
        dispatch(
            updateGridModels({
                gridLocation: 'apAutomation',
                gridName: 'documentList',
                type: 'column',
                model: gridColumnApi.getColumnState(),
            })
        );
    };

    const onFilterChanged = () => {
        dispatch(
            updateGridModels({
                gridLocation: 'apAutomation',
                gridName: 'documentList',
                type: 'filter',
                model: gridApi.getFilterModel(),
            })
        );
    };

    const sortData = (sortModel: any, data: any[]) => {
        const sortPresent = sortModel && sortModel.length > 0;
        if (!sortPresent) {
            return data;
        }
        // do an in memory sort of the data, across all the fields
        const resultOfSort = data.slice();
        resultOfSort.sort(function (a, b) {
            for (let k = 0; k < sortModel.length; k++) {
                const sortColModel = sortModel[k];

                let valueA;
                let valueB;

                if (!a[sortColModel.colId] && !b[sortColModel.colId]) {
                    const splitNested = sortColModel.colId.split('.');

                    //if there is no value AND the the value isn't nested
                    //all values are blank so we can just return

                    if (splitNested.length < 2) {
                        return;
                    }
                    valueA = a[splitNested[0]][splitNested[1]];
                    valueB = b[splitNested[0]][splitNested[1]];
                } else {
                    valueA = a[sortColModel.colId];
                    valueB = b[sortColModel.colId];
                }

                // this filter didn't find a difference, move onto the next one
                if (valueA === valueB) {
                    continue;
                }
                const sortDirection = sortColModel.sort === 'asc' ? 1 : -1;
                if (valueA > valueB) {
                    return sortDirection;
                } else {
                    return sortDirection * -1;
                }
            }
            // no filters found a difference
            return 0;
        });
        return resultOfSort;
    };

    const filterData = (filterModel: any, data: any[]) => {
        const filterPresent =
            filterModel && Object.keys(filterModel).length > 0;
        if (!filterPresent) {
            return data;
        }
        const resultOfFilter = [];
        for (let i = 0; i < data.length; i++) {
            const item = data[i];

            if (filterModel.uploader) {
                if (
                    filterModel.uploader.values.indexOf(
                        userList.getUsersFullNameFromEmail(item.uploader, true)
                    ) < 0
                ) {
                    continue;
                }
            }

            if (filterModel.invoiceNumber) {
                if (
                    filterModel.invoiceNumber.values.indexOf(
                        item.invoiceNumber
                    ) < 0
                ) {
                    continue;
                }
            }

            if (filterModel.supplierNumber) {
                if (
                    filterModel.supplierNumber.values.indexOf(
                        item.supplierNumber
                    ) < 0
                ) {
                    continue;
                }
            }

            if (filterModel.supplierName) {
                if (
                    filterModel.supplierName.values.indexOf(item.supplierName) <
                    0
                ) {
                    continue;
                }
            }

            if (filterModel.due) {
                if (
                    filterModel.due.values.indexOf(
                        currencyFormatter(item.due)
                    ) < 0
                ) {
                    continue;
                }
            }

            if (filterModel.invoiceDate) {
                if (
                    filterModel.invoiceDate.values.indexOf(
                        dateFormatter(item.invoiceDate)
                    ) < 0
                ) {
                    continue;
                }
            }

            if (filterModel.uploadDate) {
                if (
                    filterModel.uploadDate.values.indexOf(
                        dateFormatter(item.uploadDate)
                    ) < 0
                ) {
                    continue;
                }
            }

            if (filterModel.poNumber) {
                if (filterModel.poNumber.values.indexOf(item.poNumber) < 0) {
                    continue;
                }
            }

            if (filterModel['poStatus.value']) {
                if (
                    filterModel['poStatus.value'].values.indexOf(
                        item.poStatus.value
                    ) < 0
                ) {
                    continue;
                }
            }

            if (filterModel['approvalStatus.value']) {
                if (
                    filterModel['approvalStatus.value'].values.indexOf(
                        item.approvalStatus.value
                    ) < 0
                ) {
                    continue;
                }
            }

            if (filterModel.voucherNumber) {
                if (
                    filterModel.voucherNumber.values.indexOf(
                        item.voucherNumber.value
                    ) < 0
                ) {
                    continue;
                }
            }

            resultOfFilter.push(item);
        }
        return resultOfFilter;
    };

    const sortAndFilter = (
        allOfTheData: any[],
        sortModel: any,
        filterModel: any
    ) => {
        return sortData(sortModel, filterData(filterModel, allOfTheData));
    };

    const onGridReady = useCallback(
        (params: GridReadyEvent) => {
            if (isLoading) {
                return;
            }
            setGridApi(params.api);
            setGridColumnApi(params.columnApi);
            params.api.sizeColumnsToFit();

            const dataSource: any = {
                rowCount: undefined,
                getRows: (params: any) => {
                    const dataAfterSorting = sortAndFilter(
                        filteredRowData,
                        params.sortModel,
                        params.filterModel
                    );

                    const rowsThisPage = dataAfterSorting.slice(
                        params.startRow,
                        params.endRow
                    );
                    let lastRow = -1;
                    if (dataAfterSorting.length <= params.endRow) {
                        lastRow = dataAfterSorting.length;
                    }
                    params.successCallback(rowsThisPage, lastRow);
                },
            };
            params.api.setDatasource(dataSource);
        },
        [
            filteredRowData,
            documentGridOptions.showVouchered,
            isLoading,
            documentGridOptions.showRejected,
        ]
    );

    const onFirstDataRendered = () => {
        gridColumnApi?.applyColumnState({
            state: apAutomation.documentList.column,
            applyOrder: true,
        });

        gridApi?.setFilterModel(apAutomation.documentList.filter);
    };

    return {
        user,
        userList,
        shouldLoad,
        filteredRowData,
        onFirstDataRendered,
        onSortChanged,
        onFilterChanged,
        onGridReady,
        openDocument,
        getValuesAsync,
    };
};

export default useDocumentListView;
