import React from 'react';
import { AgGridColumn } from 'ag-grid-react';
import Grid from '../../../components/Grids/Grid';
import useFixedAssetTypeGrid from '../hooks/useFixedAssetTypeGrid';
import DatacorLogoSpinner from '../../../components/DatacorLogoSpinner/DatacorLogoSpinner';
import { GridOptions } from '../../../components/Grids/grid.constants';

const FixedAssetTypeGrid = () => {
    const {
        typeList,
        redirectToForm,
        getDepreciationMethodFromId,
        isLoadingAny,
    } = useFixedAssetTypeGrid();

    return isLoadingAny ? (
        <DatacorLogoSpinner />
    ) : (
        <Grid
            rowData={typeList}
            gridClass="full-width-grid"
            displayToolbar={true}
            displayCreateNewButton={true}
            onCreateNew={redirectToForm}
            onRowClicked={({ data }) => redirectToForm(data)}
            defaultColDef={GridOptions.sortFilterAndWrapColumns}>
            <AgGridColumn
                minWidth={100}
                field="sortOrder"
                headerName="Sort Order"
            />
            <AgGridColumn
                minWidth={150}
                field="code"
                headerName="Fixed Asset Type Code"
            />
            <AgGridColumn
                minWidth={250}
                field="description"
                headerName="Description"
            />
            <AgGridColumn
                minWidth={100}
                field="recoveryTerm"
                headerName="Recovery Term"
            />
            <AgGridColumn
                minWidth={100}
                field="recoveryUnit"
                headerName="Recovery Unit"
            />
            <AgGridColumn
                minWidth={150}
                field="depreciationMethodId"
                headerName="Depreciation Method"
                valueFormatter={(val) => getDepreciationMethodFromId(val.value)}
                filterParams={() => {
                    return {
                        valueFormatter: (params: any) => {
                            return getDepreciationMethodFromId(params.value);
                        },
                    };
                }}
            />
        </Grid>
    );
};

export default FixedAssetTypeGrid;
