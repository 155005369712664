import React from 'react';
import LoaderButton from '../../components/LoaderButton';
import { TextField, Grid } from '@mui/material';
import useNewPasswordRequired from './hooks/useNewPasswordRequired';

const NewPasswordRequired = () => {
    const {
        fields,
        handleFieldChange,
        handleConfirmClick,
        isConfirming,
        validateResetForm,
    } = useNewPasswordRequired();

    return (
        <div className="ResetPassword">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="New Password"
                        id="password"
                        data-testid="password"
                        type="password"
                        value={fields.password}
                        onChange={handleFieldChange}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Confirm Password"
                        id="confirmPassword"
                        data-testid="confirmPassword"
                        type="password"
                        value={fields.confirmPassword}
                        onChange={handleFieldChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <LoaderButton
                        fullWidth
                        variant="contained"
                        onClick={handleConfirmClick}
                        type="submit"
                        size="lg"
                        isLoading={isConfirming}
                        disabled={!validateResetForm()}>
                        Change Password
                    </LoaderButton>
                </Grid>
            </Grid>
        </div>
    );
};

export default NewPasswordRequired;
