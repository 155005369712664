import React from 'react';
import { AgGridColumn } from 'ag-grid-react';
import { useHistory } from 'react-router-dom';
import Grid from '../../../components/Grids/Grid';
import { useGetAllCostCodesQuery } from '../../../services/fixedAssets/fixedAssets.service';
import DatacorLogoSpinner from '../../../components/DatacorLogoSpinner/DatacorLogoSpinner';
import { GridOptions } from '../../../components/Grids/grid.constants';

const FixedAssetCostCodeGrid = () => {
    const history = useHistory();
    const { data: costCodeList, isLoading } = useGetAllCostCodesQuery();

    const redirectToForm = (row: any) => {
        if (row.id) {
            history.push(`/accounting/fixedAssets/costCode/${row.id}`);
        } else {
            history.push('/accounting/fixedAssets/costCode/new');
        }
    };

    return isLoading ? (
        <DatacorLogoSpinner />
    ) : (
        <Grid
            rowData={costCodeList}
            gridClass="full-width-grid"
            displayToolbar={true}
            displayCreateNewButton={true}
            onCreateNew={redirectToForm}
            onRowClicked={({ data }) => redirectToForm(data)}
            defaultColDef={GridOptions.sortFilterAndWrapColumns}>
            <AgGridColumn
                minWidth={100}
                field="sortOrder"
                headerName="Sort Order"
            />
            <AgGridColumn minWidth={150} field="code" headerName="Cost Code" />
            <AgGridColumn minWidth={75} field="isAdd" headerName="Is Add" />
        </Grid>
    );
};

export default FixedAssetCostCodeGrid;
