export const GridDefaults = {
    domLayout: 'autoHeight',
    tooltipShowDelay: 0,
    pagination: true,
    paginationPageSize: 10,
    immutableData: false,
    autofitColumns: true,
    displayToolbar: false,
};

export const GridOptions = {
    sortFilterAndWrapColumns: {
        resizable: true,
        sortable: true,
        unSortIcon: true,
        filter: true,
        wrapText: true,
    },
};
