import React from 'react';
import LoaderButton from '../../components/LoaderButton';
import { Controller } from 'react-hook-form';
import Select from 'react-select';
import MultiselectDropdown from '../../components/Form/MultiselectDropdown/MultiselectDropdown';
import { isFeatureFlagEnabled } from '../../utils/FeatureFlag/FeatureFlagUtil';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Box,
    IconButton,
    TextField,
    Grid,
    Checkbox,
    FormControlLabel,
    FormGroup,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SelectDropdown from '../../components/Form/SelectDropdown/SelectDropdown';
import useCreateEditUser from './hooks/useCreateEditUser';

interface CreateEditUserProps {
    show: boolean;
    handleClose: (_: boolean) => void;
    isEdit: boolean;
    username: string;
}

const CreateEditUser = ({
    show = false,
    handleClose,
    isEdit = false,
    username,
}: CreateEditUserProps) => {
    const {
        control,
        user,
        selectedApAutomationInvoiceTarget,
        selectedApAutomationInvoiceTargetChanged,
        companyList,
        onCompanyMenuClose,
        selectedCompanies,
        companySelectionChanged,
        zoneOptions,
        selectedZones,
        setSelectedZones,
        selectedRoles,
        roleOptions,
        setSelectedRoles,
        isRolesDisabled,
        isAppStream,
        setIsAppStreamUserValue,
        isAdmin,
        setIsDatacorAdminValue,
        handleSubmit,
        onSubmit,
        isLoading,
        isFormValid,
    } = useCreateEditUser({ username, isEdit, handleClose });
    const customStyles = {
        menu: (provided: any) => ({
            ...provided,
            'z-index': 99999,
        }),
    };

    return (
        <Dialog
            open={show}
            onClose={handleClose}
            data-testid="edit-user-modal"
            className="stepInfoModal">
            <DialogTitle>
                <Box display="flex" alignItems="center">
                    <Box flexGrow={1}>
                        {isEdit ? 'Edit User' : 'Create User'}
                    </Box>
                    <Box>
                        <IconButton onClick={() => handleClose(true)}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
            </DialogTitle>
            <DialogContent style={{ paddingTop: '10px' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Controller
                            name="firstName"
                            control={control}
                            render={(field) => (
                                <TextField
                                    size="small"
                                    fullWidth
                                    variant="outlined"
                                    data-testid="firstName"
                                    label="First Name"
                                    name="firstName"
                                    autoFocus
                                    {...field}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            name="lastName"
                            control={control}
                            render={(field) => (
                                <TextField
                                    size="small"
                                    fullWidth
                                    data-testid="lastName"
                                    variant="outlined"
                                    label="Last Name"
                                    name="lastName"
                                    {...field}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            name="email"
                            control={control}
                            render={(field) => (
                                <TextField
                                    size="small"
                                    fullWidth
                                    type="email"
                                    data-testid="email"
                                    variant="outlined"
                                    label="Email"
                                    name="email"
                                    disabled={isEdit}
                                    {...field}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                {isFeatureFlagEnabled(
                    user.enabledFeatureFlags,
                    'dashboard.nav.apps.apAutomation'
                ) ? (
                    <Grid item xs={12} style={{ paddingTop: '10px' }}>
                        <SelectDropdown
                            id="ap-automation-invoice-target"
                            className="basic-select basic-multi-select"
                            classNamePrefix="select"
                            closeMenuOnSelect={false}
                            value={selectedApAutomationInvoiceTarget}
                            options={companyList.map((company) => {
                                return {
                                    value: company.companyID,
                                    label: company.companyID,
                                };
                            })}
                            placeholder={'AP-Automation Invoice Target'}
                            onChange={selectedApAutomationInvoiceTargetChanged}
                        />
                    </Grid>
                ) : null}
                <Grid item xs={12} style={{ paddingTop: '10px' }}>
                    Company IDs
                    <Select
                        isMulti
                        menuPlacement={'top'}
                        id="company-id"
                        className="basic-multi-select"
                        classNamePrefix="select"
                        closeMenuOnSelect={false}
                        styles={customStyles}
                        onMenuClose={onCompanyMenuClose}
                        value={selectedCompanies}
                        options={companyList.map((company) => {
                            return {
                                value: company.companyID,
                                label: company.companyID,
                            };
                        })}
                        placeholder={'select Company Identifiers'}
                        onChange={companySelectionChanged}
                    />
                </Grid>
                <Grid item xs={12} style={{ paddingTop: '10px' }}>
                    Zones
                    <Select
                        isMulti
                        options={zoneOptions}
                        name="zones"
                        id="zones"
                        styles={customStyles}
                        value={selectedZones}
                        closeMenuOnSelect={false}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder={'Select Zones'}
                        onChange={(selectedValues: any) =>
                            setSelectedZones(
                                selectedValues.map((selectedValue: any) => {
                                    return {
                                        label: selectedValue.value,
                                        value: selectedValue.value,
                                    };
                                })
                            )
                        }
                    />
                </Grid>
                <Grid item xs={12} style={{ paddingTop: '10px' }}>
                    <MultiselectDropdown
                        isMulti
                        value={selectedRoles}
                        isDisabled={isRolesDisabled}
                        placeholder={'Roles'}
                        options={roleOptions}
                        id="user-role"
                        menuPlacement={'top'}
                        closeMenuOnSelect={false}
                        onChange={(values: any) => setSelectedRoles(values)}
                    />
                </Grid>
                <Grid item xs={12} style={{ paddingTop: '10px' }}>
                    <Controller
                        name="localUser"
                        control={control}
                        render={(field) => (
                            <TextField
                                size="small"
                                fullWidth
                                data-testid="localUser"
                                variant="outlined"
                                label="Local User"
                                name="localUser"
                                {...field}
                            />
                        )}
                    />
                </Grid>

                <Grid item xs={12}>
                    <FormGroup>
                        {isFeatureFlagEnabled(
                            user.enabledFeatureFlags,
                            'feature.apps.userPortal.createAppStreamUsers'
                        ) && (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        data-testid="isAppStreamUser"
                                        checked={isAppStream}
                                        onChange={setIsAppStreamUserValue}
                                    />
                                }
                                label="Is App Stream User?"
                            />
                        )}
                        {user.isDatacorAdmin ? (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        data-testid="isDatacorAdmin"
                                        checked={isAdmin}
                                        onChange={setIsDatacorAdminValue}
                                    />
                                }
                                label="Is User Datacor Admin?"
                            />
                        ) : null}
                    </FormGroup>
                </Grid>

                <Grid item xs={12}>
                    <LoaderButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        data-testid="updateUserBtn"
                        isLoading={isLoading}
                        onClick={handleSubmit(onSubmit)}
                        disabled={!isFormValid()}>
                        {isEdit ? 'Update User' : 'Create User'}
                    </LoaderButton>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default CreateEditUser;
