import { isNil, isNilOrEmpty } from '../utils/objectUtils';
import _ from 'lodash';
import { Validator } from './validator.interface';

const isValid = (value: any) => {
    return _.isArray(value) ? !isNilOrEmpty(value) : !isNil(value);
};

export const RequiredValidator: Validator = {
    key: 'required',
    getErrorMessage: (fieldLabel: string) => {
        return `${fieldLabel} is required`;
    },
    isValid: isValid,
};
