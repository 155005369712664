import React from 'react';
import {
    useCreateCostCodeMutation,
    useDeleteCostCodeMutation,
    useGetCostCodeQuery,
    useUpdateCostCodeMutation,
} from '../../../services/fixedAssets/fixedAssets.service';
import { skipToken } from '@reduxjs/toolkit/query';
import { FixedAssetCostCode } from '../../../types/FixedAsset.types';
import useBaseForm from '../../Form/hooks/useBaseForm';

const UseFixedAssetCostCodeForm = (id: string) => {
    const { data: activeCostCode, isLoading: isLoadingCostCode } =
        useGetCostCodeQuery(id ? id : skipToken);
    const [createCostCode] = useCreateCostCodeMutation();
    const [updateCostCode] = useUpdateCostCodeMutation();
    const [deleteCostCode] = useDeleteCostCodeMutation();

    const blankCostCode: FixedAssetCostCode = {
        code: '',
        sortOrder: null,
        isAdd: true,
    };

    const {
        fields,
        handleFieldChange,
        formMethods,
        toastProps,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
    } = useBaseForm({
        closePath: '/accounting/fixedAssets/costCode',
        blankEntity: blankCostCode,
        activeEntity: activeCostCode,
        createEntity: () => {
            return createCostCode({ postBody: fields });
        },
        updateEntity: () => {
            return updateCostCode({
                id: id as unknown as number,
                postBody: fields,
            });
        },
        deleteEntity: () => {
            return deleteCostCode(id);
        },
    });

    return {
        fields,
        handleFieldChange,
        formMethods,
        toastProps,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        isLoadingCostCode,
    };
};

export default UseFixedAssetCostCodeForm;
