import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { RootStateOrAny, useSelector } from 'react-redux';
import { useGetBuildingsQuery } from '../../../services/organizations/organizations.service';
import { skipToken } from '@reduxjs/toolkit/query';
import { GridReadyEvent } from 'ag-grid-community';

const useBuildingsGrid = () => {
    const history = useHistory();
    const user = useSelector((state: RootStateOrAny) => state.user);
    const [gridApi, setGridApi] = useState(null);

    const { data: buildings, isLoading: isLoadingBuildings } =
        useGetBuildingsQuery(
            user?.businessEntity?.id ? user?.businessEntity?.id : skipToken
        );
    const [gridColumnApi, setGridColumnApi] = useState(null);

    const onGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    };

    const redirectToForm = (row: any) => {
        if (row.id) {
            history.push(`/administration/organizations/buildings/${row.id}`);
        } else {
            history.push('/administration/organizations/buildings/new');
        }
    };

    return {
        onGridReady,
        buildings,
        isLoadingBuildings,
        redirectToForm,
    };
};

export default useBuildingsGrid;
