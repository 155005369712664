import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
    useGetAllFixedAssetsQuery,
    useGetStatusesMapQuery,
    useGetTypesMapQuery,
} from '../../../services/fixedAssets/fixedAssets.service';
import { FixedAsset } from '../../../types/FixedAsset.types';

const UseFixedAssetGrid = () => {
    const history = useHistory();
    const [isLoadingAny, setIsLoadingAny] = useState(true);

    const { data: assetList, isLoading: isLoadingAssets } =
        useGetAllFixedAssetsQuery();
    const { data: statusList, isLoading: isLoadingStatuses } =
        useGetStatusesMapQuery();
    const { data: typeList, isLoading: isLoadingTypes } = useGetTypesMapQuery();

    useEffect(() => {
        setIsLoadingAny(isLoadingAssets || isLoadingStatuses || isLoadingTypes);
    }, [isLoadingAssets, isLoadingStatuses, isLoadingTypes]);

    const redirectToForm = (row: FixedAsset) => {
        if (row.id) {
            history.push(`/accounting/fixedAssets/fixedAsset/${row.id}`);
        } else {
            history.push('/accounting/fixedAssets/fixedAsset/new');
        }
    };

    const getStatusFromId = (id: number) => {
        return statusList?.get(id)?.code || '';
    };

    const getTypeFromId = (id: number) => {
        return typeList?.get(id)?.code || '';
    };

    const getBuildingFromId = (id: number) => {
        //dummy data for now
        switch (id) {
            case 1: {
                return 'Florham Park';
            }
            case 2: {
                return 'Las Vegas';
            }
            case 3: {
                return 'San Diego';
            }
            default: {
                return '';
            }
        }
    };

    return {
        assetList,
        redirectToForm,
        getStatusFromId,
        getTypeFromId,
        getBuildingFromId,
        isLoadingAny,
    };
};

export default UseFixedAssetGrid;
