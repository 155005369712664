import React, { useEffect, useState } from 'react';
import {
    useCreateDepreciationMethodMutation,
    useDeleteDepreciationMethodMutation,
    useGetAllCostCodesQuery,
    useGetDepreciationMethodQuery,
    useGetDepreciationMethodStatusesForDropdownQuery,
    useGetDepreciationMethodTypesForDropdownQuery,
    useUpdateDepreciationMethodMutation,
} from '../../../services/fixedAssets/fixedAssets.service';
import { skipToken } from '@reduxjs/toolkit/query';
import {
    FixedAssetCostCode,
    FixedAssetDepreciationMethod,
    FixedAssetDepreciationMethodCost,
} from '../../../types/FixedAsset.types';
import useBaseForm from '../../Form/hooks/useBaseForm';
import { GridReadyEvent, RowNode, RowSelectedEvent } from 'ag-grid-community';
import { isNil, isNilOrEmpty } from '../../../utils/objectUtils';

const UseFixedAssetDepreciationMethodForm = (id: string) => {
    const [gridApi, setGridApi] = useState(null);

    const { data: activeMethod, isLoading: isLoadingMethod } =
        useGetDepreciationMethodQuery(id ? id : skipToken);
    const [createMethod] = useCreateDepreciationMethodMutation();
    const [updateMethod] = useUpdateDepreciationMethodMutation();
    const [deleteMethod] = useDeleteDepreciationMethodMutation();

    const { data: statusOptions, isLoading: isLoadingStatuses } =
        useGetDepreciationMethodStatusesForDropdownQuery();
    const { data: typeOptions, isLoading: isLoadingTypes } =
        useGetDepreciationMethodTypesForDropdownQuery();
    const { data: costCodeList, isLoading: isLoadingCostCodes } =
        useGetAllCostCodesQuery();

    const blankMethod: FixedAssetDepreciationMethod = {
        sortOrder: null,
        code: '',
        name: '',
        description: '',
        depreciationMethodStatusId: null,
        depreciationMethodTypeId: null,
        costs: [],
    };

    const onGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
    };

    const onRowSelected = (params: RowSelectedEvent) => {
        updateTotalAmount();
    };

    useEffect(() => {
        if (activeMethod && gridApi) {
            gridApi.forEachNode((node: RowNode) => {
                node.setSelected(
                    activeMethod.costs.some(
                        (cost: FixedAssetDepreciationMethodCost) =>
                            cost.costCodeId === node.data.id
                    )
                );
            });

            updateTotalAmount();
        }
    }, [activeMethod, gridApi]);

    useEffect(() => {
        updateTotalAmount();
    }, [activeMethod]);

    const updateTotalAmount = () => {
        if (!isNilOrEmpty(gridApi?.getSelectedRows())) {
            let newTotalAmount = '';

            gridApi
                .getSelectedRows()
                .forEach((costCode: FixedAssetCostCode) => {
                    newTotalAmount = isNil(newTotalAmount)
                        ? ''
                        : `${newTotalAmount} ${costCode.isAdd ? '+' : '-'}`;
                    newTotalAmount = `${newTotalAmount} ${costCode.code}`;
                });

            setValues({ totalAmount: `( ${newTotalAmount} )` });
        } else {
            setValues({ totalAmount: ' ' });
        }
    };

    const buildPostBody = (): FixedAssetDepreciationMethod => {
        const selectedCosts: FixedAssetDepreciationMethodCost[] = gridApi
            ?.getSelectedRows()
            .map((costCode: FixedAssetCostCode) => {
                return { costCodeId: costCode.id };
            });

        return { ...fields, costs: selectedCosts };
    };

    const {
        fields,
        handleFieldChange,
        setValues,
        formMethods,
        toastProps,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
    } = useBaseForm({
        closePath: '/accounting/fixedAssets/depreciationMethod',
        blankEntity: blankMethod,
        activeEntity: activeMethod,
        createEntity: () => {
            return createMethod({ postBody: buildPostBody() });
        },
        updateEntity: () => {
            return updateMethod({
                id: id as unknown as number,
                postBody: buildPostBody(),
            });
        },
        deleteEntity: () => {
            return deleteMethod(id);
        },
    });

    return {
        fields,
        handleFieldChange,
        formMethods,
        toastProps,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        isLoadingMethod,
        isLoadingStatuses,
        isLoadingTypes,
        isLoadingCostCodes,
        statusOptions,
        typeOptions,
        costCodeList,
        onGridReady,
        onRowSelected,
        updateTotalAmount,
        buildPostBody,
    };
};

export default UseFixedAssetDepreciationMethodForm;
