import React from 'react';
import { PermissionsUtil } from '../../../utils/permissions/PermissionsUtil';
import { PERMISSIONS } from '../../../constants/permissions/permissions.constants';
import { IconButton } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import useDeleteCellRenderer from '../hooks/ApprovalWorkflows/useDeleteCellRenderer';

interface DeleteCellRendererProps {
    data: any;
}

const DeleteCellRenderer = ({ data }: DeleteCellRendererProps) => {
    const { user, handleClick, popover } = useDeleteCellRenderer({ data });

    return (
        <>
            {popover}
            <IconButton
                color="error"
                onClick={handleClick}
                disabled={
                    !PermissionsUtil.isPermissionEnabled(
                        user.permissions,
                        PERMISSIONS.AP_AUTOMATION.APPROVAL_WORKFLOWS.DELETE
                    )
                }>
                <DeleteForeverIcon />
            </IconButton>
        </>
    );
};

export default DeleteCellRenderer;
