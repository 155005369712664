import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { logout, setIsTimedOut } from '../../../store/user';
import { RootState } from '../../../store';

const useLanding = () => {
    const user = useSelector((state: RootState) => state.user);
    const dispatch = useDispatch();
    const history = useHistory();
    const idleTimer = React.createRef<any>();

    const onAction = () => {
        dispatch(setIsTimedOut(false));
    };

    const onIdle = () => {
        if (user.isTimedOut) {
            logoutAndResetRoute();
        } else {
            idleTimer.current.reset();
            dispatch(setIsTimedOut(true));
        }
    };

    const logoutAndResetRoute = () => {
        history.push('/myApps');
        dispatch(logout());
    };
    return { onIdle, onAction, user, idleTimer };
};
export default useLanding;
