import React from 'react';
import './ChangeEmail.css';
import { TextField } from '@mui/material';
import Grid from '@mui/material/Grid';
import LoaderButton from '../../components/LoaderButton';
import useChangeEmail from './hooks/useChangeEmail';

const ChangeEmail = () => {
    const {
        fields,
        handleFieldChange,
        handleUpdateClick,
        isSendingCode,
        validateEmailForm,
    } = useChangeEmail();

    return (
        <div className="ChangeEmail">
            <Grid container spacing={2} style={{ padding: '10px' }}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        required
                        variant="outlined"
                        label="Email"
                        value={fields.email}
                        id="email"
                        onChange={handleFieldChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <LoaderButton
                        fullWidth
                        onClick={handleUpdateClick}
                        type="submit"
                        isLoading={isSendingCode}
                        variant="contained"
                        disabled={!validateEmailForm()}>
                        Update Email
                    </LoaderButton>
                </Grid>
            </Grid>
        </div>
    );
};

export default ChangeEmail;
