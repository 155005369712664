import { useSelector, useDispatch } from 'react-redux';
import './CompanyZoneSelect.css';
import React, { useEffect, useState } from 'react';
import LoaderButton from '../LoaderButton';
import { logout } from '../../store/user';
import { useHistory } from 'react-router-dom';
import { Company, Zone } from '../../types/User.types';
import { Grid } from '@mui/material';
import SelectDropdown from '../Form/SelectDropdown/SelectDropdown';
import {
    useApplyCompanyAndZoneMutation,
    useGetCompaniesByUserIdQuery,
    useGetZonesQuery,
} from '../../services/users/users.service';
import { RootState } from '../../store';

const CompanyZoneSelect = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.user);
    const [applyCompanyAndZone] = useApplyCompanyAndZoneMutation();
    const { data: zoneList, isLoading: isLoadingZones } = useGetZonesQuery();
    const { data: companiesList, isLoading: isLoadingCompanies } =
        useGetCompaniesByUserIdQuery(user.userId);
    const [currentCompany, setCurrentCompany] = useState(null);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [currentZone, setCurrentZone] = useState(null);
    const [selectedZone, setSelectedZone] = useState(null);
    const [isApplyDisabled, setIsApplyDisabled] = useState(true);
    const [zoneOptions, setzoneOptions] = useState([]);
    //Set the user's selected company by default
    useEffect(() => {
        if (isLoadingCompanies) {
            return;
        }
        const setDefaultCompany = () => {
            const companyId = user.companyId;
            setCurrentCompany(
                companiesList?.find(
                    (company: Company) => company.companyID === companyId
                )
            );
            setzoneOptions(setSelectZoneOptions(companyId));
            setSelectedCompany(companyId);
        };

        setDefaultCompany();
    }, [companiesList, zoneList]);

    useEffect(() => {
        if (isLoadingZones) {
            return;
        }

        //Get the user's current zone from the session
        const zoneId = user.zoneId;
        const userZones = zoneList.filter(
            (userZone: Zone) => userZone.companyID === user.companyId
        );
        const zone = userZones?.find((zone: Zone) => zone.zoneId === zoneId);

        //set the zone if the user's zone is part of the company zones, otherwise set to null
        setCurrentZone(zone);
        setSelectedZone(zone ? zone.zoneId : null);
    }, [currentCompany, zoneList, isLoadingZones]);

    useEffect(() => {
        const userZoneId = user.zoneId;
        const userCompanyId = user.companyId;
        const isSameCompanyAndZoneSelected =
            currentZone?.zoneId === userZoneId &&
            currentCompany?.companyID === userCompanyId;
        const isCompanyEmpty = !selectedCompany;

        setIsApplyDisabled(isCompanyEmpty || isSameCompanyAndZoneSelected);
    }, [currentCompany, currentZone]);

    const setSelectCompanyOptions = () => {
        return companiesList?.map((company: Company) => {
            return { value: company.companyID, label: company.companyID };
        });
    };

    const setSelectZoneOptions = (companyId: string) => {
        return zoneList
            ?.filter((zone: Zone) => zone.companyID === companyId)
            .map((zone: Zone) => {
                return { value: zone.zoneId, label: zone.zoneName };
            });
    };

    const getZonesForCompany = (zones: Array<Zone>, companyId: string) => {
        return zones?.filter((zone) => zone.companyID === companyId);
    };

    const setCompany = (companyOption: string) => {
        setCurrentCompany(
            companiesList?.find(
                (company: Company) => company.companyID === companyOption
            )
        );
        setSelectedCompany(companyOption);
        setSelectedZone(null);
        setzoneOptions(setSelectZoneOptions(companyOption));
    };

    const setZone = (selectedZone: string) => {
        const zone = zoneList?.find(
            (zone: Zone) => zone.zoneId === selectedZone
        );

        //set the zone if the user's zone is part of the company zones, otherwise set to null
        setCurrentZone(zone);
        setSelectedZone(selectedZone);
    };

    const handleApplyCompanyAndZone = async () => {
        const request = {
            companyId: selectedCompany,
            zoneId: selectedZone,
        };

        await applyCompanyAndZone(request);

        window.alert('To apply these changes, you will be logged out.');
        history.push('/myApps');
        dispatch(logout());
    };

    return (
        <Grid
            container
            spacing={2}
            justifyContent="flex-end"
            alignItems="center">
            <Grid item xs={4}>
                <SelectDropdown
                    className="basic-select company-select"
                    menuPlacement={'bottom'}
                    value={selectedCompany}
                    options={setSelectCompanyOptions()}
                    onChange={(selectedValue: string) =>
                        setCompany(selectedValue)
                    }
                    placeholder={'Select Company'}
                    id="companySelect"
                />
            </Grid>
            <Grid item xs={4}>
                <SelectDropdown
                    className="basic-select zone-select"
                    menuPlacement={'bottom'}
                    value={selectedZone}
                    options={zoneOptions}
                    onChange={(selectedValue: string) => setZone(selectedValue)}
                    placeholder={'Select Zone'}
                    id="zoneSelect"
                />
            </Grid>
            <Grid item xs={4}>
                <LoaderButton
                    isLoading={null}
                    variant="contained"
                    size="small"
                    color="primary"
                    type="button"
                    className="apply-button"
                    onClick={() => handleApplyCompanyAndZone()}
                    disabled={isApplyDisabled}>
                    Apply
                </LoaderButton>
            </Grid>
        </Grid>
    );
};

export default CompanyZoneSelect;
