import React from 'react';
import Breadcrumbs from '../Breadcrumbs';
import PricingConcernDetailFilterBy from '../PricingConcernDetails/PricingConcernDetailFilterBy';
import PricingCustomerProductDetailsGrid from './PricingCustomerProductDetailsGrid';
import PricingCustomerProductDetailsCharts from './PricingCustomerProductDetailsCharts';
import {
    Card,
    Grid,
    Tab,
    Tabs,
    Box,
    CardContent,
    CardHeader,
} from '@mui/material';
import usePricingCustomerProductDetails from '../hooks/PricingCustomerProductDetails/usePricingCustomerProductDetails';

interface TabPanelProps {
    children?: React.ReactNode;
    index?: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

const PricingCustomerProductDetails = () => {
    const { value, costomerProductName, handleChange } =
        usePricingCustomerProductDetails();

    return (
        <Card>
            <CardHeader
                title={
                    <>
                        <Breadcrumbs active={'pricingCustomerProductDetails'} />
                    </>
                }
            />
            <CardContent>
                <Grid container spacing={2} className="appPage">
                    <Grid item xs={12}>
                        <h2>{costomerProductName}</h2>
                    </Grid>
                    <Grid item xs={12}>
                        <PricingConcernDetailFilterBy
                            doFilterWarehousesByCustomerProduct={true}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Card>
                            <Box sx={{ width: '100%' }}>
                                <Box
                                    sx={{
                                        borderBottom: 1,
                                        borderColor: 'divider',
                                    }}>
                                    <Tabs
                                        value={value}
                                        textColor="inherit"
                                        indicatorColor="secondary"
                                        onChange={handleChange}
                                        aria-label="pricing customer tabs">
                                        <Tab label="Graphical" value={0} />
                                        <Tab label="Grid" value={1} />
                                    </Tabs>
                                </Box>
                                <TabPanel value={value} index={0}>
                                    <PricingCustomerProductDetailsCharts />
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                    <PricingCustomerProductDetailsGrid />
                                </TabPanel>
                            </Box>
                        </Card>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default PricingCustomerProductDetails;
