import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { useHistory } from 'react-router-dom';
import { useForm } from '../../../libs/hooksLib';
import {
    Building,
    useCreateBuildingMutation,
    useUpdateBuildingMutation,
    useGetBuildingQuery,
    useGetCountriesForDropdownQuery,
    useGetStatesQuery,
} from '../../../services/organizations/organizations.service';
import { skipToken } from '@reduxjs/toolkit/query';
import {
    BaseCreateEntityArgs,
    BaseUpdateEntityArgs,
} from '../../../services/serviceInterfaces';
import { onError } from '../../../libs/errorLib';

const useBuildingsForm = (id: string) => {
    const user = useSelector((state: RootState) => state.user);
    const history = useHistory();
    const { fields, handleFieldChange, setValues, formMethods, isFormValid } =
        useForm({});
    const {
        fields: addressFields,
        handleFieldChange: handleAddressFieldChange,
        setValues: setAddressValues,
        formMethods: addressFormMethods,
        isFormValid: isAddressFormValid,
    } = useForm({});
    const { data: building, isLoading: isLoadingBuilding } =
        useGetBuildingQuery(id ? id : skipToken);
    const { data: countryOptions, isLoading: isLoadingCountries } =
        useGetCountriesForDropdownQuery();
    const { data: states, isLoading: isLoadingStates } = useGetStatesQuery();
    const [stateSelectOptions, setStateSelectOptions] = useState([]);
    const [loading, setLoading] = useState(true);

    const [createBuilding] = useCreateBuildingMutation();
    const [updateBuilding] = useUpdateBuildingMutation();

    const defaultBuilding: any = {
        code: '',
        name: '',
        supplierId: '',
        customerId: '',
        note: '',
        businessEntityId: user?.businessEntity?.id,
    };

    const defaultAddress: any = {
        street: '',
        city: '',
        county: '',
        postalCode: '',
        countryCodeId: '',
        stateCodeId: '',
    };

    useEffect(() => {
        setValues(building || defaultBuilding);
        setAddressValues(building?.address || defaultAddress);
    }, [building]);

    useEffect(() => {
        if (!states && !addressFields?.countryCodeId) {
            setLoading(false);
            return;
        }

        const statesFilteredByCountry = states
            ?.filter((state: any) => {
                return state.countryCodeId === addressFields?.countryCodeId;
            })
            .map((state: any) => {
                return {
                    value: state.id,
                    label: state.code + ' - ' + state.name,
                };
            });

        setStateSelectOptions(statesFilteredByCountry);

        setLoading(false);
    }, [addressFields?.countryCodeId, states]);

    const handleCreateBuilding = async () => {
        if (!isFormValid || !isAddressFormValid) {
            return;
        }

        const body: BaseCreateEntityArgs<Building> = {
            postBody: {
                ...fields,
                address: {
                    ...fields?.address,
                    street: addressFields?.street,
                    city: addressFields?.city,
                    county: addressFields?.county,
                    postalCode: addressFields?.postalCode,
                    countryCodeId: addressFields?.countryCodeId,
                    stateCodeId: addressFields?.stateCodeId,
                },
            },
        };

        try {
            await createBuilding(body);
            handleClose();
        } catch (e) {
            return onError(e.message);
        }
    };

    const handleUpdateBuilding = async () => {
        if (!isFormValid || !isAddressFormValid) {
            return;
        }

        const body: BaseUpdateEntityArgs<Building> = {
            id: id as unknown as number,
            postBody: {
                ...fields,
                address: {
                    ...fields?.address,
                    street: addressFields?.street,
                    city: addressFields?.city,
                    county: addressFields?.county,
                    postalCode: addressFields?.postalCode,
                    countryCodeId: addressFields?.countryCodeId,
                    stateCodeId: addressFields?.stateCodeId,
                },
            },
        };

        try {
            await updateBuilding(body);
            handleClose();
        } catch (e) {
            return onError(e.message);
        }
    };

    const handleClose = () => {
        history.push('/administration/organizations/buildings');
    };

    return {
        fields,
        addressFields,
        isLoadingBuilding,
        handleCreateBuilding,
        handleUpdateBuilding,
        handleFieldChange,
        handleAddressFieldChange,
        handleClose,
        formMethods,
        addressFormMethods,
        countryOptions,
        stateSelectOptions,
        loading,
    };
};

export default useBuildingsForm;
