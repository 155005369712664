import React, { useState } from 'react';
import { AgGridColumn } from 'ag-grid-react';
import 'ag-grid-enterprise';
import { isNilOrEmpty, StringUtils } from '../../utils/objectUtils';
import Grid from '../Grids/Grid';
import { GridReadyEvent } from 'ag-grid-community';
import { Step } from '../../types/BatchTicket.types';
let moment = require('moment');

interface BatchTicketStepTableProps {
    step: Step;
    warehouseId: string;
}

const BatchTicketStepTable = ({
    step,
    warehouseId,
}: BatchTicketStepTableProps) => {
    const [batchTicket] = useState(step.batchTicket);
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    let nonExpandableRows = [];

    const instructionsAndComponentsRowStyle = (row: any) => {
        let style = null;
        if (row.data.quantityAvailable < 0) {
            style = { color: 'red' };
        }

        return style;
    };

    const instructionsAndComponentsColumns = {
        detailGridOptions: {
            getRowStyle: instructionsAndComponentsRowStyle,
            columnDefs: [
                { field: 'LineNumber', headerName: 'Seq', width: 80 },
                {
                    field: 'LineType',
                    headerName: 'Type',
                    width: 150,
                    filter: 'agSetColumnFilter',
                },
                { field: 'Code', headerName: 'Product #', width: 170 },
                {
                    field: 'productName',
                    headerName: 'Product Name',
                    width: 170,
                },
                { field: 'amt', headerName: 'Amount', width: 120 },
                { field: 'EnteredUM', headerName: 'U/M', width: 80 },
                { field: 'ProductUM', headerName: 'Cvt Amount', width: 100 },
                { field: 'quantityOnHand', headerName: 'On-Hand', width: 120 },
                { field: 'Available', headerName: 'Available', width: 120 },
                { field: 'description', headerName: 'Instruction', width: 170 },
            ],
            defaultColDef: {
                resizable: true,
                sortable: false,
                unSortIcon: false,
                filter: false,
                wrapText: true,
                suppressMenu: true,
                floatingFilter: true,
            },
        },
        getDetailRowData: (params: any) => {
            params.successCallback(params.data.componentsAndInstructions);
        },
    };

    const getInstructionsAndComponentsTableData = (
        componentsAndInstructions: any
    ) => {
        return componentsAndInstructions.map((item: any) => {
            const quantityOnHand = StringUtils.equals(
                item.LineType,
                'Component'
            )
                ? item.QuantityOnHand
                    ? Number.parseFloat(item.QuantityOnHand).toFixed(2)
                    : Number.parseFloat('0.0')
                : null;
            const quantityAvailable = StringUtils.equals(
                item.LineType,
                'Component'
            )
                ? item.Available
                    ? Number.parseFloat(item.Available).toFixed(2)
                    : Number.parseFloat('0.0')
                : null;
            const amount = StringUtils.equals(item.LineType, 'Component')
                ? item.Amount
                : null;
            return {
                ...item,
                productName: item.Description ? item.Description : null,
                quantityOnHand: quantityOnHand,
                quantityAvailable: quantityAvailable,
                description: item.Description,
                amt: amount,
            };
        });
    };

    const onGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);

        params.api.sizeColumnsToFit();
    };

    const batchTicketStepsTableData = batchTicket
        ? batchTicket.Steps.map((step: Step) => {
              if (isNilOrEmpty(step.ComponentsAndInstructions)) {
                  nonExpandableRows.push(step.StepNumber);
              }
              return {
                  ...step,
                  startDateTime: moment(step.StartDateTime).isValid()
                      ? moment(step.StartDateTime).format('L hh:mm a')
                      : null,
                  endDateTime: moment(step.EndDateTime).isValid()
                      ? moment(step.EndDateTime).format('L hh:mm a')
                      : null,
                  componentsAndInstructions: step.ComponentsAndInstructions
                      ? getInstructionsAndComponentsTableData(
                            step.ComponentsAndInstructions
                        )
                      : {},
              };
          })
        : null;

    const batchTicketStepsRowStyle = (row: any) => {
        let style = null;
        if (row.data.StepNumber === step.StepNumber) {
            style = { backgroundColor: '#FFFF99' };
        }

        return style;
    };

    return batchTicket ? (
        <Grid
            pagination={false}
            rowData={batchTicketStepsTableData}
            domLayout={'autoHeight'}
            onGridReady={onGridReady}
            masterDetail={true}
            detailCellRendererParams={instructionsAndComponentsColumns}
            getRowStyle={batchTicketStepsRowStyle}
            defaultColDef={{
                resizable: true,
                sortable: true,
                unSortIcon: true,
                filter: true,
                wrapText: true,
                suppressMenu: true,
            }}>
            <AgGridColumn
                minWidth={150}
                field="StepNumber"
                headerName="StepNumber"
                cellRenderer="agGroupCellRenderer"
            />
            <AgGridColumn
                minWidth={150}
                field="Description"
                headerName="Description"
            />
            <AgGridColumn
                minWidth={150}
                field="startDateTime"
                headerName="Start Date/Time"
            />
            <AgGridColumn
                minWidth={150}
                field="endDateTime"
                headerName="End Date/Time"
            />
        </Grid>
    ) : null;
};

export default BatchTicketStepTable;
