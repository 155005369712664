import { RootStateOrAny, useSelector, useDispatch } from 'react-redux';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { GridReadyEvent } from 'ag-grid-community';
import {
    organizationsApi,
    useGetSubscribersQuery,
    useGetUserQuery,
    User,
    useUpdateUserMutation,
} from '../../../services/organizations/organizations.service';
import LaunchIcon from '@mui/icons-material/Launch';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import { Tooltip, IconButton } from '@mui/material';
import { handleUpdateUserSubscriber } from '../../../store/user';
import { BaseUpdateEntityArgs } from '../../../services/serviceInterfaces';
import { skipToken } from '@reduxjs/toolkit/query';

const useSubscribersGrid = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector((state: RootStateOrAny) => state.user);
    const [gridApi, setGridApi] = useState(null);
    const [showToast, setShowToast] = useState(false);
    const [subscriberName, setSubscriberName] = useState('');
    const { data: subscribers, isLoading: isLoading } =
        useGetSubscribersQuery();

    const [updateUser] = useUpdateUserMutation();
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const { data: userData, isLoading: isLoadingUser } = useGetUserQuery(
        user.userId
            ? {
                  userId: user.userId,
                  full: true,
              }
            : skipToken
    );
    const onGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    };

    const redirectToForm = (row: any) => {
        if (row.id) {
            history.push(`/administration/organizations/subscribers/${row.id}`);
        } else {
            history.push('/administration/organizations/subscribers/new');
        }
    };

    const redirectToBusinessEntities = (subscriberId: any, event: any) => {
        event.stopPropagation();
        history.push(
            `/administration/organizations/business-entities?subscriberIdFilter=${subscriberId}`
        );
    };

    const handleSwitchSubscriber = async (subscriber: any, event: any) => {
        event.stopPropagation();
        if (!userData) {
            return;
        }

        // get the list of business entities based on subscriber id
        const businessEnts: any = await dispatch(
            organizationsApi.endpoints.getBusinessEntities.initiate(
                subscriber.id
            )
        );

        const { data } = businessEnts;
        // if the subsriberid has business entities
        // update the user with the first one in the list
        if (data.length > 0) {
            const body: BaseUpdateEntityArgs<User> = {
                id: userData.id as unknown as number,
                postBody: {
                    ...userData,
                    businessEntityId: data[0].id,
                    activeWebBusinessEntityId: data[0].id,
                },
            };

            updateUser(body);
        }
        await dispatch(
            organizationsApi.util.invalidateTags(['BusinessEntities'])
        );
        // update the redux store with the updated selected subscriber
        dispatch(handleUpdateUserSubscriber(subscriber, data[0]));
        setSubscriberName(subscriber.name || '');
        setShowToast(true);
    };

    const viewEntitiesColumn = (row: any) => {
        return (
            <Tooltip title="View Business Entities" placement="top">
                <IconButton
                    size="small"
                    color="primary"
                    ref={(ref) => {
                        if (!ref) {
                            return;
                        }

                        ref.onclick = (event) => {
                            redirectToBusinessEntities(row.data.id, event);
                        };
                    }}>
                    <LaunchIcon />
                </IconButton>
            </Tooltip>
        );
    };

    const switchSubscriberColumn = (row: any) => {
        return (
            <Tooltip title="Switch To Subscriber" placement="top">
                <IconButton
                    size="small"
                    color="primary"
                    ref={(ref) => {
                        if (!ref) {
                            return;
                        }

                        ref.onclick = (event) => {
                            handleSwitchSubscriber(row.data, event);
                        };
                    }}>
                    <SyncAltIcon />
                </IconButton>
            </Tooltip>
        );
    };

    return {
        user,
        onGridReady,
        subscribers,
        isLoading,
        isLoadingUser,
        redirectToForm,
        viewEntitiesColumn,
        switchSubscriberColumn,
        showToast,
        setShowToast,
        subscriberName,
    };
};

export default useSubscribersGrid;
