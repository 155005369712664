import {
    configureStore,
    combineReducers,
    Middleware,
    Action,
} from '@reduxjs/toolkit';
import user from './user';
import scheduler from './scheduler';
import thunk from 'redux-thunk';
import warehouses from './warehouses';
import batchTickets from './batchTickets';
import workcenters from './workcenters';
import equipment from './equipment';
import pricingAnalysisTool from './pricingAnalysisTool';
import grids from './grids';
import formulation from './formulation';
import { usersAPI } from '../services/users/users.service';
import { permissionsAndPreferencesAPI } from '../services/permissionsAndPreferences/permissionsAndPreferences.service';
import { featureFlagAPI } from '../services/featureFlag/featureFlag.service';
import { appStreamingAPI } from '../services/appStreaming/appStreaming.service';
import { apAutomationAPI } from '../services/apAutomation/apAutomation.service';
import { fixedAssetsAPI } from '../services/fixedAssets/fixedAssets.service';
import { accountingAPI } from '../services/accounting/accounting.service';
import { organizationsApi } from '../services/organizations/organizations.service';

/* istanbul ignore file */
const reducer = combineReducers({
    [usersAPI.reducerPath]: usersAPI.reducer,
    [featureFlagAPI.reducerPath]: featureFlagAPI.reducer,
    [appStreamingAPI.reducerPath]: appStreamingAPI.reducer,
    [permissionsAndPreferencesAPI.reducerPath]:
        permissionsAndPreferencesAPI.reducer,
    [apAutomationAPI.reducerPath]: apAutomationAPI.reducer,
    [fixedAssetsAPI.reducerPath]: fixedAssetsAPI.reducer,
    [accountingAPI.reducerPath]: accountingAPI.reducer,
    [organizationsApi.reducerPath]: organizationsApi.reducer,
    user,
    scheduler,
    warehouses,
    batchTickets,
    workcenters,
    equipment,
    pricingAnalysisTool,
    grids,
    formulation,
});

const rootReducer = (state: any, action: Action) => {
    if (action.type === 'user/logoutSuccess') {
        state = undefined;
    }
    return reducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: ['usersApi/executeQuery/fulfilled'],
                ignoredPaths: ['usersApi'],
            },
        })
            .concat(
                usersAPI.middleware as Middleware<
                    (action: Action<'specialAction'>) => number,
                    RootState
                >,
                featureFlagAPI.middleware as Middleware<
                    (action: Action<'specialAction'>) => number,
                    RootState
                >,
                permissionsAndPreferencesAPI.middleware as Middleware<
                    (action: Action<'specialAction'>) => number,
                    RootState
                >,
                apAutomationAPI.middleware as Middleware<
                    (action: Action<'specialAction'>) => number,
                    RootState
                >,
                appStreamingAPI.middleware as unknown as Middleware<
                    (action: Action<'specialAction'>) => number,
                    RootState
                >,
                fixedAssetsAPI.middleware as Middleware<
                    (action: Action<'specialAction'>) => number,
                    RootState
                >,
                accountingAPI.middleware as Middleware<
                    (action: Action<'specialAction'>) => number,
                    RootState
                >,
                organizationsApi.middleware as Middleware<
                    (action: Action<'specialAction'>) => number,
                    RootState
                >
            )
            .prepend(thunk),
});

export type AppDispatch = typeof store.dispatch;

export default store;
