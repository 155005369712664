import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useDeleteApprovalWorkflowMutation } from '../../../../services/apAutomation/apAutomation.service';
import { Popover, Typography } from '@mui/material';
import LoaderButton from '../../../../components/LoaderButton';
import { RootState } from '../../../../store';

const useDeleteCellRenderer = ({ data }: any) => {
    const user = useSelector((state: RootState) => state.user);
    const [deleteApprovalWorkflow] = useDeleteApprovalWorkflowMutation();
    const [showOverlay, setShowOverlay] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null
    );

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setShowOverlay(true);
    };

    const popover = (
        <Popover id="popover-basic" open={showOverlay} anchorEl={anchorEl}>
            <Typography sx={{ p: 2 }}>
                Do you want to remove this approval workflow?
            </Typography>
            <LoaderButton
                isLoading={null}
                variant="danger"
                data-testid="removeConfirmationBtn"
                onClick={() => {
                    deleteApprovalWorkflow(data.workflowId);
                    setShowOverlay(false);
                }}>
                Yes
            </LoaderButton>{' '}
            <LoaderButton
                isLoading={null}
                variant="secondary"
                data-testid="cancelRemoveBtn"
                onClick={() => setShowOverlay(false)}>
                No
            </LoaderButton>
        </Popover>
    );

    return { user, handleClick, popover };
};

export default useDeleteCellRenderer;
