import React from 'react';
import {
    useCreateContractTypeMutation,
    useDeleteContractTypeMutation,
    useGetContractTypeQuery,
    useUpdateContractTypeMutation,
} from '../../../services/accounting/accounting.service';
import { skipToken } from '@reduxjs/toolkit/query';
import { ContractType } from '../../../types/Accounting.types';
import useBaseForm from '../../Form/hooks/useBaseForm';

const UseContractTypeForm = (id: string) => {
    const { data: activeContractType, isLoading: isLoadingContractType } =
        useGetContractTypeQuery(id ? id : skipToken);
    const [createContractType] = useCreateContractTypeMutation();
    const [updateContractType] = useUpdateContractTypeMutation();
    const [deleteContractType] = useDeleteContractTypeMutation();

    const blankContractType: ContractType = {
        code: '',
        description: '',
        sortOrder: null,
    };

    const {
        fields,
        handleFieldChange,
        formMethods,
        toastProps,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
    } = useBaseForm({
        closePath: '/accounting/contractType',
        blankEntity: blankContractType,
        activeEntity: activeContractType,
        createEntity: () => {
            return createContractType({ postBody: fields });
        },
        updateEntity: () => {
            return updateContractType({
                id: id as unknown as number,
                postBody: fields,
            });
        },
        deleteEntity: () => {
            return deleteContractType(id);
        },
    });

    return {
        fields,
        handleFieldChange,
        formMethods,
        toastProps,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        isLoadingContractType,
    };
};

export default UseContractTypeForm;
