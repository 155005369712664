import React from 'react';
import { Card, CardContent, CardHeader, Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import useFixedAssetTypeForm from '../hooks/useFixedAssetTypeForm';
import DatacorLogoSpinner from '../../../components/DatacorLogoSpinner/DatacorLogoSpinner';
import FormContainer from '../../../components/Form/FormContainer/FormContainer';
import FixedAssetsFormHeader from '../../../components/Form/FormContainer/FormHeader';
import FormPartial from '../../../components/Form/FormContainer/FormPartial';
import TextInput from '../../../components/Form/FormInputs/TextInput/TextInput';
import NumericInput from '../../../components/Form/FormInputs/NumericInput/NumericInput';
import SelectInput from '../../../components/Form/FormInputs/SelectInput/SelectInput';
import ToastContainer from '../../../components/Toast/ToastContainer';

interface ParamTypes {
    typeId: string;
}

const FixedAssetTypeForm = () => {
    const { typeId } = useParams<ParamTypes>();
    const {
        fields,
        handleFieldChange,
        formMethods,
        toastProps,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        isLoadingType,
        isLoadingDepreciationMethods,
        isLoadingAccountDropdowns,
        depreciationMethodOptions,
        recoveryTermOptions,
        accountDropdowns,
        onDepreciationMethodChanged,
        accountsRequired,
    } = useFixedAssetTypeForm(typeId);

    return (
        <Card>
            <CardHeader title="Fixed Asset Type" />

            {isLoadingType ? (
                <DatacorLogoSpinner />
            ) : (
                <CardContent>
                    <ToastContainer {...toastProps} show={!!toastProps?.show} />

                    <FormContainer
                        headerTitle={`Primary Information`}
                        onSubmit={() => formMethods.setIsFormSubmitted(true)}
                        headerComponent={
                            <FixedAssetsFormHeader
                                showOnDelete={!!typeId}
                                showOnSave={true}
                                onSave={() => {
                                    if (!typeId) {
                                        onCreate();
                                    } else {
                                        onUpdate();
                                    }
                                }}
                                onDelete={() => {
                                    onDelete();
                                }}
                                onCancel={closeForm}
                                isEdit={!!typeId}
                            />
                        }>
                        <FormPartial>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextInput
                                        fullWidth
                                        label="Code"
                                        id="code"
                                        value={fields.code}
                                        onChange={handleFieldChange}
                                        formMethods={formMethods}
                                        required={true}
                                        maxLength={30}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <NumericInput
                                        fullWidth
                                        label="Sort Order"
                                        id="sortOrder"
                                        value={fields.sortOrder}
                                        onChange={handleFieldChange}
                                        formMethods={formMethods}
                                        required={true}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextInput
                                        fullWidth
                                        label="Description"
                                        id="description"
                                        value={fields.description}
                                        onChange={handleFieldChange}
                                        formMethods={formMethods}
                                        maxLength={300}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <NumericInput
                                        fullWidth
                                        label="Recovery Term"
                                        id="recoveryTerm"
                                        value={fields.recoveryTerm}
                                        onChange={handleFieldChange}
                                        formMethods={formMethods}
                                        required={true}
                                        greaterThan={1}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <SelectInput
                                        fullWidth
                                        label="Recovery Unit"
                                        id="recoveryUnit"
                                        value={fields.recoveryUnit}
                                        onChange={handleFieldChange}
                                        options={recoveryTermOptions}
                                        formMethods={formMethods}
                                        required={true}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <SelectInput
                                        fullWidth
                                        label="Depreciation Method"
                                        id="depreciationMethodId"
                                        value={fields.depreciationMethodId}
                                        onChange={onDepreciationMethodChanged}
                                        options={depreciationMethodOptions}
                                        disabled={isLoadingDepreciationMethods}
                                        formMethods={formMethods}
                                        required={true}
                                    />
                                </Grid>
                            </Grid>
                        </FormPartial>
                        <FormPartial
                            title="Accounts"
                            isVisible={accountsRequired}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <SelectInput
                                        fullWidth
                                        label="Depreciation Expense Account"
                                        id="depreciationExpenseAccountId"
                                        formMethods={formMethods}
                                        value={
                                            fields.depreciationExpenseAccountId
                                        }
                                        onChange={handleFieldChange}
                                        options={
                                            accountDropdowns?.depreciationExpenseAccounts ||
                                            []
                                        }
                                        disabled={isLoadingAccountDropdowns}
                                        required={accountsRequired}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <SelectInput
                                        fullWidth
                                        label="Accumulated Depreciation Account"
                                        id="accumulatedDeprecationAccountId"
                                        formMethods={formMethods}
                                        value={
                                            fields.accumulatedDeprecationAccountId
                                        }
                                        onChange={handleFieldChange}
                                        options={
                                            accountDropdowns?.accumulateDepreciationAccounts ||
                                            []
                                        }
                                        disabled={isLoadingAccountDropdowns}
                                        required={accountsRequired}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <SelectInput
                                        fullWidth
                                        label="Asset Account"
                                        id="assetAccountId"
                                        formMethods={formMethods}
                                        value={fields.assetAccountId}
                                        onChange={handleFieldChange}
                                        options={
                                            accountDropdowns?.assetAccounts ||
                                            []
                                        }
                                        disabled={isLoadingAccountDropdowns}
                                        required={accountsRequired}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <SelectInput
                                        fullWidth
                                        label="Asset Control Account"
                                        id="assetControlAccountId"
                                        formMethods={formMethods}
                                        value={fields.assetControlAccountId}
                                        onChange={handleFieldChange}
                                        options={
                                            accountDropdowns?.assetControlAccounts ||
                                            []
                                        }
                                        disabled={isLoadingAccountDropdowns}
                                        required={accountsRequired}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <SelectInput
                                        fullWidth
                                        label="Disposal Gain Account"
                                        id="disposalGainAccountId"
                                        formMethods={formMethods}
                                        value={fields.disposalGainAccountId}
                                        onChange={handleFieldChange}
                                        options={
                                            accountDropdowns?.disposalGainAccounts ||
                                            []
                                        }
                                        disabled={isLoadingAccountDropdowns}
                                        required={accountsRequired}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <SelectInput
                                        fullWidth
                                        label="Disposal Loss Account"
                                        id="disposalLossAccountId"
                                        formMethods={formMethods}
                                        value={fields.disposalLossAccountId}
                                        onChange={handleFieldChange}
                                        options={
                                            accountDropdowns?.disposalLossAccounts ||
                                            []
                                        }
                                        disabled={isLoadingAccountDropdowns}
                                        required={accountsRequired}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <SelectInput
                                        fullWidth
                                        label="Disposal Settlement Account"
                                        id="disposalSettlementAccountId"
                                        formMethods={formMethods}
                                        value={
                                            fields.disposalSettlementAccountId
                                        }
                                        onChange={handleFieldChange}
                                        options={
                                            accountDropdowns?.disposalSettlementAccounts ||
                                            []
                                        }
                                        disabled={isLoadingAccountDropdowns}
                                        required={accountsRequired}
                                    />
                                </Grid>
                            </Grid>
                        </FormPartial>
                    </FormContainer>
                </CardContent>
            )}
        </Card>
    );
};

export default FixedAssetTypeForm;
