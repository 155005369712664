import React from 'react';
import './Home.css';
import { Link, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import datacorLogo from '../libs/images/Datacor_Products_Logo_Selected_Outlined_RGB_ERP_Light.png';
import { Button, Card } from '@mui/material';
import { RootState } from '../store';

const Home = () => {
    const user = useSelector((state: RootState) => state.user);

    const renderLander = () => {
        return (
            <Card className="lander">
                <img
                    src={datacorLogo}
                    alt="Logo"
                    style={{ height: '80%', width: '80%' }}
                />
                <div>
                    {/*@ts-ignore*/}
                    <Button
                        component={Link}
                        to="/login"
                        style={{ color: 'white', textDecoration: 'none' }}
                        variant="contained"
                        color="primary">
                        Login
                    </Button>
                </div>
            </Card>
        );
    };

    const redirect = () => {
        return <Redirect to="/myApps" />;
    };

    return (
        <div className="Home">
            {user.isSignedIn ? redirect() : renderLander()}
        </div>
    );
};

export default Home;
