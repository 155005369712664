import React from 'react';
import LoaderButton from '../../../components/LoaderButton';
import TreeView from '../../../components/TreeView/TreeView';
import ToastContainer from '../../../components/Toast/ToastContainer';
import './FeatureFlags.css';
import DatacorLogoSpinner from '../../../components/DatacorLogoSpinner/DatacorLogoSpinner';
import { Card, CardContent, CardActions, CardHeader } from '@mui/material';
import useFeatureFlags from '../hooks/useFeatureFlags';

const FeatureFlags = () => {
    const {
        isLoading,
        treeData,
        onCheckChange,
        dataSaved,
        toggleDataSaved,
        canEditFeatureFlags,
        updateFeatureFlags,
        isUnchanged,
    } = useFeatureFlags();
    return !isLoading ? (
        <Card sx={{ width: '50%', margin: 'auto' }}>
            <CardHeader title="Feature Flags" />
            <CardContent>
                <div className="treeViewRow">
                    <TreeView
                        caption="Features"
                        dataSource={treeData}
                        onCheckChange={onCheckChange}
                        data-testid="featuresTree"
                    />
                </div>

                <ToastContainer
                    show={dataSaved}
                    close={toggleDataSaved}
                    bodyText="You'll need to log out and log in again for these changes to take effect."
                />
            </CardContent>
            <CardActions>
                {canEditFeatureFlags ? (
                    <LoaderButton
                        isLoading={null}
                        variant="contained"
                        type="submit"
                        size="lg"
                        color="primary"
                        onClick={() => updateFeatureFlags()}
                        disabled={isUnchanged}>
                        Update
                    </LoaderButton>
                ) : null}
            </CardActions>
        </Card>
    ) : (
        <DatacorLogoSpinner />
    );
};

export default FeatureFlags;
