import React, { useState, useEffect } from 'react';
import { TreeSelect } from 'mui-tree-select';
import TextInput from '../FormInputs/TextInput/TextInput';
import { FormMethods } from '../../../libs/hooksLib';

export interface ExpandableDropdownProps {
    valueSelected: (value: number | string) => void;
    defaultValue?: number | string;
    items: any[];
    idKey: string;
    labelKey: string;
    label: string;
    formMethods?: FormMethods;
    id?: string;
    required?: boolean;
    size?: 'small' | 'medium';
    disabled?: boolean;
    disableClearable?: boolean;
}

export class ExpandableTreeNode {
    id: number | string;
    label: string;
    parentId: number;
    childrenIds: number[];

    constructor(
        id: number | string,
        label: string,
        parentId: number,
        childrenIds: number[] = null
    ) {
        this.id = id;
        this.label = label;
        this.parentId = parentId;
        this.childrenIds = childrenIds;
    }

    toString() {
        return this.label;
    }

    getValue() {
        return this.id;
    }

    isEqual(to: ExpandableTreeNode) {
        return to.id === this.id;
    }

    getParent(nodeMap: Map<number, ExpandableTreeNode>) {
        return nodeMap.get(this.parentId);
    }

    getChildren(nodeMap: Map<number, ExpandableTreeNode>) {
        const children: ExpandableTreeNode[] = [];

        this.childrenIds.forEach((childrenId) => {
            children.push(nodeMap.get(childrenId));
        });

        return children.length !== 0 ? children : null;
    }
}

const ExpandableDropdown = (props: ExpandableDropdownProps) => {
    const [value, setValue] = useState(null);
    const [selectedValue, setSelectedValue] = useState('');
    const [nodes, setNodes] = useState(new Map());
    const [rootNodes, setRootNodes] = useState([]);
    let nodeList = new Map();
    let rootNodesList: ExpandableTreeNode[] = [];

    const getChildren = (node: any) => {
        return node === null ? rootNodes : node.getChildren(nodes);
    };

    const getParent = (node: any) => {
        return node.getParent(nodes);
    };

    const renderInput = (params: any) => {
        params.inputProps.value = selectedValue;
        return (
            <TextInput
                {...params}
                value={selectedValue}
                label={props.label}
                formMethods={props.formMethods}
                id={props.id}
                required={props.required}
            />
        );
    };

    const branchSelected = (event: any, node: any) => {
        if (node !== null) {
            setSelectedValue(node.label);
            setValue(node);
            props.valueSelected(node.id);
        } else {
            setSelectedValue('');
            setValue(null);
        }
    };

    const valueSelected = (node: any) => {
        if (node === null) {
            setValue(null);
            setSelectedValue('');
            props.valueSelected(null);
            return;
        }

        setValue(node);
        setSelectedValue(node.label);
        props.valueSelected(node.id);
    };

    useEffect(() => {
        if (props.idKey && props.labelKey && props.items) {
            const recursiveMap: any = (item: any, parentId: number) => {
                const itemNode = new ExpandableTreeNode(
                    item[props.idKey],
                    item[props.labelKey],
                    parentId,
                    item.children?.map((child: any) => child[props.idKey])
                );

                if (parentId === null) {
                    rootNodesList.push(itemNode);
                }
                nodeList.set(itemNode.id, itemNode);
                item.children?.map((child: any) =>
                    recursiveMap(child, itemNode.id)
                );
            };

            nodeList = new Map();
            rootNodesList = [];

            props.items.forEach((item: any) => recursiveMap(item, null));
            setRootNodes(rootNodesList);
            setNodes(new Map(nodeList));
        }
    }, [props.items, props.idKey, props.labelKey]);

    useEffect(() => {
        if (props.defaultValue && nodes) {
            const node = nodes.get(props.defaultValue);
            setValue(node);
            setSelectedValue(node ? node.label : '');
        }
    }, [props.defaultValue, nodes]);

    return (
        <TreeSelect
            disabled={props.disabled}
            disableClearable={props.disableClearable}
            pathIcon={<></>}
            getChildren={getChildren}
            size={props.size}
            getParent={getParent}
            renderInput={renderInput}
            value={value}
            onBranchChange={(event, node) => branchSelected(event, node)}
            onChange={(event, node) => valueSelected(node)}
            isOptionEqualToValue={(option, nodeValue) => {
                return (option as ExpandableTreeNode).isEqual(
                    nodeValue as ExpandableTreeNode
                );
            }}
        />
    );
};

export default ExpandableDropdown;
