import SummaryHeading from './SummaryHeading';
import React, { useState } from 'react';
import InvoiceCountGraph from './InvoiceCountGraph';
import AverageDaysToProcessGraph from './AverageDaysToProcessGraph';
import DatacorLogoSpinner from '../../../components/DatacorLogoSpinner/DatacorLogoSpinner';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import TextField from '@mui/material/TextField';
import { useGetAnalyticsForTimePeriodQuery } from '../../../services/apAutomation/apAutomation.service';

const ApAutomation = () => {
    let initialStartDate = new Date();
    initialStartDate.setMonth(initialStartDate.getMonth() - 1);
    let [startDate, setStartDate] = useState(initialStartDate.getTime());
    let [endDate, setEndDate] = useState(Date.now());
    const args = { startDate: startDate, endDate: endDate };
    const { data: analyticData, isLoading: isLoadingAnalytics } =
        useGetAnalyticsForTimePeriodQuery(args);

    return !isLoadingAnalytics ? (
        <>
            <br />
            <div className="analytics-datepicker-group">
                <DesktopDatePicker
                    label="Start Date (MM/dd/yyyy)"
                    inputFormat="MM/DD/yyyy"
                    value={new Date(startDate)}
                    onChange={(date: moment.Moment) =>
                        setStartDate(date.toDate().getTime())
                    }
                    renderInput={(params) => <TextField {...params} />}
                />
                <DesktopDatePicker
                    label="End Date (MM/dd/yyyy)"
                    inputFormat="MM/DD/yyyy"
                    value={new Date(endDate)}
                    onChange={(date: moment.Moment) =>
                        setEndDate(date.toDate().getTime())
                    }
                    renderInput={(params) => <TextField {...params} />}
                />
            </div>
            <SummaryHeading analyticData={analyticData} />
            <br />
            <div className="analytics-ap-automation-graphs-line-one">
                <InvoiceCountGraph analyticData={analyticData} />
                <AverageDaysToProcessGraph analyticData={analyticData} />
            </div>
        </>
    ) : (
        <DatacorLogoSpinner />
    );
};

export default ApAutomation;
