import React from 'react';
import { useHistory } from 'react-router-dom';

const useCasGrid = () => {
    const history = useHistory();

    const redirectToForm = () => {
        history.push('/formulation/casMaster/new');
    };

    return {
        redirectToForm,
    };
};

export default useCasGrid;
