import { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import {
    useCreateStreamingSessionMutation,
    useGetAppStreamingUrlQuery,
} from '../../../services/appStreaming/appStreaming.service';
import { AppStream } from '../../../types/AppStreaming.types';
import { onError } from '../../../libs/errorLib';
import { skipToken } from '@reduxjs/toolkit/query';

const useAppStreaming = () => {
    const [userEmail, setUserEmail] = useState(null);
    const [streamingAppUrls, setStreamingAppUrls] = useState([]);
    const { data: appStreamData, isLoading: isLoadingAppStream } =
        useGetAppStreamingUrlQuery(userEmail ? userEmail : skipToken);
    const [createStreamingSession] = useCreateStreamingSessionMutation();

    useEffect(() => {
        const handleGetEmail = async () => {
            await getUserEmail();
        };
        handleGetEmail();
    }, []);

    useEffect(() => {
        if (!appStreamData) {
            return;
        }
        const getStreamingAppUrls = async () => {
            try {
                const availableAppStreams = appStreamData?.filter(
                    (appStream: AppStream) =>
                        appStream.StreamingURLResponse !== undefined
                );
                setStreamingAppUrls(availableAppStreams);
            } catch (e) {
                onError(e);
            }
        };

        getStreamingAppUrls();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoadingAppStream, appStreamData]);

    const getUserEmail = async () => {
        const user: any = await Auth.currentSession();
        setUserEmail(user.idToken.payload.email);
    };

    const openTabToStreamApp = async (streamingInfo: AppStream) => {
        const streamingUrl = streamingInfo.StreamingURLResponse.StreamingURL;
        window.open(streamingUrl, '_blank');

        createStreamingSession(streamingInfo);
    };
    return { streamingAppUrls, isLoadingAppStream, openTabToStreamApp };
};
export default useAppStreaming;
