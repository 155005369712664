import React from 'react';
import NumericInput, { NumericInputProps } from '../NumericInput/NumericInput';

export type CurrencyInputProps = {
    currency?: string;
} & NumericInputProps;

const CurrencyInput = (props: CurrencyInputProps) => {
    return <NumericInput {...props} />;
};

export default CurrencyInput;
