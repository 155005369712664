import { useHistory } from 'react-router-dom';
import { useGetAllDepreciationMethodTypesQuery } from '../../../services/fixedAssets/fixedAssets.service';

const UseFixedAssetDepreciationMethodTypeGrid = () => {
    const history = useHistory();

    const { data: typeList, isLoading: isLoadingTypes } =
        useGetAllDepreciationMethodTypesQuery();

    const redirectToForm = (row: any) => {
        if (row.id) {
            history.push(
                `/accounting/fixedAssets/depreciationMethodType/${row.id}`
            );
        } else {
            history.push('/accounting/fixedAssets/depreciationMethodType/new');
        }
    };

    return {
        typeList,
        redirectToForm,
        isLoadingTypes,
    };
};

export default UseFixedAssetDepreciationMethodTypeGrid;
