import React from 'react';
import { AgGridColumn } from 'ag-grid-react';
import Grid from '../../../components/Grids/Grid';
import DatacorLogoSpinner from '../../../components/DatacorLogoSpinner/DatacorLogoSpinner';
import { GridOptions } from '../../../components/Grids/grid.constants';
import UseFixedAssetDepreciationMethodGrid from '../hooks/useFixedAssetDepreciationMethodGrid';

const FixedAssetDepreciationMethodGrid = () => {
    const {
        methodList,
        redirectToForm,
        getStatusFromId,
        getTypeFromId,
        CostCodeColumn,
        isLoadingAny,
    } = UseFixedAssetDepreciationMethodGrid();

    return isLoadingAny ? (
        <DatacorLogoSpinner />
    ) : (
        <Grid
            rowData={methodList}
            gridClass="full-width-grid"
            displayToolbar={true}
            displayCreateNewButton={true}
            onCreateNew={redirectToForm}
            onRowClicked={({ data }) => redirectToForm(data)}
            frameworkComponents={{
                costCodeColumn: CostCodeColumn,
            }}
            defaultColDef={GridOptions.sortFilterAndWrapColumns}>
            <AgGridColumn
                minWidth={100}
                maxWidth={125}
                field="sortOrder"
                headerName="Sort Order"
            />
            <AgGridColumn
                minWidth={150}
                field="code"
                headerName="Depreciation Method Code"
            />
            <AgGridColumn
                minWidth={200}
                field="name"
                headerName="Depreciation Method Name"
            />
            <AgGridColumn
                minWidth={250}
                field="description"
                headerName="Description"
            />
            <AgGridColumn
                minWidth={150}
                field="depreciationMethodStatusId"
                headerName="Depreciation Method Status"
                valueFormatter={(val) => getStatusFromId(val.value)}
                filterParams={() => {
                    return {
                        valueFormatter: (params: any) => {
                            return getStatusFromId(params.value);
                        },
                    };
                }}
            />
            <AgGridColumn
                minWidth={150}
                field="depreciationMethodTypeId"
                headerName="Depreciation Method Type"
                valueFormatter={(val) => getTypeFromId(val.value)}
                filterParams={() => {
                    return {
                        valueFormatter: (params: any) => {
                            return getTypeFromId(params.value);
                        },
                    };
                }}
            />
            <AgGridColumn
                minWidth={150}
                headerName="Cost Code(s)"
                cellRenderer="costCodeColumn"
                cellStyle={{
                    overflow: 'visible',
                    whiteSpace: 'normal',
                    height: 'auto',
                }}
                autoHeight={true}
            />
        </Grid>
    );
};

export default FixedAssetDepreciationMethodGrid;
