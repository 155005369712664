import React from 'react';
import LoaderButton from '../../components/LoaderButton';
import { Link } from 'react-router-dom';
import './Settings.css';
import { useSelector, useDispatch } from 'react-redux';
import { PermissionsUtil } from '../../utils/permissions/PermissionsUtil';
import { isFeatureFlagEnabled } from '../../utils/FeatureFlag/FeatureFlagUtil';
import { PERMISSIONS } from '../../constants/permissions/permissions.constants';
import { Button } from '@mui/material';
import { logout } from '../../store/user';
import { RootState } from '../../store';

// Would be a good place for user preferences
const Settings = () => {
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.user);

    const handleLogout = () => {
        dispatch(logout());
    };
    return (
        <div className="Settings">
            <Link to="/settings/email">
                <LoaderButton
                    isLoading={null}
                    size="lg"
                    data-testid="changeEmailBtn">
                    Change Email
                </LoaderButton>
            </Link>
            <Link to="/settings/password">
                <LoaderButton
                    isLoading={null}
                    size="lg"
                    data-testid="changePasswordBtn">
                    Change Password
                </LoaderButton>
            </Link>
            {isFeatureFlagEnabled(
                user.enabledFeatureFlags,
                'settings.roles'
            ) && (
                <Link to="/settings/roles">
                    <LoaderButton
                        isLoading={null}
                        size="lg"
                        data-testid="accessRolesBtn">
                        Access Roles
                    </LoaderButton>
                </Link>
            )}

            {user.isDatacorAdmin &&
                PermissionsUtil.isPermissionEnabled(
                    user.permissions,
                    PERMISSIONS.SETTINGS.FEATURE_FLAGS.VIEW
                ) && (
                    <Link to="/settings/featureFlags">
                        <LoaderButton
                            isLoading={null}
                            size="lg"
                            data-testid="featureFlagBtn">
                            Feature Flags
                        </LoaderButton>
                    </Link>
                )}
            <Button onClick={handleLogout}>Logout</Button>
        </div>
    );
};

export default Settings;
