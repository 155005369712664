import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useUploadDocumentFromWebMutation } from '../../services/apAutomation/apAutomation.service';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { IconButton, Snackbar } from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { RootStateOrAny, useSelector } from 'react-redux';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const FileDropper = () => {
    const user = useSelector((state: RootStateOrAny) => state.user);
    const [uploadInvoice] = useUploadDocumentFromWebMutation();
    const [isUploading, setIsUploading] = useState(false);
    const [shouldOpen, setShouldOpen] = useState(false);
    const [numFiles, setNumFiles] = useState(0);
    let pdfArray: any = [];
    const onDrop = useCallback(async (acceptedFiles) => {
        setShouldOpen(true);
        setNumFiles(acceptedFiles.length);
        setIsUploading(true);
        for await (const file of acceptedFiles) {
            const data = await readAsDataURL(file);
            pdfArray.push(data);
        }
        await handleUploadInvoices();
        setIsUploading(false);
    }, []);

    const readAsDataURL = (file: any) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onabort = () => console.log('file reading was aborted');
            reader.onerror = () => console.log('file reading has failed');
            reader.onload = async () => {
                // Do whatever you want with the file contents
                const binaryStr = reader.result;
                resolve(binaryStr);
            };
            reader.onerror = () => reject();
            reader.readAsDataURL(file);
        });
    };

    const handleUploadInvoices = async () => {
        //make sure there are no duplicates
        let uniquePdfs: any = [];
        const pdfsToUpload = pdfArray.filter((ele: any) => {
            const isDuplicate = uniquePdfs.includes(ele);

            if (!isDuplicate) {
                uniquePdfs.push(ele);
                return true;
            }
            return false;
        });

        const args = {
            pdfArray: Array(pdfsToUpload.length).fill('0'),
            user: {
                email: user.signInEmail,
            },
        };
        const uploadUrls: any = await uploadInvoice(args);

        for (const urlObj of uploadUrls.data) {
            const index: number = uploadUrls.data.indexOf(urlObj);
            const parsed = JSON.parse(urlObj);
            let pdfFile = pdfArray[index];
            let binary = window.atob(pdfFile.split(',')[1]);
            let array = [];
            for (let i = 0; i < binary.length; i++) {
                array.push(binary.charCodeAt(i));
            }
            let blobData = new Blob([new Uint8Array(array)], {
                type: 'application/pdf',
            });

            await fetch(parsed.uploadURL, {
                method: 'PUT',
                body: blobData,
                headers: {
                    'Content-Type': 'application/pdf',
                },
            });
        }
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
    });

    return (
        <>
            <Snackbar
                open={shouldOpen}
                autoHideDuration={5000}
                onClose={() => setShouldOpen(false)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}>
                <Alert
                    onClose={() => setShouldOpen(false)}
                    severity="success"
                    sx={{ width: '100%' }}>
                    {numFiles} File(s) Uploaded.
                </Alert>
            </Snackbar>
            <div {...getRootProps()}>
                <input {...getInputProps()} />

                <div className="overlay-grid">
                    <IconButton color="primary" size="large">
                        <FileUploadIcon />
                    </IconButton>
                    {isDragActive && <span>Drop files to upload</span>}
                </div>
            </div>
        </>
    );
};

export default FileDropper;
