import React from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardHeader, Grid } from '@mui/material';
import FormContainer from '../../components/Form/FormContainer/FormContainer';
import useSubscriberForm from './hooks/useSubscriberForm';
import FixedAssetsFormHeader from '../../components/Form/FormContainer/FormHeader';
import TextInput from '../../components/Form/FormInputs/TextInput/TextInput';
import FormPartial from '../../components/Form/FormContainer/FormPartial';
import DatacorLogoSpinner from '../../components/DatacorLogoSpinner/DatacorLogoSpinner';
import AgGrid from '../../components/Grids/Grid';
import { AgGridColumn } from 'ag-grid-react';
import TreeView from '../../components/TreeView/TreeView';

interface ParamTypes {
    id: string;
}

const SubscriberForm = () => {
    const { id } = useParams<ParamTypes>();
    const {
        fields,
        businessEntities,
        handleClose,
        handleFieldChange,
        handleUpdateSubscriber,
        handleCreateSubscriber,
        departmentCodes,
        isDepartmentCodesLoading,
        onGridReady,
        isLoading,
    } = useSubscriberForm(id);

    return (
        <Card>
            <CardHeader title={id ? fields.name : 'Create New Subscriber'} />
            {isLoading ? (
                <DatacorLogoSpinner />
            ) : (
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xl={9} lg={9} md={12} sm={12} xs={12}>
                            <FormContainer
                                headerTitle={`Primary Information`}
                                headerComponent={
                                    <FixedAssetsFormHeader
                                        showOnDelete={false}
                                        showOnSave={true}
                                        onSave={async () => {
                                            if (!id) {
                                                await handleCreateSubscriber();
                                            } else {
                                                await handleUpdateSubscriber();
                                            }
                                        }}
                                        onDelete={handleClose}
                                        onCancel={handleClose}
                                        isEdit={!!id}
                                    />
                                }>
                                <FormPartial>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <TextInput
                                                fullWidth
                                                label="Name"
                                                id="name"
                                                value={fields.name}
                                                onChange={handleFieldChange}
                                                required={true}
                                                maxLength={90}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextInput
                                                fullWidth
                                                label="Description"
                                                id="description"
                                                value={fields.description}
                                                onChange={handleFieldChange}
                                                maxLength={300}
                                            />
                                        </Grid>
                                    </Grid>
                                </FormPartial>
                                <FormPartial title="Department Codes">
                                    {isDepartmentCodesLoading ? (
                                        <DatacorLogoSpinner />
                                    ) : (
                                        <AgGrid
                                            rowData={departmentCodes || []}
                                            onGridReady={onGridReady}
                                            gridClass="full-width-grid">
                                            <AgGridColumn
                                                minWidth={150}
                                                field="name"
                                                headerName="Name"
                                            />
                                            <AgGridColumn
                                                minWidth={150}
                                                field="code"
                                                headerName="Code"
                                            />
                                        </AgGrid>
                                    )}
                                </FormPartial>
                            </FormContainer>
                        </Grid>
                        <Grid item xl={3} lg={3} md={12} sm={12} xs={12}>
                            {businessEntities && (
                                <div style={{ paddingLeft: '30px' }}>
                                    <h3>Business Entities</h3>
                                    <TreeView
                                        dataSource={businessEntities}
                                        businessEntity
                                    />
                                </div>
                            )}
                        </Grid>
                    </Grid>
                </CardContent>
            )}
        </Card>
    );
};

export default SubscriberForm;
