import { AgGridColumn } from 'ag-grid-react';
import React from 'react';
import CreateEditUser from './CreateEditUser';
import { PermissionsUtil } from '../../utils/permissions/PermissionsUtil';
import { PERMISSIONS } from '../../constants/permissions/permissions.constants';
import moment from 'moment';
import { isFeatureFlagEnabled } from '../../utils/FeatureFlag/FeatureFlagUtil';
import SingleSelectGrid from '../../components/Grids/SingleSelectGrid';
import DatacorLogoSpinner from '../../components/DatacorLogoSpinner/DatacorLogoSpinner';
import useUsersGrid from './hooks/useUsersGrid';

const UsersGrid = () => {
    const {
        usersList,
        editUser,
        CompaniesColumn,
        RolesColumn,
        DeleteUserColumn,
        onGridReady,
        handleEditUserModalClose,
        handleCreateUserModalOpen,
        selectedUsername,
        isEdit,
        showCreateEditUserModal,
        isLoadingUsers,
        isLoadingRoles,
        user,
        getRolesForUsers,
    } = useUsersGrid();

    return (
        <>
            {showCreateEditUserModal ? (
                <CreateEditUser
                    show={showCreateEditUserModal}
                    handleClose={handleEditUserModalClose}
                    isEdit={isEdit}
                    username={selectedUsername}
                />
            ) : null}

            {!isLoadingUsers && !isLoadingRoles ? (
                <SingleSelectGrid
                    testId="user-list-grid"
                    rowData={usersList.getAllUsers()}
                    displayCreateNewButton={PermissionsUtil.isPermissionEnabled(
                        user.permissions,
                        PERMISSIONS.USER_PORTAL.USERS.CREATE
                    )}
                    onCreateNew={() => handleCreateUserModalOpen()}
                    displayToolbar={true}
                    immutableData={true}
                    dataKey="email"
                    onGridReady={onGridReady}
                    onRowClicked={
                        PermissionsUtil.isPermissionEnabled(
                            user.permissions,
                            PERMISSIONS.USER_PORTAL.USERS.EDIT
                        )
                            ? editUser
                            : null
                    }
                    frameworkComponents={{
                        deleteUserColumn: DeleteUserColumn,
                        rolesColumn: RolesColumn,
                        companiesColumn: CompaniesColumn,
                    }}
                    defaultColDef={{
                        resizable: true,
                        sortable: true,
                        unSortIcon: true,
                        filter: true,
                        wrapText: true,
                    }}>
                    <AgGridColumn
                        minWidth={100}
                        filter={false}
                        resizable={false}
                        sortable={false}
                        field="deleteUserColumn"
                        headerName=""
                        hide={
                            !PermissionsUtil.isPermissionEnabled(
                                user.permissions,
                                PERMISSIONS.USER_PORTAL.USERS.DELETE
                            )
                        }
                        cellRenderer="deleteUserColumn"
                    />
                    <AgGridColumn
                        minWidth={150}
                        field="firstName"
                        headerName="First Name"
                    />
                    <AgGridColumn
                        minWidth={150}
                        field="lastName"
                        headerName="Last Name"
                    />
                    <AgGridColumn
                        minWidth={250}
                        field="email"
                        headerName="Email"
                        flex={1}
                    />
                    <AgGridColumn
                        minWidth={250}
                        field="companies"
                        headerName="Companies"
                        cellRenderer="companiesColumn"
                        keyCreator={(params) => {
                            return params.value.map((company: any) => {
                                return company.companyID;
                            });
                        }}
                        valueFormatter={(val) => {
                            return val.value
                                .map((company: any) => {
                                    return company.companyID;
                                })
                                .join(', ');
                        }}
                    />
                    <AgGridColumn
                        minWidth={150}
                        field="isDatacorAdmin"
                        valueFormatter={(val) =>
                            val.value ? val.value : false
                        }
                        headerName="Datacor Admin"
                    />
                    <AgGridColumn
                        minWidth={250}
                        field="roles"
                        headerName="Roles"
                        cellRenderer="rolesColumn"
                        keyCreator={(params) => {
                            return params.value.map((role: any) => {
                                return getRolesForUsers(role);
                            });
                        }}
                        valueFormatter={(val) => {
                            return val.value
                                .map((role: any) => {
                                    return getRolesForUsers(role);
                                })
                                .join(', ');
                        }}
                    />
                    {isFeatureFlagEnabled(
                        user.enabledFeatureFlags,
                        'dashboard.nav.apps.apAutomation'
                    ) ? (
                        <AgGridColumn
                            minWidth={250}
                            field="invoiceAutomationCompanyTarget"
                            headerName="AP-Automation Company Invoice Target"
                        />
                    ) : null}
                    <AgGridColumn
                        minWidth={250}
                        field="isAppStreamUser"
                        valueFormatter={(val) =>
                            val.value ? val.value : false
                        }
                        headerName="App Stream User"
                    />
                    <AgGridColumn
                        minWidth={250}
                        field="status"
                        headerName="Status"
                    />
                    <AgGridColumn
                        minWidth={250}
                        field="dateCreated"
                        headerName="Created Date"
                        valueFormatter={(val) =>
                            moment(val.value).isValid()
                                ? moment(val.value).format('MM/DD/YYYY')
                                : ''
                        }
                        filter="agDateColumnFilter"
                    />
                    <AgGridColumn
                        minWidth={250}
                        field="lastUpdateDate"
                        headerName="Updated Date"
                        valueFormatter={(val) =>
                            moment(val.value).isValid()
                                ? moment(val.value).format('MM/DD/YYYY')
                                : ''
                        }
                        filter="agDateColumnFilter"
                    />
                </SingleSelectGrid>
            ) : (
                <DatacorLogoSpinner />
            )}
        </>
    );
};

export default UsersGrid;
