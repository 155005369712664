import React, { ReactNode, useState } from 'react';
import { BsChevronRight, BsChevronDown } from 'react-icons/bs';
import './CollapsiblePanel.css';
import {
    FormGroup,
    FormControlLabel,
    Checkbox,
    IconButton,
    Tooltip,
} from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
import { useHistory } from 'react-router-dom';

interface CollapsiblePanelProps {
    collapsed: any;
    label: any;
    checked?: boolean;
    class: any;
    hasCheckbox: boolean;
    onCheckChange: (param: any) => void;
    value: any;
    children: ReactNode;
    defaultChecked: any;
    hasTooltip?: boolean;
}

const CollapsiblePanel = (props: CollapsiblePanelProps) => {
    const [isCollapsed, setIsCollapsed] = useState(!!props.collapsed);
    let history = useHistory();
    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (props.onCheckChange) {
            const node = {
                label: props.label,
                value: props.value,
                checked: event.target.checked,
            };

            props.onCheckChange(node);
        }
    };

    return (
        <>
            {isCollapsed && (
                <BsChevronRight
                    style={{ paddingRight: '10px' }}
                    onClick={() => setIsCollapsed(!isCollapsed)}
                    className="ic-w mx-1"
                />
            )}
            {!isCollapsed && (
                <BsChevronDown
                    style={{ paddingRight: '10px' }}
                    onClick={() => setIsCollapsed(!isCollapsed)}
                    className="ic-w mx-1"
                />
            )}
            {props.hasTooltip && props.value ? (
                <FormGroup style={{ display: 'inline-block' }}>
                    <FormControlLabel
                        label={props.label}
                        control={
                            <Tooltip
                                title="View Business Entity"
                                placement="left-start">
                                <IconButton
                                    size="small"
                                    color="primary"
                                    ref={(ref) => {
                                        if (!ref) {
                                            return;
                                        }

                                        ref.onclick = (event) => {
                                            history.push(
                                                `/administration/organizations/business-entities/${props.value}`
                                            );
                                        };
                                    }}>
                                    <LaunchIcon />
                                </IconButton>
                            </Tooltip>
                        }
                    />
                </FormGroup>
            ) : (
                <>
                    {props.hasCheckbox && props.value ? (
                        <FormGroup>
                            <FormControlLabel
                                label={props.label}
                                control={
                                    <Checkbox
                                        defaultChecked={props.checked}
                                        onChange={(event) => onChange(event)}
                                    />
                                }
                            />
                        </FormGroup>
                    ) : (
                        <label>{props.label}</label>
                    )}
                </>
            )}

            <div
                className={`${props.class} collapse-content ${
                    isCollapsed ? 'collapsed' : 'expanded'
                }`}>
                {props.children}
            </div>
        </>
    );
};

export default CollapsiblePanel;
