import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { GridReadyEvent } from 'ag-grid-community';
import { useGetDepartmentCodesQuery } from '../../../services/organizations/organizations.service';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { skipToken } from '@reduxjs/toolkit/query';

const useDepartmentCodesGrid = () => {
    const user = useSelector((state: RootState) => state.user);
    const history = useHistory();
    const [gridApi, setGridApi] = useState(null);
    const { data: rowData, isLoading: isLoading } = useGetDepartmentCodesQuery(
        user?.subscriber?.id ? user?.subscriber?.id.toString() : skipToken
    );

    const [gridColumnApi, setGridColumnApi] = useState(null);

    const onGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    };

    const redirectToForm = (row: any) => {
        if (row.id) {
            history.push(
                `/administration/organizations/departmentCodes/${row.id}`
            );
        } else {
            history.push('/administration/organizations/departmentCodes/new');
        }
    };

    return {
        onGridReady,
        rowData,
        isLoading,
        redirectToForm,
    };
};

export default useDepartmentCodesGrid;
