import { RootStateOrAny, useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { GridReadyEvent } from 'ag-grid-community';
import LaunchIcon from '@mui/icons-material/Launch';
import { Tooltip, IconButton } from '@mui/material';
import {
    BusinessEntity,
    useGetBusinessEntitiesQuery,
    useGetBusinessEntityChildrenQuery,
    useGetSubscriberQuery,
} from '../../../services/organizations/organizations.service';
import { skipToken } from '@reduxjs/toolkit/query';
import * as _ from 'lodash';

export interface BusinessEntityBreadcrumb {
    id: string;
    type: 'SUBSCRIBER' | 'BUSINESS_ENTITY';
    name: string;
}

const useBusinessEntityGrid = () => {
    const { search } = useLocation();
    const history = useHistory();
    const user = useSelector((state: RootStateOrAny) => state.user);
    const [rowData, setRowData] = useState(null);
    const [gridApi, setGridApi] = useState(null);
    const [businessEntityId, setBusinessEntityId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const {
        data: businessEntityChildren,
        isLoading: isLoadingBusinessEntityChildren,
        refetch: refetchEntityChildren,
    } = useGetBusinessEntityChildrenQuery(
        businessEntityId ? businessEntityId : skipToken
    );
    const {
        data: subscriberBusinessEntities,
        isLoading: isLoadingSubscriberBusinessEntities,
        refetch: refetchSubscriberEntities,
    } = useGetBusinessEntitiesQuery(
        search ? search.split('=')[1] : user?.subscriber?.id.toString()
    );

    const { data: subscriberInfo, isLoading: isLoadingSubscriberInfo } =
        useGetSubscriberQuery(search ? search.split('=')[1] : skipToken);

    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [breadcrumbs, setBreadcrumbs] = useState([
        {
            id: subscriberInfo?.id || user?.subscriber?.id || '',
            name: subscriberInfo?.name || user?.subscriber?.name || '',
            type: 'SUBSCRIBER',
        },
    ]);

    const onGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    };

    useEffect(() => {
        businessEntityId
            ? refetchEntityChildren()
            : setRowData(subscriberBusinessEntities);
    }, [businessEntityId]);

    useEffect(() => {
        setRowData(
            businessEntityId
                ? businessEntityChildren
                : subscriberBusinessEntities
        );
        setIsLoading(
            businessEntityId
                ? isLoadingBusinessEntityChildren
                : isLoadingSubscriberBusinessEntities
        );
    }, [subscriberBusinessEntities, businessEntityChildren]);

    const redirectToForm = (row: any) => {
        if (row.id) {
            history.push(
                `/administration/organizations/business-entities/${row.id}`
            );
        } else {
            history.push('/administration/organizations/business-entities/new');
        }
    };

    const viewEntitiesColumn = (row: any) => {
        return (
            <Tooltip title="View Business Entities" placement="top">
                <IconButton
                    size="small"
                    color="primary"
                    ref={(ref) => {
                        if (!ref) {
                            return;
                        }

                        ref.onclick = (event) => {
                            redirectToBusinessEntities(row.data, event);
                        };
                    }}>
                    <LaunchIcon />
                </IconButton>
            </Tooltip>
        );
    };

    const navigateToPreviousEntity = (entity: BusinessEntityBreadcrumb) => {
        if (entity.type === 'SUBSCRIBER') {
            setBreadcrumbs([breadcrumbs[0]]);
            setBusinessEntityId(null);
            return;
        }

        const index = _.findIndex(breadcrumbs, {
            id: entity.id,
            type: 'BUSINESS_ENTITY',
        });
        breadcrumbs.splice(index);
        setBreadcrumbs([...breadcrumbs]);

        setBusinessEntityId(entity.id);
    };

    const redirectToBusinessEntities = (
        businessEntity: BusinessEntity,
        event: any
    ) => {
        event.stopPropagation();
        setBusinessEntityId(businessEntity.id);
        setBreadcrumbs([
            ...breadcrumbs,
            {
                id: businessEntity.id,
                name: businessEntity.name,
                type: 'BUSINESS_ENTITY',
            },
        ]);
    };

    return {
        breadcrumbs,
        onGridReady,
        rowData,
        isLoading,
        redirectToForm,
        viewEntitiesColumn,
        navigateToPreviousEntity,
    };
};

export default useBusinessEntityGrid;
