import { Auth } from 'aws-amplify';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import config from '../../config';

export const featureFlagAPI = createApi({
    reducerPath: 'featureFlagApi',
    baseQuery: fetchBaseQuery({
        baseUrl: config.apiGateway.URL,
        prepareHeaders: async (headers, { getState }) => {
            const token = (await Auth.currentSession())
                .getIdToken()
                .getJwtToken();
            // If we have a token set in state, let's assume that we should be passing it.
            if (token) {
                headers.set('Authorization', token);
            }

            return headers;
        },
    }),
    tagTypes: ['FeatureFlags'],
    endpoints: (build) => ({
        getAvailableFeatureFlags: build.query<any, void>({
            query: () => `/featureFlags/getAvailableFlags`,
            providesTags: ['FeatureFlags'],
        }),
        getEnabledFeatureFlags: build.query<any, void>({
            query: () => `/featureFlags/getEnabledFlags`,
            providesTags: ['FeatureFlags'],
        }),
        updateCompanyFeatureFlags: build.mutation<any, any>({
            query: (featureFlag) => ({
                url: '/featureFlags/updateCompanyFeatureFlags',
                method: 'POST',
                body: featureFlag,
            }),
            invalidatesTags: ['FeatureFlags'],
        }),
    }),
});

export const {
    useGetAvailableFeatureFlagsQuery,
    useGetEnabledFeatureFlagsQuery,
    useUpdateCompanyFeatureFlagsMutation,
} = featureFlagAPI;
