import React from 'react';
import { AgGridColumn } from 'ag-grid-react';
import Grid from '../../../components/Grids/Grid';
import { GridOptions } from '../../../components/Grids/grid.constants';
import useFormulationItemMasterGrid from '../hooks/useFormulationItemMasterGrid';

const FormulationItemMasterGrid = () => {
    const { redirectToForm } = useFormulationItemMasterGrid();

    return (
        <Grid
            gridClass="full-width-grid"
            displayToolbar={true}
            displayCreateNewButton={true}
            onCreateNew={redirectToForm}
            defaultColDef={GridOptions.sortFilterAndWrapColumns}>
            <AgGridColumn minWidth={150} field="itemId" headerName="Item #" />
        </Grid>
    );
};

export default FormulationItemMasterGrid;
