import React from 'react';
import { AgGridColumn } from 'ag-grid-react';
import Grid from '../../../components/Grids/Grid';
import DatacorLogoSpinner from '../../../components/DatacorLogoSpinner/DatacorLogoSpinner';
import { GridOptions } from '../../../components/Grids/grid.constants';
import {
    currencyFormatter,
    dateFormatter,
} from '../../../utils/formattingUtils';
import UseFixedAssetGrid from '../hooks/useFixedAssetGrid';
import { numberAsStringComparator } from '../../../utils/comparisonUtils';

const FixedAssetGrid = () => {
    const {
        assetList,
        redirectToForm,
        getStatusFromId,
        getTypeFromId,
        getBuildingFromId,
        isLoadingAny,
    } = UseFixedAssetGrid();

    return isLoadingAny ? (
        <DatacorLogoSpinner />
    ) : (
        <Grid
            rowData={assetList}
            gridClass="full-width-grid"
            displayToolbar={true}
            displayCreateNewButton={true}
            onCreateNew={redirectToForm}
            onRowClicked={({ data }) => redirectToForm(data)}
            defaultColDef={GridOptions.sortFilterAndWrapColumns}>
            <AgGridColumn minWidth={150} field="number" headerName="Asset No" />
            <AgGridColumn minWidth={150} field="code" headerName="Asset Code" />
            <AgGridColumn minWidth={200} field="name" headerName="Asset Name" />
            <AgGridColumn
                minWidth={250}
                field="description"
                headerName="Description"
            />
            <AgGridColumn
                minWidth={150}
                field="statusId"
                headerName="Asset Status"
                valueFormatter={(val) => getStatusFromId(val.value)}
                filterParams={() => {
                    return {
                        valueFormatter: (params: any) => {
                            return getStatusFromId(params.value);
                        },
                    };
                }}
            />
            <AgGridColumn
                minWidth={150}
                field="ledgerTypeId"
                headerName="Asset Type (Book)"
                valueFormatter={(val) => getTypeFromId(val.value)}
                filterParams={() => {
                    return {
                        valueFormatter: (params: any) => {
                            return getTypeFromId(params.value);
                        },
                    };
                }}
            />
            <AgGridColumn
                minWidth={150}
                field="taxTypeId"
                headerName="Asset Type (Tax)"
                valueFormatter={(val) => getTypeFromId(val.value)}
                filterParams={() => {
                    return {
                        valueFormatter: (params: any) => {
                            return getTypeFromId(params.value);
                        },
                    };
                }}
            />
            <AgGridColumn
                minWidth={150}
                field="buildingId"
                headerName="Building"
                valueFormatter={(val) => getBuildingFromId(val.value)}
                filterParams={() => {
                    return {
                        valueFormatter: (params: any) => {
                            return getBuildingFromId(params.value);
                        },
                    };
                }}
            />
            <AgGridColumn
                minWidth={150}
                field="acquireDate"
                headerName="Acquire Date"
                valueFormatter={dateFormatter}
            />
            <AgGridColumn
                minWidth={150}
                field="inServiceDate"
                headerName="In Service Date"
                valueFormatter={dateFormatter}
            />
            <AgGridColumn
                minWidth={75}
                field="totalCost"
                headerName="Total Cost"
                valueFormatter={currencyFormatter}
            />
        </Grid>
    );
};

export default FixedAssetGrid;
