import React from 'react';
import LoaderButton from '../../../components/LoaderButton';
import { PermissionsUtil } from '../../../utils/permissions/PermissionsUtil';
import { canInvoiceBeUpdated, isInvoiceLocked } from '../utils';
import {
    Grid,
    TextField,
    FormGroup,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import SelectDropdown from '../../../components/Form/SelectDropdown/SelectDropdown';
import useDocumentInfoTab from '../hooks/Tabs/useDocumentInfoTab';

interface DocumentInfoTabProps {
    documentId: string;
}

const DocumentInfoTab = ({ documentId }: DocumentInfoTabProps) => {
    const {
        user,
        fields,
        handleFieldChange,
        updateDate,
        selectedSupplierRemitTo,
        setSelectSupplierOptions,
        setSupplierRemitToFromObject,
        isLoadingErpVendorAddresses,
        isExistingSupplierAddress,
        onManualOverwriteChanged,
        isLoadingSave,
        submitSaveDocument,
        activeDocument,
        prepareSupplierValueForField,
    } = useDocumentInfoTab(documentId);

    return (
        <Grid container spacing={2}>
            <br />
            <Grid item xs={6}>
                <TextField
                    fullWidth
                    label="Invoice Number"
                    id="invoiceNumber"
                    required
                    type="text"
                    value={fields.invoiceNumber}
                    data-testid="invoice-number"
                    onChange={handleFieldChange}
                    disabled={isInvoiceLocked(activeDocument)}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    fullWidth
                    label="PO Number"
                    id="poNumber"
                    type="text"
                    value={fields.poNumber}
                    onChange={handleFieldChange}
                    data-testid="po-number"
                    disabled={isInvoiceLocked(activeDocument)}
                />
            </Grid>
            <Grid item xs={6}>
                <DesktopDatePicker
                    label="Invoice Date (MM/dd/yyyy)"
                    inputFormat="MM/DD/yyyy"
                    value={fields.invoiceDate}
                    onChange={(date: Date) => updateDate(date)}
                    renderInput={(params) => <TextField {...params} />}
                    disabled={isInvoiceLocked(activeDocument)}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    required
                    id="due"
                    label="Total Amount"
                    fullWidth
                    type="text"
                    value={fields.due}
                    onChange={handleFieldChange}
                    data-testid="due"
                    disabled={isInvoiceLocked(activeDocument)}
                />
            </Grid>

            <Grid item xs={6}>
                <SelectDropdown
                    value={selectedSupplierRemitTo}
                    className="basic-select"
                    menuPlacement={'top'}
                    id="select-supplier"
                    options={setSelectSupplierOptions()}
                    placeholder={'Remittance Address Information (Optional)'}
                    onChange={setSupplierRemitToFromObject}
                    renderValue={(val: any) => {
                        return prepareSupplierValueForField(val);
                    }}
                    isDisabled={isLoadingErpVendorAddresses}
                />
            </Grid>

            <Grid item xs={6}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                defaultChecked={!isExistingSupplierAddress}
                                style={{ fontSize: 15 }}
                                id="manualOverwrite"
                                className="mb-2 font-weight-bold"
                                onChange={(e) => {
                                    onManualOverwriteChanged(e);
                                }}
                            />
                        }
                        label="Manual Overwrite?"
                    />
                </FormGroup>
            </Grid>

            <Grid item xs={12}>
                <TextField
                    id="supplierNumber"
                    label="Supplier Number"
                    fullWidth
                    required
                    type="text"
                    value={fields.supplierNumber}
                    data-testid="VNUM"
                    disabled={isExistingSupplierAddress}
                    onChange={handleFieldChange}
                />
            </Grid>

            <Grid item xs={12}>
                <TextField
                    id="supplierName"
                    label="Supplier Name"
                    fullWidth
                    required
                    type="text"
                    value={fields.supplierName}
                    data-testid="supplierName"
                    disabled={isExistingSupplierAddress}
                    onChange={handleFieldChange}
                />
            </Grid>

            <Grid item xs={12}>
                <TextField
                    id="Ad1"
                    label="Line 1"
                    fullWidth
                    required
                    type="text"
                    value={fields.Ad1}
                    data-testid="Ad1"
                    disabled={isExistingSupplierAddress}
                    onChange={handleFieldChange}
                />
            </Grid>

            <Grid item xs={12}>
                <TextField
                    id="Ad2"
                    label="Line 2"
                    fullWidth
                    type="text"
                    value={fields.Ad2}
                    data-testid="Ad2"
                    disabled={isExistingSupplierAddress}
                    onChange={handleFieldChange}
                />
            </Grid>

            <Grid item xs={12}>
                <TextField
                    id="Ad3"
                    label="Line 3"
                    fullWidth
                    type="text"
                    value={fields.Ad3}
                    data-testid="Ad3"
                    disabled={isExistingSupplierAddress}
                    onChange={handleFieldChange}
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    id="city"
                    label="City"
                    fullWidth
                    required
                    type="text"
                    value={fields.city}
                    data-testid="city"
                    disabled={isExistingSupplierAddress}
                    onChange={handleFieldChange}
                />
            </Grid>

            <Grid item xs={4}>
                <TextField
                    id="state"
                    label="State"
                    fullWidth
                    required
                    type="text"
                    value={fields.state}
                    data-testid="state"
                    disabled={isExistingSupplierAddress}
                    onChange={handleFieldChange}
                />
            </Grid>

            <Grid item xs={4}>
                <TextField
                    id="zip"
                    label="Postal Code"
                    fullWidth
                    required
                    type="text"
                    value={fields.zip}
                    data-testid="zip"
                    disabled={isExistingSupplierAddress}
                    onChange={handleFieldChange}
                />
            </Grid>

            <Grid item xs={12}>
                <LoaderButton
                    fullWidth
                    variant="contained"
                    color="primary"
                    type="submit"
                    data-testid="updateInvoiceBtn"
                    isLoading={isLoadingSave}
                    onClick={submitSaveDocument}
                    disabled={
                        !canInvoiceBeUpdated(activeDocument) ||
                        !PermissionsUtil.isPermissionEnabled(
                            user.permissions,
                            'permission.apAutomation.updateInvoice'
                        )
                    }>
                    Update Invoice
                </LoaderButton>
            </Grid>
        </Grid>
    );
};

export default DocumentInfoTab;
