import { useForm } from '../../../libs/hooksLib';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Group,
    useCreateGroupMutation,
    useGetBusinessEntitiesQuery,
    useGetGroupQuery,
    useGetUsersForDropdownQuery,
    useUpdateGroupMutation,
} from '../../../services/organizations/organizations.service';
import { onError } from '../../../libs/errorLib';
import {
    BaseCreateEntityArgs,
    BaseUpdateEntityArgs,
} from '../../../services/serviceInterfaces';
import { skipToken } from '@reduxjs/toolkit/query';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';

const useGroupsForm = (id: string) => {
    const user = useSelector((state: RootState) => state.user);
    const history = useHistory();
    const { fields, handleFieldChange, setValues, formMethods, isFormValid } =
        useForm({});
    const { data: group, isLoading: isLoading } = useGetGroupQuery(
        id ? { groupId: id, full: true } : skipToken
    );
    const { data: businessEntityOptions } = useGetBusinessEntitiesQuery(
        user?.subscriber?.id ? user?.subscriber?.id.toString() : skipToken
    );

    const { data: users } = useGetUsersForDropdownQuery(
        user?.businessEntity?.id
            ? user?.businessEntity?.id.toString()
            : skipToken
    );
    const [createGroup] = useCreateGroupMutation();
    const [updateGroup] = useUpdateGroupMutation();

    const defaultGroup: Group = {
        name: '',
        description: '',
        businessEntityId: user?.businessEntity?.id,
        users: [],
    };

    useEffect(() => {
        setValues(group || defaultGroup);
    }, [group, user]);

    const handleCreateGroup = async () => {
        if (!isFormValid) {
            return;
        }

        const body: BaseCreateEntityArgs<Group> = {
            postBody: fields,
        };

        try {
            await createGroup(body);
            handleClose();
        } catch (e) {
            return onError(e.message);
        }
    };

    const handleUpdateGroup = async () => {
        if (!isFormValid) {
            return;
        }

        const userIds = fields?.users?.map((user: any) => {
            return { id: user };
        });

        const body: BaseUpdateEntityArgs<Group> = {
            id: id as unknown as number,
            postBody: { ...fields, users: [...userIds] },
        };

        try {
            await updateGroup(body);
            handleClose();
        } catch (e) {
            return onError(e.message);
        }
    };

    const handleClose = () => {
        history.push('/administration/organizations/groups');
    };

    return {
        fields,
        user,
        users,
        group,
        isLoading,
        handleCreateGroup,
        handleUpdateGroup,
        handleFieldChange,
        handleClose,
        setValues,
        businessEntityOptions,
        formMethods,
    };
};

export default useGroupsForm;
