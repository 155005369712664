import { isNilOrEmpty } from '../objectUtils';

export class UserList {
    #userList: any = null;
    #emailDictionary: any = null;
    #usernameDictionary: any = null;

    constructor(userList: any) {
        this.#userList = userList;
        this.#emailDictionary = {};
        this.#usernameDictionary = {};

        for (let user of this.#userList) {
            this.#emailDictionary[user.email] = user;
            this.#usernameDictionary[user.username] = user;
        }
    }

    getAllUsers() {
        return this.#userList;
    }

    getUsersFullNameFromEmail(email: string, returnEmailIfNotFound: boolean) {
        const user = this.#emailDictionary[email];

        if (!isNilOrEmpty(user)) {
            return `${user.firstName} ${user.lastName}`;
        } else if (returnEmailIfNotFound) {
            return email;
        } else {
            return '';
        }
    }

    getUserFromUsername(username: string) {
        return this.#usernameDictionary[username];
    }
}
