import React from 'react';
import {
    useCreateDepreciationMethodTypeMutation,
    useDeleteDepreciationMethodTypeMutation,
    useGetDepreciationMethodTypeQuery,
    useUpdateDepreciationMethodTypeMutation,
} from '../../../services/fixedAssets/fixedAssets.service';
import { skipToken } from '@reduxjs/toolkit/query';
import { FixedAssetDepreciationMethodType } from '../../../types/FixedAsset.types';
import useBaseForm from '../../Form/hooks/useBaseForm';

const UseFixedAssetDepreciationMethodTypeForm = (id: string) => {
    const { data: activeType, isLoading: isLoadingType } =
        useGetDepreciationMethodTypeQuery(id ? id : skipToken);
    const [createType] = useCreateDepreciationMethodTypeMutation();
    const [updateType] = useUpdateDepreciationMethodTypeMutation();
    const [deleteType] = useDeleteDepreciationMethodTypeMutation();

    const blankType: FixedAssetDepreciationMethodType = {
        sortOrder: null,
        code: '',
        description: '',
        postsJournalEntries: false,
        predefinedSchedule: false,
        usageBased: false,
    };

    const {
        fields,
        handleFieldChange,
        setValues,
        formMethods,
        toastProps,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
    } = useBaseForm({
        closePath: '/accounting/fixedAssets/depreciationMethodType',
        blankEntity: blankType,
        activeEntity: activeType,
        createEntity: () => {
            return createType({ postBody: fields });
        },
        updateEntity: () => {
            return updateType({
                id: id as unknown as number,
                postBody: fields,
            });
        },
        deleteEntity: () => {
            return deleteType(id);
        },
    });

    const onPredefinedScheduleChanged = (event: any) => {
        if (event.target.checked && fields.usageBased) {
            setValues({
                predefinedSchedule: true,
                usageBased: false,
            });
        } else {
            handleFieldChange(event);
        }
    };

    const onUsageBasedChanged = (event: any) => {
        if (event.target.checked && fields.predefinedSchedule) {
            setValues({
                usageBased: true,
                predefinedSchedule: false,
            });
        } else {
            handleFieldChange(event);
        }
    };

    return {
        fields,
        handleFieldChange,
        setValues,
        formMethods,
        toastProps,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        isLoadingType,
        onPredefinedScheduleChanged,
        onUsageBasedChanged,
    };
};

export default UseFixedAssetDepreciationMethodTypeForm;
