import { Auth } from 'aws-amplify';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import config from '../../config';

export const apAutomationAPI = createApi({
    reducerPath: 'apAutomationApi',
    baseQuery: fetchBaseQuery({
        baseUrl: config.apiGateway.URL,
        prepareHeaders: async (headers, { getState }) => {
            const token = (await Auth.currentSession())
                .getIdToken()
                .getJwtToken();
            // If we have a token set in state, let's assume that we should be passing it.
            if (token) {
                headers.set('Authorization', token);
            }

            return headers;
        },
    }),
    tagTypes: [
        'Invoice',
        'Approvers',
        'Users',
        'Addresses',
        'Tolerance',
        'Comment',
        'Workflows',
    ],
    endpoints: (build) => ({
        getAllDocuments: build.query<any, void>({
            query: () => `/apAutomation/getAllSupplierInvoices`,
            providesTags: ['Invoice'],
        }),
        getAllOpenDocuments: build.query<any, void>({
            query: () => `/apAutomation/getAllOpenSupplierInvoices`,
            providesTags: ['Invoice'],
        }),
        getDocumentsByApprover: build.query<any, string>({
            query: (approverEmail) =>
                `/apAutomation/getSupplierInvoicesByApprover/${approverEmail}`,
            providesTags: ['Invoice'],
        }),
        getOpenDocumentsByApprover: build.query<any, string>({
            query: (approverEmail) =>
                `/apAutomation/getOpenSupplierInvoicesByApprover/${approverEmail}`,
            providesTags: ['Invoice'],
        }),
        getDocument: build.query<any, string>({
            query: (documentId) =>
                `/apAutomation/getDocumentById/${documentId}`,
            providesTags: ['Invoice'],
        }),
        getDocumentPdfUrl: build.query<any, string>({
            query: (documentId) =>
                `/apAutomation/getDocumentFileById/${documentId}`,
            providesTags: ['Invoice'],
        }),
        getDefaultApprovers: build.query<any, void>({
            query: () =>
                '/apAutomation/getDefaultApproversForCustAndDocType/invoice',
            transformResponse: (response: any) => {
                if (response) {
                    return response.approvers;
                } else {
                    return null;
                }
            },
            providesTags: ['Approvers'],
        }),
        updateDefaultApprovers: build.mutation<any, any>({
            query: (approvers) => ({
                url: '/apAutomation/setDefaultApproversForCustAndDocType',
                method: 'POST',
                body: {
                    approvers: approvers.map((approver: any) => approver.email),
                    documentType: 'invoice',
                },
            }),
            invalidatesTags: ['Approvers'],
        }),
        updateInvoiceApprovers: build.mutation<any, any>({
            query: (args) => ({
                url: '/apAutomation/setApproversForDocument',
                method: 'POST',
                body: {
                    approvers: args.approvers.map(
                        (approver: any) => approver.email
                    ),
                    documentType: 'invoice',
                    documentId: args.documentId,
                },
            }),
            invalidatesTags: ['Approvers', 'Invoice'],
        }),
        getUsers: build.query<any, void>({
            query: () => '/users/company',
            providesTags: ['Users'],
        }),
        getAllErpVendorAddresses: build.query<any, void>({
            query: () => '/apAutomation/getAllSupplierAddresses',
            providesTags: ['Addresses'],
        }),
        approveDocument: build.mutation<any, any>({
            query: (documentId) => ({
                url: `/apAutomation/approveDocument/${documentId}`,
                method: 'GET',
            }),
            invalidatesTags: ['Invoice'],
        }),
        unapproveDocument: build.mutation<any, any>({
            query: (documentId) => ({
                url: `/apAutomation/unapproveDocument/${documentId}`,
                method: 'GET',
            }),
            invalidatesTags: ['Invoice'],
        }),
        rejectDocument: build.mutation<any, any>({
            query: (args) => ({
                url: `/apAutomation/rejectDocument`,
                method: 'POST',
                body: {
                    documentId: args.documentId,
                    reason: args.reason,
                },
            }),
            invalidatesTags: ['Invoice'],
        }),
        saveDocument: build.mutation<any, any>({
            query: (args) => ({
                url: `/apAutomation/saveDocument/${args.documentId}`,
                method: 'POST',
                body: {
                    invoiceNumber: args.invoiceNumber,
                    invoiceDate: args.invoiceDate,
                    poNumber: args.poNumber,
                    due: args.due,
                    remitAddress: args.remitAddress,
                },
            }),
            invalidatesTags: ['Invoice'],
        }),
        getDefaultTolerance: build.query<any, void>({
            query: () =>
                '/apAutomation/getDefaultToleranceForCustAndDocType/invoice',
            providesTags: ['Tolerance'],
            transformResponse: (response: any) => response.toleranceChecks,
        }),
        updateToleranceChecksForDocument: build.mutation<any, any>({
            query: (args) => ({
                url: `/apAutomation/setToleranceForDocument/${args.documentId}`,
                method: 'POST',
                body: {
                    toleranceChecks: args.toleranceChecks,
                },
            }),
            invalidatesTags: ['Invoice', 'Tolerance'],
        }),
        updateDefaultToleranceChecks: build.mutation<any, any>({
            query: (toleranceChecks) => ({
                url: '/apAutomation/setDefaultToleranceForCustAndDocType',
                method: 'POST',
                body: {
                    toleranceChecks: toleranceChecks,
                    documentType: 'invoice',
                },
            }),
            invalidatesTags: ['Invoice', 'Tolerance'],
        }),
        postComment: build.mutation<any, any>({
            query: (args) => ({
                url: `/apAutomation-api/createComment/${args.documentId}`,
                method: 'POST',
                body: {
                    comment: args.comment,
                },
            }),
            invalidatesTags: ['Invoice', 'Comment'],
        }),
        deleteComment: build.mutation<any, any>({
            query: (args) => ({
                url: `/apAutomation-api/deleteComment/${args.documentId}/${args.commentDate}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Invoice', 'Comment'],
        }),
        getAnalyticsForTimePeriod: build.query<any, any>({
            query: (args) =>
                `/apAutomation/getAnalyticsForTimePeriod?startTimestamp=${args.startDate}&endTimestamp=${args.endDate}`,
            providesTags: ['Invoice'],
        }),
        getAllApprovalWorkflows: build.query<any, void>({
            query: () =>
                '/apAutomation/getAllApprovalWorkflowsForCustAndDocType/invoice',
            providesTags: ['Invoice', 'Workflows', 'Approvers'],
        }),
        getApprovalWorkflowById: build.query<any, any>({
            query: (workflowId) =>
                `/apAutomation/getApprovalWorkflowByIdAndDocType/invoice/${workflowId}`,
            providesTags: ['Invoice', 'Workflows', 'Approvers'],
        }),
        createApprovalWorkflow: build.mutation<any, any>({
            query: (args) => ({
                url: '/apAutomation/createApprovalWorkflow/invoice',
                method: 'POST',
                body: {
                    approvers: args.approvers,
                    workflowName: args.workflowName,
                },
            }),
            invalidatesTags: ['Invoice', 'Approvers', 'Workflows'],
        }),
        updateApprovalWorkflow: build.mutation<any, any>({
            query: (args) => ({
                url: `/apAutomation/updateApprovalWorkflowByIdAndDocType/invoice/${args.workflowId}`,
                method: 'POST',
                body: {
                    workflowName: args.workflowName,
                    approvers: args.approvers,
                    isActive: args.isActive,
                },
            }),
            invalidatesTags: ['Invoice', 'Approvers', 'Workflows'],
        }),
        deleteApprovalWorkflow: build.mutation<any, any>({
            query: (workflowId) => ({
                url: `/apAutomation/deleteApprovalWorkflow/invoice/${workflowId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Invoice', 'Approvers', 'Workflows'],
        }),
        assignApprovalWorkflowToDocument: build.mutation<any, any>({
            query: (args) => ({
                url: `/apAutomation/assignApprovalWorkflowToDocument/${args.documentId}`,
                method: 'POST',
                body: {
                    workflowId: args.workflowId,
                    approvers: args.approvers,
                },
            }),
            invalidatesTags: ['Invoice', 'Approvers', 'Workflows'],
        }),
        getAllUnmatchedByPoNum: build.query<any, any>({
            query: (poNumber) =>
                `/apAutomation/getAllUnmatchedByPoNum/${poNumber}`,
            providesTags: ['Invoice'],
        }),
        getAllUnmatchedByCompany: build.query<any, void>({
            query: () => `/apAutomation/getAllUnmatchedByCompany`,
            providesTags: ['Invoice'],
        }),
        manuallyUnlinkPOToInvoice: build.mutation<any, any>({
            query: (args) => ({
                url: '/apAutomation/manuallyUnlinkPOToInvoice',
                method: 'POST',
                body: {
                    poNumber: args.poNumber,
                    receiptNumber: args.receiptNumber,
                    erpCostCategory: args.erpCostCategory,
                },
            }),
            invalidatesTags: ['Invoice'],
        }),
        manuallyLinkPOToInvoice: build.mutation<any, any>({
            query: (args) => ({
                url: `/apAutomation/manuallyLinkPOToInvoice/${args.documentId}`,
                method: 'POST',
                body: {
                    poNumber: args.poNumber,
                    receiptNumber: args.receiptNumber,
                    erpCostCategory: args.erpCostCategory,
                },
            }),
            invalidatesTags: ['Invoice'],
        }),
        updatePurchaseOrderMatchStatus: build.mutation<any, any>({
            query: (args) => ({
                url: `/apAutomation/updatePurchaseOrderMatchStatus/${args.documentId}/${args.matchAction}`,
                method: 'POST',
            }),
            invalidatesTags: ['Invoice'],
        }),
        uploadDocumentFromWeb: build.mutation<any, any>({
            query: (args) => ({
                url: `/apAutomation/uploadDocumentFromWeb`,
                method: 'POST',
                body: {
                    pdfArray: args.pdfArray,
                    user: args.user,
                },
            }),
        }),
    }),
});

export const {
    useGetAllDocumentsQuery,
    useGetAllOpenDocumentsQuery,
    useGetDocumentsByApproverQuery,
    useGetOpenDocumentsByApproverQuery,
    useGetDocumentQuery,
    useGetDocumentPdfUrlQuery,
    useGetDefaultApproversQuery,
    useUpdateDefaultApproversMutation,
    useUpdateInvoiceApproversMutation,
    useGetUsersQuery,
    useGetAllErpVendorAddressesQuery,
    useApproveDocumentMutation,
    useUnapproveDocumentMutation,
    useRejectDocumentMutation,
    useSaveDocumentMutation,
    useGetDefaultToleranceQuery,
    useUpdateToleranceChecksForDocumentMutation,
    useUpdateDefaultToleranceChecksMutation,
    usePostCommentMutation,
    useDeleteCommentMutation,
    useGetAnalyticsForTimePeriodQuery,
    useGetAllApprovalWorkflowsQuery,
    useGetApprovalWorkflowByIdQuery,
    useCreateApprovalWorkflowMutation,
    useUpdateApprovalWorkflowMutation,
    useDeleteApprovalWorkflowMutation,
    useAssignApprovalWorkflowToDocumentMutation,
    useGetAllUnmatchedByPoNumQuery,
    useGetAllUnmatchedByCompanyQuery,
    useManuallyUnlinkPOToInvoiceMutation,
    useManuallyLinkPOToInvoiceMutation,
    useUpdatePurchaseOrderMatchStatusMutation,
    useUploadDocumentFromWebMutation,
} = apAutomationAPI;
