import CreateZone from './CreateZone';
import React from 'react';
import { PermissionsUtil } from '../../utils/permissions/PermissionsUtil';
import { PERMISSIONS } from '../../constants/permissions/permissions.constants';
import ToastContainer from '../../components/Toast/ToastContainer';
import AgGrid from '../../components/Grids/Grid';
import { AgGridColumn } from 'ag-grid-react';
import DatacorLogoSpinner from '../../components/DatacorLogoSpinner/DatacorLogoSpinner';
import useZonesGrid from './hooks/useZonesGrid';

const ZonesGrid = () => {
    const {
        onGridReady,
        handleCreateZoneModalOpen,
        handleCreateZoneModalClose,
        showToast,
        showCreateZoneModal,
        zones,
        isLoadingZones,
        user,
        setShowToast,
    } = useZonesGrid();

    return (
        <>
            <CreateZone
                show={showCreateZoneModal}
                handleClose={handleCreateZoneModalClose}
            />
            {!isLoadingZones ? (
                <AgGrid
                    testId="zone-list-grid"
                    pagination={true}
                    paginationPageSize={10}
                    rowData={zones}
                    immutableData={true}
                    dataKey="zoneId"
                    domLayout={'autoHeight'}
                    onCreateNew={handleCreateZoneModalOpen}
                    displayCreateNewButton={PermissionsUtil.isPermissionEnabled(
                        user.permissions,
                        PERMISSIONS.USER_PORTAL.COMPANIES.CREATE
                    )}
                    displayToolbar={true}
                    onGridReady={onGridReady}
                    defaultColDef={{
                        resizable: true,
                        sortable: true,
                        unSortIcon: true,
                        filter: true,
                        wrapText: true,
                    }}>
                    <AgGridColumn
                        flex={1}
                        field="zoneName"
                        headerName="Zone Name"
                    />
                    <AgGridColumn
                        flex={2}
                        field="zoneId"
                        headerName="Zone ID"
                    />
                    <AgGridColumn
                        flex={3}
                        field="companyID"
                        headerName="Company ID"
                    />
                    <AgGridColumn minWidth={150} field="url" headerName="Url" />
                    <AgGridColumn
                        minWidth={250}
                        field="port"
                        headerName="Port"
                    />
                </AgGrid>
            ) : (
                <DatacorLogoSpinner />
            )}

            <ToastContainer
                show={showToast}
                close={() => setShowToast(false)}
                bodyText="You'll need to add this zone to the user in the Users Tab."
            />
        </>
    );
};

export default ZonesGrid;
