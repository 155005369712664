import React, { useEffect } from 'react';
import InputBase, { InputBaseProps } from '../InputBase/InputBase';
import { MaxLengthValidator } from '../../../../validators/maxLength.validator';
import { MinLengthValidator } from '../../../../validators/minLength.validator';

export type TextInputProps = {
    minLength?: number;
    maxLength?: number;
} & InputBaseProps;

const TextInput = (props: TextInputProps) => {
    useEffect(() => {
        if (props.formMethods) {
            addValidators(props);
        }
    }, []);

    return <InputBase {...props} />;
};

const addValidators = (props: TextInputProps) => {
    if (props.maxLength) {
        props.formMethods?.addValidator(
            props.id,
            props.label.toString(),
            MaxLengthValidator(props.maxLength)
        );
    }

    if (props.minLength) {
        props.formMethods?.addValidator(
            props.id,
            props.label.toString(),
            MinLengthValidator(props.minLength)
        );
    }
};

export default TextInput;
