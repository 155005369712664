import React from 'react';
import { Card, CardContent, CardHeader, Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import FormContainer from '../../components/Form/FormContainer/FormContainer';
import DatacorLogoSpinner from '../../components/DatacorLogoSpinner/DatacorLogoSpinner';
import useGeneralLedgerAccountForm from './hooks/useGeneralLedgerAccountForm';
import FormPartial from '../../components/Form/FormContainer/FormPartial';
import TextInput from '../../components/Form/FormInputs/TextInput/TextInput';
import FixedAssetsFormHeader from '../../components/Form/FormContainer/FormHeader';
import SelectInput from '../../components/Form/FormInputs/SelectInput/SelectInput';
import { AgGridColumn } from 'ag-grid-react';
import EntityGrid from '../../components/Grids/Grid';
import ToastContainer from '../../components/Toast/ToastContainer';
import { GridOptions } from '../../components/Grids/grid.constants';

interface ParamTypes {
    accountId: string;
}

const GeneralLedgerAccountForm = () => {
    const { accountId } = useParams<ParamTypes>();
    const {
        fields,
        formMethods,
        statusOptions,
        balanceTypeOptions,
        accountTypeOptions,
        toastProps,
        onCreate,
        onUpdate,
        onDelete,
        closeForm,
        handleFieldChange,
        isLoadingAccount,
        activeAccount,
        hasFunctions,
    } = useGeneralLedgerAccountForm(accountId);

    return (
        <Card>
            <CardHeader title="General Ledger Account" />

            {isLoadingAccount ? (
                <DatacorLogoSpinner />
            ) : (
                <CardContent>
                    <ToastContainer {...toastProps} show={!!toastProps?.show} />

                    <FormContainer
                        headerTitle={`Primary Information`}
                        onSubmit={() => formMethods.setIsFormSubmitted(true)}
                        headerComponent={
                            <FixedAssetsFormHeader
                                showOnDelete={!!accountId}
                                showOnSave={true}
                                onSave={() => {
                                    if (!accountId) {
                                        onCreate();
                                    } else {
                                        onUpdate();
                                    }
                                }}
                                onDelete={() => {
                                    onDelete();
                                }}
                                onCancel={closeForm}
                                isEdit={!!accountId}
                            />
                        }>
                        <FormPartial>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextInput
                                        fullWidth
                                        label="Number"
                                        id="number"
                                        value={fields.number}
                                        onChange={handleFieldChange}
                                        formMethods={formMethods}
                                        required={true}
                                        maxLength={30}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextInput
                                        fullWidth
                                        label="Name"
                                        id="name"
                                        value={fields.name}
                                        onChange={handleFieldChange}
                                        formMethods={formMethods}
                                        required={true}
                                        maxLength={90}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextInput
                                        fullWidth
                                        label="Description"
                                        id="description"
                                        value={fields.description}
                                        onChange={handleFieldChange}
                                        formMethods={formMethods}
                                        maxLength={300}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <SelectInput
                                        fullWidth
                                        label="Status"
                                        id="status"
                                        value={fields.status}
                                        onChange={handleFieldChange}
                                        options={statusOptions}
                                        formMethods={formMethods}
                                        required={true}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <SelectInput
                                        fullWidth
                                        label="Balance Type"
                                        id="balanceType"
                                        value={fields.balanceType}
                                        onChange={handleFieldChange}
                                        options={balanceTypeOptions}
                                        formMethods={formMethods}
                                        required={true}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <SelectInput
                                        fullWidth
                                        label="Account Type"
                                        id="accountType"
                                        value={fields.accountType}
                                        onChange={handleFieldChange}
                                        options={accountTypeOptions}
                                        formMethods={formMethods}
                                        required={true}
                                    />
                                </Grid>
                            </Grid>
                        </FormPartial>
                        {hasFunctions() && (
                            <FormPartial title="Account Functions">
                                <EntityGrid
                                    rowData={activeAccount.functions}
                                    gridClass="full-width-grid"
                                    defaultColDef={
                                        GridOptions.sortFilterAndWrapColumns
                                    }>
                                    <AgGridColumn
                                        minWidth={175}
                                        maxWidth={250}
                                        field="functionCode.code"
                                        headerName="Function Code"
                                    />
                                    <AgGridColumn
                                        minWidth={300}
                                        field="functionCode.description"
                                        headerName="Description"
                                    />
                                </EntityGrid>
                            </FormPartial>
                        )}
                    </FormContainer>
                </CardContent>
            )}
        </Card>
    );
};

export default GeneralLedgerAccountForm;
