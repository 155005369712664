import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormFields } from '../../../libs/hooksLib';
import { isNilOrEmpty } from '../../../utils/objectUtils';
import { Auth } from 'aws-amplify';
import { onError } from '../../../libs/errorLib';

const useChangeEmail = () => {
    const history = useHistory();
    const [fields, handleFieldChange] = useFormFields({
        email: '',
    });
    const [isSendingCode, setIsSendingCode] = useState(false);

    function validateEmailForm() {
        return !isNilOrEmpty(fields.email);
    }

    async function handleUpdateClick(
        event: React.MouseEvent<HTMLButtonElement>
    ) {
        event.preventDefault();

        setIsSendingCode(true);

        try {
            const user = await Auth.currentUserInfo();
            await Auth.updateUserAttributes(user, { email: fields.email });

            history.push('/settings/emailConfirmation');
        } catch (error) {
            onError(error);
            setIsSendingCode(false);
        }
    }

    return {
        fields,
        handleFieldChange,
        handleUpdateClick,
        isSendingCode,
        validateEmailForm,
    };
};

export default useChangeEmail;
