import React from 'react';
import { useParams } from 'react-router-dom';
import {
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Grid,
} from '@mui/material';
import FormContainer from '../../components/Form/FormContainer/FormContainer';
import FixedAssetsFormHeader from '../../components/Form/FormContainer/FormHeader';
import TextInput from '../../components/Form/FormInputs/TextInput/TextInput';
import FormPartial from '../../components/Form/FormContainer/FormPartial';
import DatacorLogoSpinner from '../../components/DatacorLogoSpinner/DatacorLogoSpinner';
import { useUserForm } from './hooks/useUserForm';
import SelectInput from '../../components/Form/FormInputs/SelectInput/SelectInput';
import { isNilOrEmpty } from '../../utils/objectUtils';
import ToastContainer from '../../components/Toast/ToastContainer';

interface ParamTypes {
    id: string;
}

const UserForm = () => {
    const { id } = useParams<ParamTypes>();
    const {
        fields,
        user,
        employeeFields,
        userData,
        isLoading,
        businessEntityOptions,
        messagingPreferenceOptions,
        groupsOptions,
        rolesOptions,
        departmentCodeOptions,
        usersDropdownOptions,
        handleCreateUser,
        handleUpdateUser,
        handleFieldChange,
        handleEmployeeFieldChange,
        handleClose,
        setValues,
        openToast,
        setOpenToast,
        employeeFormMethods,
        formMethods,
    } = useUserForm(id);

    const isEitherFieldPreset =
        !isNilOrEmpty(employeeFields.supervisorId) ||
        !isNilOrEmpty(employeeFields.departmentCodeId);

    const checkEmployeeInfo = !id || (id && isEitherFieldPreset);
    const shouldShowCheckbox = !isNilOrEmpty(fields.isDatacorAdmin) || !id;

    return (
        <Card>
            <ToastContainer
                show={openToast}
                close={() => setOpenToast(false)}
                bodyText={'This email address is already in use...'}
            />
            <CardHeader
                title={
                    (id
                        ? `${fields.firstName} ${fields.lastName}`
                        : 'Create New User') +
                    ` (${user?.businessEntity?.name})`
                }
            />
            {isLoading ? (
                <DatacorLogoSpinner />
            ) : (
                <CardContent>
                    <FormContainer
                        headerTitle={`Primary Information`}
                        onSubmit={() => {
                            formMethods.setIsFormSubmitted(true);
                            employeeFormMethods.setIsFormSubmitted(true);
                        }}
                        headerComponent={
                            <FixedAssetsFormHeader
                                showOnDelete={false}
                                showOnSave={true}
                                onSave={async () => {
                                    if (!id) {
                                        await handleCreateUser();
                                    } else {
                                        await handleUpdateUser();
                                    }
                                }}
                                onDelete={handleClose}
                                onCancel={handleClose}
                                isEdit={!!id}
                            />
                        }>
                        <FormPartial>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextInput
                                        fullWidth
                                        label="First Name"
                                        id="firstName"
                                        value={fields.firstName}
                                        formMethods={formMethods}
                                        onChange={handleFieldChange}
                                        required={true}
                                        maxLength={90}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextInput
                                        fullWidth
                                        label="Middle Name"
                                        id="middleName"
                                        value={fields.middleName}
                                        formMethods={formMethods}
                                        onChange={handleFieldChange}
                                        maxLength={90}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextInput
                                        fullWidth
                                        label="Last Name"
                                        id="lastName"
                                        value={fields.lastName}
                                        formMethods={formMethods}
                                        onChange={handleFieldChange}
                                        required={true}
                                        maxLength={90}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextInput
                                        fullWidth
                                        label="Email"
                                        id="email"
                                        value={fields.email}
                                        required={true}
                                        formMethods={formMethods}
                                        onChange={handleFieldChange}
                                        maxLength={300}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextInput
                                        fullWidth
                                        label="Phone"
                                        id="mobilePhone"
                                        value={fields.mobilePhone}
                                        formMethods={formMethods}
                                        onChange={handleFieldChange}
                                        maxLength={300}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <SelectInput
                                        fullWidth
                                        label="Messaging Preference"
                                        id="messagingPrefId"
                                        value={fields.messagingPrefId}
                                        onChange={handleFieldChange}
                                        options={messagingPreferenceOptions}
                                        formMethods={formMethods}
                                        required={true}
                                    />
                                </Grid>
                                {/*
                                //TODO: determine if we want to keep this in the form or just have it be in the title
                                <Grid item xs={6}>
                                    <ExpandableDropdown
                                        valueSelected={(value) =>
                                            setValues({
                                                businessEntityId: value,
                                            })
                                        }
                                        defaultValue={fields.businessEntityId}
                                        items={businessEntityOptions}
                                        idKey="id"
                                        labelKey="name"
                                        label="Parent Business Entity"
                                        id="businessEntityId"
                                        formMethods={formMethods}
                                        required={true}
                                    />
                                </Grid>
                                */}
                                <Grid item xs={6}>
                                    {shouldShowCheckbox ? (
                                        <FormGroup
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                            }}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        id="isDatacorAdmin"
                                                        checked={
                                                            fields.isDatacorAdmin
                                                        }
                                                        onChange={
                                                            handleFieldChange
                                                        }
                                                    />
                                                }
                                                label="Datacor Admin"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        id="isAppStreamUser"
                                                        checked={
                                                            fields.isAppStreamUser
                                                        }
                                                        onChange={
                                                            handleFieldChange
                                                        }
                                                    />
                                                }
                                                label="App Stream"
                                            />
                                        </FormGroup>
                                    ) : (
                                        ''
                                    )}
                                </Grid>
                            </Grid>
                        </FormPartial>
                        <FormPartial title="Groups and Roles">
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <SelectInput
                                        fullWidth
                                        SelectProps={{
                                            multiple: true,
                                        }}
                                        label="Groups"
                                        id="groups"
                                        value={fields.groups}
                                        onChange={handleFieldChange}
                                        options={groupsOptions}
                                        formMethods={formMethods}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <SelectInput
                                        fullWidth
                                        SelectProps={{
                                            multiple: true,
                                        }}
                                        label="Roles"
                                        id="roles"
                                        value={fields.roles}
                                        onChange={handleFieldChange}
                                        options={rolesOptions}
                                        formMethods={formMethods}
                                    />
                                </Grid>
                            </Grid>
                        </FormPartial>
                        {checkEmployeeInfo ? (
                            <FormPartial title="Employee Information">
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <SelectInput
                                            fullWidth
                                            label="Department Code ID"
                                            id="departmentCodeId"
                                            value={
                                                employeeFields.departmentCodeId
                                            }
                                            onChange={handleEmployeeFieldChange}
                                            options={departmentCodeOptions}
                                            formMethods={employeeFormMethods}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <SelectInput
                                            fullWidth
                                            label="Supervisor ID"
                                            id="supervisorId"
                                            value={employeeFields.supervisorId}
                                            onChange={handleEmployeeFieldChange}
                                            options={usersDropdownOptions}
                                            formMethods={employeeFormMethods}
                                        />
                                    </Grid>
                                </Grid>
                            </FormPartial>
                        ) : (
                            ''
                        )}
                    </FormContainer>
                </CardContent>
            )}
        </Card>
    );
};

export default UserForm;
