import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormFields } from '../../../libs/hooksLib';
import { isNilOrEmpty } from '../../../utils/objectUtils';
import { Auth } from 'aws-amplify';
import { onError } from '../../../libs/errorLib';

const useChangeEmailConfirmation = () => {
    const history = useHistory();
    const [fields, handleFieldChange] = useFormFields({
        code: '',
    });
    const [isConfirming, setIsConfirming] = useState(false);

    function validateConfirmForm() {
        return !isNilOrEmpty(fields.code);
    }

    async function handleConfirmClick(
        event: React.MouseEvent<HTMLButtonElement>
    ) {
        event.preventDefault();
        setIsConfirming(true);

        try {
            await Auth.verifyCurrentUserAttributeSubmit('email', fields.code);

            history.push('/settings');
        } catch (error) {
            onError(error);
            setIsConfirming(false);
        }
    }

    return {
        fields,
        handleFieldChange,
        handleConfirmClick,
        isConfirming,
        validateConfirmForm,
    };
};

export default useChangeEmailConfirmation;
