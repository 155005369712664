import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
    getMonthlyDetailSummary,
    updateSelectedPricingConcerns,
} from '../../../store/pricingAnalysisTool';
import { RootState } from '../../../store';

const usePricingAnalysisTool = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { pricingAnalysisToolSet } = useSelector(
        (state: RootState) => state.pricingAnalysisTool
    );
    const [showAlert, setShowAlert] = useState(false);
    const [maxAsOfDate] = useState(
        pricingAnalysisToolSet.summaryAsOfDate.rawDate
    );

    useEffect(() => {
        if (
            moment(pricingAnalysisToolSet.summaryStartDate.rawDate) <
            moment(pricingAnalysisToolSet.summaryAsOfDate.rawDate)
        ) {
            setShowAlert(false);
            dispatch(
                getMonthlyDetailSummary(
                    pricingAnalysisToolSet.summaryStartDate.rawDate,
                    pricingAnalysisToolSet.summaryAsOfDate.rawDate
                )
            );
        } else {
            setShowAlert(true);
        }
    }, [
        dispatch,
        pricingAnalysisToolSet.summaryAsOfDate.rawDate,
        pricingAnalysisToolSet.summaryStartDate.rawDate,
    ]);

    const handlePricingConcernChecks = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        dispatch(updateSelectedPricingConcerns(event.target));
    };

    const gotoDetails = () => {
        history.push('/pricingAnalysisTool/pricingConcernDetails');
    };
    return {
        gotoDetails,
        handlePricingConcernChecks,
        maxAsOfDate,
        showAlert,
        dispatch,
        pricingAnalysisToolSet,
    };
};
export default usePricingAnalysisTool;
