import React, { useEffect, useState } from 'react';
import { useForm } from '../../../libs/hooksLib';
import { useHistory } from 'react-router-dom';

const useFormulationCasForm = () => {
    const history = useHistory();

    const isLoadingCasRecord = false;

    //Need to change this with an appropriate Type
    const blankCasMaster = {
        casNum: '',
        chemicalName: '',
        chemicalUpdate: false,
        lastChemicalUpdate: null as Date,
        indexNum: '',
        ecNum: '',
        reachNum: '',
        classification: '',
        molecularFormula: '',
        showOnSDS: false,
        weightThreshold: null as number,
        tradeSecret: false,
    };

    const { fields, handleFieldChange, formMethods, isFormValid } =
        useForm(blankCasMaster);

    const handleClose = () => {
        history.push('/formulation/casMaster');
    };

    const updateDateField = (id: string, date: Date): void => {
        handleFieldChange({
            target: {
                id: id,
                value: date,
            },
        });
    };

    const handleCreateCasRecord = async () => {
        if (!isFormValid) {
            return;
        }
        console.log(fields);
    };

    return {
        fields,
        formMethods,
        handleFieldChange,
        handleClose,
        updateDateField,
        handleCreateCasRecord,
        isLoadingCasRecord,
    };
};

export default useFormulationCasForm;
