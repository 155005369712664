import { AgGridColumn } from 'ag-grid-react';
import React from 'react';
import moment from 'moment';
import AgGrid from '../../components/Grids/Grid';
import DatacorLogoSpinner from '../../components/DatacorLogoSpinner/DatacorLogoSpinner';
import useSubscribersGrid from './hooks/useSubscribersGrid';
import ToastContainer from '../../components/Toast/ToastContainer';

const SubscribersGrid = () => {
    const {
        user,
        onGridReady,
        subscribers,
        isLoading,
        isLoadingUser,
        redirectToForm,
        viewEntitiesColumn,
        switchSubscriberColumn,
        showToast,
        setShowToast,
        subscriberName,
    } = useSubscribersGrid();

    return (
        <>
            <ToastContainer
                show={showToast}
                close={() => setShowToast(false)}
                bodyText={`Switched to Subscriber: ${subscriberName}`}
            />
            {!isLoading && !isLoadingUser ? (
                <AgGrid
                    testId="company-list-grid"
                    dataKey="id"
                    immutableData={true}
                    rowData={subscribers}
                    displayToolbar={true}
                    onCreateNew={(row) => redirectToForm(row)}
                    displayCreateNewButton={true}
                    onRowClicked={({ data }) => redirectToForm(data)}
                    onGridReady={onGridReady}
                    frameworkComponents={{
                        viewEntities: viewEntitiesColumn,
                        switchSubscriber: switchSubscriberColumn,
                    }}
                    defaultColDef={{
                        resizable: true,
                        sortable: true,
                        unSortIcon: true,
                        filter: true,
                        wrapText: true,
                    }}>
                    {user.isDatacorAdmin && (
                        <AgGridColumn
                            field="action"
                            filter={false}
                            sortable={false}
                            resizable={false}
                            headerName=""
                            width={70}
                            cellRenderer="switchSubscriber"
                        />
                    )}
                    <AgGridColumn
                        field="action"
                        filter={false}
                        sortable={false}
                        resizable={false}
                        headerName=""
                        width={70}
                        cellRenderer="viewEntities"
                    />

                    <AgGridColumn
                        flex={1}
                        minWidth={250}
                        field="name"
                        headerName="Subscriber Name"
                    />
                    <AgGridColumn
                        minWidth={200}
                        field="description"
                        headerName="Description"
                    />
                    <AgGridColumn
                        minWidth={200}
                        field="createdAt"
                        headerName="Date Created"
                        valueFormatter={(val) =>
                            val.value && moment(val.value).isValid()
                                ? moment(val.value).format('L')
                                : ''
                        }
                    />
                    <AgGridColumn
                        minWidth={200}
                        field="updatedAt"
                        headerName="Last Update Date"
                        valueFormatter={(val) =>
                            val.value && moment(val.value).isValid()
                                ? moment(val.value).format('L')
                                : ''
                        }
                    />
                </AgGrid>
            ) : (
                <DatacorLogoSpinner />
            )}
        </>
    );
};

export default SubscribersGrid;
