import React from 'react';
import { AgGridColumn } from 'ag-grid-react';
import Grid from '../../components/Grids/Grid';
import DatacorLogoSpinner from '../../components/DatacorLogoSpinner/DatacorLogoSpinner';
import { currencyFormatter, dateFormatter } from '../../utils/formattingUtils';
import { numberAsStringComparator } from '../../utils/comparisonUtils';
import useContractGrid from './hooks/useContractGrid';
import { GridOptions } from '../../components/Grids/grid.constants';

const ContractGrid = () => {
    const {
        contractList,
        redirectToForm,
        getContractTypeFromId,
        isLoadingAny,
    } = useContractGrid();

    return isLoadingAny ? (
        <DatacorLogoSpinner />
    ) : (
        <Grid
            rowData={contractList}
            gridClass="full-width-grid"
            displayToolbar={true}
            displayCreateNewButton={true}
            onCreateNew={redirectToForm}
            onRowClicked={({ data }) => redirectToForm(data)}
            defaultColDef={GridOptions.sortFilterAndWrapColumns}>
            <AgGridColumn
                minWidth={150}
                field="number"
                headerName="Contract No"
            />
            <AgGridColumn
                minWidth={200}
                field="name"
                headerName="Contract Name"
            />
            <AgGridColumn
                minWidth={250}
                field="description"
                headerName="Description"
            />
            <AgGridColumn
                minWidth={150}
                field="contractTypeId"
                headerName="Contract Type"
                valueFormatter={(val) => getContractTypeFromId(val.value)}
                filterParams={() => {
                    return {
                        valueFormatter: (params: any) => {
                            return getContractTypeFromId(params.value);
                        },
                    };
                }}
            />
            <AgGridColumn
                minWidth={200}
                field="supplier"
                headerName="Provider"
            />
            <AgGridColumn
                minWidth={150}
                field="startDate"
                headerName="Date Start"
                valueFormatter={dateFormatter}
            />
            <AgGridColumn
                minWidth={150}
                field="endDate"
                headerName="Date End"
                valueFormatter={dateFormatter}
            />
            <AgGridColumn
                minWidth={150}
                field="amount"
                headerName="Contract Amount"
                valueFormatter={currencyFormatter}
                comparator={numberAsStringComparator}
            />
        </Grid>
    );
};

export default ContractGrid;
