import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from './containers/Home';
import NotFound from './containers/NotFound';
import Login from './containers/Login/Login';
import Signup from './containers/Login/Signup';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import UnauthenticatedRoute from './components/UnauthenticatedRoute';
import BatchScheduler from './containers/BatchTicketScheduler/BatchScheduler';
import ResetPassword from './containers/Login/ResetPassword/ResetPassword';
import ChangePassword from './containers/Login/ChangePassword';
import ChangeEmail from './containers/Login/ChangeEmail';
import Settings from './containers/Settings/Settings';
import ChangeEmailConfirmation from './containers/Login/ChangeEmailConfirmation';
import ResetPasswordSuccess from './containers/Login/ResetPassword/ResetPasswordSuccess';
import ResetPasswordConfirmation from './containers/Login/ResetPassword/ResetPasswordConfirmation';
import UserPortal from './containers/UserPortal/UserPortal';
import NewPasswordRequired from './containers/Login/NewPasswordRequired';
import ApiDocumentation from './containers/ApiDocumentation/ApiDocumentation';
import AppStreaming from './containers/AppStreaming/AppStreaming';
import MyApps from './containers/MyApps/MyApps';
import PricingAnalysisTool from './containers/PricingAnalysisTool/PricingAnalysisTool';
import PricingConcernDetails from './containers/PricingAnalysisTool/PricingConcernDetails/PricingConcernDetails';
import ApAutomation from './containers/ApAutomation/ApAutomation';
import PricingCustomerProductDetails from './containers/PricingAnalysisTool/PricingCustomerProductDetails/PricingCustomerProductDetails';
import BusinessIntelligence from './containers/BusinessIntelligence/BusinessIntelligence';
import DocumentManagement from './containers/DocumentManagement/DocumentManagement';
import DocumentView from './containers/ApAutomation/DocumentView';
import PricingProposalReport from './containers/PricingAnalysisTool/PricingProposalReport/PricingProposalReport';
import RolesGrid from './containers/Settings/Roles/RolesGrid';
import Analytics from './containers/Analytics/Analytics';
import FeatureFlags from './containers/Settings/FeatureFlags/FeatureFlags';
import WorkflowPortal from './containers/Workflow/WorkflowPortal';
import WorkflowApps from './containers/Workflow/System/WorkflowAppList';
import WorkflowApp from './containers/Workflow/System/WorkflowApp';
import WorkflowTestHarnessPortal from './containers/Workflow/TestHarness/TestHarnessPortal';
import SystemWorkflow from './containers/Workflow/System/SystemWorkflow';
import SystemAction from './containers/Workflow/System/SystemAction';
import FixedAssets from './containers/FixedAssets/FixedAssets';
import FixedAssetFunctionCodesGrid from './containers/FixedAssets/Pages/FixedAssetFunctionCodesGrid';
import FixedAssetFunctionCodesForm from './containers/FixedAssets/Pages/FixedAssetFunctionCodesForm';
import FixedAssetStatusGrid from './containers/FixedAssets/Pages/FixedAssetStatusGrid';
import FixedAssetsStatusForm from './containers/FixedAssets/Pages/FixedAssetsStatusForm';
import FixedAssetCostCodeGrid from './containers/FixedAssets/Pages/FixedAssetsCostCodeGrid';
import FixedAssetsCostCodeForm from './containers/FixedAssets/Pages/FixedAssetsCostCodeForm';
import ContractTypeGrid from './containers/Accounting/ContractTypeGrid';
import ContractTypeForm from './containers/Accounting/ContractTypeForm';
import GeneralLedgerAccountGrid from './containers/Accounting/GeneralLedgerAccountGrid';
import GeneralLedgerAccountForm from './containers/Accounting/GeneralLedgerAccountForm';
import ContractGrid from './containers/Accounting/ContractGrid';
import ContractForm from './containers/Accounting/ContractForm';
import FixedAssetGrid from './containers/FixedAssets/Pages/FixedAssetGrid';
import FixedAssetForm from './containers/FixedAssets/Pages/FixedAssetForm';
import FixedAssetDepreciationMethodStatusGrid from './containers/FixedAssets/Pages/FixedAssetDepreciationMethodStatusGrid';
import FixedAssetDepreciationMethodStatusForm from './containers/FixedAssets/Pages/FixedAssetDepreciationMethodStatusForm';
import FixedAssetDepreciationMethodTypeForm from './containers/FixedAssets/Pages/FixedAssetDepreciationMethodTypeForm';
import FixedAssetDepreciationMethodTypeGrid from './containers/FixedAssets/Pages/FixedAssetDepreciationMethodTypeGrid';
import FixedAssetDepreciationMethodGrid from './containers/FixedAssets/Pages/FixedAssetDepreciationMethodGrid';
import FixedAssetDepreciationMethodForm from './containers/FixedAssets/Pages/FixedAssetDepreciationMethodForm';
import FixedAssetTypeGrid from './containers/FixedAssets/Pages/FixedAssetTypeGrid';
import FixedAssetTypeForm from './containers/FixedAssets/Pages/FixedAssetTypeForm';
import SubscriberForm from './containers/Administration/SubscriberForm';
import SubscribersGrid from './containers/Administration/SubscribersGrid';
import BusinessEntityGrid from './containers/Administration/BusinessEntityGrid';
import BusinessEntityForm from './containers/Administration/BusinessEntityForm';
import UserForm from './containers/Administration/UserForm';
import UsersGrid from './containers/Administration/UsersGrid';
import GroupsForm from './containers/Administration/GroupsForm';
import GroupsGrid from './containers/Administration/GroupsGrid';
import RoleForm from './containers/Administration/RoleForm';
import AdministrationRolesGrid from './containers/Administration/RolesGrid';
import CasMaster from './containers/Formulation/CasMaster';
import FormulationCasForm from './containers/Formulation/Pages/FormulationCasForm';
import ItemMaster from './containers/Formulation/ItemMaster';
import FormulationItemMasterForm from './containers/Formulation/Pages/FormulationItemMasterForm';
import PermissionTree from './containers/Administration/PermissionTree';
import DepartmentCodesGrid from './containers/Administration/DepartmentCodesGrid';
import DepartmentCodeForm from './containers/Administration/DepartmentCodeForm';
import BuildingsGrid from './containers/Administration/BuildingsGrid';
import BuildingsForm from './containers/Administration/BuildingsForm';

export default function Routes() {
    return (
        <Switch>
            <Route exact path="/">
                <Home />
            </Route>
            <UnauthenticatedRoute exact path="/login">
                <Login useLegacy />
            </UnauthenticatedRoute>
            <UnauthenticatedRoute exact path="/login/updated">
                <Login useLegacy={false} />
            </UnauthenticatedRoute>
            <UnauthenticatedRoute exact path="/signup">
                <Signup />
            </UnauthenticatedRoute>
            <UnauthenticatedRoute exact path="/signupConfirmation">
                <Signup />
            </UnauthenticatedRoute>
            <AuthenticatedRoute
                featureFlag="dashboard.nav.apps.scheduler"
                exact
                path="/scheduler">
                <BatchScheduler />
            </AuthenticatedRoute>
            <AuthenticatedRoute
                featureFlag="dashboard.nav.apps.pricingAnalysisTool"
                exact
                path="/pricingAnalysisTool">
                <PricingAnalysisTool />
            </AuthenticatedRoute>
            <AuthenticatedRoute
                featureFlag="dashboard.nav.apps.pricingAnalysisTool"
                exact
                path="/pricingAnalysisTool/pricingConcernDetails">
                <PricingConcernDetails />
            </AuthenticatedRoute>
            <AuthenticatedRoute
                featureFlag="dashboard.nav.apps.pricingAnalysisTool"
                exact
                path="/pricingAnalysisTool/pricingProposalReport">
                <PricingProposalReport />
            </AuthenticatedRoute>
            <AuthenticatedRoute
                featureFlag="dashboard.nav.apps.pricingAnalysisTool"
                exact
                path="/pricingAnalysisTool/pricingCustomerProductDetails/:customerProductId">
                <PricingCustomerProductDetails />
            </AuthenticatedRoute>
            <UnauthenticatedRoute exact path="/login/newPasswordRequired">
                <NewPasswordRequired />
            </UnauthenticatedRoute>
            <UnauthenticatedRoute exact path="/login/reset">
                <ResetPassword />
            </UnauthenticatedRoute>
            <UnauthenticatedRoute exact path="/login/resetConfirmation">
                <ResetPasswordConfirmation />
            </UnauthenticatedRoute>
            <UnauthenticatedRoute exact path="/login/resetSuccess">
                <ResetPasswordSuccess />
            </UnauthenticatedRoute>
            <AuthenticatedRoute
                featureFlag="dashboard.nav.apps.settings"
                exact
                path="/settings">
                <Settings />
            </AuthenticatedRoute>
            <AuthenticatedRoute
                featureFlag="dashboard.nav.apps.userPortal"
                exact
                path="/userPortal">
                <UserPortal />
            </AuthenticatedRoute>
            <AuthenticatedRoute
                featureFlag="dashboard.nav.apps.apiDocumentation"
                exact
                path="/apiDocumentation">
                <ApiDocumentation />
            </AuthenticatedRoute>
            <AuthenticatedRoute
                featureFlag="dashboard.nav.apps.erpAppStreaming"
                exact
                path="/erpAppStreaming">
                <AppStreaming />
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/myApps">
                <MyApps />
            </AuthenticatedRoute>
            <AuthenticatedRoute
                featureFlag="dashboard.nav.apps.businessIntelligence"
                exact
                path="/businessIntelligence">
                <BusinessIntelligence />
            </AuthenticatedRoute>
            <AuthenticatedRoute
                featureFlag="dashboard.nav.apps.documentManagement"
                exact
                path="/documentManagement">
                <DocumentManagement />
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/settings/password">
                <ChangePassword />
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/settings/email">
                <ChangeEmail />
            </AuthenticatedRoute>
            <AuthenticatedRoute
                featureFlag="settings.roles"
                exact
                path="/settings/roles">
                <RolesGrid />
            </AuthenticatedRoute>
            <AuthenticatedRoute
                featureFlag="settings.viewFeatureFlags"
                exact
                path="/settings/featureFlags">
                <FeatureFlags />
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/settings/emailConfirmation">
                <ChangeEmailConfirmation />
            </AuthenticatedRoute>
            <AuthenticatedRoute
                featureFlag="dashboard.nav.apps.apAutomation"
                exact
                path="/apAutomation">
                <ApAutomation />
            </AuthenticatedRoute>
            <AuthenticatedRoute
                featureFlag="dashboard.nav.apps.apAutomation"
                exact
                path="/apAutomation/document/:documentId">
                <DocumentView />
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/accounting/fixedAssets">
                <FixedAssets />
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/accounting/fixedAssets/fixedAsset">
                <FixedAssetGrid />
            </AuthenticatedRoute>
            <AuthenticatedRoute
                exact
                path="/accounting/fixedAssets/fixedAsset/new">
                <FixedAssetForm />
            </AuthenticatedRoute>
            <AuthenticatedRoute
                exact
                path="/accounting/fixedAssets/fixedAsset/:assetId">
                <FixedAssetForm />
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/accounting/fixedAssets/status">
                <FixedAssetStatusGrid />
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/accounting/fixedAssets/status/new">
                <FixedAssetsStatusForm />
            </AuthenticatedRoute>
            <AuthenticatedRoute
                exact
                path="/accounting/fixedAssets/status/:statusId">
                <FixedAssetsStatusForm />
            </AuthenticatedRoute>
            <AuthenticatedRoute
                exact
                path="/accounting/fixedAssets/functionCode">
                <FixedAssetFunctionCodesGrid />
            </AuthenticatedRoute>
            <AuthenticatedRoute
                exact
                path="/accounting/fixedAssets/functionCode/:functionCodeId">
                <FixedAssetFunctionCodesForm />
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/accounting/fixedAssets/costCode">
                <FixedAssetCostCodeGrid />
            </AuthenticatedRoute>
            <AuthenticatedRoute
                exact
                path="/accounting/fixedAssets/costCode/new">
                <FixedAssetsCostCodeForm />
            </AuthenticatedRoute>
            <AuthenticatedRoute
                exact
                path="/accounting/fixedAssets/costCode/:costCodeId">
                <FixedAssetsCostCodeForm />
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/accounting/fixedAssets/type">
                <FixedAssetTypeGrid />
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/accounting/fixedAssets/type/new">
                <FixedAssetTypeForm />
            </AuthenticatedRoute>
            <AuthenticatedRoute
                exact
                path="/accounting/fixedAssets/type/:typeId">
                <FixedAssetTypeForm />
            </AuthenticatedRoute>
            <AuthenticatedRoute
                exact
                path="/accounting/fixedAssets/depreciationMethodStatus">
                <FixedAssetDepreciationMethodStatusGrid />
            </AuthenticatedRoute>
            <AuthenticatedRoute
                exact
                path="/accounting/fixedAssets/depreciationMethodStatus/new">
                <FixedAssetDepreciationMethodStatusForm />
            </AuthenticatedRoute>
            <AuthenticatedRoute
                exact
                path="/accounting/fixedAssets/depreciationMethodStatus/:statusId">
                <FixedAssetDepreciationMethodStatusForm />
            </AuthenticatedRoute>
            <AuthenticatedRoute
                exact
                path="/accounting/fixedAssets/depreciationMethodType">
                <FixedAssetDepreciationMethodTypeGrid />
            </AuthenticatedRoute>
            <AuthenticatedRoute
                exact
                path="/accounting/fixedAssets/depreciationMethodType/new">
                <FixedAssetDepreciationMethodTypeForm />
            </AuthenticatedRoute>
            <AuthenticatedRoute
                exact
                path="/accounting/fixedAssets/depreciationMethodType/:typeId">
                <FixedAssetDepreciationMethodTypeForm />
            </AuthenticatedRoute>
            <AuthenticatedRoute
                exact
                path="/accounting/fixedAssets/depreciationMethod">
                <FixedAssetDepreciationMethodGrid />
            </AuthenticatedRoute>
            <AuthenticatedRoute
                exact
                path="/accounting/fixedAssets/depreciationMethod/new">
                <FixedAssetDepreciationMethodForm />
            </AuthenticatedRoute>
            <AuthenticatedRoute
                exact
                path="/accounting/fixedAssets/depreciationMethod/:methodId">
                <FixedAssetDepreciationMethodForm />
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/accounting/contract">
                <ContractGrid />
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/accounting/contract/new">
                <ContractForm />
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/accounting/contract/:contractId">
                <ContractForm />
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/accounting/contractType">
                <ContractTypeGrid />
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/accounting/contractType/new">
                <ContractTypeForm />
            </AuthenticatedRoute>
            <AuthenticatedRoute
                exact
                path="/accounting/contractType/:contractTypeId">
                <ContractTypeForm />
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/accounting/generalLedgerAccount">
                <GeneralLedgerAccountGrid />
            </AuthenticatedRoute>
            <AuthenticatedRoute
                exact
                path="/accounting/generalLedgerAccount/new">
                <GeneralLedgerAccountForm />
            </AuthenticatedRoute>
            <AuthenticatedRoute
                exact
                path="/accounting/generalLedgerAccount/:accountId">
                <GeneralLedgerAccountForm />
            </AuthenticatedRoute>
            <AuthenticatedRoute
                featureFlag="dashboard.nav.apps.analytics"
                exact
                path="/analytics">
                <Analytics />
            </AuthenticatedRoute>
            <AuthenticatedRoute
                featureFlag="dashboard.nav.apps.workflow"
                exact
                path="/workflow">
                <WorkflowPortal />
            </AuthenticatedRoute>
            <AuthenticatedRoute
                featureFlag="dashboard.nav.apps.workflow"
                exact
                path="/workflow/apps">
                <WorkflowApps />
            </AuthenticatedRoute>
            <AuthenticatedRoute
                featureFlag="dashboard.nav.apps.workflow"
                exact
                path="/workflow/apps/:id">
                <WorkflowApp />
            </AuthenticatedRoute>
            <AuthenticatedRoute
                featureFlag="dashboard.nav.apps.workflow"
                exact
                path="/workflow/systemWorkflows/:id">
                <SystemWorkflow />
            </AuthenticatedRoute>
            <AuthenticatedRoute
                featureFlag="dashboard.nav.apps.workflow"
                exact
                path="/workflow/systemActions/:id">
                <SystemAction />
            </AuthenticatedRoute>
            <AuthenticatedRoute
                featureFlag="dashboard.nav.apps.workflow"
                exact
                path="/workflow-test-harness">
                <WorkflowTestHarnessPortal />
            </AuthenticatedRoute>
            <AuthenticatedRoute
                featureFlag="dashboard.nav.apps.administration"
                exact
                path="/administration/organizations/subscribers">
                <SubscribersGrid />
            </AuthenticatedRoute>
            <AuthenticatedRoute
                exact
                path="/administration/organizations/subscribers/new">
                <SubscriberForm />
            </AuthenticatedRoute>
            <AuthenticatedRoute
                exact
                path="/administration/organizations/subscribers/:id">
                <SubscriberForm />
            </AuthenticatedRoute>
            <AuthenticatedRoute
                featureFlag="dashboard.nav.apps.administration"
                exact
                path="/administration/organizations/business-entities">
                <BusinessEntityGrid />
            </AuthenticatedRoute>
            <AuthenticatedRoute
                featureFlag="dashboard.nav.apps.administration"
                exact
                path="/administration/organizations/business-entities/new">
                <BusinessEntityForm />
            </AuthenticatedRoute>
            <AuthenticatedRoute
                featureFlag="dashboard.nav.apps.administration"
                exact
                path="/administration/organizations/business-entities/:id">
                <BusinessEntityForm />
            </AuthenticatedRoute>
            <AuthenticatedRoute
                featureFlag="dashboard.nav.apps.administration"
                exact
                path="/administration/organizations/users">
                <UsersGrid />
            </AuthenticatedRoute>
            <AuthenticatedRoute
                featureFlag="dashboard.nav.apps.administration"
                exact
                path="/administration/organizations/users/new">
                <UserForm />
            </AuthenticatedRoute>
            <AuthenticatedRoute
                featureFlag="dashboard.nav.apps.administration"
                exact
                path="/administration/organizations/users/:id">
                <UserForm />
            </AuthenticatedRoute>

            <AuthenticatedRoute
                featureFlag="dashboard.nav.apps.administration"
                exact
                path="/administration/organizations/groups">
                <GroupsGrid />
            </AuthenticatedRoute>
            <AuthenticatedRoute
                featureFlag="dashboard.nav.apps.administration"
                exact
                path="/administration/organizations/groups/new">
                <GroupsForm />
            </AuthenticatedRoute>
            <AuthenticatedRoute
                featureFlag="dashboard.nav.apps.administration"
                exact
                path="/administration/organizations/groups/:id">
                <GroupsForm />
            </AuthenticatedRoute>
            <AuthenticatedRoute
                featureFlag="dashboard.nav.apps.administration"
                exact
                path="/administration/organizations/roles">
                <AdministrationRolesGrid />
            </AuthenticatedRoute>
            <AuthenticatedRoute
                featureFlag="dashboard.nav.apps.administration"
                exact
                path="/administration/organizations/roles/new">
                <RoleForm />
            </AuthenticatedRoute>
            <AuthenticatedRoute
                featureFlag="dashboard.nav.apps.administration"
                exact
                path="/administration/organizations/roles/:id">
                <RoleForm />
            </AuthenticatedRoute>
            <AuthenticatedRoute
                featureFlag="dashboard.nav.apps.formulation"
                exact
                path="/formulation/casMaster">
                <CasMaster />
            </AuthenticatedRoute>
            <AuthenticatedRoute exact path="/formulation/casMaster/new">
                <FormulationCasForm />
            </AuthenticatedRoute>
            <AuthenticatedRoute
                featureFlag="dashboard.nav.apps.formulation"
                exact
                path="/formulation/itemMaster">
                <ItemMaster />
            </AuthenticatedRoute>
            <AuthenticatedRoute
                featureFlag="dashboard.nav.apps.formulation"
                exact
                path="/formulation/itemMaster/new">
                <FormulationItemMasterForm />
            </AuthenticatedRoute>

            <AuthenticatedRoute
                featureFlag="dashboard.nav.apps.administration"
                exact
                path="/administration/organizations/permissions">
                <PermissionTree />
            </AuthenticatedRoute>
            <AuthenticatedRoute
                featureFlag="dashboard.nav.apps.administration"
                exact
                path="/administration/organizations/departmentCodes">
                <DepartmentCodesGrid />
            </AuthenticatedRoute>
            <AuthenticatedRoute
                featureFlag="dashboard.nav.apps.administration"
                exact
                path="/administration/organizations/departmentCodes/new">
                <DepartmentCodeForm />
            </AuthenticatedRoute>
            <AuthenticatedRoute
                featureFlag="dashboard.nav.apps.administration"
                exact
                path="/administration/organizations/departmentCodes/:id">
                <DepartmentCodeForm />
            </AuthenticatedRoute>
            <AuthenticatedRoute
                featureFlag="dashboard.nav.apps.administration"
                exact
                path="/administration/organizations/buildings">
                <BuildingsGrid />
            </AuthenticatedRoute>
            <AuthenticatedRoute
                featureFlag="dashboard.nav.apps.administration"
                exact
                path="/administration/organizations/buildings/new">
                <BuildingsForm />
            </AuthenticatedRoute>
            <AuthenticatedRoute
                featureFlag="dashboard.nav.apps.administration"
                exact
                path="/administration/organizations/buildings/:id">
                <BuildingsForm />
            </AuthenticatedRoute>
            {/* Finally, catch all unmatched routes */}
            <Route>
                <NotFound />
            </Route>
        </Switch>
    );
}
