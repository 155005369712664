import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useGetCompaniesByUserIdQuery } from '../../../services/users/users.service';
import {
    useDeleteRoleMutation,
    useGetRolesByBatchCompaniesQuery,
    useUpdateRoleMutation,
} from '../../../services/permissionsAndPreferences/permissionsAndPreferences.service';
import { skipToken } from '@reduxjs/toolkit/query';
import { GridReadyEvent } from 'ag-grid-community';
import { CompanyRole } from '../../../types/User.types';
import { Button, Tooltip } from '@mui/material';
import { StringUtils } from '../../../utils/objectUtils';
import { RootState } from '../../../store';

interface ActionButtonsProps {
    data: CompanyRole;
}

const useRolesGrid = () => {
    const user = useSelector((state: RootState) => state.user);
    const { data: companies } = useGetCompaniesByUserIdQuery(user.userId);
    const {
        data: companiesRoles,
        isLoading: isLoadingRoles,
        isFetching,
    } = useGetRolesByBatchCompaniesQuery(
        companies
            ? companies.filter(
                  (company) => company.companyID === user.companyId
              )
            : skipToken
    );
    const [updateRole] = useUpdateRoleMutation();
    const [deleteRole] = useDeleteRoleMutation();
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [showCreateRoleModal, setShowCreateRoleModal] = useState(false);
    const [selectedRow, setSelectedRole] = useState(null);

    const onGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);

        params.api.sizeColumnsToFit();
    };

    const handleCreateRoleModalOpen = () => {
        setShowCreateRoleModal(true);
    };

    const handleCreateRoleModalClose = () => {
        setShowCreateRoleModal(false);
    };

    const activateRole = async (role: any) => {
        const body = {
            roleId: role.roleId,
            name: role.name,
            status: 'ACTIVE',
            note: role.note || '',
            companyId: role.companyId,
        };

        updateRole(body);
    };

    const deactivateRole = async (role: any) => {
        const body = {
            roleId: role.roleId,
            name: role.name,
            status: 'INACTIVE',
            note: role.note || '',
            companyId: role.companyId,
        };

        updateRole(body);
    };

    const deleteRoleClick = async (role: CompanyRole) => {
        const body = {
            roleId: role.roleId,
            companyId: role.companyId,
        };

        deleteRole(body);
    };

    const onSelectionChanged = () => {
        const selectedRows = gridApi.getSelectedRows();
        setSelectedRole(selectedRows[0]);
    };

    const actionButtons = (props: ActionButtonsProps) => {
        return (
            <>
                <Tooltip title="Delete Role" placement="top">
                    <Button
                        variant="contained"
                        style={{ maxHeight: '20px' }}
                        color="error"
                        size="small"
                        onClick={($event) => deleteRoleClick(props.data)}>
                        Delete
                    </Button>
                </Tooltip>

                {StringUtils.equals(props.data.status, 'INACTIVE') && (
                    <Tooltip title="Activate Role" placement="top">
                        <Button
                            variant="contained"
                            style={{ maxHeight: '20px' }}
                            color="success"
                            size="small"
                            onClick={($event) => activateRole(props.data)}>
                            ACTIVATE
                        </Button>
                    </Tooltip>
                )}

                {StringUtils.equals(props.data.status, 'ACTIVE') && (
                    <Tooltip title="Deactivate Role" placement="top">
                        <Button
                            variant="contained"
                            color="error"
                            size="small"
                            style={{ maxHeight: '20px' }}
                            onClick={($event) => deactivateRole(props.data)}>
                            Deactivate
                        </Button>
                    </Tooltip>
                )}
            </>
        );
    };

    return {
        showCreateRoleModal,
        handleCreateRoleModalClose,
        isLoadingRoles,
        isFetching,
        handleCreateRoleModalOpen,
        companiesRoles,
        onGridReady,
        onSelectionChanged,
        actionButtons,
        selectedRow,
    };
};

export default useRolesGrid;
